import { DialogActions, DialogContent } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import debounce from 'lodash.debounce';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicButton, Dialogbox, PreviewCard } from "../../../../../components";
import { complainantPersonalValidationSchema, PersonalDetailsForm, respondentPersonalValidationSchema } from "../../../../../components/controls/Forms/PersonalDetails";
import { useCaseSpecificData } from "../../../../../hooks/useCaseSpecificData";
import { IComplainantRespondantDetailsResponse } from "../../../../../models/cases/commonCase/ICommonCaseModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import PersonalDetailsModel, { IPersonalDetailsModelToShow } from "../../../../../models/forms/PersonalDetailsModel";
import { RootState } from "../../../../../redux";
import { ConsumerCaseServices } from "../../../../../services/CaseService/ConsumerCaseService/ConsumerCaseServices";
import { FileNewCaseServices } from "../../../../../services/CaseService/ConsumerCaseService/FileNewCaseService/FileNewCaseServices";
import { complainantRespondantDetails, generateComplainantRespondantDataToSubmit } from "../../../../../utils/CaseHelper";
import { flattenArrayToIPreview, makeDropdownOptions } from "../../../../../utils/Helper";
import { IPersonalDetailWithAdvocateDetails } from "../../../../dashboard/components/CasePreview";
import { MasterService } from "../../../../../services/MasterService/MasterService";

/**
 * Renders preview of the entire file new case form
 *
 * @returns JSX.Element
 */


interface NoticePreviewProps {
    filingReferenceNumber: number;
}
const NoticePreview: React.FC<NoticePreviewProps> = ({ filingReferenceNumber }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { caseTypeId } =
        location.state;
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const { complainantLabel, respondentLabel } = useCaseSpecificData({
        caseTypeId,
        setNewFilingReferenceNumber: () => { },
        handleNext: () => { },
    });

    const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
    const [editStep, setEditStep] = useState<number>(0);
    const [advocateOptions, setAdvocateOptions] = useState<IDropdown[]>([]);

    const [isRespondent, setIsRespondent] = useState<number>(0);
    const [crSequenceNumber, setCrSequenceNumber] = useState<number>(0);
    const [complainantDetails, setComplainantDetails] =
        useState<IPersonalDetailWithAdvocateDetails>();
    const [complainantDetailsToShow, setComplainantDetailsToShow] =
        useState<IPersonalDetailsModelToShow>();
    const [respondentDetails, setRespondentDetails] =
        useState<PersonalDetailsModel>();
    const [respondentDetailsToShow, setRespondentDetailsToShow] =
        useState<IPersonalDetailsModelToShow>();
    const [additionalComplainantDetails, setAdditionalComplainantDetails] =
        useState<IPersonalDetailWithAdvocateDetails[]>([]);
    const [additionalComplainantDetailsToShow, setAdditionalComplainantDetailsToShow] =
        useState<IPersonalDetailWithAdvocateDetails[]>([]);
    const [additionalRespondentDetails, setAdditionalRespondentDetails] =
        useState<PersonalDetailsModel[]>([]);
    const [additionalRespondentDetailsToShow, setAdditionalRespondentDetailsToShow] =
        useState<IPersonalDetailWithAdvocateDetails[]>([]);


    const handlePersonalDetailsSubmit = async (data: PersonalDetailsModel) => {
        const caseFillingComplainantRespondantDetails = [generateComplainantRespondantDataToSubmit(data, +isRespondent + 1, crSequenceNumber, filingReferenceNumber, userDetailsData.userId)]
        await FileNewCaseServices.addUpdateComplainantRespondentDetailsV2(caseFillingComplainantRespondantDetails);
        toast.success("Details updated");
        setEditDialogVisible(false);
    };



    const personalDetailsFormik = useFormik({
        initialValues: PersonalDetailsModel.init(),
        validationSchema: isRespondent
            ? respondentPersonalValidationSchema
            : complainantPersonalValidationSchema,
        onSubmit: handlePersonalDetailsSubmit,
    });

    const editElements: React.ReactNode[] = [
        // 
        <PersonalDetailsForm
            key={0}
            formik={personalDetailsFormik}
            advocateOptions={advocateOptions}
            CRsequenceNumber={crSequenceNumber}
            isRespondent={isRespondent}
            complainantLabel={complainantLabel}
            respondentLabel={respondentLabel}
        />,

    ];




    // Gets all complainants and all respondents data (including additional complainant and additional respondent) using API
    const handleComplainantRespondentDetails = useCallback(async () => {
        const response =
            await ConsumerCaseServices.getComplainantRespondentDetail(
                filingReferenceNumber
            );

        if (response) {
            const {
                complainant,
                respondent,
                additionalComplainant,
                additionalRespondent,
            } = await complainantRespondantDetails(response, true);
            setRespondentDetails(respondent);
            // const advocateDetails = getAdvocatePreviewDetails(response.complainantRespondantDetailsResponse.advocateDetails, 1, 0);
            setComplainantDetails({ ...complainant });
            setAdditionalComplainantDetails(additionalComplainant);
            await handleSetComplainantdetails(response)
            setAdditionalRespondentDetails(additionalRespondent);
        }
    }, [filingReferenceNumber]);

    const handleSetComplainantdetails = async (response: IComplainantRespondantDetailsResponse) => {

        const {
            complainantToShow,
            respondentToShow,
            additionalComplainantToShow,
            additionalRespondentToShow
        } = await complainantRespondantDetails(response, true);

        // const advocateDetails = await getAdvocatePreviewDetails(response.complainantRespondantDetailsResponse.advocateDetails, 1, 0);
        setRespondentDetailsToShow({ ...respondentToShow })
        setComplainantDetailsToShow({ ...complainantToShow });
        setAdditionalComplainantDetailsToShow(additionalComplainantToShow);
        setAdditionalRespondentDetailsToShow(additionalRespondentToShow);
    };




    const editPersonalDetails = async (
        data: PersonalDetailsModel,
        CRTypeId: number,
        CRsequenceNumber: number
    ) => {
        if (data) {
            await personalDetailsFormik.setValues(data);
            setIsRespondent(CRTypeId);
            setCrSequenceNumber(CRsequenceNumber);
            setEditStep(1);
            setEditDialogVisible(true);
        }
    };



    const closeEditDialog = () => {
        setEditDialogVisible(false);

        setAdvocateOptions([]);
        personalDetailsFormik.resetForm();
    };
    // const formikSubmit = async () => {
    //     switch (editStep) {

    //         case 0:
    //             await personalDetailsFormik.submitForm();
    //             if (personalDetailsFormik.isValid) {
    //                 handleComplainantRespondentDetails();
    //             }

    //     }
    // };
    const formikSubmit = async () => {
        await personalDetailsFormik.submitForm()

        handleComplainantRespondentDetails();



    }

    const handleGetAdvocateQuery = async (query: string) => {
        try {
            const advocateList = await MasterService.getAllAdvocatesbySearch(query);
            const listOfAdvocates = advocateList.slice(0, 5000).map((advocates) => ({
                ...advocates,
                advocateList: `${advocates.advocateNameEn}, ${advocates?.mobileNumber !== -1 ? advocates.mobileNumber : "N/A"}`,
            }))
            setAdvocateOptions(makeDropdownOptions(listOfAdvocates, "advocateList", "advocateId"));
        } catch (error) {
            console.error('Error fetching advocate options:', error);
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetAdvocateQuery = useCallback(
        debounce((query) => {
            handleGetAdvocateQuery(query);
        }, 300),
        []
    );

    useEffect(() => {
        if (personalDetailsFormik.values.advocateText?.length as number > 4) {
            debouncedGetAdvocateQuery(personalDetailsFormik.values.advocateText);
        }
    }, [personalDetailsFormik.values.advocateText, debouncedGetAdvocateQuery]);

    useEffect(() => {
        handleComplainantRespondentDetails();
    }, [handleComplainantRespondentDetails]);


    return (
        <section className="section margin-top-20">
            <div className="container-fluid">
                <form>
                    <Dialogbox
                        title="Edit"
                        open={editDialogVisible}
                        onClose={closeEditDialog}
                        maxWidth="lg"
                    >
                        <DialogContent>
                            {editElements.map((element: React.ReactNode, index: number) => {
                                const id = 0;
                                return (
                                    <div key={id}
                                    >
                                        {element}
                                    </div>
                                );
                            })}
                        </DialogContent>
                        <DialogActions>
                            {/* {editStep === 1 && crSequenceNumber > 0 && (
                <BasicButton
                  text={t("BtnText.Delete")}
                  onClick={handlePersonalDetailsDelete}
                  variant="outlined"
                />
              )} */}
                            <BasicButton
                                text={t("BtnText.Submit")}
                                onClick={formikSubmit}
                                variant="contained"
                                autoFocus
                            />
                        </DialogActions>
                    </Dialogbox>
                </form>

                {
                    // Complainant
                    complainantDetails && complainantDetailsToShow && (
                        <PreviewCard
                            className="margin-top-25"
                            heading={t("Label.ComplainantDetails")}
                            items={flattenArrayToIPreview(complainantDetailsToShow)}
                            edit={() => editPersonalDetails(complainantDetails, 1, 0)}
                        />
                    )
                }
                {
                    // Respondent
                    respondentDetails && respondentDetailsToShow && (
                        <PreviewCard
                            className="margin-top-25"
                            heading={t("Label.OppositeParty") + " " + t("Label.Details")}
                            items={flattenArrayToIPreview(respondentDetailsToShow)}
                            edit={() => editPersonalDetails(respondentDetails, 2, 0)}
                        />
                    )
                }
                {
                    // Additional Complainant
                    !!additionalComplainantDetailsToShow?.length &&
                    additionalComplainantDetailsToShow.length > 0 &&
                    additionalComplainantDetailsToShow.map(
                        (value: PersonalDetailsModel, index: number) => {
                            return (
                                <PreviewCard
                                    key={value.mobileNumber}
                                    className="margin-top-25"
                                    heading={
                                        t("Label.AdditionalComplainantDetails") + ` ${index + 1}`
                                    }
                                    items={flattenArrayToIPreview(value)}
                                    edit={() => editPersonalDetails(additionalComplainantDetails[index] as PersonalDetailsModel, 1, index + 1)}
                                />
                            );
                        }
                    )
                }
                {
                    // Additional Respondent
                    !!additionalRespondentDetailsToShow?.length &&
                    additionalRespondentDetailsToShow.length > 0 &&
                    additionalRespondentDetailsToShow.map(
                        (value: PersonalDetailsModel, index: number) => {
                            return (
                                <PreviewCard
                                    key={value.mobileNumber}
                                    className="margin-top-25"
                                    heading={
                                        t("Label.AdditionalOppositePartyDetails") +
                                        ` ${index + 1}`
                                    }
                                    items={flattenArrayToIPreview(value)}
                                    edit={() => editPersonalDetails(additionalRespondentDetails[index] as PersonalDetailsModel, 2, index + 1)}
                                />
                            );
                        }
                    )
                }
                {


                }



            </div>
        </section>
    );
};

export default NoticePreview;
