import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useEffect } from "react";
import { ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Textbox } from "../../../../../../components";
import { FaSaRpCaveatCaseDetailsViewModel } from "../../../../../../models/cases/appealTypeCase/CaseDetailsViewModel";
import ConfonetTextEditorTiny from "../../../../../../components/controls/ConfonetTextEditorTinyMCE";
import FaBankDetails from "./FaBankDetails";
import { ValidationRegExp } from "../../../../../../utils/ValidationRegExp";
import { dropdownValidationSchema } from "../../../../../../utils/Helper";
import { caseTypeEnum } from "../../../../../../utils/Enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux";
import { useLocation } from "react-router-dom";


interface IFaSaRpCaveatCaseDetailsProps {
    formik: FormikProps<FaSaRpCaveatCaseDetailsViewModel>,
}

export const commonFaSaRpCaveatCaseDetailsValidationSchema = Yup.object({
    caseSummary: Yup.string()
        .max(2000, "Max 2000 charactera are allowed"),
})

export const FaSaRpCaveatCaseDetails = ({ formik }: IFaSaRpCaveatCaseDetailsProps) => {

    const { t } = useTranslation();
    const location = useLocation();
    const caseTypeId = location.state?.caseTypeId;


    const editorValueChange = (value: string) => {
        formik.setFieldValue("caseSummary", value);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="margin-top-25">
                <ConfonetCard>
                    <ConfonetCardHeader>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="card-title card-primary-header-title">{t("Label.CaseDetails")}</div>
                            {location.state?.caveatDetails &&
                                <div className="card-title card-primary-header-title">{`${t('Label.Caveatorname')}-(${location.state?.caveatDetails.caseNumber})`}</div>
                            }
                        </div>
                    </ConfonetCardHeader>
                    <ConfonetCardBody>
                        {caseTypeId === caseTypeEnum.FirstAppeal ? (
                            <FaBankDetails formik={formik} />
                        ) : (
                            <div className="row">
                                <div className="col-lg-12">
                                    <ConfonetTextEditorTiny
                                        id="caseDetails"
                                        value={formik.values.caseSummary}
                                        onEditorChange={editorValueChange}
                                    />
                                </div>
                            </div>
                        )}

                    </ConfonetCardBody>
                </ConfonetCard>
            </div>
        </>
    )
}