import React from "react";
import {
	GenerateNoticePersonalStatusViewModel,
	GenerateNoticeViewModel,
} from "../../models/IssueNotice/IssueNoticeModel";
import { IFindUserResponse } from "../../models/user/LoginModel";

interface INoticeViewTemplateProps {
	data: GenerateNoticeViewModel;
	caseNumber: string;
	authorisedSignatory: string;
	notice: string;
	userDetailsData: IFindUserResponse;
}

/** Renders daily order entry template component */
const NoticeViewTemplate = ({
	data,
	caseNumber,
	authorisedSignatory,
	notice,
	userDetailsData,
}: INoticeViewTemplateProps): React.ReactElement => {


	return (
		<div className="table-page">
			<table className="table-head-container" >
				<colgroup>
					<col className="col-style-on" />
					<col className="col-style-tw" />
				</colgroup>
				<tbody>
					<tr className="tableHeight">
						<th className="table-h-style" colSpan={2}>
							<div>
								<span className="span-changeb">
									{userDetailsData.userName}
								</span>
							</div>
						</th>
					</tr>
					<tr className="tableHeight">
						<th className="table-h-style" colSpan={2}>
							<div>
								<span className="span-changeb">
									Under the Consumer Protection Act, 2019
								</span>
							</div>
						</th>
					</tr>
					<tr className="table-ro-style" >
						<td className="table-column-style">
							<div>
								{!!userDetailsData.mobileNumber && (
									<div>Telephone No.: {userDetailsData.mobileNumber}</div>
								)}

								{!!userDetailsData.emailId && (
									<div>Email: {userDetailsData.emailId}</div>
								)}
							</div>
						</td>
						<td className="table-column-style">
							<div>
								{!!userDetailsData.houseAppartmentBuilding && (
									<div>{userDetailsData.houseAppartmentBuilding}</div>
								)}
								{!!userDetailsData.blockStreetMohalla && (
									<div>{userDetailsData.blockStreetMohalla}</div>
								)}
								{!!userDetailsData.landmarkLocality && (
									<div>{userDetailsData.landmarkLocality}</div>
								)}
								{!!userDetailsData.postalPinCode && (
									<div>{userDetailsData.postalPinCode}</div>
								)}
							</div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div>
								<div className="text-center">
									<strong>Consumer Complainant No. {caseNumber}</strong>
								</div>
							</div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary">
							<div className="text-left" >
								<span className="span-changebg" >
									{data.complainantStatuses?.[0]?.name}
								</span>
							</div>
						</td>
						<td className="border-left-0">
							<div className="text-right" >
								<span className="span-changebg" >
									.....Petitioner/Appellant
								</span>
							</div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary text-center" colSpan={2}>
							<div>
								<span className="span-changebg" >Versus</span>
							</div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary">
							<div className="text-left">
								<span className="span-changebg" >
									{data.respondentStatuses?.[0]?.name}
								</span>
							</div>
						</td>
						<td className="border border-secondary">
							<div className="text-right">
								<span className="span-changebg" >
									.....Opposite Party/Respondent(s)
								</span>
							</div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div>
								<span className="span-changebg" >To</span>
							</div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div className="text-center">
								<strong>Complainant / Petitioner / Appellant Name:</strong>
							</div>
						</td>
					</tr>
					<tr className="complainantStatus">
						<td className="border border-secondary" colSpan={2}>
							<ol className="m-0 p-2">
								{data.complainantStatuses.map(
									(status: GenerateNoticePersonalStatusViewModel) => {
										return status.status && <li>{status.name}</li>;
									}
								)}
							</ol>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div className="text-center" >
								<strong>
									Complainant / Petitioner / Appellant Advocate Name:
								</strong>
							</div>
						</td>
					</tr>
					<tr className="complainantAdvocateStatus">
						<td colSpan={2}>
							<ol className="m-0 p-2">
								{data.complainantStatuses.map(
									(status: GenerateNoticePersonalStatusViewModel) => {
										return (
											status.advocate?.status && <li>{status.advocate.name}</li>
										);
									}
								)}
							</ol>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="table-row-style" colSpan={2}>
							<div className="text-center" >
								<strong>Opposite Party: Respondent Name:</strong>
							</div>
						</td>
					</tr>
					<tr className="respondentStatus">
						<td colSpan={2}>
							<ol className="m-0 p-2">
								{data.respondentStatuses.map(
									(status: GenerateNoticePersonalStatusViewModel) => {
										return status.status && <li>{status.name}</li>;
									}
								)}
							</ol>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div className="text-center" >
								<strong>Opposite Party: Respondent Advocate Name:</strong>
							</div>
						</td>
					</tr>
					<tr className="respondentAdvocateStatus">
						<td colSpan={2}>
							<ol className="m-0 p-2">
								{data.respondentStatuses.map(
									(status: GenerateNoticePersonalStatusViewModel) => {
										return (
											status.advocate?.status && <li>{status.advocate.name}</li>
										);
									}
								)}
							</ol>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div className="text-center" >
								<span className="span-changebg" >
									<strong>
										<u>Notice</u>
									</strong>
								</span>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div dangerouslySetInnerHTML={{ __html: notice }}></div>
						</td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}></td>
					</tr>
					<tr className="tableHeight">
						<td className="border border-secondary" colSpan={2}>
							<div className="text-right">
								<span className="span-changebg" >
									{authorisedSignatory}
								</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default NoticeViewTemplate;
