import { DateTime } from "luxon";
import { IDropdown } from "../../common/IDropdown";

export class InterlocutoryApplicationCaseDetailsViewModel {
  constructor(
    public reason: IDropdown,
    public caseSummary: string,
    public dateOfOrder: DateTime
  ) {
    this.reason = reason;
    this.caseSummary = caseSummary;
    this.dateOfOrder = dateOfOrder;
  }
  public static init = () =>
    new InterlocutoryApplicationCaseDetailsViewModel(
      { label: "", value: "" },
      "",
      DateTime.now()
    );
}

export interface IAReasonsListResponse {
  caseReasonTypeId: number;
  caseReasonTypeDisplayTextEn: string;
  activeStatus: number;
}

export interface IInterlocutoryApplicationCaseDetailsRequest {
  previousFilingReferenceNumber: number;
  previousCaseNumber?: string;
  previousCaseTypeId: number;
  caseFiledBy: number;
  previousCommissionId: number;
  caseReasonTypeId?: number;
  caseReasonTypeDisplayText?: string;
  remarks?: string;
  clientIpAddress?: string;
  caseTypeId: number
}

export interface IIaMaUpdateCaseDetailRequest {
  filingReferenceNumber: number;
  caseReasonTypeId: number;
  caseReasonTypeDisplayText: string;
  remarks: string;
  clientIpAddress: string;
  lastUpdationDoneBy: number;
}

export interface IIaMaUpdateCaseDetailResponse {
  fillingReferenceNumber: number;
  fileApplicationNumber: any;
  caseNumber: string;
  caseTypeId: number;
  filedInComissionId: number;
  dateOfNextHearing: string;
  claimAmount: number;
  paidAmount: number;
  compensesionAamount: number;
  stateIdOfPlaceOfCause: number;
  districtIdOfPlaceOfCause: number;
  dateOfCause: string;
  previousFillingReferenceNumber: number;
  previousCaseNumber: string;
  previousCaseTypeId: number;
  previousCommissionId: number;
  previousCaseOrderNumber: number;
  caseStageId: number;
  caseFiledBy: number;
  caseFilingDate: string;
  caseCategoryId: number;
  firstOriginalFillingReferenceNumber: number;
  firstOriginalCaseNumber: string;
  bunchCasesDetails: string;
  transactionId: number;
  recieptId: number;
  paymentAmount: number;
  paymentModeTypeId: number;
  paymentInstitutionId: number;
  paymentInstitutionReferenceNumber: number;
  paymentInstitutionResponseStatusId: number;
  offlineTransactionTypeId: number;
  offlineTransactionReferenceNumber: number;
  offlineTransactionBankId: number;
  offlineTransactionBranchId: number;
  offlineTransactionIfscCode: number;
  lastUpdationDoneBy: number;
  lastUpdationDateTime: string;
  remarks: string;
  clientIpAddress: string;
  casefillingStageId: number;
  createdOnDateTime: string;
  activeStatus: number;
  previousCaseOrderDate: string;
  caseReasonTypeId: number;
  caseReasonTypeDisplayText: string;
}

export interface IInterlocutoryApplicationCaseDetailsResponse {
  filingReferenceNumber: number
  fileApplicationNumber: any
  caseNumber: any
  caseTypeId: number
  filedInCommissionId: number
  dateOfNextHearing: any
  claimAmount: number
  paidAmount: number
  compensationAmount: any
  stateIdOfPlaceOfCause: number
  districtIdOfPlaceOfCause: number
  dateOfCause: any
  previousFilingReferenceNumber: number
  destinationCommissionId: any
  admissionStatusId: any
  admissionDate: any
  caseStageId: number
  caseFilingFeePaymentDetailsId: any
  refundStatus: any
  caseFiledBy: any
  caseFilingDate: any
  caseCategoryId: number
  firstOriginalFilingReferenceNumber: any
  firstOriginalCaseNumber: any
  bunchCasesDetails: any
  sourceApplicationTypeId: any
  caseReasonTypeId: number
  updatedBy: any
  updatedAt: string
  remarks: any
  clientIpAddress: any
  createdAt: string
  activeStatus: boolean
  caseCategoryIdLevel02: any
  caseCategoryIdLevel01: any
  defectTypeId: any
}

export interface IIACaseStageListRequest {
  caseNumber: string;
  caseTypeId: number;
}

export interface IIACaseStageListPayload {
  caseNumber: string;
}

export interface IIACaseStageListResponse {
  caseNumber: string;
  caseStageId: number;
  caseStageName: string;
}

export interface IIaCaseStageSubmitRequest {
  caseNumber: string;
  caseStageId: number;
}

export interface IICaseStageSubmitResponse {
  fillingReferenceNumber: number;
  fileApplicationNumber: null;
  caseNumber: string;
  caseTypeId: number;
  filedInComissionId: number;
  dateOfNextHearing: string;
  claimAmount: number;
  paidAmount: number;
  compensesionAamount: null;
  stateIdOfPlaceOfCause: number;
  districtIdOfPlaceOfCause: number;
  dateOfCause: string;
  previousFillingReferenceNumber: null;
  previousCaseNumber: null;
  previousCaseTypeId: null;
  previousCommissionId: null;
  previousCaseOrderNumber: null;
  caseStageId: number;
  caseFiledBy: number;
  caseFilingDate: string;
  caseCategoryId: number;
  firstOriginalFillingReferenceNumber: null;
  firstOriginalCaseNumber: null;
  bunchCasesDetails: null;
  transactionId: null;
  recieptId: null;
  paymentAmount: null;
  paymentModeTypeId: null;
  paymentInstitutionId: null;
  paymentInstitutionReferenceNumber: null;
  paymentInstitutionResponseStatusId: null;
  offlineTransactionTypeId: null;
  offlineTransactionReferenceNumber: null;
  offlineTransactionBankId: null;
  offlineTransactionBranchId: null;
  offlineTransactionIfscCode: null;
  lastUpdationDoneBy: null;
  lastUpdationDateTime: null;
  remarks: string;
  clientIpAddress: string;
  casefillingStageId: number;
  createdOnDateTime: string;
  activeStatus: number;
  previousCaseOrderDate: null;
  caseReasonTypeId: null;
  caseReasonTypeDisplayText: null;
}
