import React from 'react';
import { ConfonetCard, ConfonetCardBody, ConfonetCardHeader } from '../../../../components/controls/ConfonetCard';
import { Typography } from '@mui/material';
import { BasicBreadcrumbs } from '../../../../components/controls/BasicBreadcrumbs';
import { JUDGE_ACT_RULES, JUDGE_DASHBOARD } from '../../../../navigation/CONSTANTS';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../../assets/images/back.png'
import homeIcon from '../../../../assets/images/home.png'

const ActRulesCombined = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleHomeClick = () => {
      navigate(JUDGE_DASHBOARD); 
    };
  
    const handleBackClick = () => {
      navigate(-1);
    };

    const combinedData = [
        {
            header: 'Consumer Protection Act (CPA)',
            cases: [
                { to: 'https://ncdrc.nic.in/bare_acts/Consumer%20Protection%20Act-1986.html', title: 'Consumer Protection Act (CPA), 1986' },
                { to: 'https://ncdrc.nic.in/bare_acts/1_1_2.html', title: 'Commentary on Consumer Protection Act, 1986' },
                { to: 'https://ncdrc.nic.in/bare_acts/CPA2019.pdf', title: 'The Consumer Protection Act, 2019' },
            ],
        },
        {
            header: 'Consumer Protection Rules (CPR)',
            cases: [
                { to: 'https://ncdrc.nic.in/bare_acts/1987.html', title: 'Consumer Protection Rules, 1987' },
                { to: 'https://ncdrc.nic.in/bare_acts/1_4.html', title: 'Consumer Welfare Fund Rules, 1992' },
                { to: 'https://ncdrc.nic.in/bare_acts/1_29.html', title: 'Bureau of Indian Standards Rules, 1991' },
            ],
        },
        {
            header: 'Regulations',
            cases: [
                { to: 'https://ncdrc.nic.in/bare_acts/Regulations2005.html', title: 'Consumer Protection Regulations, 2005' },
                { to: 'https://ncdrc.nic.in/bare_acts/Consumer%20Protection%20Regulation.pdf', title: 'Consumer Protection (non-adv) Regulation 2014' },
                { to: 'https://ncdrc.nic.in/bare_acts/ConsumerProtectionRegulations2018.pdf', title: 'Consumer Protection Regulations, 2018' },
                { to: 'https://ncdrc.nic.in/bare_acts/ADMRegulations2020.pdf', title: 'ADM Control Over SC DC Regulations, 2020' },
                { to: 'https://ncdrc.nic.in/bare_acts/CCPRegulations2020.pdf', title: 'CCP Regulations, 2020' },
                { to: 'https://ncdrc.nic.in/bare_acts/MediationRegulations2020.pdf', title: 'Mediation Regulations, 2020' },
            ],
        },
        {
            header: 'Other Act and Rules',
            cases: [
                { to: 'https://ncdrc.nic.in/bare_acts/1_37.html', title: 'Supreme Court Rules Relevant to CPA, 1986' },
                { to: 'https://ncdrc.nic.in/bare_acts/notification12012021.pdf', title: 'NCDRC Notification Dt. 12.01.2021' },
                { to: 'https://ncdrc.nic.in/rti.html', title: 'RTI Act, 2005' },
            ],
        },
    ];

    if (!Array.isArray(combinedData) || combinedData.length === 0) {
        return <Typography variant="body2">No data available</Typography>;
    }

    return (<>
        <div>
            <div className="col-lg-12 breadcrum-dis">
                <BasicBreadcrumbs className='breadcrum-dashboard'
                    items={[
                        { to: JUDGE_DASHBOARD, label: t("Breadcrumb.Dashboard") },
                        { to: '', label: t("Breadcrumb.Act&rules") },
                    ]}
                />
            </div>
            <div className='font-20-bold' style={{marginLeft:"3%"}}>Act & Rules</div>
            {combinedData.map((section, index) => {
                if (!section || !section.header || !Array.isArray(section.cases)) {
                    return null;
                }

                return (<>
                    <div className="wrapped-card-container">
                    <div key={index}>
                        <ConfonetCard>
                            <ConfonetCardHeader>
                                <div className="act-rules-header">{section.header}</div>
                            </ConfonetCardHeader>
                            <ConfonetCardBody>
                                <div className="row">
                                    {section.cases.length > 0 ? (
                                        section.cases.map((cases) => (
                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-3" key={cases.title}>
                                                <a
                                                    className="text-decoration-none"
                                                    href={cases.to}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <div className="card h-100" style={{ width: '100%', marginBottom: '10px' }}>
                                                        <div className="card-body">
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '15px',
                                                                    fontWeight: 600,
                                                                    lineHeight: '18.15px',
                                                                    textAlign: 'left',
                                                                    color: '#001233',
                                                                }}
                                                            >
                                                                {cases.title}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                    ) : (
                                        <Typography variant="body2">No cases available</Typography>
                                    )}
                                </div>
                            </ConfonetCardBody>
                        </ConfonetCard>
                    </div>
                    </div>
                    </>
                );
            })}
        </div>
        <div className='home-back-buttons-rules'>
        <div onClick={handleHomeClick} style={{ cursor: 'pointer' }}>
            <img src={homeIcon} alt="Home" className='button_back' />
        </div>
        <div onClick={handleBackClick} style={{ cursor: 'pointer' }}>
            <img src={backIcon} alt="Back" className='button_back'/>
        </div>
        </div>
        </>
    );
};

export default ActRulesCombined;
