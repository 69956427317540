import { DateTime } from "luxon";
import { IDropdown } from "../common/IDropdown";

/**
 * important notes search
 */
export class ImportantNotesSearchViewModel {
    constructor(
        public caseNumber: string,
        public date: DateTime,
        public typeId: string
    ) {
        this.caseNumber = caseNumber;
        this.date = date;
        this.typeId = typeId;
    }

    static readonly init = () => new ImportantNotesSearchViewModel("", DateTime.now(), "1");
};

export class ImportantNotesCreateViewModel {

    constructor(
        public title: string,
        public date?: DateTime,
        public caseNumber?: IDropdown,
        public notes?: string,
        public typeId?: "1" | "2",
        public noteId?: number,
        public document: File[] = []

    ) {
        this.title = title;
        this.date = date;
        this.caseNumber = caseNumber;
        this.notes = notes;
        this.typeId = typeId;
        this.noteId = noteId;
        this.document = document;
    }

    static readonly init = (searchedCaseNumber?: string) => new ImportantNotesCreateViewModel("", DateTime.now(), { label: "", value: "" }, "", "1", 0, []);
}

export class IsaveAndUpdateNotesRequestModel {
    constructor(
        public userId: number,
        public caseNumber: string,
        public filingReferenceNumber: number,
        public notes: string,
        public typeId: "1" | "2",
        public title: string,
        public date?: DateTime,
    ) {
        this.userId = userId;
        this.caseNumber = caseNumber;
        this.filingReferenceNumber = filingReferenceNumber;
        this.notes = notes;
        this.typeId = typeId;
        this.title = title;
        this.date = date;
    }
    static readonly init = () => new IsaveAndUpdateNotesRequestModel(0, "", 0, "", "1", "", DateTime.now());
}


export interface IsaveAndUpdateNotesResponse {
    status: number;
    noteId: number,
    caseNumber: string,
    filingReferenceNumber: number,
    dateOfHearing: string,
    createdBy: number,
    updatedBy: number,
    createdAt: any,
    updatedAt: any,
    notes: string,
    documentPath: any,
    typeId: number,
    activeStatus: boolean,
    title: string,
    document?: File[],
    date?: string
}

export interface IgetNoteListResponse {
    noteId: number,
    caseNumber: string,
    filingReferenceNumber: number,
    createdBy: number,
    updatedBy: number,
    createdAt: any,
    updatedAt: any,
    notes: string,
    documentPath: any,
    typeId: '1' | '2',
    activeStatus: boolean,
    title: string,
    document?: File[],
    date?: string
}

export interface IgetNotesList {
    noteId: number,
    caseNumber: string,
    filingReferenceNumber: number,
    createdBy: number,
    updatedBy: number,
    createdAt: any,
    updatedAt: any,
    notes: string,
    documentPath: any,
    typeId: number,
    activeStatus: boolean,
    title: string,
    document?: File[],
    date?: string
}

export interface IgetCaseNotesResponse {
    noteId: number,
    caseNumber: string,
    filingReferenceNumber: number,
    documentNames: {
        fileContent: string,
        fileName: string
    }[],
    createdBy: number,
    updatedBy: number,
    createdAt: any,
    updatedAt: any,
    notes: string,
    documentPath: any,
    title: string,
    typeId: '1' | '2',
    activeStatus: boolean,

}
