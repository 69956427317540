import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BasicButton } from "../../../../../../components";
import { IGetCaseListResponse } from "../../../../../../models/cases/consumerCases/IConsumerCaseModel";
import { ADVOCATE_APPEAL_TYPE_CASE_STEPPERFORM, ADVOCATE_FILENEWCASE_STEPPERFORM, CONSUMER_APPEAL_TYPE_CASE_STEPPERFORM, CONSUMER_FILENEWCASE_STEPPERFORM } from "../../../../../../navigation/CONSTANTS";
import { RootState } from "../../../../../../redux";
import { CaseServices } from "../../../../../../services/CaseService/CaseServices";
import { caseStages, caseTypeEnum } from "../../../../../../utils/Enum";
import { createHeaderWithBreaks, navigateToDashboardPath, navigateToDifferentPath } from "../../../../../../utils/Helper";
import { CaseList } from "../../../CaseList";

interface IDraftCaseRows {
	id: number;
	referenceNumber: number;
	complainantNameEn: string;
	respondantNameEn: string;
	casefillingStageId: number;
	casefillingStageNameEn: string;
	dateOfFilling: string;
}

export const DraftCaseList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { userDetailsData } = useSelector((state: RootState) => state.user);

	const [caseListRows, setCaseListRows] = useState<IDraftCaseRows[]>([]);

	const caseListColumns: GridColDef[] = [
		{ field: "id", headerName: t("Label.No"), flex: 0.25 },
		{ field: "referenceNumber", headerName: t("Label.ReferenceDiaryNumber"), flex: 1 },
		{ field: "complainantNameEn", headerName: createHeaderWithBreaks([t("Label.ComplainantOrAppellant"), t("Label.Petitioner")]), description: t("Label.Complainant/Appellant/Petitioner"), flex: 1.5, headerClassName: 'multiline-header' },
		{ field: "respondantNameEn", headerName: createHeaderWithBreaks([t("Label.OppositeParty"), t("Label.Respondent")]), description: t("Label.OppositeParty/Respondent"), flex: 1, headerClassName: 'multiline-header' },
		{ field: "caseType", headerName: t("Label.CaseType"), flex: 1 },
		{ field: "casefillingStageNameEn", headerName: t("Label.CaseStage"), flex: 1 },
		{ field: "dateOfFilling", headerName: t("Label.FilingDate"), flex: 1 },
		{
			field: "Action",
			headerName: t("Label.Action"),
			minWidth: 70,
			flex: 1,
			renderCell: (params) => {
				return (
					<BasicButton
						text={t("BtnText.Edit")}
						size="small"
						color="secondary"
						variant="contained"
						onClick={() => {
							const state = {
								filingReferenceNumber: params.row.referenceNumber,
								caseTypeId: params.row.caseTypeId,
								isDraft: true
							}
							if (params.row.caseTypeId === caseTypeEnum.ConsumerCase) {
								navigate(navigateToDifferentPath(userDetailsData.roleId, CONSUMER_FILENEWCASE_STEPPERFORM, ADVOCATE_FILENEWCASE_STEPPERFORM), { state })
							}
							else {
								navigate(navigateToDifferentPath(userDetailsData.roleId, CONSUMER_APPEAL_TYPE_CASE_STEPPERFORM, ADVOCATE_APPEAL_TYPE_CASE_STEPPERFORM), { state })
							}
						}}
					/>
				)
			}
		},
	]

	// get the list of draft cases
	const handleGetCaseList = useCallback
		(async () => {
			const caseListResponse = await CaseServices.getAllCaseList(userDetailsData?.commissionId ?? 0, userDetailsData.roleId, userDetailsData.userId, [caseStages.draft]);
			setCaseListRows(caseListResponse.map((value: IGetCaseListResponse, index: number) => {
				return {
					id: index + 1,
					referenceNumber: value.referenceNumber,
					complainantNameEn: value.complainantNameEn,
					respondantNameEn: value.respondantNameEn,
					caseType: value.caseType,
					casefillingStageId: value.casefillingStageId,
					casefillingStageNameEn: value.caseStageNameEn,
					caseTypeId: value.caseTypeId,
					dateOfFilling: DateTime.fromFormat(
						value.dateOfFilling,
						"yyyy-MM-dd"
					).toFormat("dd-MM-yyyy")
				}
			}) ?? []);
		}, [userDetailsData?.commissionId, userDetailsData.roleId, userDetailsData.userId]);

	useEffect(() => {
		handleGetCaseList()
	}, [handleGetCaseList]);

	const breadCrumbs = [
		{ to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
		{ to: '', label: t("Breadcrumb.DraftCases") },
	]

	return (
		<>
			<CaseList
				caseListRows={caseListRows}
				caseListColumns={caseListColumns}
				breadCrumbs={breadCrumbs}
			/>
		</>
	)
}