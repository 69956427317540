import { AxiosResponse } from "axios";
import { CASE_FILING_SERVICE } from "../../../CONSTANTS";
import { postCall, putCall } from "../../../api";
import { IGetPreCaseDetailsPayload, IGetPreDetailsResponse } from "../../../../models/cases/appealTypeCase/PreDetailsModel";
import {
    IIACaseStageListRequest,
    IIACaseStageListResponse,
    IIaMaUpdateCaseDetailRequest,
    IIaMaUpdateCaseDetailResponse,
    IInterlocutoryApplicationCaseDetailsRequest,
    IInterlocutoryApplicationCaseDetailsResponse
} from "../../../../models/cases/interlocutoryApplicationCase/InterlocutoryApplicationCaseDetailsModel";

export class InterlocutoryCaseService {
    /**
   * Call an API to get pre case details
   * @param data IGetPreCaseDetailsPayload
   * @returns Promise<IGetPreDetailsResponse>
   */
    public static readonly getPreCaseDetails = async (data: IGetPreCaseDetailsPayload): Promise<IGetPreDetailsResponse> => {
        const response: AxiosResponse<IGetPreDetailsResponse> = await postCall(
            `${CASE_FILING_SERVICE}/getLowerCommissionCaseDetail`,
            data
        );
        return response?.data;
    };

    /**
    * Call an API to save case details
    * @param data IInterlocutoryApplicationCaseDetailsRequest
    * @returns Promise<IInterlocutoryApplicationCaseDetailsResponse>
    */
    public static readonly saveCaseDetailsSubmit = async (data: IInterlocutoryApplicationCaseDetailsRequest): Promise<IInterlocutoryApplicationCaseDetailsResponse> => {
        const response: AxiosResponse<IInterlocutoryApplicationCaseDetailsResponse> = await postCall(
            `${CASE_FILING_SERVICE}/saveCaseForIaMaTa`,
            data
        );
        return response?.data;
    };

    /**
     * Call an API to get the list of IA case Stage List
     * @param data IIACaseStageListRequest
     * @returns Promise<IIACaseStageListResponse[]>
     */
    public static readonly getIaCaseStageList = async (data: IIACaseStageListRequest): Promise<IIACaseStageListResponse[]> => {
        const response: AxiosResponse<IIACaseStageListResponse[]> = await postCall(
            `${CASE_FILING_SERVICE}/getCaseStageForIACase`,
            data
        );

        return response?.data;
    }

    /**
     * Call an API to get the list of IA case Stage List
     * @param data IIACaseStageListRequest
     * @returns Promise<IIACaseStageListResponse[]>
     */
    // public static readonly submitIaCaseStageList = async (data: IIaCaseStageSubmitRequest[]): Promise<IICaseStageSubmitResponse[]> => {
    //     const response: AxiosResponse<IICaseStageSubmitResponse[]> = await postCall(
    //         `${CASE_FILING_SERVICE}/updateCaseStageForIACase`,
    //         data
    //     );

    //     return response?.data;
    // };

    /**
    * Call an API to save case details
    * @param data IInterlocutoryApplicationCaseDetailsRequest
    * @returns Promise<IInterlocutoryApplicationCaseDetailsResponse>
    */
    public static readonly updateIaMaCaseDetailsSubmit = async (data: IIaMaUpdateCaseDetailRequest): Promise<IIaMaUpdateCaseDetailResponse> => {
        const response: AxiosResponse<IIaMaUpdateCaseDetailResponse> = await putCall(
            `${CASE_FILING_SERVICE}/updateRemarkForIaMa`,
            data
        );
        return response?.data;
    };
}