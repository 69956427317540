import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {BasicBreadcrumbs, BasicTabs} from "../../../components";
import { ROOT } from "../../../navigation/CONSTANTS";
import { lazy } from "react";



const AdHocReportsView = lazy(()=> import("./components/AdHocReports/AdHocReportsView"));
// const RegularReportsView = lazy(() => import("./components/RegularReports/RegularReportsView"));
const DynamicReportView = lazy(() => import("./components/DynamicReports/DynamicReportView"));
const JudgesWiseReportsView = lazy(() => import("./components/JudgesWiseReports/JudgesWiseReportsView"));
const DetailsWiseSearchReports = lazy(() => import("./components/DetailsWiseSearchReports/DetailsWiseSearchReports"))

/**
 * Component for reports page view
 */
const ReportView = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<number>(0);

    const tabs = [
        // {
        //     value: 0,
        //     label: t("Label.RegularReport"),
        //     children: <RegularReportsView/>,
        // },
        {
            value: 0,
            label: t("Label.NcdrcRelatedReports"), 
            children: <AdHocReportsView/>,
        },
        {
            value: 1,
            label: t("Label.DynamicReport"),  
            children: <DynamicReportView/>,
        },
        {
            value: 2,
            label: t("Label.JudgesWiseReports"), 
            children: <JudgesWiseReportsView/>,
        },
        {
            value: 3,
            label: t("Label.DetailsWiseReports"), 
            children: <DetailsWiseSearchReports/>,
        },
    ]

    return (
        <>
            <section className="section margin-top-35 margin-bottom-30">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <BasicBreadcrumbs
                                items={[
                                    { to: ROOT, label: t("Breadcrumb.Dashboard") },
                                    { to: "", label: t("Breadcrumb.Reports") },
                                ]}
                            />
                        </div>
                        <div className="col-lg-12 margin-top-20">
                            <Typography sx={{ 'fontSize': 'var(--size-20)' }} variant="h3">
                                {t("Label.ConsumerCommissionsReportSummary")}
                            </Typography>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 margin-bottom-20">
                            <BasicTabs
                                className="margin-top-20"
                                tabs={tabs}
                                value={currentTab}
                                onChange={(
                                    event: React.SyntheticEvent<Element, Event>,
                                    newValue: string
                                ) => {
                                    setCurrentTab(+newValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ReportView;
