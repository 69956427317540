import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';

// Styling for animation
const AnimatedIcon = styled(CancelIcon)(({ theme }) => ({
    fontSize: '5rem',
    color: theme.palette.error.main,
    animation: 'shake 0.5s',
    '@keyframes shake': {
        '0%': { transform: 'translateX(0)' },
        '25%': { transform: 'translateX(-5px)' },
        '50%': { transform: 'translateX(5px)' },
        '75%': { transform: 'translateX(-5px)' },
        '100%': { transform: 'translateX(0)' },
    },
}));

const PaymentFailure = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const queryParams = new URLSearchParams(location.search);

    const handleRetry = () => {
        // Handle retry payment logic
    };
    const { order } = location.state || {};

    useEffect(() => {
        if (order) {


        }


    }, [order])


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bgcolor="background.default"
            textAlign="center"
        >
            <AnimatedIcon />
            <Typography variant="h4" color="error" gutterBottom>
                Payment Failed
            </Typography>
            <Typography variant="body1" color="textSecondary" mb={3}>
                Oops! Something went wrong with your payment. Please try again.
            </Typography>
            {/* <Button variant="contained" color="primary" onClick={handleRetry}>
                Retry Payment
            </Button> */}
        </Box>
    );
};

export default PaymentFailure;
