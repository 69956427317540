import { AxiosResponse } from "axios";
import { IGetRazorPayOrderPayload, IGetRazorPayResponse, IPaymentReconciliation, IPaymentResponse, IPaymentResponsePreview, IRazorPayFailedPayload, IRazorPayPaymentLinkPayload, IRazorPayRefundPayload, IRazorPayVerifyPayload } from "../../models/Payment/paymentModel";
import { PAYMENT_SERVICE } from "../CONSTANTS";
import { getCall, postCall } from "../api";


/** Service which holds all the methods related to Payment */
export class PaymentService {
  public static readonly PaymentCheckout = async (amount: string): Promise<IPaymentResponse> => {
    const response: AxiosResponse<IPaymentResponse> = await postCall(
      `${PAYMENT_SERVICE}/orderCreation?amount=${amount}`, {
    }
    )
    return response?.data
  }

  /**
  * Call an post api for payment details  details
  * @param data 
  * @returns 
  */
  public static readonly paymentCheckoutPreview = async (referenceNumber: string) => {
    const response: AxiosResponse<IPaymentResponsePreview> = await postCall(
      `${PAYMENT_SERVICE}/paymentPreview`,
      {
        filingReferenceNumber: referenceNumber
      }

    )
    return response?.data;
  }

  /**
   * Call an post api for payment details  details 
   * @param data 
   * @returns 
   */
  public static readonly razorPayOrderGeneration = async (data: IGetRazorPayOrderPayload, referenceNumber: any, userId: any) => {
    const response: AxiosResponse<IGetRazorPayResponse> = await postCall(
      `${PAYMENT_SERVICE}/razorpayOrder?fillingReferenceNumber=${referenceNumber}&userId=${userId}`,
      data
    )
    return response?.data;
  }
  /**
   * Call an post api for payment details  verification
   * @param data 
   * @returns 
   */
  public static readonly razorPayVerify = async (data: IRazorPayVerifyPayload) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/razorpayPaymentVerify`,
      data
    )
    return response?.data;
  }
  /**
   * Call an post api for razorpay refund 
   * @param data 
   * @returns 
   */
  public static readonly razorPayRefund = async (data: IRazorPayRefundPayload) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/razorpayRefund`,
      data
    )
    return response?.data;
  }

  /**
   * Call an post api for razorpay Failed transaction
   * @param data 
   * @returns 
   */
  public static readonly razorPayFailed = async (data: IRazorPayFailedPayload) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/razorpayPaymentFailed`,
      data
    )
    return response?.data;
  }

  /**
   * Call an post api for razorpay Payment link
   * @param data 
   * @returns 
   */
  public static readonly razorPayPaymentLink = async (data: IRazorPayPaymentLinkPayload, referenceNumber: any) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/razorpayPaymentLink?filingReferenceNumber=${referenceNumber}`,
      {
        data
      }
    )
    return response?.data;
  }

  /**
   * Call an post api for razorpay History transaction
   * @param data 
   * @returns 
   */

  public static readonly PaymentHistory = async (userId: number) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/razorpayPaymentHistory?userId=${userId}`, {
    }
    )
    return response?.data
  }
  /**
  * Call an post api for payment details  details 
  * @param data 
  * @returns 
  */
  public static readonly billDeskOrderGeneration = async (data: any, referenceNumber: any, userId: any) => {
    const response = await postCall(
      `${PAYMENT_SERVICE}/billdesk/createOrder?fillingReferenceNumber=${referenceNumber}&userId=${userId}`,
      data
    )
    return response?.data;
  }

  /**
   * Call an post api for payment details  details 
   * @param data 
   * @returns 
   */
  public static readonly billDeskVerify = async (data: any, referenceNumber: any, userId: any) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/billdesk/transactionResponse?fillingReferenceNumber=${referenceNumber}&userId=${userId}`,
      data
    )
    return response?.data;
  }

  /**
   * Call an post api for payment details  details 
   * @param data 
   * @returns 
   */
  // public static readonly paymentOrderGeneration = async (data:IGetPaymentOrderPayload) => {
  //   const response: AxiosResponse<IPaymentOrderGeneration> = await postCall(
  //       `${PAYMENT_SERVICE}/orderGeneration`,
  //      data
  //   )
  //   return response?.data;
  // } 

  // public static readonly paymentOrderGenerationTest = async (data:any)=> {
  //   const response: AxiosResponse<any> = await postCall(
  //     `${PAYMENT_SERVICE}/testRedirect`,data
  //   )
  //   return response?.data
  // }
  /** Call an API for payment (surePay)
  * @param data fillingReferenceNumber & replyRejoinderFlag
  */
  // public static readonly PaymentCheckoutSure = async(payload: any) => {
  //     try {
  //         const response: AxiosResponse<any> =
  //           await postCallFile(
  //             `https://pilot.surepay.ndml.in/SurePayPayment/sp/processRequest`,
  //             payload
  //           );
  //         if (response?.data) {
  //           return response?.data;
  //         }
  //       } catch (error) {
  //         toast.error("Error saving case filling document details:");
  //         throw error;
  //       }
  // };

  // public static readonly GetKey = async (): Promise<IPaymentKeyResponse> => {
  //     const response: AxiosResponse<IPaymentKeyResponse> = await getCall(
  //         `http://www.localhost:4000/api/getkey`,
  //     )
  //     return response?.data
  // }


  /**
 * Call an API to get list of advocated in a district
 * @param stateId number
 * @param districtId number
 * @returns Promise<IGetAdvocateResponse>
 */
  // public static readonly proceedToPay = async (data: IGetProceedToPayPayload): Promise<any> => {
  //     const response: AxiosResponse<any> = await postCall(
  //         `${CASE_FILING_SERVICE}/dummyPayment`,
  //         data
  //     );
  //     return response?.data;
  // };

  // Calling API for Payment Reconciliation Details for commission
  public static readonly getPaymentReconciliationByCommissionId = async (comissionId: number | undefined) => {
    const response: AxiosResponse<IPaymentReconciliation[]> = await getCall(
      `${PAYMENT_SERVICE}/paymentDetailsByComissionId?comissionId=${comissionId}`
    )
    return response?.data
  }

  /**
     * Call an post api for payment details  details 
     * @param data 
     * @returns 
     */
  public static readonly surePayOrderGeneration = async (data: any, referenceNumber: any, userId: any) => {
    const response: AxiosResponse<any> = await postCall(
      `${PAYMENT_SERVICE}/payGovOrder?filingReferenceNumber=${referenceNumber}&userId=${userId}`,
      data
    )
    return response?.data;
  }


}
