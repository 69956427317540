import React from 'react';
import { Typography, IconButton } from '@mui/material';
import grid from '../../../assets/images/grid.png';
import icon_list from '../../../assets/images/ion_list-sharp.png';

interface HeaderViewModeToggleProps {
  viewMode: string;
  setViewMode: (mode: 'grid' | 'list') => void;
}

const HeaderViewModeToggle: React.FC<HeaderViewModeToggleProps> = ({ viewMode, setViewMode }) => {
  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <Typography variant="h6"
        style={{
          fontFamily: 'Inter',
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '24.2px',
          textAlign: 'left'
        }}
      >
        Documents
      </Typography>
      <div>
        <IconButton onClick={() => setViewMode('grid')} size="small">
          <img src={grid} alt="Grid View" style={{ width: '24px', height: '24px' }} />
        </IconButton>
        <IconButton onClick={() => setViewMode('list')} size="small">
          <img src={icon_list} alt="List View" style={{ width: '24px', height: '24px' }} />
        </IconButton>
      </div>
    </div>
  );
};

export default HeaderViewModeToggle;
