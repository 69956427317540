import React from 'react';
import { Divider, Typography, Box } from '@mui/material';

interface ILineWithTextProps {
    text: string;
}

export const ConfonetLineWithText:React.FC<ILineWithTextProps> = ({text}) => {
  return (
    <Box my={4} display="flex" justifyContent="center" alignItems="center">
      <Divider sx={{ flexGrow: 1, borderBottomWidth: 3, borderColor: 'rgba(0, 0, 0, 0.6)' }} />
      <Typography
        variant="h6" // Adjust the size of the text
        sx={{
            mx: 2, // Margin between lines and text
            whiteSpace: 'nowrap', // Prevent text from wrapping
            backgroundColor: 'white', // Make sure text background is white
            padding: '0 8px', // Padding around text
        }}
      >
        {text}
      </Typography>
      <Divider sx={{ flexGrow: 1, borderBottomWidth: 3, borderColor: 'rgba(0, 0, 0, 0.6)' }} />
    </Box>
  );
};