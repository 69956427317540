import { DataTable } from '../DataTable';
import { GridColDef } from '@mui/x-data-grid';
import { IDropdown } from '../../../models/common/IDropdown';
import { Dropdown } from '../Dropdown';

interface IDataTableWithSearchAndTitleProps {
    column: GridColDef[];
    row: any;
    title: string,
    partyDropdown?: IDropdown[],
    selectedParty?: string | number,
    handlePartyChange?: (value: string) => void
}

const DataTableWithSearchAndTitle = ({ row, column, title, partyDropdown, selectedParty, handlePartyChange }: IDataTableWithSearchAndTitleProps) => {

    return (
        <div className="margin-top-25">
            <div className="card-title card-primary-header-title d-flex justify-content-between">
                {title}
                {partyDropdown ? (
                    <>
                        <Dropdown
                            id="partyType"
                            items={partyDropdown}
                            label="Party"
                            value={selectedParty}
                            sx={{ marginRight: 20, paddingRight: 2 }}
                            onDropDownChange={(e) => handlePartyChange && handlePartyChange(e.target.value)}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
            <DataTable
                className="margin-top-25"
                rows={row}
                columns={column}
            />
        </div>
    )
}

export default DataTableWithSearchAndTitle