import PersonalDetailsModel from "../../forms/PersonalDetailsModel";

/**
 * View model for the complainant-respondent form
 */
export class ComplainantRespondentViewModel {
  constructor(
    public iAmComplainant: '0' | '1' | '2',
    public complainant: PersonalDetailsModel,
    public respondent: PersonalDetailsModel
  ) {
    this.iAmComplainant = iAmComplainant;
    this.complainant = complainant;
    this.respondent = respondent;
  }

  static readonly init = () =>
    new ComplainantRespondentViewModel(
      '0',
      PersonalDetailsModel.init(),
      PersonalDetailsModel.init()
    );
}

/**
 * Request model for the complainant-respondent form
 */
export class ComplainantRespondentModel {
  constructor(
    public caseFillingComplainantRespondantDetails: CaseFillingComplainantRespondantDetail[],
    public caseFillingAdvocateDetails?: CaseFillingAdvocateDetails[]
  ) {
    this.caseFillingComplainantRespondantDetails =
      caseFillingComplainantRespondantDetails;
    this.caseFillingAdvocateDetails = caseFillingAdvocateDetails;
  }
}

export class ComplainantRespondentModelNew {
  constructor(
    public caseFillingComplainantRespondantDetails: CaseFillingComplainantRespondantDetailNew[],
  ) {
    this.caseFillingComplainantRespondantDetails =
      caseFillingComplainantRespondantDetails;
  }
}

export class ComplainantRespondantPKId {
  constructor(
    public fillingReferenceNumber: number,
    public complainantRespondantTypeId: number,
    public complainantRespondantSequenceNumber: number,
    public addressTypeId: number
  ) {
    this.fillingReferenceNumber = fillingReferenceNumber;
    this.complainantRespondantTypeId = complainantRespondantTypeId;
    this.complainantRespondantSequenceNumber =
      complainantRespondantSequenceNumber;
    this.addressTypeId = addressTypeId;
  }
}

export class CaseFillingComplainantRespondantDetail {
  constructor(
    public complainantRespondantPKId: ComplainantRespondantPKId,
    public complainantRespondantNameEn: string,
    public seniorCitizenTypeId: number,
    public widowTypeId: number,
    public handicapTypeId: number,
    public mobileNumber: number,
    public emailId: string,
    public houseNumber: string,
    public areaNameNumber: string,
    public landmarkLocality: string,
    public postalPinCode: number,
    public districtId: number,
    public stateId: number,
    public postOfficeName: string,
    public createBy: number,
    public activeStatus: number,
    public advocateId?: number
  ) {
    this.complainantRespondantPKId = complainantRespondantPKId;
    this.complainantRespondantNameEn = complainantRespondantNameEn;
    this.seniorCitizenTypeId = seniorCitizenTypeId;
    this.widowTypeId = widowTypeId;
    this.handicapTypeId = handicapTypeId;
    this.mobileNumber = mobileNumber;
    this.emailId = emailId;
    this.houseNumber = houseNumber;
    this.areaNameNumber = areaNameNumber;
    this.landmarkLocality = landmarkLocality;
    this.postalPinCode = postalPinCode;
    this.postOfficeName = postOfficeName;
    this.districtId = districtId;
    this.stateId = stateId;
    this.createBy = createBy;
    this.activeStatus = activeStatus;
    this.advocateId = advocateId
  }
}

export interface CaseFillingComplainantRespondantDetailNew {
  filingReferenceNumber: number;
  complainantRespondentTypeId: number;
  complainantRespondentSequenceNumber: number;
  complainantRespondentNameEn: string;
  complainantRespondentNameLl: string;
  seniorCitizen: boolean;
  widow: boolean;
  seriousAilments: boolean;
  handicapTypeId: number;
  nri: boolean;
  mobileNumber: number;
  phoneNumber: number;
  emailId: string;
  createdBy: number;
  updatedBy: number;
  remarks: string;
  addressDto: AddressDto[];
  advocateDto: AdvocateDto[];
  partyId?: number
}

export interface AddressDto {
  addressDetailsId?: number;
  addressTypeId: number;
  houseDoorFlatNumber: string;
  landmarkLocality: string;
  areaNameNumber: string;
  postalPinCode: number;
  districtId: number;
  stateId: number;
  countryId: number;
  locationTypeId: number;
  nri: boolean,
  stateOfNri: string | undefined,
  districtOfNri: string | undefined,
  pinCodeOfNri: string | undefined
}

export interface AdvocateDto {
  advocateSequenceNumber: number;
  advocateId: number;
  remarks: string;
}

export interface CaseFilingComplainantRespondentResponse {
  complainantRespondant: ComplainantRespondant;
  addressDetailsList: AddressDetailsList[];
  advocateDetailsList: AdvocateDetailsResponseList[];
}

export interface AddressDetailsList {
  addressDetailsId: number
  partyId: number
  addressTypeId: number
  houseDoorFlatNumber: string
  landmarkLocality: string
  areaNameNumber: string
  postalPinCode: number
  villageId: any
  tehsilId: any
  districtId: number
  stateId: number
  countryId: number
  locationTypeId: number
  createdBy: number
  updatedBy: number
  updatedAt: string
  remarks: any
  clientIpAddress: any
  createdAt: string
  activeStatus: boolean
  postOfficeId: any
  stateOfNri: string
  districtOfNri: string
  pinCodeOfNri: string
  nri: boolean
}

export interface AdvocateDetailsResponseList {
  caseFilingAdvocateDetailsId: number
  partyId: number
  advocateSequenceNumber: number
  advocateId: number
  createdBy: number
  updatedBy: number
  updatedAt: string
  remarks: string
  clientIpAddress: any
  createdAt: string
  activeStatus: boolean
  advocateName: any
  stateName: any
  districtName: any
  barCouncilId: any
  mobileNumber: any
  emailId: any
  addressTypeName: any
  postalPinCode: any
}

export interface ComplainantRespondant {
  partyId: number;
  filingReferenceNumber: number;
  complainantRespondentTypeId: number;
  complainantRespondentSequenceNumber: number;
  complainantRespondentNameEn: string;
  complainantRespondentNameLl: string;
  seniorCitizen: boolean;
  widow: boolean;
  seriousAilments: boolean;
  handicapTypeId: number;
  nri: boolean;
  mobileNumber: number;
  phoneNumber: number;
  faxNumber: null;
  emailId: string;
  createdBy: number;
  updatedBy: number;
  updatedAt: Date;
  remarks: string;
  clientIpAddress: null;
  createdAt: Date;
  activeStatus: boolean;
}



export class CaseFillingAdvocatePKId {
  constructor(
    public fillingReferenceNumber: number,
    public complainantRespondantTypeId: number,
    public complainantRespondantSequenceNumber: number,
    public advocateSequenceNumber: number
  ) {
    this.fillingReferenceNumber = fillingReferenceNumber;
    this.complainantRespondantTypeId = complainantRespondantTypeId;
    this.complainantRespondantSequenceNumber =
      complainantRespondantSequenceNumber;
    this.advocateSequenceNumber = advocateSequenceNumber;
  }
}

export class CaseFillingAdvocateDetails {
  constructor(
    public caseFillingAdvocatePKId: CaseFillingAdvocatePKId,
    public advocateNameEn: string,
    public mobileNumber: number,
    public emailId: string,
    public addressTypeId: number,
    public houseNumber: string,
    public landmarkLocality: string,
    public postalPinCode: number,
    public districtId: number,
    public stateId: number,
    public locationTypeId: number,
    public createBy: number,
    public activeStatus: number
  ) {
    this.caseFillingAdvocatePKId = caseFillingAdvocatePKId;
    this.advocateNameEn = advocateNameEn;
    this.mobileNumber = mobileNumber;
    this.emailId = emailId;
    this.addressTypeId = addressTypeId;
    this.houseNumber = houseNumber;
    this.landmarkLocality = landmarkLocality;
    this.postalPinCode = postalPinCode;
    this.districtId = districtId;
    this.stateId = stateId;
    this.locationTypeId = locationTypeId;
    this.createBy = createBy;
    this.activeStatus = activeStatus;
  }
}
