import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DocumentUpdateRequestPayload, UploadFileModel } from '../../models/cases/fileNewCase/DocumentUploadModel';
import { CaseFillingComplainantRespondantDetailNew } from '../../models/cases/fileNewCase/ComplainantResondentModel';
import { FinalSubmitModel } from '../../models/cases/fileNewCase/FinalSubmitModel';
import { RootState } from '../../redux';
import { FileNewCaseServices } from '../../services/CaseService/ConsumerCaseService/FileNewCaseService/FileNewCaseServices';
import { InterlocutoryCaseService } from '../../services/CaseService/ConsumerCaseService/InterlocutoryCaseService/InterlocutoryCaseService';
import { caseTypeEnum } from '../../utils/Enum';

interface IIaWithConsumerCaseSubmitProps {
    iaDocs: UploadFileModel[],
    ccFilingRefrenceNumber: number,
    ccCaseTypeId: number,
    complainantRespondentdetailsForIa: CaseFillingComplainantRespondantDetailNew[]
}

const useIaWithConsumerCaseSubmit = ({ iaDocs, ccFilingRefrenceNumber, ccCaseTypeId, complainantRespondentdetailsForIa }: IIaWithConsumerCaseSubmitProps) => {
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [iaFilingRefrenceNumberList, setIaFilingRefrenceNumberList] = useState<number[]>([]);

    const handleIaCaseFiling = async (commission: number) => {
        let iaRefNumberList: number[] = []
        for (const iaCase of iaDocs.filter((doc) => doc.documentId !== 1)) {
            try {
                // Submit IA case details API
                const iaFilingRefrenceNumber = await handleIaCaseDetailSubmit(iaCase, commission);

                // Check if the API call succeeded
                if (iaFilingRefrenceNumber) {
                    // Process further API calls
                    await handleIaComplainantRespondentSubmit(iaFilingRefrenceNumber);
                    await handleDocumentSubmitOfIa(iaFilingRefrenceNumber, iaCase);
                    await handleFinalSubmit(iaFilingRefrenceNumber, commission);
                    // Add the reference number to the list
                    iaRefNumberList.push(iaFilingRefrenceNumber);
                } else {
                    // Stop processing if the reference number is invalid
                    console.error('IA case detail submission failed. Stopping further iterations.');
                    break;
                }
            } catch (error) {
                // Handle any error during API calls and stop further iterations
                console.error('Error processing IA case:', error);
                break;
            }
        };
        setIaFilingRefrenceNumberList(iaRefNumberList);
        return iaRefNumberList;
    };

    const handleIaCaseDetailSubmit = async (iaCase: UploadFileModel, commission: number) => {
        const caseDetailsData = {
            caseFiledBy: userDetailsData.userId,
            caseReasonTypeDisplayText: iaCase?.selectedIaReason && iaCase?.selectedIaReason.label,
            caseReasonTypeId: iaCase?.selectedIaReason && parseInt(iaCase?.selectedIaReason.value),
            // previousCaseNumber: preCaseNumber as string,
            previousCaseTypeId: ccCaseTypeId,
            previousFilingReferenceNumber: ccFilingRefrenceNumber,
            previousCommissionId: commission,
            caseTypeId: 10,
        };
        const response = await InterlocutoryCaseService.saveCaseDetailsSubmit(
            caseDetailsData
        );

        return response?.filingReferenceNumber
    };


    const handleIaComplainantRespondentSubmit = async (iaFilingRefrenceNumber: number) => {
        const complainantRespondentData = complainantRespondentdetailsForIa.map((party) => ({
            ...party,
            filingReferenceNumber: iaFilingRefrenceNumber,
            partyId: undefined,
            addressDto: party.addressDto.map((address) => ({
                ...address,
                addressDetailsId: undefined
            }))
        }))

        await FileNewCaseServices.addUpdateComplainantRespondentDetailsV2(
            complainantRespondentData
        );
    };

    const handleDocumentSubmitOfIa = async (iaFilingRefrenceNumber: number, iaDoc: UploadFileModel) => {
        const ccIndexDoc = iaDocs.filter((doc) => doc.documentId === 1);
        const documentOfIa = [ccIndexDoc[0], iaDoc]
        const documentDetailsList = documentOfIa.filter((value) => value.activeStatus).map((item): DocumentUpdateRequestPayload => {
            return {
                filingReferenceNumber: iaFilingRefrenceNumber,
                documentSequenceNumber: item.documentSequenceNumber,
                documentUploadStageId: 1,
                documentId: item.documentId,
                documentNameEn: item.documentNameEn,
                createdBy: userDetailsData.userId,
                updatedBy: userDetailsData.userId,
                activeStatus: item.activeStatus,
                caseTypeId: caseTypeEnum.InterlocutoryApplication,
                parentFilingReferenceNumber: ccFilingRefrenceNumber,
                caseFilingDocumentDetailsId: item?.caseFilingDocumentDetailsId
            }
        });

        const files: File[] = documentOfIa.flatMap((item, index) => {
            return item.files;
        });

        if (documentDetailsList) {
            //saveCasefilling in  FileNewCaseServices to make the API call
            const jsonData = JSON.stringify(documentDetailsList);

            let formData = new FormData();
            formData.append("details", jsonData);
            for (let file of files) {
                formData.append("files", file);
            }

            await FileNewCaseServices.saveCaseFillingDocumentDetails(
                formData
            );
        }
    };

    const handleFinalSubmit = async (iaFilingRefrenceNumber: number, commission: number) => {
        if (commission) {
            const data: FinalSubmitModel = {
                commissionId: commission,
                fillingReferenceNumber: iaFilingRefrenceNumber,
            };
            await FileNewCaseServices.saveFinalSubmit(data);
        }
    };
    return ({
        handleIaCaseFiling,
        iaFilingRefrenceNumberList
    })
}

export default useIaWithConsumerCaseSubmit