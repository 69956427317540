import { Add } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { FormikProps } from "formik";
import { ChangeEvent, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import debounce from 'lodash.debounce'
import {
  BasicButton,
  BasicCheckbox,
  PersonalDetails,
  personalValidationSchema,
} from "../../../../../components";
import { AdditionalComplainantViewModel } from "../../../../../models/cases/fileNewCase/AdditionalComplainantModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import PersonalDetailsModel from "../../../../../models/forms/PersonalDetailsModel";
import { MasterService } from "../../../../../services/MasterService/MasterService";
import { makeDropdownOptions } from "../../../../../utils/Helper";
import { FileNewCaseServices } from "../../../../../services/CaseService/ConsumerCaseService/FileNewCaseService/FileNewCaseServices";

export interface IAdditionalComplainantProps {
  formik: FormikProps<AdditionalComplainantViewModel>;
  advocateOptions: IDropdown[];
  label: string;
  setAdvocateOptions: (value: SetStateAction<IDropdown[]>) => void
}

// Validations for additional complainant
export const additionalComplainantValidationSchema = Yup.object({
  additionalComplainants: Yup.array().of(personalValidationSchema),
  additionalComplainantPerforma: Yup.array().of(personalValidationSchema),
});

/** The `AdditionalComplainant` Component is used to fill the details of any additional
 * complainants as well as any additional advocates.
 *
 * @param IAdditionalComplainantProps
 *
 * @returns JSX.Element
 */
const AdditionalComplainant = ({
  formik,
  advocateOptions,
  label,

}: IAdditionalComplainantProps) => {
  const { t } = useTranslation();
  // Add new complainant to array
  const addAdditionalComplainant = () => {
    const additionalComplainantsCopy = Array.from(
      formik.values.additionalComplainants
    );
    additionalComplainantsCopy.push(PersonalDetailsModel.init());
    formik.setFieldValue(`additionalComplainants`, additionalComplainantsCopy);
  };

  const addAdditionalPerformaComplainant = () => {
    const additionalComplainantsPerformaCopy = Array.from(
      formik.values.additionalComplainantPerforma
    );
    additionalComplainantsPerformaCopy.push(PersonalDetailsModel.init());
    formik.setFieldValue(`additionalComplainantPerforma`, additionalComplainantsPerformaCopy);
  };

  // Remove complainant with gven index from array
  const deleteAdditionalComplainant = async (index: number, partyId: number) => {
    if (partyId) {
      await FileNewCaseServices.deleteComplainantRespondantDetailsV2(partyId);
    }
    const additionalComplainantsCopy = Array.from(
      formik.values.additionalComplainants
    );
    additionalComplainantsCopy.splice(index, 1);
    formik.setFieldValue(`additionalComplainants`, additionalComplainantsCopy);
    formik.setFieldTouched(`additionalComplainants[${index}]`, false);
  };

  const deleteAdditionalPerformaComplainant = async (index: number, partyId: number) => {
    if (partyId) {
      await FileNewCaseServices.deleteComplainantRespondantDetailsV2(partyId);
    }
    const additionalComplainantsPerformaCopy = Array.from(
      formik.values.additionalComplainantPerforma
    );
    additionalComplainantsPerformaCopy.splice(index, 1);
    formik.setFieldValue(`additionalComplainantPerforma`, additionalComplainantsPerformaCopy);
    formik.setFieldTouched(`additionalComplainantPerforma[${index}]`, false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {formik.values.additionalComplainants?.length > 0 &&
        formik.values.additionalComplainants.map(
          (value: PersonalDetailsModel, index: number) => {
            return (
              <div
                key={`additional_complainants_${value.isDifferentlyAbled}`}
                className="margin-top-25 position-relative card-primary-wrap"
              >
                <PersonalDetails
                  name={`additionalComplainants[${index}]`}
                  label={
                    t("Label.Additional") +
                    " " +
                    label +
                    " " +
                    t("Label.Details")
                  }
                  values={formik.values.additionalComplainants[index]}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  touched={formik.touched?.additionalComplainants?.[index]}
                  errors={formik.errors.additionalComplainants?.[index]}
                  singleAddress
                  advocateList={advocateOptions}
                />
                {formik.values.additionalComplainants.length > 0 && (
                  <BasicButton
                    className="btn-custom-delete min-width-auto"
                    text=""
                    variant="text"
                    type="button"
                    onClick={() => deleteAdditionalComplainant(index, formik.values.additionalComplainants[index].partyId as number)}
                  />
                )}
              </div>
            );
          }
        )}
      <div className="margin-top-25">
        <BasicButton
          type="button"
          color="secondary"
          text={t("Label.AddAdditional") + " " + label}
          startIcon={<Add />}
          variant="text"
          onClick={addAdditionalComplainant}
        />
      </div>

      {formik.values.additionalComplainantPerforma?.length > 0 &&
        formik.values.additionalComplainantPerforma.map(
          (value: PersonalDetailsModel, index: number) => (
            <div
              key={`performa_complainants_${value.isDifferentlyAbled}`}
              className="margin-top-25 position-relative card-primary-wrap"
            >
              <PersonalDetails
                name={`additionalComplainantPerforma[${index}]`}
                label={`${t("Label.AdditionalPerforma")}  ${t("Label.Details")}`}
                values={formik.values.additionalComplainantPerforma[index]}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                touched={formik.touched?.additionalComplainantPerforma?.[index]}
                errors={formik.errors.additionalComplainantPerforma?.[index]}
                singleAddress
                advocateList={advocateOptions}
              />
              {formik.values.additionalComplainantPerforma.length > 0 && (
                <BasicButton
                  className="btn-custom-delete min-width-auto"
                  text=""
                  variant="text"
                  type="button"
                  onClick={() => deleteAdditionalPerformaComplainant(index, formik.values.additionalComplainantPerforma[index].partyId as number)}
                />
              )}
            </div>
          )
        )}
      <div className="margin-top-25">
        <BasicButton
          type="button"
          color="secondary"
          text={t("Label.AddAdditionalProforma")}
          startIcon={<Add />}
          variant="text"
          onClick={addAdditionalPerformaComplainant}
        />
      </div>
    </>
  );
};

export default AdditionalComplainant;
