import DateFillIconPath from "../../assets/images/date_fill_icon.svg";
import DraftStatusIconPath from "../../assets/images/draft_status_icon.svg";
import FiledStatusIconPath from "../../assets/images/filed_status_icon.svg";
import PendingStatusIconPath from "../../assets/images/pending_status_icon.svg";
import DisposedStatusIconPath from "../../assets/images/disposed_status_icon.svg";
import RevertedStatusIconPath from "../../assets/images/reverted_status_icon.svg";
import VerifiedStatusIconPath from "../../assets/images/verified_status_icon.svg";
import SubmittedStatusIconPath from "../../assets/images/submitted_status_icon.svg";
import NicLogoPath from "../../assets/images/nic_logo.png";
import DigitalIndiaLogoPath from "../../assets/images/digital_india_logo.png";
import UserEditIconPath from "../../assets/images/user_edit_icon.svg";
import UserLogoutIconPath from "../../assets/images/user_logout.svg";
import UserIconPath from "../../assets/images/userLogo.png";
import EditIconPath from "../../assets/images/edit_icon.svg";
import ArrowIconImgPath from "../../assets/images/button_arrow_icon.svg";
import ArrowBackIconPath from "../../assets/images/arrow_back_icon.svg";
import DocumentFillIconPath from "../../assets/images/document_fill_icon.svg";
import PendingForApprovalIconPath from "../../assets/images/pending_for_approval_icon.svg";
import VerifiedPendingForApprovalIconPath from "../../assets/images/verified_pending_for_payment_icon.svg";
import VideoTutorials1Path from "../../assets/images/video_tutorials_1.png";
import VideoTutorials2Path from "../../assets/images/video_tutorials_2.png";
import VideoTutorials3Path from "../../assets/images/video_tutorials_3.png";
import VideoTutorials4Path from "../../assets/images/video_tutorials_4.png";
import WorkInPrpgressPath from "../../assets/images/WorkInProgress.png";
import UploadIconPath from "../../assets/images/file_upload_icon.svg";
import BillDeskPath from "../../assets/images/PayGovLogo.jpg"
import RazorPayPath from "../../assets/images/razorpay-logo.jpeg";
import CourtRoomIconPath from "../../assets/images/courtroom/courtroom.png";
import CourtRoomIconBluePath from "../../assets/images/courtroom/court-blue.svg";
import CourtRoomIconGreenPath from "../../assets/images/courtroom/court-green.svg";
import CourtRoomIconOrangePath from "../../assets/images/courtroom/court-orange.svg";
// import loginLogoGif from "/e-jagritiLogo.gif";

const loginLogoGif = "/header/e-jagritiLogo.gif";



type BasicIconProps = {
	className?: string | "";
	height?: number;
	width?: number;
	alt?: string;
	onClick?: () => void;
};

/** BasicIcons component
@param BasicIconProps
}
**/

//Upload Icon
export function UploadIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={UploadIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Work In Prpgress
export function WorkInPrpgress({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={WorkInPrpgressPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Video Tutorials 1
export function VideoTutorials1({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={VideoTutorials1Path}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Video Tutorials 2
export function VideoTutorials2({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={VideoTutorials2Path}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Video Tutorials 3
export function VideoTutorials3({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={VideoTutorials3Path}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Video Tutorials 4
export function VideoTutorials4({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={VideoTutorials4Path}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Verified Pending For Approval Icon
export function VerifiedPendingForApprovalIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={VerifiedPendingForApprovalIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Pending For Approval Icon
export function VerifiedPendingForPaymentIcon({
	className,
	height,
	width,
	alt,
	onClick,
}: BasicIconProps) {
	return (
		<VerifiedPendingForApprovalIcon
			className={className}
			height={height}
			width={width}
			alt={alt}
			onClick={onClick}
		/>
	);
}

//Pending For Approval Icon
export function PendingForApprovalIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={PendingForApprovalIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Document Fill Icon
export function DocumentFillIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={DocumentFillIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Arrow Back Icon
export function ArrowBackIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={ArrowBackIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Arrow Icon Img
export function ArrowIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={ArrowIconImgPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Edit Icon
export function EditIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={EditIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//User Icon
export function UserIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={UserIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Logo Icon
export function LogoIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={loginLogoGif}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//User Logout Icon
export function UserLogoutIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={UserLogoutIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//User Edit Icon
export function UserEditIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={UserEditIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Digital India Logo
export function DigitalIndiaLogo({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={DigitalIndiaLogoPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Nic Logo
export function NicLogo({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={NicLogoPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Date Fill Icon
export function DateFillIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={DateFillIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Draft Status Icon
export function DraftStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={DraftStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Filed Status Icon
export function FiledStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={FiledStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Pending Status Icon
export function PendingStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={PendingStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Disposed Status Icon
export function DisposedStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={DisposedStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Reverted Status Icon
export function RevertedStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={RevertedStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Verified Status Icon
export function BillDeskIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={BillDeskPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}
//Verified Status Icon
export function RazorPayIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={RazorPayPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}
//Verified Status Icon
export function VerifiedStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={VerifiedStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Submitted Status Icon
export function SubmittedStatusIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: BasicIconProps) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={SubmittedStatusIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Court Room Icon
export function CourtroomIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: Readonly<BasicIconProps>) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={CourtRoomIconPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}


//Court room Blue Icon
export function CourtroomBlueIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: Readonly<BasicIconProps>) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={CourtRoomIconBluePath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Court room Green Icon
export function CourtroomGreenIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: Readonly<BasicIconProps>) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={CourtRoomIconGreenPath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}

//Court room Orange Icon
export function CourtroomOrangeIcon({
	className,
	height,
	width,
	alt,
	onClick,
	...rest
}: Readonly<BasicIconProps>) {
	return (
		<>
			<img
				className={className}
				height={height}
				width={width}
				alt={alt}
				src={CourtRoomIconOrangePath}
				onClick={onClick}
				{...rest}
			/>
		</>
	);
}