import { DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BasicButton, Dialogbox } from "../../../components";
import ConfonetPdfViewer from "../../../components/controls/ConfonetPdfViewer";
import { RootState } from "../../../redux";
import { CaseServices } from "../../../services/CaseService/CaseServices";
import { AppealTypeCaseServices } from "../../../services/CaseService/ConsumerCaseService/AppealTypeCaseService/AppealTypeCaseService";
import { caseStages, caseTypeEnum } from "../../../utils/Enum";
import { createHeaderWithBreaks, getExpirationLimit, indianRupeeInput, navigateToDashboardPath } from "../../../utils/Helper";
import { generateLimitationPDF } from "../../../utils/Templates/LimitationTemplate";
import { CaseList } from "../../dashboard";

interface IScrutinyCaseList {
    id: number,
    ReferenceNumber: number,
    CaseType: string | undefined,
    Complainant: string,
    Respondent: string,
    ClaimAmount: string,
    FilingDate: string,
    caseTypeId: number,
    previousCaseNumber: string,
    previousFilingReferenceNumber: number,
    caseReasonTypeId: number,
    caseReasonTypeDisplayText: string
}

export const CaseLimitationReport = () => {
    const { t } = useTranslation();

    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [editCase, setEditCase] = useState<IScrutinyCaseList[]>([]);
    const [expiredScrutinyCase, setExpiredScrutinyCase] = useState<IScrutinyCaseList[]>([]);
    const [limitationDoc, setLimitationDoc] = useState<Uint8Array>(new Uint8Array([255]));


    const checkIfCaseExpired = (filingDate: string, roleId: number, caseTypeId: number): boolean => {
        const currentDate = DateTime.now();
        const expirationLimit = getExpirationLimit(roleId, caseTypeId);
        if (expirationLimit === 0) {
            return false
        }
        const filingDateTime = DateTime.fromFormat(filingDate, "dd-MM-yyyy");
        return currentDate.diff(filingDateTime, 'days').days > expirationLimit;
    };

    const handleLimitationReport = async (filingReferenceNumber: number, filingDate: string, caseType: string, caseTypeId: number) => {
        if (caseTypeId === caseTypeEnum.FirstAppeal || caseTypeId === caseTypeEnum.SecondAppeal || caseTypeId === caseTypeEnum.RevisionPetition) {
            const response = await AppealTypeCaseServices.getAppealTypeCaseDetails(
                filingReferenceNumber,
                userDetailsData.userId
            );
            const expirationLimit = getExpirationLimit(userDetailsData?.roleId, caseTypeId)
            if (response && response.previousCaseDateOfHearing) {
                const parsedFilingDate = DateTime.fromFormat(filingDate, "dd-MM-yyyy");
                const parsedCauseDate = DateTime.fromFormat(response?.previousCaseDateOfHearing, "yyyy-MM-dd");
                const daysDifference = Math.abs(parsedCauseDate.diff(parsedFilingDate, 'days').days);
                const delay = daysDifference > expirationLimit ? daysDifference - expirationLimit : 0;

                const limitReport = {
                    caseNumber: filingReferenceNumber,
                    causeDate: DateTime.fromFormat(response?.previousCaseDateOfHearing, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                    filingDate: filingDate,
                    limitationPeriod: expirationLimit,
                    withinTime: daysDifference < expirationLimit,
                    delayDays: delay,
                    timeTaken: daysDifference,
                    caseType: caseType
                }
                const pdfData = await generateLimitationPDF(limitReport)
                setLimitationDoc(pdfData)
            }
        }

    };

    const caseListColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.SerialNo"), maxWidth: 70, flex: 1 },
        { field: "ReferenceNumber", headerName: t("Label.ReferenceDiaryNumber"), flex: 1 },
        { field: "CaseType", headerName: t("Label.CaseType"), flex: 1 },
        { field: "Complainant", headerName: createHeaderWithBreaks([t("Label.ComplainantOrAppellant"), t("Label.Petitioner")]), description: t("Label.Complainant/Appellant/Petitioner"), flex: 1.5, headerClassName: 'multiline-header' },
        { field: "Respondent", headerName: createHeaderWithBreaks([t("Label.OppositeParty"), t("Label.Respondent")]), description: t("Label.OppositeParty/Respondent"), flex: 1, headerClassName: 'multiline-header' },
        { field: "ClaimAmount", headerName: t("Label.ClaimAmount"), flex: 0.8 },
        { field: "FilingDate", headerName: t("Label.FilingDate"), flex: 0.5 },
        {
            field: "Action",
            headerName: t("Label.Action"),
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                const { ReferenceNumber, FilingDate, CaseType, caseTypeId } = params.row;
                return (
                    <BasicButton
                        text={t("BtnText.View")}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleLimitationReport(ReferenceNumber, FilingDate, CaseType, caseTypeId)}
                    />
                )
            }
        },
    ]

    const handleGetCaseList = useCallback(async () => {
        const caseListResponse = await CaseServices.getAllCaseList(userDetailsData?.commissionId ?? 0, userDetailsData.roleId, userDetailsData.userId, [caseStages.pendingForScrutiny]);
        const data: IScrutinyCaseList[] = caseListResponse?.filter(item => item.caseTypeId === caseTypeEnum.FirstAppeal || item.caseTypeId === caseTypeEnum.RevisionPetition || item.caseTypeId === caseTypeEnum.SecondAppeal).map((caseList, index) => {
            const { referenceNumber, caseType, complainantNameEn, respondantNameEn, dateOfFilling, claimAmount, caseTypeId, caseReasonTypeId, caseReasonTypeDisplayText } = caseList;
            return (
                {
                    id: index + 1,
                    ReferenceNumber: referenceNumber,
                    CaseType: caseType?.toUpperCase(),
                    Complainant: complainantNameEn?.toUpperCase(),
                    Respondent: respondantNameEn?.toUpperCase(),
                    // eslint-disable-next-line no-useless-concat
                    ClaimAmount: claimAmount ? indianRupeeInput(claimAmount?.toString()).formattedValue + " " + "INR" : "N/A",
                    FilingDate: DateTime.fromFormat(dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                    caseTypeId: caseTypeId,
                    previousCaseNumber: caseList.previousCaseNumber,
                    previousFilingReferenceNumber: caseList.previousFillingReferenceNumber,
                    caseReasonTypeId,
                    caseReasonTypeDisplayText

                }
            )
        });
        const expiredCases = data?.filter(caseItem =>
            checkIfCaseExpired(caseItem.FilingDate, userDetailsData?.roleId, caseItem.caseTypeId)
        );
        const nonExpiredCases = data?.filter(caseItem =>
            !checkIfCaseExpired(caseItem.FilingDate, userDetailsData.roleId, caseItem.caseTypeId)
        )
        setExpiredScrutinyCase(expiredCases)
        setEditCase(nonExpiredCases)
    }, [userDetailsData?.commissionId, userDetailsData.roleId, userDetailsData.userId])

    useEffect(() => {
        handleGetCaseList();
    }, [handleGetCaseList]);


    const breadCrumbs = [
        { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
        { to: '', label: t("Breadcrumb.LimitationReport") },
    ]
    return (
        <>
            {limitationDoc.length > 0 &&
                <Dialogbox
                    open={limitationDoc.length > 1}
                    onClose={() => { setLimitationDoc(new Uint8Array([255])) }}
                    maxWidth='lg'

                >
                    <DialogContent>
                        {limitationDoc.length > 1 && (
                            <ConfonetPdfViewer fileUrl={limitationDoc} />
                        )}
                    </DialogContent>
                </Dialogbox>
            }
            <CaseList
                caseListRows={editCase}
                caseListColumns={caseListColumns}
                breadCrumbs={breadCrumbs}
            />
        </>
    )
}


