import { Edit, Visibility } from "@mui/icons-material";
import ViewListIcon from '@mui/icons-material/ViewList';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Avatar, DialogContent, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ICaseFillingDocumentDetailsResponseList } from "../../../models/cases/consumerCases/IConsumerCaseModel";
import {
  convertBase64ToUInt8,
  convertFileToUnit8Array,
  mergePdfUnit8Array,
} from "../../../utils/Helper";
import { BasicButton } from "../BasicButton";
import { DocumentFillIcon } from "../BasicIcons";
import InfoIcon from "@mui/icons-material/Info";
import {
  ConfonetCard,
  ConfonetCardBody,
  ConfonetCardHeader,
} from "../ConfonetCard";
import ConfonetPdfViewer from "../ConfonetPdfViewer";
import { IConfonetToggleButtonProps } from "../ConfonetToggleButton";
import { ConfonetToggleButtonGroup } from "../ConfonetToggleButtonGroup";
import { Dialogbox } from "../Dialogbox";
import { loaderService } from "../../../services/CommonService/loaderService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { caseTypeEnum, ROLE } from "../../../utils/Enum";
import { UploadFileModel } from "../../../models/cases/fileNewCase/DocumentUploadModel";

interface IDocumentPreviewCardProps {
  heading: string;
  documentDetails: ICaseFillingDocumentDetailsResponseList[];
  iaDocuments?: UploadFileModel[];
  className?: string;
  caseType?: number;
  edit?: () => void;
  showNumbering?:boolean
}

/** Renders a preview card with given items
 *
 * @param PreviewCardProps
 *
 * @returns JSX.Element
 */
export const DocumentPreviewCard = ({
  heading,
  className,
  documentDetails,
  iaDocuments,
  caseType,
  edit,
  showNumbering
}: IDocumentPreviewCardProps) => {
  const [view, setView] = useState('module');
  const [documentContent, setDocumentContent] = useState<Uint8Array>(
    new Uint8Array([255])
  );
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(caseType === caseTypeEnum.InterlocutoryApplication ? false : true);

  const handleViewDocument = (file: string) => {
    loaderService.setLoading(true);
    setDocumentContent(convertBase64ToUInt8(file));
    loaderService.clearLoading();
  };

  const handleIaDocumentView = async(file:File) => {
    loaderService.setLoading(true);
    await setDocumentContent(await convertFileToUnit8Array(file));
    loaderService.clearLoading();
  }

  const handleViewAllDocument = async (
    documentList: ICaseFillingDocumentDetailsResponseList[]
  ) => {
    loaderService.setLoading(true);
    const documentArray: Uint8Array[] = [];

    documentList.map(
      (document: ICaseFillingDocumentDetailsResponseList) => {
        documentArray.push(convertBase64ToUInt8(document.fileContent));
      }
    );
    const mergedPDf = await mergePdfUnit8Array(documentArray);
    setDocumentContent(mergedPDf);
    loaderService.clearLoading();
  };

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    setView(nextView);
  };

  const toggleButtonItem: IConfonetToggleButtonProps[] = [
    {
      icon: <ViewListIcon />,
      value: "list",
    },
    {
      icon: <ViewModuleIcon />,
      value: "module"
    }
  ];

  // Function to handle expansion toggle
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={className}>
      {/* Dialogue box for file iewving */}
      <Dialogbox
        open={documentContent.length > 1}
        onClose={async () => {
          setDocumentContent(new Uint8Array([255]));
        }}
        maxWidth="lg"
        fullScreen
      >
        <DialogContent>
          {documentContent.length > 1 && (
            <ConfonetPdfViewer
              fileUrl={documentContent}
            />
          )}
        </DialogContent>
      </Dialogbox>

      {/* Display card for the preview of all documents */}
      <ConfonetCard>
        <ConfonetCardHeader>
          <div className="card-title card-primary-header-title d-flex">
            {heading}
            <IconButton
              disableRipple={true}
              className="p-0 ms-auto"
              onClick={() => setExpanded(!expanded)}
              sx={{
                position: "absolute",
                top: '25%',
                right: '0.2%'
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          {edit && (
            <div className="card-primary-header-edit-icon">
              <BasicButton
                text={t("BtnText.Edit")}
                startIcon={<Edit />}
                variant="text"
                color="warning"
                onClick={edit}
              />
            </div>
          )}
          {userDetailsData.roleId !== ROLE.President && (
            <div style={{ marginRight: "20px" }} className="card-primary-header-view-icon">
              <BasicButton
                text={t("BtnText.PreviewAll")}
                startIcon={<Visibility />}
                variant="text"
                color="secondary"
                className="padding-right-40"
                onClick={() => handleViewAllDocument(documentDetails)}
              />
            </div>
          )}
        </ConfonetCardHeader>
        {!expanded && <ConfonetCardBody>
          <div className="input-form-control-help-text">
            <InfoIcon
              className="margin-right-5"
              fontSize="small"
            />
            To view previous case documents, click on the expand button.
          </div>
        </ConfonetCardBody>
        }
        {expanded &&
          <ConfonetCardBody>
            <div className={view === "list" ? "" : "row"}>
              <div className="d-flex margin-bottom-20 justify-content-end">
                <ConfonetToggleButtonGroup
                  toggleButton={toggleButtonItem}
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                />
              </div>
              {documentDetails.length > 0 && documentDetails.map(
                (
                  document: ICaseFillingDocumentDetailsResponseList,
                  index: number
                ) => {
                  return (
                    <>
                      {view === "list" ? (
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <DocumentFillIcon
                                  className="img-fluid pointer"
                                  onClick={() => handleViewDocument(document.fileContent)}
                                  alt=""
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${index + 1}. ${document.documentName}`} secondary={document.fileName} />
                          </ListItem>
                        </List>
                      ) : (
                        <div
                          key={`preview_col_${document.documentName}`}
                          className="margin-bottom-20 col-lg-4"
                        >
                          <Typography className="mb-3" variant="body2">
                            {index + 1} {document.documentName}
                          </Typography>
                          <DocumentFillIcon
                            className="img-fluid pointer"
                            onClick={() => handleViewDocument(document.fileContent)}
                            alt=""
                          />
                          <Typography variant="body1" className="mt-2">
                            {document.fileName}
                          </Typography>
                        </div >
                      )}
                    </>
                  );
                }
              )}
              {iaDocuments && iaDocuments?.length > 0 && iaDocuments.filter((value) => value.documentId !== 1).map(
                (
                  document: UploadFileModel,
                  index: number
                ) => {
                  return (
                    <>
                      {view === "list" ? (
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <DocumentFillIcon
                                  className="img-fluid pointer"
                                  onClick={() => handleIaDocumentView(document.files[0])}
                                  alt=""
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${index + 1}. ${document.documentNameEn}`} secondary={document.files[0].name} />
                          </ListItem>
                        </List>
                      ) : (
                        <div
                          key={`preview_col_${document.documentNameEn}`}
                          className="margin-bottom-20 col-lg-4"
                        >
                          <Typography className="mb-3" variant="body2">
                            {index + 1} {document.documentNameEn}
                          </Typography>
                          <DocumentFillIcon
                            className="img-fluid pointer"
                            onClick={() => handleIaDocumentView(document.files[0])}
                            alt=""
                          />
                          <Typography variant="body1" className="mt-2">
                            {document.files[0]?.name}
                          </Typography>
                        </div >
                      )}
                    </>
                  );
                }
              )}
            </div>
          </ConfonetCardBody>
        }
      </ConfonetCard>
    </div>
  );
};
