import InfoIcon from '@mui/icons-material/Info';
import { TextField } from "@mui/material";
import { FormikProps } from "formik";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import {
	BasicDatePicker,
	ConfonetAutocomplete,
	ConfonetCard,
	ConfonetCardBody,
	ConfonetCardHeader,
	Textbox
} from "../../../../../components";
import ConfonetTextEditorTiny from '../../../../../components/controls/ConfonetTextEditorTinyMCE';
import { CaseDetailFormViewModel } from "../../../../../models/cases/fileNewCase/CaseDetailFormModel";
import { IGetCaseCategory } from "../../../../../models/cases/fileNewCase/IFileNewCaseModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import { RootState, useAppDispatch } from '../../../../../redux';
import { MasterService } from "../../../../../services/MasterService/MasterService";
import { dropdownValidationSchema, getCommissionPaidConsideration, indianRupeeInput, makeDropdownOptions, numberToWords } from "../../../../../utils/Helper";
import { ValidationRegExp } from "../../../../../utils/ValidationRegExp";
import { addCaseCategoryList, addDistrictList, addStateList, addSubCategoryList, addSubSubCategoryList } from '../../../../../redux/SpeechReducer/SpeechReducer';
import { useLocation } from 'react-router-dom';

export interface ICaseDetailFormProps {
	formik: FormikProps<CaseDetailFormViewModel>,
	isDraft: boolean
}

// Validations for Case Detail Form.
export const caseDetailFormValidationSchema = Yup.object({
	paidConsideration: Yup.string()
		.required("Paid as consideration is a required field")
		.matches(ValidationRegExp.NUMBER_AND_COMMA, "Only number allowed")
		.max(20, "Max 15 character is allowed"),
	claimConsideration: Yup.string()
		.max(20, "Max 15 character is allowed"),
	dateOfCause: Yup.date()
		.required("Date of cause is required"),
	stateOfCauseOfAction: dropdownValidationSchema("State of cause of action is required").required(),
	districtOfCauseOfAction: dropdownValidationSchema("District of cause of action is required").required(),
	caseCategory: dropdownValidationSchema("Case category is required").required(),
	// subCategory: dropdownValidationSchema("Sub category is required").required(),
	// subSubCategory: dropdownValidationSchema("Sub sub category is required").required(),
	caseDetails: Yup.string()
		.max(2000, "Max 2000 character is allowed"),
});

/**
 * The CaseDetailForm component represents a form for capturing case file details. It includes various
 * sections such as payment considerations, state and district of cause of action, category details, and case details.
 */
const CaseDetailForm = ({ formik, isDraft }: ICaseDetailFormProps) => {
	const { state } = useSelector((state: RootState) => state.master);
	const [stateListOption, setStateListOption] = useState<IDropdown[]>([]);
	const [districtListOption, setDistrictListOption] = useState<IDropdown[]>([]);
	const [caseCategoryListOption, setCaseCategoryListOption] = useState<IDropdown[]>([]);
	const [caseSubCategoryListOption, setCaseSubCategoryListOption] = useState<IDropdown[]>([]);
	const [caseSubSubCategoryListOption, setCaseSubSubCategoryListOption] = useState<IDropdown[]>([]);
	const [paidAmountInWords, setPaidAmountInWords] = useState<string>("");
	const [claimAmountInWords, setClaimAmountInWords] = useState<string>("");
	const [commissionTypeName, setCommissionTypeName] = useState<string>("");

	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useAppDispatch()

	// Get all district API Calling
	const handleGetAllDistricts = useCallback(async (stateId: string) => {
		const getAlldistrictResponse = await MasterService.getDistrictsByStateId(parseInt(stateId));
		const districtList = makeDropdownOptions(getAlldistrictResponse, "districtNameEn", "districtId");
		setDistrictListOption(districtList);
		dispatch(addDistrictList(districtList))
	}, []);

	// Get All states API Calling
	const handleGetAllStates = useCallback(async () => {
		setStateListOption(state);
		dispatch(addStateList(state))
	}, [state]);

	// Get all Case categories API Calling
	const handleGetCaseCategories = useCallback(async () => {
		const caseCategoryData: IGetCaseCategory = {
			caseCategoryLevel: 1,
			parentCaseCategoryId: 0,
		};
		const caseCategoryResponse = await MasterService.getCaseCategory(caseCategoryData);
		const categoryList = makeDropdownOptions(caseCategoryResponse, "caseCategoryNameEn", "caseCategoryId")
		setCaseCategoryListOption(categoryList);
		dispatch(addCaseCategoryList(categoryList))
	}, []);

	// Get all Case Sub categories API Calling
	const handleGetCaseSubCategories = useCallback(async (caseCategoryId: number) => {
		const caseSubCategoryData: IGetCaseCategory = {
			caseCategoryLevel: 2,
			parentCaseCategoryId: caseCategoryId,
		};
		const caseSubCategoryResponse = await MasterService.getCaseCategory(caseSubCategoryData);
		const subCategoryList = makeDropdownOptions(caseSubCategoryResponse, "caseCategoryNameEn", "caseCategoryId");
		setCaseSubCategoryListOption(subCategoryList);
		dispatch(addSubCategoryList(subCategoryList))
	}, []);

	// Get all Case Sub Sub categories API Calling
	const handleGetCaseSubSubCategories = useCallback(async (caseCategoryId: number) => {
		const caseSubSubCategoryData: IGetCaseCategory = {
			caseCategoryLevel: 3,
			parentCaseCategoryId: caseCategoryId,
		};
		const caseSubSubCategoryResponse = await MasterService.getCaseCategory(caseSubSubCategoryData);
		const subSubCategoryList = makeDropdownOptions(caseSubSubCategoryResponse, "caseCategoryNameEn", "caseCategoryId")
		setCaseSubSubCategoryListOption(subSubCategoryList);
		dispatch(addSubSubCategoryList(subSubCategoryList))
	}, []);

	useEffect(() => {
		handleGetAllStates();
		handleGetCaseCategories();
	}, [handleGetAllStates, handleGetCaseCategories]);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	useEffect(() => {
		if (formik.values.stateOfCauseOfAction && formik.values.stateOfCauseOfAction.value !== "") {
			handleGetAllDistricts(formik.values.stateOfCauseOfAction.value);
		}
		else {
			setDistrictListOption([{ label: "", value: "" }])
		}
	}, [formik.values.stateOfCauseOfAction, handleGetAllDistricts])

	useEffect(() => {
		if (formik.values.caseCategory && formik.values.caseCategory.value !== "") {
			handleGetCaseSubCategories(formik.values.caseCategory.value);
		}
		else {
			setCaseSubCategoryListOption([])
		}
		setCaseSubSubCategoryListOption([])
	}, [formik.values.caseCategory, handleGetCaseSubCategories]);

	useEffect(() => {
		if (formik.values.subCategory && formik.values.subCategory.value !== "") {
			handleGetCaseSubSubCategories(formik.values.subCategory.value);
		}
		else {
			setCaseSubSubCategoryListOption([{ label: "", value: "" }])
		}
	}, [formik.values.subCategory, handleGetCaseSubSubCategories]);

	// handle paid as consideration change
	const handlePaidConsiderationChange = (value: string) => {
		const { originalValue, formattedValue } = indianRupeeInput(value);
		formik.setFieldValue("paidConsideration", formattedValue);
		numberToWords(parseInt(originalValue), setPaidAmountInWords);
		getCommissionPaidConsideration(parseInt(originalValue), setCommissionTypeName);
	};

	// handle claim as consideration change
	const handleClaimConsiderationChange = (value: string) => {
		const { originalValue, formattedValue } = indianRupeeInput(value);
		formik.setFieldValue("claimConsideration", formattedValue);
		numberToWords(parseInt(originalValue), setClaimAmountInWords);
	};

	// handle state of cause of action change
	const handleStateChange = (value: IDropdown | null) => {
		formik.setFieldValue("districtOfCauseOfAction", { label: "", value: "" });
		formik.setFieldTouched("districtOfCauseOfAction", false);
		formik.setFieldValue("stateOfCauseOfAction", value ?? { label: "", value: "" });
	};

	// handle case category change
	const handleCaseCategoryChange = (value: IDropdown | null) => {
		formik.setFieldValue("caseCategory", value ?? { label: "", value: "" });
		formik.setFieldValue("subCategory", { label: "", value: "" });
		formik.setFieldTouched("subCategory", false);
		formik.setFieldValue("subSubCategory", { label: "", value: "" });
		formik.setFieldTouched("subSubCategory", false);
	};

	// handle sub case category change
	const handleSubCaseCategoryChange = (value: IDropdown | null) => {
		formik.setFieldValue("subSubCategory", { label: "", value: "" });
		formik.setFieldTouched("subSubCategory", false);
		formik.setFieldValue("subCategory", value ?? { label: "", value: "" });
	};


	return (
		<>
			<div className="margin-top-25">
				<ConfonetCard>
					<ConfonetCardHeader>
						<div className="card-title card-primary-header-title">{t("Label.CaseDetails")}</div>
					</ConfonetCardHeader>
					<ConfonetCardBody>
						<div className="row">
							<div className="col-lg-6">
								<Textbox
									id="paidConsideration"
									name={"paidConsideration"}
									label={t("Label.PaidConsideration") + "*"}
									value={formik.values.paidConsideration}
									type="text"
									inputProps={{
										maxLength: 21
									}}
									onChange={(e) => handlePaidConsiderationChange(e.target.value)}
									onBlur={formik.handleBlur}
									placeholder={t("Label.EnterAmount")}
									error={formik.touched.paidConsideration && Boolean(formik.errors.paidConsideration)}
									helperText={
										<>

											{commissionTypeName &&
												<span className="text-primary">
													{`Your case will be submitted to  ${commissionTypeName}`}
												</span>
											}
											<span className='MuiFormHelperText-info'>
												<InfoIcon className='float-start margin-right-5' fontSize='small' />
												{t("Label.EnterNumberValuePaidForService")}
											</span>


										</>
									}
									disabled={!isDraft}
								/>
								<span className="convert-to-word">{paidAmountInWords === "" ? "" : paidAmountInWords + ` Rupees`}</span>
							</div>
							<div className="col-lg-6">
								<Textbox
									id="claimConsideration"
									name={`claimConsideration`}
									label={t("Label.ClaimConsideration")}
									value={formik.values.claimConsideration}
									type="text"
									inputProps={{
										maxLength: 21
									}}
									onChange={(e) => handleClaimConsiderationChange(e.target.value)}
									onBlur={formik.handleBlur}
									placeholder={t("Label.EnterYourClaimAmount")}
									error={formik.touched.claimConsideration && Boolean(formik.errors.claimConsideration)}
									helperText={
										<>
											<span className='MuiFormHelperText-info'>
												<InfoIcon className='float-start margin-right-5' fontSize='small' />
												{t("Label.DefineExactClaimAmount")}
											</span>
											{
												formik.touched.claimConsideration && formik.errors.claimConsideration
											}
										</>
									}
								/>
								<span className="convert-to-word">{claimAmountInWords === "" ? "" : claimAmountInWords + ` Rupees`}</span>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4">
								<BasicDatePicker
									label={t("Label.DateOfCause")}
									value={formik.values.dateOfCause}
									className="w-100"
									onChange={(e) => formik.setFieldValue("dateOfCause", e, true)}
									slotProps={{
										textField: {
											variant: "outlined",
											error: formik.touched.dateOfCause && Boolean(formik.errors.dateOfCause),
											helperText: (
												<>
													<span className="text-danger">
														{formik.touched.dateOfCause && formik.errors.dateOfCause as string}
													</span>
												</>
											),
											onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
										}
									}}
									maxDate={DateTime.now()}
								/>
							</div>
							<div className="col-lg-4">
								<ConfonetAutocomplete
									id="stateOfCauseOfAction"
									value={formik.values.stateOfCauseOfAction}
									options={stateListOption}
									renderInput={
										(params) => (
											<TextField
												{...params}
												label={t("Label.StateOfCauseOfAction") + "*"}
												error={formik.touched.stateOfCauseOfAction && Boolean(formik.errors.stateOfCauseOfAction)}
												helperText={formik.touched.stateOfCauseOfAction && formik.errors.stateOfCauseOfAction?.label}
												fullWidth
											/>
										)
									}
									onChange={(_, value: IDropdown | null) => handleStateChange(value)}
									onBlur={formik.handleBlur}
									disabled={!isDraft}
								/>
							</div>
							<div className="col-lg-4">
								<ConfonetAutocomplete
									id="districtOfCauseOfAction"
									value={formik.values.districtOfCauseOfAction}
									options={districtListOption}
									renderInput={
										(params) => (
											<TextField
												{...params}
												label={t("Label.DistrictOfCauseOfAction") + "*"}
												error={formik.touched.districtOfCauseOfAction && Boolean(formik.errors.districtOfCauseOfAction)}
												helperText={formik.touched.districtOfCauseOfAction && formik.errors.districtOfCauseOfAction?.label}
												fullWidth
											/>
										)
									}
									onChange={(_, value: IDropdown | null) => {
										formik.setFieldValue("districtOfCauseOfAction", value ?? { label: "", value: "" })
									}}
									onBlur={() => formik.setFieldTouched("districtOfCauseOfAction.label", true)}
									disabled={!isDraft}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4">
								<ConfonetAutocomplete
									id="caseCategory"
									value={formik.values.caseCategory}
									options={caseCategoryListOption}
									renderInput={
										(params) => (
											<TextField
												{...params}
												label={t("Label.CaseCategory") + "*"}
												error={formik.touched.caseCategory && Boolean(formik.errors.caseCategory)}
												helperText={formik.touched.caseCategory && formik.errors.caseCategory?.label}
												fullWidth
											/>
										)
									}
									onChange={(_, value: IDropdown | null) => handleCaseCategoryChange(value)}
									onBlur={formik.handleBlur}
								/>
							</div>
							{(caseCategoryListOption.length > 0 || caseSubCategoryListOption.length > 0) &&
								<div className="col-lg-4">
									<ConfonetAutocomplete
										id="subCategory"
										value={formik.values.subCategory}
										options={caseSubCategoryListOption}
										renderInput={
											(params) => (
												<TextField
													{...params}
													label={t("Label.SubCategory") + "*"}
													error={formik.touched.subCategory && Boolean(formik.errors.subCategory)}
													helperText={formik.touched.subCategory && formik.errors.subCategory?.label}
													fullWidth
												/>
											)
										}
										onChange={(_, value: IDropdown | null) => handleSubCaseCategoryChange(value)}
										onBlur={formik.handleBlur}
									/>
								</div>
							}
							{caseSubSubCategoryListOption.length > 0 && caseCategoryListOption.length > 0 &&
								<div className="col-lg-4">
									<ConfonetAutocomplete
										id="subSubCategory"
										value={formik.values.subSubCategory}
										options={caseSubSubCategoryListOption}
										renderInput={
											(params) => (
												<TextField
													{...params}
													label={t("Label.SubSubCategory") + "*"}
													error={formik.touched.subSubCategory && Boolean(formik.errors.subSubCategory)}
													helperText={formik.touched.subSubCategory && formik.errors.subSubCategory?.label}
													fullWidth
												/>
											)
										}
										onChange={(_, value: IDropdown | null) => {
											formik.setFieldValue("subSubCategory", value ?? { label: "", value: "" })
										}}
										onBlur={formik.handleBlur}
									/>
								</div>
							}
						</div>
						{/* <div className="row">
							<div className='col-lg-12 remarks-style'>
								{t("Label.RemarksOrPrayer")}
							</div>
							<div className="col-lg-12">
								<ConfonetTextEditorTiny
									id="caseDetails"
									value={formik.values.caseDetails}
									onEditorChange={editorValueChange}
									disabled
								/>

								{formik.touched.caseDetails && formik.errors.caseDetails && (
									<span className='text-danger'>{formik.errors.caseDetails}</span>
								)}
							</div>
						</div> */}
					</ConfonetCardBody>
				</ConfonetCard>
			</div>
		</>
	);
};

export default CaseDetailForm;
