import { ReactSVG } from "react-svg";
import "./india-map.scss";
import { states } from "./state.service";
import { useMemo, useRef, useState } from "react";

export default function IndiaMap({
  onValueChange,
  defaultFillColor = "#006699",
  hoverFillColor = "#004669",
  strokeColor = "#FFFFFF",
  chipBackgroundColor = "#2c8dc5",
  chipTextColor = "#FFFFFF",
}: {
  onValueChange?: (value: any) => void;
  defaultFillColor?: string;
  hoverFillColor?: string;
  strokeColor?: string;
  chipBackgroundColor?: string;
  chipTextColor?: string;
}) {
  const [showChip, setShowChip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [stateName, setStateName] = useState<string>("");

  const divRef: any = useRef(null);
  const handleMouseMove = (event: any) => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left + 12;
      const y = event.clientY - 100;

      setPosition({ x: x, y: y });
    }
  };

  const handleMouseEnter = () => {
    setShowChip(true);
  };

  const handleMouseLeave = () => {
    setShowChip(false);
  };

  const findStateName = useMemo(() => {
    const value = states.find((item: any) => {
      return stateName.includes(item.id);
    });

    if (value) {
      return value;
    } else return null;
  }, [stateName]);

  return (
    <div
      ref={divRef}
      className="app d-flex align-items-center"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        "--default-fill-color": defaultFillColor,
        "--hover-fill-color": hoverFillColor,
        "--stroke-color": strokeColor,
        "--chip-background-color": chipBackgroundColor,
        "--chip-text-color": chipTextColor,
      } as React.CSSProperties}
    >
      <div
        className="chipv position-absolute"
        style={{
          left: position.x,
          top: position.y,
          opacity: showChip && findStateName ? 1 : 0,
        }}
      >
        <div className="chiptext">
          <span className="text-white">{`${findStateName?.title}`}</span>
        </div>
      </div>

      <ReactSVG
        beforeInjection={(svg) => {
          const delhi = svg.querySelector('[id^="Delhi"]') // Assuming Delhi's id is 'Delhi'
          if (delhi) {
            delhi.classList.add("highlight-delhi");
            delhi.setAttribute("fill", "rgb(255, 115, 0)"); // Light blue color for Delhi
          }
        }}
        onMouseMove={(e: any) => {
          setStateName(e.target.id);
        }}
        onClick={() => {
          if (onValueChange) {
            onValueChange(findStateName);
          }
        }}
        className="margin-top-negative-14 india-map-size"
        src="/map/india-map.svg"
      />
    </div>
  );
}
