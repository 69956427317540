import React from "react";

interface IAcknowledgementTemplateProps {
    headerMain: string;
    complainantHeader: string;
    respondentHeader: string;
    referenceNumber: number;
    complainant: string;
    respondent: string;
    category: string;
    dateOfFiling: string;
    commission: string;
    commissionTypeAbbreviation: string;
}

const AcknowledgementTemplate = ({
    headerMain,
    complainantHeader,
    respondentHeader,
    referenceNumber,
    complainant,
    respondent,
    category,
    dateOfFiling,
    commission,
    commissionTypeAbbreviation

}: IAcknowledgementTemplateProps): React.ReactElement => {

    return (
        <div className="container p-5 custom-styles" >
            <div className="d-flex flex-column align-items-center">
                <p><b className="fs-1 text-uppercase">{headerMain}</b></p>
                <p className="fs-5 mt-n3 mb-3">Case filed in {commission}, {commissionTypeAbbreviation}</p>
            </div>
            <table className="table table-bordered w-100">
                <colgroup>
                    <col className="custom-column-style" />
                    <col className="custom-column-style"/>
                </colgroup>
                <tbody>
                    <tr>
                        <td className="text-center p-2">
                            <span className="text-secondary">Reference Number</span>
                        </td>
                        <td className="text-center">
                            <span className="text-primary">{referenceNumber}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center p-2">
                            <span className="text-secondary">{complainantHeader}</span>
                        </td>
                        <td className="text-center">
                            <span className="text-primary">{complainant}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center p-2">
                            <span className="text-secondary">{respondentHeader}</span>
                        </td>
                        <td className="text-center">
                            <span className="text-primary">{respondent}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center p-2">
                            <span className="text-secondary">Category</span>
                        </td>
                        <td className="text-center">
                            <span className="text-primary">{category}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center p-2">
                            <span className="text-secondary">Date of Filing</span>
                        </td>
                        <td className="text-center">
                            <span className="text-primary">{dateOfFiling}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center p-2">
                            <span className="text-secondary">Commission Name</span>
                        </td>
                        <td className="text-center">
                            <span className="text-primary">{commission}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AcknowledgementTemplate;
