import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import '../../../../src/' 

const SearchBar = () => {
    return (
        <div className="search-bar">
            <SearchIcon className="search-icon" />
            <input 
                type="text" 
                className="search-input" 
                placeholder="Search your keywords here...."
            />
        </div>
    );
};

export default SearchBar;
