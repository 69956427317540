import { Alert, Paper } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { FileUploader } from "../..";

export interface IDocumentUploadContainer {
	index: number;
	updateFileList: (
		index: number,
		newFiles: File[],
	) => void;
	fileList: File[];
	error: boolean | undefined,
	id?: string
	helperText?: React.ReactNode,
	maxSize?: number
}

/**
 *
 * This is component is engaged with container  which holds title and FileUploader component for reusability in Document Upload component
 * @params IDocumentUploadContainer
 */
export const DocumentUploadContainer: React.FC<IDocumentUploadContainer> = ({
	index,
	updateFileList,
	error,
	fileList,
	helperText,
	maxSize
}) => {
	// Update list of files
	const handleFileChange = (newFiles: File[]) => {
		updateFileList(index, newFiles);
	};

	return (
		<Paper
			variant="outlined"
			sx={{ marginBottom: "20px", padding: "10px" }}
		>
			{/* <> */}
			<FileUploader
				key={`dropzone_${index}`}
				dropzoneClassName="dropzone"
				text={t("Label.DragAndDropFilesHereOrClickToUpload")}
				onFileDrop={handleFileChange}
				accept={{
					"application/pdf": [".pdf"],
				}}
				fileList={fileList}
				maxSize={maxSize}
			/>
			{
				error && 
				<Alert className="margin-top-10" severity="error">{helperText}</Alert>
			}
			{/* </> */}
		</Paper>
	);
};
