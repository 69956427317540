import {
	Outlet,
	Navigate,
	RouteProps,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import { FooterView, HeaderView, SidebarView } from "../../layout";
import { useEffect, useState } from "react";
import { ResponsiveHeaderView } from "../../layout/ResponsiveHeaderView";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { BasicButton, Dialogbox } from "../../components";
import { DialogActions, DialogContent } from "@mui/material";
import { t } from "i18next";
import {
	ANALYTICS_DASHBOARD,
	ROOT,
} from "../CONSTANTS";
import useIdleTimeout from "./useIdleTimeout";

type PrivateRouteProps = {
	requiredRoles?: number[];
	unauthorizedPath?: string;
	loginPath?: string;
} & Pick<RouteProps, "path" | "element">;

const PrivateRoute = ({
	requiredRoles,
	unauthorizedPath = "/unauthorized",
	loginPath = "/login",
}: PrivateRouteProps) => {
	const { isAuthenticated, signOut } = useAuth();

	const { userDetailsData } = useSelector((state: RootState) => state.user);
	const [openSidebar, setOpenSidebar] = useState<boolean>(true);
	const [openSessionDialog, setOpenSessionDialog] = useState<boolean>(false);
	const location = useLocation();
	const navigate = useNavigate();

	const [autoSecssionExpire, setautoSessionExpire] = useState(0); // after 2nd warning user heard section expire

	const handleIdle = () => {
		heard_session_expire();
		setOpenSessionDialog(true);
		setautoSessionExpire(autoSecssionExpire + 1); //warning count for section expire
	};

	const handleStay = () => {
		setOpenSessionDialog(false);
		idleTimer.reset();
	};

	const handleLogout = async () => {
		const userLogout = await signOut();
		if (userLogout) {
			navigate(ROOT);
		}
		setOpenSessionDialog(false);
	};

	useEffect(() => {
		if (location.pathname === ANALYTICS_DASHBOARD) {
			setOpenSidebar(false);
		}
	}, [location])

	// ---Implemented automatic session expiration after 15 minutes of user inactivity, Enhancing user experience and security.
	const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 900 });

	async function heard_session_expire() {
		if (autoSecssionExpire === 2) {
			const userLogout = await signOut();
			if (userLogout) {
				navigate(ROOT);
			}
		}
	}

	useEffect(() => {
		idleTimer.reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoSecssionExpire === 1]);

	if (!userDetailsData.userId) {

		return <Navigate to={loginPath} replace />;
	}

	if (!isAuthenticated()) {
		return <Navigate to={loginPath} replace />;
	}

	const isAuthorized = requiredRoles?.some(
		(role) => userDetailsData.roleId === role
	);
	if (requiredRoles && !isAuthorized) {
		return <Navigate to={unauthorizedPath} replace />;
	}

	return (
		<>
			<div className="d-none d-md-none d-xl-block">
				{location.pathname !== ANALYTICS_DASHBOARD && (
					<SidebarView open={openSidebar} />
				)}
			</div>
			<ResponsiveHeaderView />
			<div
				className={
					openSidebar
						? "page-wrapper-sidebar"
						: "page-wrapper"
				}
			>
				<div className="content-wrapper">
					<HeaderView
						openSidebar={openSidebar}
						onSidebarClick={() => setOpenSidebar((prevState) => !prevState)}
					/>
					<main className="flex-grow-1 padding-top-80 page-wrapper-fix-footer padding-bottom-70">
						<Outlet />
					</main>
					<FooterView />
				</div>
			</div>
			<Dialogbox
				title={"Session Timeout"}
				open={openSessionDialog}
				onClose={() => handleLogout()}
			>
				<DialogContent>
					<p>
						{"Your session has expired. You'll be automatically signed out."}
					</p>
					<p>{"Do you want to stay signed in ?"}</p>
				</DialogContent>
				<DialogActions>
					<BasicButton
						text={t("BtnText.No")}
						onClick={() => handleLogout()}
						variant="outlined"
					/>
					<BasicButton
						text={t("BtnText.Yes")}
						onClick={handleStay}
						variant="contained"
						autoFocus
					/>
				</DialogActions>
			</Dialogbox>
		</>
	);
};

export default PrivateRoute;
