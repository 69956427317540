import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { DialogContent, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import arrow_image from '../../../assets/images/arrow_image.png';
import image from '../../../assets/images/basil_document-solid.png';
import { BasicBreadcrumbs, Dialogbox, PreviewCard } from '../../../components';
import { JUDGE_BENCH_DETAILS, JUDGE_CASE_PREVIEW } from '../../../navigation/CONSTANTS';
import { RootState } from '../../../redux';
import { CommissionCaseService } from '../../../services/CaseService/CommissionCaseService/CommissionCaseService';
import { CaseStatusHistoryService } from '../../../services/CommonService/CaseStatusHistoryService';
import { convertBase64ToUInt8, flattenArrayToIPreview, mergePdfUnit8Array } from '../../../utils/Helper';
import HeaderViewModeToggle from './HeaderViewModeToggle';
import { loaderService } from '../../../services/CommonService/loaderService';
import { ICaseDocumentList } from './JudgeCasePagesPreview';
import ConfonetPdfViewer from '../../../components/controls/ConfonetPdfViewer';
import { toast } from 'react-toastify';
import ButtoBack from './Button';
import { DateTime } from 'luxon';

interface ICaseDataModel {
    icon: JSX.Element;
    title: string;
    id: number;
    fileContent: string;
}

const casesData = [
    { icon: <PictureAsPdfIcon style={{ color: 'blue' }} />, title: 'Complete Document', id: 100, fileContent: "" },
    { icon: <PictureAsPdfIcon style={{ color: 'blue' }} />, title: 'Reply', id: 200, fileContent: "" },
    { icon: <PictureAsPdfIcon style={{ color: 'blue' }} />, title: "Rejoinder", id: 300, fileContent: "" }
];

const IaCaseDetailsForBench = () => {
    const location = useLocation();
    const { t } = useTranslation()
    const { caseNumber, previousCaseNumber } = location.state
    const [caseStatusDetails, setCaseStatusDetails] = useState<any>();
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [documentList, setDocumentList] = useState<ICaseDataModel[]>([]);
    const [allMergedDocument, setAllMergedDocument] = useState<Uint8Array>(new Uint8Array([255]));
    const [allMergedDocumentList, setAllMergedDocumentList] = useState<Uint8Array[]>([]);
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));
    const [allDocumentList, setAllDocumentList] = useState<ICaseDocumentList[]>([]);
    const [replyDocList, setReplyDocList] = useState<ICaseDocumentList[]>([]);
    const [rejoinder, setRejoinder] = useState<ICaseDocumentList[]>([]);
    const [cardClickId, setCardClickId] = useState<number>();
    const [openDoc, setOpenDoc] = useState<boolean>(false);


    // define all the redux state
    const { userDetailsData: { commissionId } } = useSelector((state: RootState) => state.user);

    const getCaseStatus = async () => {
        const caseStatusResponse = await CaseStatusHistoryService.getCaseStausDetails(caseNumber);
        if (caseStatusResponse && Object.keys(caseStatusResponse).length > 0) {
            const filingDate = DateTime.fromISO(caseStatusResponse.caseFilingDate).toFormat("dd-MM-yyyy");
           const lastHearingDate = DateTime.fromISO(caseStatusResponse.dateOfNextearing).toFormat("dd-MM-yyyy");
            const caseDetails = {
                caseNumber: caseStatusResponse.caseNumber,
                purpose: caseStatusResponse.purpose,
                filedBy: caseStatusResponse.complainant,
                filingDate: filingDate,
                lastHearingDate: lastHearingDate,
                complainant: caseStatusResponse.complainant,
                'Opposite Party(ies)': caseStatusResponse.respondent
            }

            setCaseStatusDetails(caseDetails);
        }
    };

    const handleGetDocumentDetails = useCallback(async () => {
        setDocumentList([]);
        const consolidatedFiles = await CommissionCaseService.getAllConsolidatedFile(caseNumber, commissionId as number);
        const documentArray: Uint8Array[] = [];
        const documentLabelList: ICaseDocumentList[] = [];
        const replyDocumentList: ICaseDocumentList[] = [];
        const rejoinderDocumentList: ICaseDocumentList[] = [];

        let replySequenceIndex = 1;
        let rejoinderSequenceIndex = 1;

        // Sets to track seen document labels
        const seenReplyLabels = new Set<string>();
        const seenRejoinderLabels = new Set<string>();
        const seenSubseQuentLabels = new Set<string>();

        const dataOfcase = [
            { icon: <PictureAsPdfIcon style={{ color: 'blue' }} />, title: 'Complete Document', id: 100, fileContent: "" },
            { icon: <PictureAsPdfIcon style={{ color: 'blue' }} />, title: 'Reply', id: 200, fileContent: "" },
            { icon: <PictureAsPdfIcon style={{ color: 'blue' }} />, title: "Rejoinder", id: 300, fileContent: "" }
        ];

        if (consolidatedFiles.caseFillingDocumentDetailsResponseList.length) {
            const allCaseFilingDocuments = consolidatedFiles.caseFillingDocumentDetailsResponseList
                .sort((a, b) => a.documentSequenceNumber - b.documentSequenceNumber)
                .map((document) => {
                    documentArray.push(convertBase64ToUInt8(document.fileContent));
                    const list: ICaseDataModel = {
                        title: document.documentName,
                        fileContent: document.fileContent,
                        icon: <PictureAsPdfIcon style={{ color: 'blue' }} />,
                        id: document.caseFilingDocumentDetailsId
                    };
                    return list
                });
            casesData.length = 0;
            casesData.push(...dataOfcase);
            casesData.splice(1, 0, ...allCaseFilingDocuments)
            setDocumentList(casesData);

            allCaseFilingDocuments.filter((doc) => doc.id !== 0).forEach((document, index) => {
                const caseList: ICaseDocumentList = {
                    caseFilingDocumentDetailsId: document.id,
                    documentName: document.title,
                    fileContent: document.fileContent,
                    sequenceNumber: index + 1,
                };
                documentLabelList.push(caseList);
            })

            // // Process reply and rejoinder documents
            if (consolidatedFiles.caseFillingReplyRejoinderDocumentDetailsList.length) {
                consolidatedFiles.caseFillingReplyRejoinderDocumentDetailsList
                    .flatMap((replyRejoinderList) => replyRejoinderList) // Flatten the nested arrays
                    .forEach((replyrejoinderDocument) => {
                        replyrejoinderDocument?.documentList?.forEach((docs) => {
                            documentArray.push(convertBase64ToUInt8(docs));
                        });

                        const isReplyFlag = replyrejoinderDocument.replyRejoinderFlag === 1;
                        const isRejoinderFlag = replyrejoinderDocument.replyRejoinderFlag === 2;
                        const isSubseQuentFlag = replyrejoinderDocument.replyRejoinderFlag === 3

                        if (isReplyFlag && !seenReplyLabels.has(replyrejoinderDocument.documentLabel)) {
                            const list: ICaseDocumentList = {
                                caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                                documentName: replyrejoinderDocument.documentLabel,
                                fileContent: replyrejoinderDocument.documentList[0],
                                sequenceNumber: replySequenceIndex++,
                            };
                            replyDocumentList.push(list);
                            seenReplyLabels.add(replyrejoinderDocument.documentLabel);
                        } else if (isRejoinderFlag && !seenRejoinderLabels.has(replyrejoinderDocument.documentLabel)) {
                            const list: ICaseDocumentList = {
                                caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                                documentName: replyrejoinderDocument.documentLabel,
                                fileContent: replyrejoinderDocument.documentList[0],
                                sequenceNumber: rejoinderSequenceIndex++,
                            };
                            rejoinderDocumentList.push(list);
                            seenRejoinderLabels.add(replyrejoinderDocument.documentLabel);
                        } else if (isSubseQuentFlag && !seenSubseQuentLabels.has(replyrejoinderDocument.documentLabel)) {
                            const list: ICaseDocumentList = {
                                caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                                documentName: replyrejoinderDocument.documentLabel,
                                fileContent: replyrejoinderDocument.documentList[0],
                                sequenceNumber: rejoinderSequenceIndex++,
                            };
                            documentLabelList.push(list);
                            seenRejoinderLabels.add(replyrejoinderDocument.documentLabel);
                        }
                    });
            } else {
                console.warn('No documents found in caseFillingReplyRejoinderDocumentDetailsList');
            }

            loaderService.setLoading(true);
            setAllMergedDocumentList(documentArray);
            const mergedPDf = await mergePdfUnit8Array(documentArray);

            // set all the local states
            setAllMergedDocument(mergedPDf);
            setAllDocumentList(documentLabelList);
            setReplyDocList(replyDocumentList);
            setRejoinder(rejoinderDocumentList);
        }
        loaderService.clearLoading();
    }, [commissionId, caseNumber]);

    const handleCardClick = async (id: number, file: string) => {
        setDocumentContent(new Uint8Array([255]));
        setCardClickId(id);
        switch (id) {
            case 100:
                if (allMergedDocument.length > 1) {
                    setDocumentContent(allMergedDocument);
                } else {
                    const mergedPDf = await mergePdfUnit8Array(allMergedDocumentList);
                    setDocumentContent(mergedPDf)
                }
                break;
            case 200:
                if (!replyDocList.length) {
                    toast.warn('Reply documents are not available')
                }
                break;
            case 300:
                if (!rejoinder.length) {
                    toast.warn('Rejoinder documents are not available')
                }
                break;
            default:
                setDocumentContent(convertBase64ToUInt8(file));
                break;
        }
    };

    const handleDocumentChange = (id: number) => {
        const document = allDocumentList.find((item) => item.caseFilingDocumentDetailsId === id);
        const documentFromReplyList = replyDocList.find((item) => item.caseFilingDocumentDetailsId === id);
        const documentFromRejoinderList = rejoinder.find((item) => item.caseFilingDocumentDetailsId === id);
        if (document) {
            const pdf = convertBase64ToUInt8(document.fileContent);
            setDocumentContent(pdf);
        } else if (documentFromReplyList) {
            const pdf = convertBase64ToUInt8(documentFromReplyList.fileContent);
            setDocumentContent(pdf);
        } else if (documentFromRejoinderList) {
            const pdf = convertBase64ToUInt8(documentFromRejoinderList.fileContent);
            setDocumentContent(pdf);
        }

    }

    useEffect(() => {
        if (documentContent.length > 1) {
            setOpenDoc(true);
        } else {
            setOpenDoc(false)
        }
    }, [documentContent])

    useEffect(() => {
        getCaseStatus();
        handleGetDocumentDetails();
        return () => {
            setDocumentList([]);
        };
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-sm-7 margin-bottom-30">
                        <BasicBreadcrumbs items={[
                            { to: JUDGE_BENCH_DETAILS, label: t("Breadcrumb.Dashboard") },
                            { to: JUDGE_CASE_PREVIEW, label: t("Label.CaseDetails") },
                            { to: '', label: t("Attached Cases") },
                        ]} />
                    </div>
                </div>
                {caseStatusDetails &&
                    <PreviewCard
                        className="margin-top-25"
                        heading={t("Label.CaseDetails")}
                        items={flattenArrayToIPreview(caseStatusDetails)}
                    />
                }
                {documentList.length > 0 && <HeaderViewModeToggle viewMode={viewMode} setViewMode={setViewMode} />}

                <Grid container spacing={2} className='mb-4'>
                    {documentList.map((caseItem, index) => {
                        return (
                            <Grid item xs={10} md={viewMode === 'grid' ? 3 : 12} key={index}>
                                <div className="card h-100" style={{ width: '100%' }} onClick={() => handleCardClick(caseItem.id, caseItem.fileContent)}>
                                    <div className="important-link-card"
                                        style={{ cursor: 'pointer' }}>
                                        <div className='main-image-judge'>
                                            <img src={image} alt="Document Icon" className="image-class" />
                                        </div>
                                        <div className="card-content-judge">
                                            <Typography variant="body2" className="card-title-judge">
                                                {caseItem.title}
                                            </Typography>
                                            <img src={arrow_image} alt="Arrow Icon" className="arrow-image-judge" />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
                <ButtoBack />
            </div>
            <Dialogbox
                open={openDoc}
                onClose={async () => {
                    await setOpenDoc(false);
                    setDocumentContent(new Uint8Array([255]));
                }}
                maxWidth="lg"
                fullScreen
            >
                <DialogContent>
                    {documentContent.length > 1 && cardClickId !== 100 ? (
                        <ConfonetPdfViewer
                            fileUrl={documentContent ? documentContent : ""}
                            onClose={async () => {
                                await setOpenDoc(false);
                                setDocumentContent(new Uint8Array([255]));
                            }}
                            caseNumber={caseNumber ?? ""}
                        />
                    ) : (
                        documentContent.length > 1 && (

                            <ConfonetPdfViewer
                                fileUrl={documentContent ? documentContent : ""}
                                documentNameList={allDocumentList}
                                replyDocumentList={replyDocList}
                                rejoinderDocumentList={rejoinder}
                                handleDocumentChange={handleDocumentChange}
                                onClose={async () => {
                                    await setOpenDoc(false);
                                    setDocumentContent(new Uint8Array([255]));
                                }}
                                caseNumber={caseNumber ?? ""}
                            />
                        )
                    )}
                </DialogContent>
            </Dialogbox>
        </>
    )
}

export default IaCaseDetailsForBench