import { pdfMakeFonts, pdfMakeLibrary } from "../CONSTANTS";

import { verdana } from "../../Verdana";

// Load the default fonts into pdfMakeLibrary
pdfMakeLibrary.vfs = pdfMakeFonts.pdfMake.vfs;

// Add your custom Verdana font to the VFS
pdfMakeLibrary.vfs["Verdana.ttf"] = verdana;
export const generateLimitationPDF = async (data: any): Promise<Uint8Array> => {
    const { caseNumber, causeDate, filingDate, limitationPeriod, timeTaken, withinTime, delayDays, caseType } = data;
    pdfMakeLibrary.fonts = {
        Verdana: {
            normal: 'Verdana.ttf',
            bold: 'Verdana.ttf',
            italics: 'Verdana.ttf',
            bolditalics: 'Verdana.ttf',
        },

    }
    const docDefinition = {
        content: [
            {
                text: 'e - Jagriti Limitation',
                style: 'header',
                alignment: 'center',
            },
            {
                text: `NATIONAL CONSUMER DISPUTES REDRESSAL COMMISSION\n ${caseType} No. ${caseNumber}\n\n`,
                style: 'subheader',
                alignment: 'center',
                margin: [0, 20, 0, 20],
            },
            {
                columns: [
                    {
                        width: '70%',
                        margin: [25, 0, 0, 0],
                        text: [
                            { text: 'Date of Impugned Order(SC):\n\n', bold: true },
                            { text: 'Date of Filing:\n\n', bold: true },
                            { text: 'Date of Order:\n\n', bold: true },
                            { text: 'Time Taken for Filing:\n\n', bold: true },
                            { text: 'Less prescribed period for Filing:\n\n', bold: true },
                            { text: 'Whether within time:\n\n', bold: true },
                            { text: 'DELAY , IF ANY\n\n', bold: true },
                        ]
                    },
                    {
                        width: '30%',
                        text: [
                            `${causeDate}\n\n`,
                            `${filingDate}\n\n`,
                            `${causeDate}\n\n`,
                            `${timeTaken}\n\n`,
                            `${limitationPeriod}\n\n`,
                            `${withinTime ? 'Yes' : 'No'}\n\n`,
                            `${delayDays} Days\n\n`,
                        ],
                        style: "value",
                    }
                ],
            },
        ],
        styles: {
            header: {
                fontSize: 14,
                font: "Verdana",
                bold: true,
                margin: [0, 10, 0, 0],
            },
            subheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 10],
            },
            value: {
                color: "blue",
                bold: true
            },
            footerText: {
                fontSize: 10,
                alignment: 'center',
            },
        },
        defaultStyle: {
            fontSize: 11,
            lineHeight: 1.2,
            font: "Verdana",  // Font family applied globally
            bold: true,  // Bold applied globally
        },
    };

    pdfMakeLibrary.vfs = pdfMakeFonts.pdfMake.vfs;
    // pdfMakeLibrary.createPdf(docDefinition).download();
    return new Promise((resolve, reject) => {
        pdfMakeLibrary.createPdf(docDefinition).getBlob((blob: Blob) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
        });
    });
}




