import { Pagination } from "@mui/lab";
import dayjs from "dayjs";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

interface JudgeData {
  filing_reference_number: string;
  case_number: string;
  filing_date: string;
  stage: string;
  no_of_hearings: string;
  orders: string;
  judgement_given: string;
}

interface Props {
  data: JudgeData[];
  categoryName: string;
}

const JudgeTable: React.FC<Props> = ({ data, categoryName }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  // Calculate the starting and ending indices for the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to get only the items for the current page
  const displayedData = data.slice(startIndex, endIndex);

  // Total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12 margin-top-20">
          <p className="heading-graphs m-0"><span className="text-orange">{categoryName}</span> Case Status Details</p>
          <TableContainer className="judgesTable" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="table-cell">Filing Reference Number</TableCell>
                  <TableCell className="table-cell other">Case Number</TableCell>
                  <TableCell className="table-cell other">Filing Date</TableCell>
                  <TableCell className="table-cell other">Stage</TableCell>
                  <TableCell className="table-cell other">Hearings</TableCell>
                  <TableCell className="table-cell other">Orders</TableCell>
                  <TableCell className="table-cell other">Judgements</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((judge) => (
                  <TableRow key={judge.filing_reference_number}>
                    <TableCell>{judge.filing_reference_number}</TableCell>
                    <TableCell>{judge.case_number}</TableCell>
                    <TableCell>{dayjs(judge.filing_date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell className={judge.stage === 'Disposed' ? 'green-highlight' : judge.stage === 'Pending' ? 'red-highlight' : ''}>{judge.stage}</TableCell>
                    <TableCell>{judge.no_of_hearings}</TableCell>
                    <TableCell>{judge.orders}</TableCell>
                    <TableCell>{judge.judgement_given}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-center mt-3">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JudgeTable;
