import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CauselistIaCase, CauselistResponse } from '../../models/causelist/CauselistModel';
import { JUDGE_CASE_PREVIEW } from '../../navigation/CONSTANTS';
import { CaseStatusHistoryService } from '../../services/CommonService/CaseStatusHistoryService';
import { handleCaseTypePartyNames } from '../CaseHelper';
import { caseTypeEnum } from '../Enum';
import { convertNumberToRoman } from '../Helper';
import "./causeListHtmlpdf.scss";

interface ICauseListHtmlPdfProps {
    causelistResponse: CauselistResponse[],
    selectedDate: string,
    onClose?: () => void,
    handleGetDocument: (filingReferenceNumber: number, docName: string) => void,
    setSaveEditResponse: any
};

const CauseListDragDropHtml: React.FC<ICauseListHtmlPdfProps> = ({ causelistResponse, selectedDate, onClose, handleGetDocument }) => {
    const navigate = useNavigate();

    const formattedDate = DateTime.fromISO(selectedDate).toFormat('dd-MM-yyyy');
    const formatedDate = DateTime.fromFormat(formattedDate, 'dd-MM-yyyy');

    // Filter out any invalid or undefined responses
    const validCauselistResponse = causelistResponse?.filter(cause => cause?.courtRoomId !== undefined);

    // Check if validCauselistResponse is empty
    if (validCauselistResponse?.length === 0) {
        console.error('No valid causelist response data available.');
        // return null;
    }

    const causeListDistinct: CauselistResponse[] = validCauselistResponse?.reduce((result: CauselistResponse[], causeList: CauselistResponse) => {
        if (!result.find((currentCauseList: CauselistResponse) => currentCauseList.courtRoomId === causeList.courtRoomId)) {
            result.push(causeList);
        }
        return result;
    }, []).sort((a: CauselistResponse, b: CauselistResponse) => a.courtRoomId - b.courtRoomId);

    const handleNavigate = async (caseNumber: string, caseTypeId: number) => {
        const caseStatusResponse = await CaseStatusHistoryService.getCaseStausDetails(caseNumber);

        console.log("OOOOOOOOOOOOOOO", caseStatusResponse)
        if (caseStatusResponse && Object.keys(caseStatusResponse).length > 0) {
            onClose && onClose();
            navigate(JUDGE_CASE_PREVIEW, { state: { caseNumber, caseTypeId, caseDetails: caseStatusResponse } });

        } else {
            toast.warn('Case Details not complete in migrated data')
        }
    };


    return (
        <div style={{ width: "!00%", margin: "50px 30px 0px 30px", fontFamily: "sans-serif, roboto" }}>
            <h4 className='causelist-center-align' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {validCauselistResponse[0]?.comissionId === 11000000 ? `${validCauselistResponse[0]?.commissionType.toUpperCase()}` : `${validCauselistResponse[0]?.commissionName.toUpperCase()} ${validCauselistResponse[0]?.commissionType.toUpperCase()}`}
            </h4>
            <h4 className='causelist-center-align' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {validCauselistResponse[0]?.comissionId === 11000000 ? `NEW DELHI` : `${validCauselistResponse[0]?.stateName}`}
            </h4>
            <h6 className='causelist-center-align margin-top-40' style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                LIST OF BUSINESS FOR {formatedDate.weekdayLong?.toUpperCase()} THE {formatedDate.toFormat('dd LLL yyyy')}
            </h6>
            {/* <h6 className='causelist-center-align margin-top-40' style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                {DateTime.now().toFormat("dd MM yyyy") >= selectedDate.toFormat("dd MM yyyy") ? `` : `TENTATIVE LIST`}
            </h6> */}

            {causeListDistinct.flatMap((currentCourtRoomDetails: CauselistResponse, idx: number) => {
                const judgeNames = currentCourtRoomDetails.judgeName?.sort((a: any, b: any) => parseInt(a.seniority_sequence_id) - parseInt(b.seniority_sequence_id)).map(judge => judge?.judge_name) ?? []

                const causeListTypeData = validCauselistResponse.reduce((uniqueCauseListTypeArr: CauselistResponse[], causeList: CauselistResponse) => {
                    if (uniqueCauseListTypeArr.find((currentCauseList: CauselistResponse) => currentCauseList.causelistTypeId === causeList.causelistTypeId)
                        ||
                        causeList.courtRoomId !== currentCourtRoomDetails.courtRoomId
                    ) {
                        return uniqueCauseListTypeArr;
                    }
                    return [...uniqueCauseListTypeArr, causeList];
                }, []);



                return (
                    <>
                        <h6 className='causelist-center-align margin-top-50' style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                            BENCH NO. {currentCourtRoomDetails.courtRoomName}
                        </h6>
                        <h6 className='causelist-center-align' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            At 10.30 A.M.
                        </h6>
                        <h4 className='margin-top-30' style={{ marginTop: 30 }}>Before:</h4>
                        {judgeNames?.map((judge) => {
                            return (
                                <h4 style={{ marginLeft: "5%" }}>{judge?.toUpperCase()}</h4>
                            )
                        })}
                        {causeListTypeData.flatMap((currentCauseListTypeData: CauselistResponse) => {
                            const casesForTypeAndRoom = validCauselistResponse?.filter(data => data?.causelistTypeId === currentCauseListTypeData?.causelistTypeId
                                && data.courtRoomId === currentCauseListTypeData.courtRoomId);



                            return (
                                <>
                                    <h4 className='causelist-center-align margin-top-80' style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 80 }}>
                                        {currentCauseListTypeData.causelistTypeName}
                                    </h4>
                                    {casesForTypeAndRoom?.map((uniqueSlnoData: CauselistResponse, index: number) => {
                                        // declare all the variables used in case table
                                        let caseNumberText: string = uniqueSlnoData?.caseNumber;
                                        let iaCaseList: CauselistIaCase[] = [];
                                        let previousCaseNumber: string = "";

                                        const attachedCaseList = uniqueSlnoData.attachedIaCaseList?.filter(val => val);

                                        // set the IA case Details
                                        if (attachedCaseList?.length > 0) {
                                            iaCaseList = attachedCaseList;
                                        };

                                        // set the Aplication case number
                                        if (uniqueSlnoData?.previousCaseNumber &&
                                            [caseTypeEnum.ExecutionApplication, caseTypeEnum.ReviewApplication, caseTypeEnum.MiscellaneousApplication].includes(uniqueSlnoData.caseTypeId)
                                        ) {
                                            previousCaseNumber = uniqueSlnoData?.previousCaseNumber;
                                        }

                                        const complainantList = uniqueSlnoData.complainantList.sort((a, b) => parseInt(a.complainant_sequence_number) - parseInt(b.complainant_sequence_number))

                                        // set the complainant, respondant and advocates
                                        const complainantName = uniqueSlnoData.complainantList?.[0]?.complainant_name;
                                        const respondantName = uniqueSlnoData.respondantList?.[0]?.respondant_name;
                                        const complainantAdvocateList = uniqueSlnoData.complainantAdvocateList?.map(advocate => advocate?.advocate_name.toUpperCase()) ?? [];
                                        const respondantAdvocateList = uniqueSlnoData.respondantAdvocateList?.map(advocate => advocate?.advocate_name.toUpperCase()) ?? [];

                                        return (
                                            <div className="case-table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>CASE NO.</th>
                                                            <th colSpan={2}>PARTY NAME(S)</th>
                                                            <th colSpan={2}>COUNSEL NAME(S)</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th>{handleCaseTypePartyNames(uniqueSlnoData.caseTypeId).complainantLabel.toUpperCase()}(S)</th>
                                                            <th>{handleCaseTypePartyNames(uniqueSlnoData.caseTypeId).respondentLabel.toUpperCase()}(S)</th>
                                                            <th>{handleCaseTypePartyNames(uniqueSlnoData.caseTypeId).complainantLabel.toUpperCase()} COUNSEL NAME</th>
                                                            <th>{handleCaseTypePartyNames(uniqueSlnoData.caseTypeId).respondentLabel.toUpperCase()} COUNSEL NAME</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p className='d-flex'>
                                                                    {uniqueSlnoData.slno}{")"}
                                                                    <p
                                                                        style={{
                                                                            color: "darkblue",
                                                                            textDecoration: "underline",
                                                                            cursor: "pointer",
                                                                            marginLeft: 4
                                                                        }}
                                                                        onClick={() => handleNavigate(uniqueSlnoData.caseNumber, uniqueSlnoData.caseTypeId)}
                                                                    >
                                                                        {caseNumberText}
                                                                    </p>
                                                                </p>
                                                                {iaCaseList.length > 0 &&
                                                                    <>
                                                                        <br />
                                                                        <p>WITH</p>
                                                                    </>
                                                                }

                                                                {previousCaseNumber !== "" &&
                                                                    <>
                                                                        <br />
                                                                        <p>IN</p>
                                                                    </>
                                                                }
                                                            </td>
                                                            <td>
                                                                <p
                                                                    style={{
                                                                        color: "darkblue",
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={() => handleGetDocument(uniqueSlnoData.filingReferenceNumber, "Memo of Parties")}
                                                                >
                                                                    {complainantName}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p
                                                                    style={{
                                                                        color: "darkblue",
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={() => handleGetDocument(uniqueSlnoData.filingReferenceNumber, "Memo of Parties")}
                                                                >
                                                                    {respondantName}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p
                                                                    style={{
                                                                        color: "darkblue",
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={() => handleGetDocument(uniqueSlnoData.filingReferenceNumber, "Vakalatnama")}
                                                                >
                                                                    {complainantAdvocateList.length ? complainantAdvocateList.map((advocate) => `${advocate} \n`) : ""}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p
                                                                // style={{
                                                                //     color: "darkblue",
                                                                //     textDecoration: "underline",
                                                                //     cursor: "pointer"
                                                                // }}
                                                                // onClick={() => () => handleGetDocument(uniqueSlnoData.filingReferenceNumber, "Vakalatnama")}
                                                                >
                                                                    {respondantAdvocateList.length ? respondantAdvocateList.map((advocate) => `${advocate} \n`) : ""}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        {iaCaseList && iaCaseList.length > 0 &&
                                                            <>
                                                                <tr>
                                                                    <td colSpan={1} className="subheading">APPLICATION NO.</td>
                                                                    <td colSpan={2} className="subheading">PURPOSE OF FILING</td>
                                                                    <td colSpan={2} className="subheading">FILED BY</td>
                                                                </tr>
                                                                {iaCaseList?.length > 0 && iaCaseList?.map((iaCase, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <p className='d-flex'>
                                                                                    {convertNumberToRoman(i + 1)}{")"}
                                                                                    <p
                                                                                        style={{
                                                                                            color: "darkblue",
                                                                                            textDecoration: "underline",
                                                                                            cursor: "pointer",
                                                                                            marginLeft: 4
                                                                                        }}
                                                                                        onClick={() => handleNavigate(iaCase.ia_case_number, uniqueSlnoData.caseTypeId)}
                                                                                    >
                                                                                        {iaCase.ia_case_number}
                                                                                    </p>
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={2}>{iaCase.reason_of_ia}</td>
                                                                            <td colSpan={2}></td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </>
                                                        }

                                                        {previousCaseNumber !== "" &&
                                                            <>
                                                                <tr>
                                                                    <td colSpan={1} className="subheading">APPLICATION NO.</td>
                                                                    <td colSpan={4} className="subheading">FILED BY</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <p
                                                                            style={{
                                                                                color: "darkblue",
                                                                                textDecoration: "underline",
                                                                                cursor: "pointer"
                                                                            }}
                                                                            onClick={() => handleNavigate(previousCaseNumber, uniqueSlnoData.caseTypeId)}
                                                                        >
                                                                            {previousCaseNumber}
                                                                        </p>
                                                                    </td>
                                                                    <td colSpan={4}></td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}
                                </>
                            )
                        })}
                    </>
                )
            })
            }
        </div>
    )
}

export default CauseListDragDropHtml
