import { ButtonProps } from "@mui/material";
import sha256 from 'crypto-js/sha256';
import { FormikValues } from "formik";
import html2canvas from "html2canvas";
import { internalIpV4 } from "internal-ip";
import JSEncrypt from "jsencrypt";
import jsPDF from "jspdf";
import { DateTime } from "luxon";
import { PDFDocument } from "pdf-lib";
import React from "react";
import { ToWords } from "to-words";
import * as Yup from "yup";
import { IPreviewValues } from "../components/controls/Forms/PreviewCard";
import { AppealTypeComplainantRespondentViewModel } from "../models/cases/appealTypeCase/AppealTypeComplainantRespondentModel";
import {
  ICCPACaseDetailViewModel,
  IGetCCPACaseDetailsResponse,
} from "../models/cases/ccpaTypeCase/ICCPACaseDetailsModel";
import {
  IAdvocateDetails,
  IComplainantRespondentDetails,
  IGetCaseDetailsResponse,
  IGetCaseListResponse,
  IGetComplainantRespondentDetailsResponse,
} from "../models/cases/consumerCases/IConsumerCaseModel";
import { CaseDetailFormViewModel } from "../models/cases/fileNewCase/CaseDetailFormModel";
import { IDropdown } from "../models/common/IDropdown";
import { AddressDetailsModel } from "../models/forms/AddressDetailsModel";
import PersonalDetailsModel from "../models/forms/PersonalDetailsModel";
import {
  IGetDailyOrderDataListNew,
  IGetDailyOrderDataListResponse,
} from "../models/judgementUpload/JudgementUploadFormViewModel";
import {
  ADVOCATE_DASHBOARD,
  COMMISSION_DASHBOARD,
  CONSUMER_DASHBOARD,
  JUDGE_DASHBOARD,
  DEALING_ASSISTANT_DASHBOARD,
  COURT_MASTER_DASHBOARD,
  DOCA_USER_DASHBOARD,
  ROOT,
} from "../navigation/CONSTANTS";
import { IPersonalDetailWithAdvocateDetails } from "../pages/dashboard/components/CasePreview";
import ApprovedBox from "../pages/MainWebPages/CaseHistoryCaseStatus/components/StatusBox/ApprovedBox";
import DisposedBox from "../pages/MainWebPages/CaseHistoryCaseStatus/components/StatusBox/DisposedBox";
import FiledBox from "../pages/MainWebPages/CaseHistoryCaseStatus/components/StatusBox/FiledBox";
import HearingBox from "../pages/MainWebPages/CaseHistoryCaseStatus/components/StatusBox/HearingBox";
import RevertedBox from "../pages/MainWebPages/CaseHistoryCaseStatus/components/StatusBox/RevertedBox";
import { MasterService } from "../services/MasterService/MasterService";
import { pdfMakeLibrary } from "./CONSTANTS";
import { ROLE, caseTypeEnum } from "./Enum";
import { reportPdfMaker } from "./PdfReports/Template/Template";
import { ISpeechValue } from "../hooks/useSpeech";
import { store } from "../redux";
import { clearFileCase } from "../redux/FileNewCaseReducer/FileNewCaseReducer";
import { clear } from "../redux/CaseReducer/CaseReducer";
import { clearUserData } from "../redux/AuthReducer/AuthReducer";
import { clearCourtRoomCaseStage } from "../redux/CommissionCaseReducer/CommissionCaseReducer";
import { UserModel } from "../models/user/UserModel";
import { CauselistComplainant, CauselistRespondent } from "../models/causelist/CauselistModel";
import { IPartyRoleDetails } from "../pages/Consumer/AppealTypeCases/components/stepperComponents/AppealTypeComplainantRespondent";

/**
 * paddingStart Function will return the padding of the string
 * @param text
 * @param maxLength
 * @param fillString
 */
export const padStartString = (
  text: string,
  maxLength: number,
  fillString?: string
) => {
  return text.padStart(maxLength, fillString);
};

/**
 * sliceString Function will return the slice of the string
 * @param text
 * @param start
 * @param end
 */
export const sliceString = (text: string, start?: number, end?: number) => {
  return text.slice(start, end);
};

/**
 * camelToTitleCase will convert and camelCase string to Title Case
 * @param text string
 * @returns string
 */
export const camelToTitleCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const capsToTitleCase = (text: string | null): string | null => {
  const titleCase = text && text.charAt(0) + text.slice(1).toLowerCase();
  return titleCase;
};

/**
 * makeDropdownOptions will convert any array to an IDropdown array
 * @param data any[]
 * @param labelField string
 * @param valueField string
 * @returns IDropdown[]
 */
export const makeDropdownOptions = (
  data: any[],
  labelField: string,
  valueField: string
): IDropdown[] => {
  let dropdown: IDropdown[] = [];
  if (data) {
    dropdown = data.map((item: any) => ({
      label: item[labelField],
      value: item[valueField],
    }));
  }
  return dropdown;
};

/**
 * dropdownValidationSchema returns aschema for a dropdown with custom label required message
 * @param message string
 * @returns Yup.AnyObjectSchema
 */
export const dropdownValidationSchema = (
  message?: string
): Yup.AnyObjectSchema => {
  if (message) {
    return Yup.object({
      label: Yup.string().nullable().required(message),
      value: Yup.number().required("Value is a required field"),
    });
  } else {
    return Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    });
  }
};

/**
 * Conert numbers to word
 * @param number
 * @param setFunction
 * @returns
 */
export const numberToWords = (
  number: number,
  setFunction?: React.Dispatch<React.SetStateAction<string>>
) => {
  // Convert number to word
  const toWords = new ToWords();
  let words = "Zero";
  if (!Number.isNaN(number)) {
    words = toWords.convert(number);
  }

  if (setFunction) {
    setFunction(words);
  }
  return words;
};

/**
 * Format the number with comma's
 * @param value string
 */
export const indianRupeeInput = (value: string) => {
  const sanitizedValue = value?.replace(/,/g, "");
  let formattedValue = "";
  formattedValue = Number(sanitizedValue).toLocaleString("en-IN");
  if (formattedValue === "0") {
    formattedValue = "";
  }
  const originalValue = formattedValue?.replaceAll(",", "");
  return { originalValue, formattedValue };
};

// function for Format a number to 2 decimal place
export const formatToDecimals = (value: string) => {
  const num = parseFloat(value);
  return Math.round(num * 100) / 100;
};

/**
 * Call an API to get all the Roles list
 */
// Caaling an API for getting all the roles
export const handleGetAllRoles: () => Promise<IDropdown[]> = async () => {
  const rolesResponse = await MasterService.getAllRoles();
  return makeDropdownOptions(rolesResponse, "roleNameEnglish", "roleId");
};

/**
 * Get Role Name according to role Id
 */
export const getRoleName = (roleId: number) => {
  const rolesList: IDropdown[] = JSON.parse(
    sessionStorage.getItem("rolesList") ?? "{}"
  );
  let roleName = "";

  if (rolesList?.length > 0) {
    const currentRole: IDropdown[] = rolesList.filter(
      (roles) => roles.value === roleId
    );
    roleName = currentRole[0]?.label;
  }

  return roleName;
};

/**
 * Returns district for given ID
 * @param districtId number
 * @param stateId number
 * @returns Idropdown
 */
export const getdistrictById = async (districtId: number, stateId: number) => {
  const districtList: IDropdown[] = makeDropdownOptions(
    await MasterService.getDistrictsByStateId(stateId),
    "districtNameEn",
    "districtId"
  );
  return (
    districtList.find(
      (district: IDropdown) => district.value === districtId
    ) ?? { label: "", value: "" }
  );
};

/**
 * Returns subCategory with given ID
 * @param subCategoryId number
 * @param caseCategoryId number
 * @returns Idropdown
 */
export const getSubCategoryById = async (
  subCategoryId: number,
  caseCategoryId: number
) => {
  const subCategoryList: IDropdown[] = makeDropdownOptions(
    (await MasterService.getCaseCategory({
      caseCategoryLevel: 2,
      parentCaseCategoryId: caseCategoryId,
    })) ?? [],
    "caseCategoryNameEn",
    "caseCategoryId"
  );

  return (
    subCategoryList.find(
      (subCategory: IDropdown) => subCategory.value === subCategoryId
    ) ?? { label: "", value: "" }
  );
};

/**
 * Returns sub sub category with given ID
 * @param subSubCategoryId number
 * @param subCategoryId number
 * @returns Idropdown
 */
export const getSubSubCategoryById = async (
  subSubCategoryId: number,
  subCategoryId: number
) => {
  const subSubCategoryList: IDropdown[] = makeDropdownOptions(
    (await MasterService.getCaseCategory({
      caseCategoryLevel: 3,
      parentCaseCategoryId: subCategoryId,
    })) ?? [],
    "caseCategoryNameEn",
    "caseCategoryId"
  );

  return (
    subSubCategoryList.find(
      (subSubCategory: IDropdown) => subSubCategory.value === subSubCategoryId
    ) ?? { label: "", value: "" }
  );
};

/**
 * get the case counts accrding to case stage
 * @param caselist
 * @param caseStageId
 * @returns number
 */
export const getCaseCount = (
  caselist: IGetCaseListResponse[][],
  caseStageId?: number
): number => {
  let count = 0;
  if (caseStageId) {
    count = caselist[caseStageId - 1]?.length ?? 0;
  } else {
    count = caselist.flat().length;
  }
  return count;
};

export const convertBase64ToUInt8 = (base64Data: string): Uint8Array => {
  return Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
};

/**
 * Function to recursively reduce nested objects to unit depth (does not flatten IDropdown values)
 *
 * @param object any
 *
 * @returns IPreviewValues[]
 */
export const flattenArrayToIPreview = (object: any): IPreviewValues[] => {
  return Object.entries(object).flatMap(([label, value]: any, _) => {
    if (Object(value) !== value) {
      return { label: camelToTitleCase(label), value: value };
    }
    if ("label" in value && "value" in value) {
      return { label: camelToTitleCase(label), value: value.label };
    }
    if ((value as DateTime)?.isValid) {
      return {
        label: camelToTitleCase(label),
        value: (value as DateTime).toFormat("dd-MM-yyyy"),
      };
    }
    return flattenArrayToIPreview(value);
  });
};

/**
 * Function to recursively reduce nested objects to unit depth (does not flatten IDropdown values)
 *
 * @param object any
 *
 * @returns ISpeechValues[]
 */
export const flattenArrayToSpeech = (
  values: FormikValues,
  prefix?: string
): ISpeechValue[] => {
  const prefixNew = prefix ? prefix + "." : "";
  const result = Object.entries(values).flatMap(
    ([label, value]: any): ISpeechValue[] => {
      if (Object(value) !== value) {
        return [{ name: prefixNew + label, type: typeof value, label }];
      }
      if ("label" in value && "value" in value) {
        return [{ name: prefixNew + label, type: "dropdown", label }];
      }
      if ((value as DateTime).isValid) {
        return [{ name: prefixNew + label, type: "datetime", label }];
      }
      return flattenArrayToSpeech(value, prefixNew + label);
    }
  );
  return result;
};

// Case preview helper functions
// Convert response JSON's to class based models
export const caseDetailsResponseToView = (
  response: IGetCaseDetailsResponse
): CaseDetailFormViewModel => {
  const state: IDropdown = {
    label: response?.stateNamePlaceOfCause,
    value: response?.stateIdOfPlaceOfCause,
  };
  const district: IDropdown = {
    label: response?.districtNamePlaceOfCause,
    value: response?.districtIdOfPlaceOfCause,
  };
  const caseCategory: IDropdown = {
    label: response?.caseCategoryNameEnLvl01,
    value: response?.caseCategoryIdLvl01,
  };
  const subCategory: IDropdown = {
    label: response?.caseCategoryNameEnLvl02,
    value: response?.caseCategoryIdLvl02,
  };
  const subSubCategory: IDropdown = {
    label: response?.caseCategoryNameEnLvl03,
    value: response?.caseCategoryIdLvl03,
  };

  const caseDetails = new CaseDetailFormViewModel(
    response?.claimAmount?.toString(),
    response?.paidAmount?.toString(),
    response?.dateOfCause ? DateTime.fromFormat(response?.dateOfCause, "yyyy-MM-dd") : DateTime.now(),
    state,
    district,
    caseCategory,
    subCategory,
    subSubCategory,
    response?.remarks,
    response?.filingReferenceNumber?.toString()
  );

  return caseDetails;
};

export const ccpaCaseDetailsResponseToView = (
  response: IGetCCPACaseDetailsResponse
) => {
  const authority: IDropdown = {
    label: response.otherLegalBodyNameEn,
    value: response.otherLegalBodyId,
  };
  const caseCategory: IDropdown = {
    label: response.caseCategoryNameEnLvl01,
    value: response.caseCategoryIdLvl01,
  };
  const subCategory: IDropdown = {
    label: response.caseCategoryNameEnLvl02,
    value: response.caseCategoryIdLvl02,
  };
  const subSubCategory: IDropdown = {
    label: response.caseCategoryNameEnLvl03,
    value: response.caseCategoryIdLvl03,
  };
  const caseDetails = new ICCPACaseDetailViewModel(
    authority,
    response.authorityId,
    DateTime.fromISO(response.otherLegalBodyOrderDate),
    caseCategory,
    subCategory,
    subSubCategory,
    response.remark,
    response.filingReferenceNumber.toString()
  );

  return caseDetails;
};

interface IComplainantRespondentResponseToView {
  complainant: PersonalDetailsModel;
  respondent: PersonalDetailsModel;
  additionalComplainant: IPersonalDetailWithAdvocateDetails[];
  additionalRespondent: IPersonalDetailWithAdvocateDetails[];
  allComplainant?: PersonalDetailsModel[];
  allRespondent?: PersonalDetailsModel[];
  mainAdvocateList: IAdvocateDetails[];
  selectedComplainantRespondent: AppealTypeComplainantRespondentViewModel;
  allAdvocateList: IAdvocateDetails[];
}

export const complainantRespondentResponseToView = (
  response: IGetComplainantRespondentDetailsResponse,
  showAdvocateDetails = false
): IComplainantRespondentResponseToView => {
  // Complainant Respondent and additionals
  let complainant: PersonalDetailsModel = PersonalDetailsModel.init([]);
  let respondent: PersonalDetailsModel = PersonalDetailsModel.init([]);
  let additionalComplainant: IPersonalDetailWithAdvocateDetails[] = [];
  let additionalRespondent: PersonalDetailsModel[] = [];
  let selectedComplainantRespondent =
    AppealTypeComplainantRespondentViewModel.init();
  const addtionalComplainantAdvocateList = response.additionalComplainantDetailsResponse?.advocateDetails;
  // const addtionalRespondantAdvocateList = response.additionalRespondantDetailsResponse.advocateDetails;
  const emptyAdvocateDetail = {
    advocateBarId: "",
    advocateMobileNumber: 0,
    advocateEmail: "",
    advocateHouseNumber: "",
    advocatLocality: "",
    advocateState: "",
    advocateDistrict: "",
    advocateAddressType: "",
    advocatePinCode: 0,
  }

  response.complainantRespondantDetailsResponse?.complainantRespondantDetails?.forEach(
    (details: IComplainantRespondentDetails) => {
      // Extract address details
      const addressType: IDropdown = {
        label: details.addressTypeName,
        value: details.addressTypeId,
      };
      const state: IDropdown = {
        label: details.stateName,
        value: details.stateId,
      };
      const district: IDropdown = {
        label: details.districtName,
        value: details.districtId,
      };
      const postOffice: IDropdown = { label: details.postOfficeName, value: 0 };
      const address = new AddressDetailsModel(
        addressType,
        details.houseNumber,
        details.areaNameNumber,
        details.landmarkLocality,
        postOffice,
        state,
        district,
        details.postalPinCode?.toString(),
        false,
        { label: "", value: "" }
      );

      // Add details to complainant
      if (details.complainantRespondantTypeId === 1) {
        complainant.name = details.complainantRespondantNameEn;
        complainant.mobileNumber = details.mobileNumber?.toString();
        complainant.email = details.emailId;
        complainant.isWidow = Boolean(details.widowTypeId);
        complainant.isSeniorCitizen = Boolean(details.seniorCitizenTypeId);
        complainant.isDifferentlyAbled = Boolean(details.handicapTypeId);
        complainant.address = [...complainant.address, address];
        complainant.advocate = { label: "", value: "" };
        if (details.selectedComplainantRespondent === 1) {
          selectedComplainantRespondent.selectedComplainant =
            details.complainantRespondantSequenceNumber;
        }
      }
      // Add details for respondent
      else if (details.complainantRespondantTypeId === 2) {
        respondent.name = details.complainantRespondantNameEn;
        respondent.mobileNumber = details.mobileNumber?.toString();
        respondent.email = details.emailId;
        respondent.isWidow = Boolean(details.widowTypeId);
        respondent.isSeniorCitizen = Boolean(details.seniorCitizenTypeId);
        respondent.isDifferentlyAbled = Boolean(details.handicapTypeId);
        respondent.address = [...respondent.address, address];
        // respondent.advocate = { label: "", value: "" };
        if (details.selectedComplainantRespondent === 1) {
          selectedComplainantRespondent.selectedRespondent =
            details.complainantRespondantSequenceNumber;
        }
      }
    }
  );

  // Add advocate details
  response.complainantRespondantDetailsResponse?.advocateDetails.forEach(
    async (advocate: IAdvocateDetails) => {
      if (advocate.complainantRespondantTypeId === 1) {
        complainant.advocate = {
          label: showAdvocateDetails
            ? `${advocate.advocateNameEn} , ${advocate.mobileNumber}, ${advocate.emailId}, ${advocate.houseNumber}, ${advocate.landmarkLocality}, ${advocate.stateName}, ${advocate.districtName} ${advocate.postalPinCode},`
            : advocate.advocateNameEn,
          value: advocate.advocateId,
        };
      }
      if (advocate.complainantRespondantTypeId === 2) {
        respondent.advocate = {
          label: advocate.advocateNameEn,
          value: advocate.advocateId,
        };
      }
    }
  );


  // Additional Complainant
  response.additionalComplainantDetailsResponse?.complainantRespondantDetails.forEach(
    (details: IComplainantRespondentDetails) => {
      const addressType: IDropdown = {
        label: details.addressTypeName,
        value: details.addressTypeId,
      };
      const state: IDropdown = {
        label: details.stateName,
        value: details.stateId,
      };
      const district: IDropdown = {
        label: details.districtName,
        value: details.districtId,
      };
      const postOffice: IDropdown = { label: details.postOfficeName, value: 0 };
      const address = new AddressDetailsModel(
        addressType,
        details.houseNumber,
        details.areaNameNumber,
        details.landmarkLocality,
        postOffice,
        state,
        district,
        details.postalPinCode?.toString(),
        false,
        { label: "", value: "" }
      );

      let index = details.complainantRespondantSequenceNumber - 1;
      if (!additionalComplainant[index]) {
        additionalComplainant[index] = { ...PersonalDetailsModel.init([]), ...emptyAdvocateDetail }
      }

      if (index >= 0) {
        additionalComplainant[index].name = details.complainantRespondantNameEn;
        additionalComplainant[index].mobileNumber =
          details.mobileNumber?.toString();
        additionalComplainant[index].email = details.emailId;
        additionalComplainant[index].isWidow = Boolean(details.widowTypeId);
        additionalComplainant[index].isSeniorCitizen = Boolean(
          details.seniorCitizenTypeId
        );
        additionalComplainant[index].isDifferentlyAbled = Boolean(
          details.handicapTypeId
        );
        additionalComplainant[index].address = [
          ...additionalComplainant[index].address,
          address,
        ];
        additionalComplainant[index].advocate = { label: "", value: "" };
        additionalComplainant[index].advocateBarId = addtionalComplainantAdvocateList[index]?.barCouncilId;
        additionalComplainant[index].advocateMobileNumber = addtionalComplainantAdvocateList[index]?.mobileNumber;
        additionalComplainant[index].advocateEmail = addtionalComplainantAdvocateList[index]?.emailId;
        additionalComplainant[index].advocateHouseNumber = addtionalComplainantAdvocateList[index]?.houseNumber;
        additionalComplainant[index].advocatLocality = addtionalComplainantAdvocateList[index]?.landmarkLocality;
        additionalComplainant[index].advocateState = addtionalComplainantAdvocateList[index]?.stateName;
        additionalComplainant[index].advocateDistrict = addtionalComplainantAdvocateList[index]?.districtName;
        additionalComplainant[index].advocateAddressType = addtionalComplainantAdvocateList[index]?.addressTypeNameEn;
        additionalComplainant[index].advocatePinCode = addtionalComplainantAdvocateList[index]?.postalPinCode;

        if (details.selectedComplainantRespondent === 1) {
          selectedComplainantRespondent.selectedComplainant =
            details.complainantRespondantSequenceNumber;
        }
      }
    }
  );

  //Additional Complainant advocate
  response.additionalComplainantDetailsResponse?.advocateDetails.forEach(
    (advocate: IAdvocateDetails) => {
      let index = advocate.complainantRespondantSequenceNumber - 1;
      if (additionalComplainant[index]) {
        additionalComplainant[index].advocate = {
          label: showAdvocateDetails
            ? `${advocate.advocateNameEn} , ${advocate.mobileNumber}, ${advocate.emailId}, ${advocate.houseNumber}, ${advocate.landmarkLocality}, ${advocate.stateName}, ${advocate.districtName} ${advocate.postalPinCode},`
            : advocate.advocateNameEn,
          value: advocate.advocateId,
        };
      }
    }
  );

  // Additional Respondent
  response.additionalRespondantDetailsResponse?.complainantRespondantDetails.forEach(
    (details: IComplainantRespondentDetails) => {
      const addressType: IDropdown = {
        label: details.addressTypeName,
        value: details.addressTypeId,
      };
      const state: IDropdown = {
        label: details.stateName,
        value: details.stateId,
      };
      const district: IDropdown = {
        label: details.districtName,
        value: details.districtId,
      };
      const postOffice: IDropdown = { label: details.postOfficeName, value: 0 };
      const address = new AddressDetailsModel(
        addressType,
        details.houseNumber,
        details.areaNameNumber,
        details.landmarkLocality,
        postOffice,
        state,
        district,
        details.postalPinCode?.toString(),
        false,
        { label: "", value: "" }
      );

      let index = details.complainantRespondantSequenceNumber - 1;
      if (!additionalRespondent[index]) {
        additionalRespondent[index] = PersonalDetailsModel.init([]);
      }

      if (index >= 0) {
        additionalRespondent[index].name = details.complainantRespondantNameEn;
        additionalRespondent[index].mobileNumber =
          details.mobileNumber?.toString();
        additionalRespondent[index].email = details.emailId;
        additionalRespondent[index].isWidow = Boolean(details.widowTypeId);
        additionalRespondent[index].isSeniorCitizen = Boolean(
          details.seniorCitizenTypeId
        );
        additionalRespondent[index].isDifferentlyAbled = Boolean(
          details.handicapTypeId
        );
        additionalRespondent[index].address = [
          ...additionalRespondent[index].address,
          address,
        ];
        additionalRespondent[index].advocate = { label: "", value: "" };
        // if (addtionalRespondantAdvocateList.length) {
        //   additionalRespondent[index].advocateMobileNumber = addtionalRespondantAdvocateList[index].mobileNumber;
        //   additionalRespondent[index].advocateEmail = addtionalRespondantAdvocateList[index].emailId;
        //   additionalRespondent[index].advocateHouseNumber = addtionalRespondantAdvocateList[index].houseNumber;
        //   additionalRespondent[index].advocatLocality = addtionalRespondantAdvocateList[index].landmarkLocality;
        //   additionalRespondent[index].advocateState = addtionalRespondantAdvocateList[index].stateName;
        //   additionalRespondent[index].advocateDistrict = addtionalRespondantAdvocateList[index].districtName;
        //   additionalRespondent[index].advocateAddressType = addtionalRespondantAdvocateList[index].addressTypeNameEn;
        //   additionalRespondent[index].advocatePinCode = addtionalRespondantAdvocateList[index].postalPinCode;
        // }
        if (details.selectedComplainantRespondent === 1) {
          selectedComplainantRespondent.selectedRespondent =
            details.complainantRespondantSequenceNumber;
        }
      }
    }
  );

  // Additional respondent advocate
  response.additionalRespondantDetailsResponse?.advocateDetails.forEach(
    (advocate: IAdvocateDetails) => {
      let index = advocate.complainantRespondantSequenceNumber - 1;
      if (additionalRespondent[index]) {
        additionalRespondent[index].advocate = {
          label: advocate.advocateNameEn,
          value: advocate.advocateId,
        };
      }
    }
  );
  // get all complainant and respondent list
  const allComplainant = [complainant, ...additionalComplainant];
  const allRespondent = [respondent, ...additionalRespondent];
  const mainAdvocateList =
    response.complainantRespondantDetailsResponse?.advocateDetails;
  // const allAdvocateList = [...response.complainantRespondantDetailsResponse?.advocateDetails, ...response.additionalComplainantDetailsResponse?.advocateDetails, ...response.additionalRespondantDetailsResponse?.advocateDetails];

  return {
    complainant,
    respondent,
    additionalComplainant,
    additionalRespondent,
    allComplainant,
    allRespondent,
    mainAdvocateList,
    selectedComplainantRespondent,
    allAdvocateList: []
  };
};

// return a promise that resolves with a File instance
export const urltoFile = (url: string, filename: string, mimeType: string) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((buf) => new File([buf], filename, { type: mimeType }));
};

// return a file from base64 string
export const b64toBlob = (
  b64Data: string,
  contentType: string,
  fileName: string,
  sliceSize?: number
) => {
  contentType = contentType || "";
  sliceSize = sliceSize ?? 512;

  const byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, fileName, { type: contentType });
};

export const convertFileToUnit8Array = async (
  file: File
): Promise<Uint8Array> => {
  let Uint8ArrayFile: Uint8Array = new Uint8Array([255]);
  if (file) {
    await file.arrayBuffer().then((buff) => {
      const x = new Uint8Array(buff); // x is your uInt8Array
      // perform all required operations with x here.
      Uint8ArrayFile = x;
    });
  }
  return Uint8ArrayFile;
};

export const htmlStringToPdf = async (
  htmlString: string,
  fileName: string,
  orientation: "p" | "landscape" | "portrait" | "l" | undefined = "landscape"
): Promise<{ myFile: File; imgData: string; doc: jsPDF }> => {
  // Create a hidden iframe for rendering the HTML content
  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  let iframedoc =
    iframe.contentDocument ?? (iframe.contentWindow?.document as Document);
  iframedoc.body.innerHTML = htmlString;

  // Use html2canvas to render the HTML content
  let canvas = await html2canvas(iframedoc.body, {
    logging: true,
    useCORS: true,
    allowTaint: true,
    scale: 3, // Increase the scale for higher resolution
  });

  // Convert the canvas to an image
  let imgData = canvas.toDataURL("image/jpeg", 1.0); // Higher quality JPEG

  // Remove iframe after rendering
  document.body.removeChild(iframe);

  // Initialize jsPDF with page orientation
  const doc = new jsPDF({
    orientation,
    unit: "px",
    format: "a4",
    hotfixes: ["px_scaling"],
  });

  const pageWidth =
    orientation === "l" || orientation === "landscape" ? 1122 : 794;
  const pageHeight =
    orientation === "l" || orientation === "landscape" ? 794 : 1122;

  // Get canvas dimensions
  const imgWidth = canvas.width;
  const imgHeight = canvas.height;

  // Calculate scaling ratio to fit the image into the page
  const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

  const imgScaledWidth = imgWidth * ratio;
  const imgScaledHeight = imgHeight * ratio;

  // Center the image on the PDF
  const xOffset = (pageWidth - imgScaledWidth) / 2;  // Center horizontally
  const yOffset = (pageHeight - imgScaledHeight) / 2; // Center vertically

  // Add the image to the PDF, centered
  doc.addImage(
    imgData,
    "JPEG",
    xOffset, // X-axis offset to center the image
    yOffset, // Y-axis offset to center the image
    imgScaledWidth,
    imgScaledHeight,
    undefined,
    "FAST"
  );

  // Convert to blob and return the PDF file
  let blob = doc.output("blob");
  const myFile = new File([blob], `${fileName}.pdf`, {
    type: "application/pdf",
  });

  return { myFile, imgData, doc };
};




// -----------------------------------------------------------------------------------
// Reports as pdf viewer
export const ReportsPdfViewer = async (
  htmlString: string,
  fileName: string,
  orientation: "p" | "landscape" | "portrait" | "l" | undefined = "landscape",
  type?: string
): Promise<{ myFile: File; imgData: string; doc: jsPDF }> => {
  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  let iframedoc =
    iframe.contentDocument || (iframe.contentWindow?.document as Document);
  iframedoc.body.innerHTML = htmlString;

  let canvas = await html2canvas(iframedoc.body, {
    logging: true,
    useCORS: true,
    allowTaint: true,
    scale: 2,
  });

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL("image/png");

  // Create a PDF document and add the image as a page.
  const doc = new jsPDF({
    orientation,
    unit: "px",
    format: "a4",
    hotfixes: ["px_scaling"],
  });

  const width = orientation === "l" || orientation === "landscape" ? 1122 : 794;
  const height =
    orientation === "l" || orientation === "landscape" ? 794 : 1122;

  let y = 500; // Height position of new content
  if (y >= height) {
    doc.addPage();
  }
  doc.addImage(imgData, "JPEG", 0, 0, width, height, "FAST");

  const myBlob: any = await reportPdfMaker(type as string);

  const myFile = new File([myBlob], `${fileName}.pdf`, {
    type: "application/pdf",
  });

  return { myFile, imgData, doc };
};
// -----------------------------------------------------------------------------------------

// generate Encrypted Password
export const encryptedString = (value: string): string => {
  const encryptMethod = new JSEncrypt();

  encryptMethod.setPublicKey(process.env.REACT_APP_SECRET_PUBLIC_KEY);

  const encryptedString = encryptMethod.encrypt(value) as string;

  return encryptedString;
};

export const navigateToDashboardPath = (roleId: number): string => {
  let path = ROOT;
  switch (roleId) {
    case ROLE.Consumer:
      path = CONSUMER_DASHBOARD;
      break;
    case ROLE.Advocate:
      path = ADVOCATE_DASHBOARD;
      break;
    case ROLE.President:
    case ROLE.Member:
      path = JUDGE_DASHBOARD;
      break;
    case ROLE.DealingAssistant:
      path = DEALING_ASSISTANT_DASHBOARD;
      break;
    case ROLE.CourtMaster:
      path = COURT_MASTER_DASHBOARD;
      break;
    case ROLE.DocaAdmin:
      path = DOCA_USER_DASHBOARD;
      break;
    case ROLE.NcdrcAdmin:
    case ROLE.ScdrcAdmin:
    case ROLE.DcdrcAdmin:
      path = COMMISSION_DASHBOARD;
      break;
    default:
      break;
  }

  return path;
};

// fuction accept array of PDF data in Uint8Array format and return single pdf data in Uint8Array format
export const mergePdfUnit8Array = async (pdfDataArray: Uint8Array[]) => {
  const mergedPdf = await PDFDocument.create();

  for (const pdfData of pdfDataArray) {
    const pdf = await PDFDocument.load(pdfData);
    const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    pages.forEach((page) => mergedPdf.addPage(page));
  }

  const mergedPdfData = await mergedPdf.save();

  return mergedPdfData;
};

// function to fill required data in an html string based on span markers
// Not in Use
export const fillTextWithData = (htmlString: string, replacerMap?: Object) => {
  const replacerMapTemp = {
    DTTODAY: DateTime.now().toFormat("dd-MM-yyyy"),
    DTOFNEXTHEARING: DateTime.now().plus({ days: 2 }).toFormat("dd-MM-yyyy"),
    DTTOFPREVHEARING: DateTime.now().plus({ years: 2 }).toFormat("dd-MM-yyyy"),
    STATENAME: "Haryana",
    COMPADDRESS: "23-H, Pratap nagar, Delhi, 110001",
    STATEDATE: DateTime.now().toFormat("dd-MM-yyyy"),
    STATENUMBER: "23",
  };

  Object.entries(replacerMapTemp).forEach(([key, value]: [string, string]) => {
    htmlString = htmlString.replaceAll(
      `<<**${key}**>>`,
      `<strong>${value}</strong>`
      // new RegExp(`<data type=["']${key}["']>[^>]*</data>`, "g"),
      // `<data type='${key}'>${value}</data>`
    );
  });
  return htmlString;
};

export const truncate = (string: string, length: number) => {
  if (string.length > length) return string.substring(0, length) + "...";
  else return string;
};

export const getCaseStatusColor = (status: string): ButtonProps["color"] => {
  switch (status) {
    case "Disposed":
      return "success";
    case "FINAL Judgement Uploaded":
      return "success";
    case "Submitted":
      return "primary";
    case "Rejected":
      return "error";
    case "Moved to courtroom":
      return "secondary";
    default:
      return "warning";
  }
};

// get component of Status of the case
export const getCaseStatusComponent = (status: string): React.ReactNode => {
  switch (status) {
    case "Disposed":
      return <DisposedBox />;
    case "Submitted":
      return <FiledBox />;
    case "Rejected":
      return <RevertedBox />;
    case "Moved to courtroom":
      return <ApprovedBox />;
    case "FINAL Judgement Uploaded":
      return <DisposedBox />;
    default:
      return <HearingBox />;
  }
};

export const navigateToDifferentPath = (
  roleId: number,
  pathOfConsumer: string,
  pathOfAdvocate: string
) => {
  let path = pathOfConsumer;

  if (roleId !== ROLE.Consumer) {
    path = pathOfAdvocate;
  }

  return path;
};

// Do nothing for specified amount of time
export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

// Convert audio bytes to base64 representation
export const audioToBase64 = (
  audioBlob: Blob
): Promise<string | ArrayBuffer | null | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => resolve(e.target?.result);
    reader.readAsDataURL(audioBlob);
  });
};

// Play an audio file asynchronously
export const playAudio = async (audio: HTMLAudioElement) => {
  return new Promise((resolve) => {
    audio.onended = resolve;
    audio.play();
  });
};

export const getCssPropertiesValue = (
  isTablet: boolean,
  isMobile: boolean,
  heightForDeskTop: string,
  heightForTablet?: string,
  heightForMobile?: string
) => {
  let height = heightForDeskTop;
  if (isTablet) {
    height = heightForTablet as string;
  }

  if (isMobile) {
    height = heightForMobile as string;
  }

  return height;
};

// convert content to file using pdfmake
export const convertContentToFile = async (
  docDefinition: any,
  fileName: string
): Promise<File> => {
  let pdf = pdfMakeLibrary.createPdf(docDefinition);
  const blobContent: BlobPart = await new Promise((resolve) =>
    pdf.getBlob((blob: any) => {
      resolve(blob);
    })
  );
  const myFile = new File([blobContent], fileName, { type: "application/pdf" });

  return myFile;
};

export function convertBytes(bytes: number) {
  if (bytes < 1024) {
    return bytes + " bytes";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }
}

export const filterArrayUsingObjectKey = (
  data: any,
  key: string,
  value: string
) => {
  return data.filter((obj: any) => obj[key] === value);
};

export const getCaseNumberGroupedData = (
  data: IGetDailyOrderDataListResponse[][]
) => {
  const extractedArrayFull = data.flatMap((subArray) => subArray);

  const newOriginalArray = extractedArrayFull.map((object) => {
    return {
      recordnumber: object.primaryKey.record_number,
      datatext: object.primaryKey.data_text,
      sequencenumber: object.primaryKey.sequence_number,
      sequencetext: object.primaryKey.sequence_text,
      subsequencenumber: object.primaryKey.subsequence_number,
      caseNumber: object.primaryKey.case_number,
      attachedCaseNumber: object.combined_ia_case_numbers,
      mainCaseNumber: object.first_original_case_number,
      previousCaseNumber: object.previous_case_number,
      againstCaseTypeId: object.case_type_id,
      caseTypeName: object.case_type_name,
      previousCaseOrderDate: object.previous_case_order_date,
      previousComissionId: object.previous_comission_id,
      previousCommissionName: object.previous_commission_name,
      previousCommissionType: object.previous_commission_type
    };
  });

  const groupedData: { [key: string]: IGetDailyOrderDataListNew[] } = {};

  // Group the data by group key
  newOriginalArray.forEach((obj) => {
    const groupKey = obj.caseNumber;
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = [];
    }
    groupedData[groupKey].push(obj);
  });

  // Convert the grouped data into an array of objects
  const arrayOfObjects = Object.keys(groupedData).map((key) => ({
    caseNumber: key,
    data: groupedData[key],
  }));

  return arrayOfObjects;
};

export const getIpAddress = async () => {
  let clientIp = "";
  await internalIpV4().then((ip) => {
    clientIp = ip as string;
  });
  return clientIp;
};

export const preCaseTypeName = (caseTypeId: number) => {
  if (
    caseTypeId === caseTypeEnum.FirstAppeal ||
    caseTypeId === caseTypeEnum.AppealExecution ||
    caseTypeId === caseTypeEnum.CCPAAppeal ||
    caseTypeId === caseTypeEnum.TransferApplication ||
    caseTypeId === caseTypeEnum.ExecutionApplication ||
    caseTypeId === caseTypeEnum.ReviewApplication ||
    caseTypeId === caseTypeEnum.MiscellaneousApplication
  ) {
    return {
      complainantLabel: "Appellant",
      respondentLabel: "Respondent",
    };
  } else if (
    caseTypeId === caseTypeEnum.RevisionPetition ||
    caseTypeId === caseTypeEnum.SecondAppeal
  ) {
    return {
      complainantLabel: "Petitioner",
      respondentLabel: "Respondent",
    };
  } else {
    return {
      complainantLabel: "Complainant",
      respondentLabel: "Opposite Party",
    };
  }
};

export const getAdvocatePreviewDetails = (
  response: IAdvocateDetails[],
  complainantRespondantTypeId: number,
  sequencenumber: number
) => {
  const advocate = response?.filter((adv) => adv.complainantRespondantTypeId === complainantRespondantTypeId || adv.complainantRespondantSequenceNumber)
  return {
    advocateBarId: advocate[0]?.barCouncilId,
    advocateMobileNumber: advocate[0]?.mobileNumber,
    advocateEmail: advocate[0]?.emailId,
    advocateHouseNumber: advocate[0]?.houseNumber,
    advocatLocality: advocate[0]?.landmarkLocality,
    advocateState: advocate[0]?.stateName,
    advocateDistrict: advocate[0]?.districtName,
    advocateAddressType: advocate[0]?.addressTypeNameEn,
    advocatePinCode: advocate[0]?.postalPinCode,
  };
};

export const getCommissionType = (commissionTypeId: number) => {
  switch (commissionTypeId) {
    case 2:
      return "STATE COMMISSION"
    case 3:
      return "DISTRICT COMMISSION"
    default:
      return ""
  }
}

export const getAgainstCaseType = (caseTypeId: number) => {
  if (caseTypeId === caseTypeEnum.FirstAppeal || caseTypeId === caseTypeEnum.SecondAppeal) {
    return "Appeal"
  }
  else {
    return "Complaint"
  }
}

export const getPreviousCommissionType = (previousCommissionType: string) => {
  switch (previousCommissionType) {
    case "District Consumer Disputes Redressal Commission":
      return "District Commission"
    case "State Consumer Disputes Redressal Commission":
      return "State Commission"
    case "National Consumer Disputes Redressal Commission":
      return "National Commission"
    default:
      return ""
  }
}

export const maskNumber = (number: string) => {
  const numStr = String(number);
  const firstTwo = numStr.slice(0, 2);
  const lastTwo = numStr.slice(-2);
  const maskedMiddle = "*".repeat(numStr.length - 4);
  return `${firstTwo}${maskedMiddle}${lastTwo}`;
};

export const maskEmail = (email: string) => {
  if (email === "") {
    return email;
  } else {
    const parts = email.split("@");
    const len = parts[0].length;
    return email.replace(parts[0].slice(2, -2), "*".repeat(len - 2));
  }
};

export const showInText = (caseTypeId: caseTypeEnum) => {
  switch (caseTypeId) {
    case caseTypeEnum.ExecutionApplication:
      return true;

    case caseTypeEnum.ReviewApplication:
      return true;

    case caseTypeEnum.MiscellaneousApplication:
      return true;

    default:
      return "";
  }
}

export const showAgainstText = (caseTypeId: caseTypeEnum) => {
  switch (caseTypeId) {
    case caseTypeEnum.FirstAppeal:
      return true;

    case caseTypeEnum.SecondAppeal:
      return true;

    case caseTypeEnum.RevisionPetition:
      return true;

    case caseTypeEnum.AppealExecution:
      return true;

    case caseTypeEnum.CCPAAppeal:
      return true;

    default:
      return "";
  }
}

export function getCharactersAfterFirstComma(str: string) {
  // Find the index of the first comma.
  const index = str.indexOf(',');

  // If there is no comma, return the empty string.
  if (index === -1) {
    return '';
  }

  // Return the substring after the comma.
  return str.substring(index + 1);
};

// replace all the spaces in a string with underscore
export const replaceSpaceWithUnderScore = (value: string) => {
  return value.replace(/ /g, "_");
};

// convert an array of object of IDropdown to an Object list of key and value pair
export const convertDropDownArrayToObject = (caseStageResponse: IDropdown[]) => {

  var obj: any = {};
  for (var i = 0; i < caseStageResponse.length; i++) {

    var item = caseStageResponse[i];

    obj[replaceSpaceWithUnderScore(item.label)] = item.value;

  }

  return obj;
};

// Password hashing with SHA-256 method
export const hashedString = async (value: string, randomNumber: string): Promise<{ firstHashedString: string, secondHashedString: string }> => {

  // first hashed password
  const firstHashedString = sha256(value).toString();

  // second hashed password
  const secondHashedString = sha256(`${firstHashedString}${randomNumber}`).toString();

  return { firstHashedString, secondHashedString };
};

// logout function
export const userLogout = async () => {
  await sessionStorage.clear();
  store.dispatch(clearFileCase());
  store.dispatch(clear());
  store.dispatch(clearUserData());
  store.dispatch(clearCourtRoomCaseStage());
  const user: UserModel = JSON.parse(sessionStorage.getItem("user") ?? "{}");
  return user.userId === undefined;
};

// get the commission type name according to paid consideration
export const getCommissionPaidConsideration = (
  paidValue: number,
  setFunction?: React.Dispatch<React.SetStateAction<string>>
) => {
  let commission = ""
  if (paidValue > 0 && paidValue <= 5000000) {
    commission = "DCDRC"
  } else if (paidValue > 5000000 && paidValue <= 20000000) {
    commission = "SCDRC"
  } else if (paidValue > 20000000) {
    commission = "NCDRC"
  };

  if (setFunction) {
    setFunction(commission);
  }

};

// check if date is in utc format or not
export const isValidUTC = (dateString: string) => {
  // Parse the date string using Luxon
  const dateTime = DateTime.fromISO(dateString, { zone: 'utc' });

  // Check if the parsed date is valid
  return dateTime.isValid && dateTime.offset === 0;
};

export function containsDigit(str: string) {
  return /\d/.test(str);
}

export const isOnlyDigits = (str: string) => {
  return /^\d+$/.test(str);
}

export function extractDigits(str: string) {
  // Use a regular expression to match all digits in the string
  const digits = str.match(/\d+/g);

  // Join the digits array into a single string if any digits are found
  return digits ? digits.join('') : '';
}

export function isCombinationOfLettersAndDigits(str: string) {
  const hasLetters = /[a-zA-Z]/.test(str); // Checks for at least one letter
  const hasDigits = /\d/.test(str);        // Checks for at least one digit
  return hasLetters && hasDigits;
}

export const convertNumberToRoman = (num: number) => {
  const romanNumeralMap = [
    { value: 1000, symbol: 'M' },
    { value: 900, symbol: 'CM' },
    { value: 500, symbol: 'D' },
    { value: 400, symbol: 'CD' },
    { value: 100, symbol: 'C' },
    { value: 90, symbol: 'XC' },
    { value: 50, symbol: 'L' },
    { value: 40, symbol: 'XL' },
    { value: 10, symbol: 'X' },
    { value: 9, symbol: 'iX' },
    { value: 5, symbol: 'V' },
    { value: 4, symbol: 'iV' },
    { value: 1, symbol: 'i' }
  ];

  let romanNumeral = '';

  for (const { value, symbol } of romanNumeralMap) {
    while (num >= value) {
      romanNumeral += symbol;
      num -= value;
    }
  }

  return romanNumeral;
}

// Salutations for Advocate
export const salutationDropdown: IDropdown[] = [
  { label: "Mr.", value: 1 },
  { label: "Mrs.", value: 2 },
  { label: "Dr.", value: 3 },
  { label: "M/s.", value: 4 },
  { label: "Miss.", value: 5 },
  { label: "Master", value: 6 },

];
// Relation dropdown list
export const relationDropdown: IDropdown[] = [
  { label: "S/o.", value: 1 },
  { label: "D/o.", value: 2 },
  { label: "M/o.", value: 3 },
  { label: "F/o.", value: 4 },
  { label: "W/o.", value: 5 },
  { label: "C/o.", value: 6 },

];

// split name, relation and relative name

export const splitName = (name: string) => {
  const regex = /^(.+?)\s([A-Za-z]+\/o\.)\s(.+)$/;
  const parts = name.match(regex);
  if (parts) {
    const firstName = parts[1].trim();
    const delimiter = parts[2] ? parts[2].trim() : '';
    const relativeName = parts[3] ? parts[3].trim() : '';
    return { firstName, delimiter, relativeName };
  }
}

// Set limit for different case types in different commissions
export const getExpirationLimit = (roleId: number, caseTypeId: number): number => {
  const expirationLimits: { [roleId: number]: { [caseTypeId: number]: number } } = {
    10: {
      1: 730,
      8: 30,
      2: 90,
      14: 30,
      16: 30,
      17: 30,
      5: 30,
      9: 30
    },
    11: {
      1: 730,
      8: 45,
      14: 30,
      5: 30,
      9: 30
    },
    12: {
      1: 730,
      5: 30,
      9: 30
    }
  };
  return expirationLimits[roleId]?.[caseTypeId] ?? 0;
};

// utils/base64Converter.js

// utils/base64Converter.ts

/**
 * Converts an image URL to a base64 string.
 * @param {string} imgUrl - The URL of the image to convert.
 * @returns {Promise<string>} - A promise that resolves to a base64 string of the image.
 */
export const convertImageToBase64 = (imgUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgUrl;
    img.crossOrigin = 'Anonymous'; // Handle cross-origin if the image is from a different domain

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png'); // Adjust format if needed
        resolve(dataURL);
      } else {
        reject(new Error("Unable to get canvas context"));
      }
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};


export const findLowestSequenceComplainant = (complainantList: CauselistComplainant[]): CauselistComplainant | null => {
  if (complainantList.length === 0) {
    return null; // Return null if the list is empty
  }

  // Sort complainants by sequence number
  complainantList.sort((a, b) => {
    return parseInt(a.complainant_sequence_number) - parseInt(b.complainant_sequence_number);
  });

  // The first element in the sorted array has the lowest sequence number
  return complainantList[0]
};

export const findLowestSequenceRespondent = (respondantList: CauselistRespondent[] | null): CauselistRespondent | null => {
  // Return null if the list is null or empty
  if (respondantList === null || respondantList.length === 0) {
    return null;
  }

  // Sort respondents by sequence number, ensuring valid numbers are used
  respondantList.sort((a, b) => {
    const seqA = parseInt(a.respondant_sequence_number || '0'); // Default to 0 if undefined
    const seqB = parseInt(b.respondant_sequence_number || '0');
    return seqA - seqB;
  });

  // Return the first element in the sorted array (lowest sequence number)
  return respondantList[0];
};


// Function to deep compare objects ignoring partyId and addressId
function removePartyIdAndAddressId(obj: any) {
  // Clone the object to avoid mutating the original
  const objCopy = JSON.parse(JSON.stringify(obj));

  // Remove partyId
  delete objCopy.partyId;
  delete objCopy.filingRefrenceNumber;

  // Remove addressId inside address array
  if (objCopy.address && Array.isArray(objCopy.address)) {
    objCopy.address.forEach((address: any) => {
      delete address.addressId;
    });
  }

  return objCopy;
}

// Function to compare two objects after removing partyId and addressId
export function isSameObjectExceptIdAndAddressId(obj1: any, obj2: any) {
  const obj1Cleaned = removePartyIdAndAddressId(obj1);
  const obj2Cleaned = removePartyIdAndAddressId(obj2);

  return JSON.stringify(obj1Cleaned) === JSON.stringify(obj2Cleaned);
}


export const updateSelectedIaPartydetails = (array: PersonalDetailsModel[], updateObj: PersonalDetailsModel) => {
  return array.map(obj => {
    // Update everything except partyId and addressId inside address
    let updatedObj = { ...obj, ...updateObj };

    // Preserve partyId
    updatedObj.partyId = obj.partyId;
    updatedObj.filingRefrenceNumber = obj.filingRefrenceNumber;

    // Update address by merging each address but keeping the original addressId
    updatedObj.address = obj.address.map((address, index) => {
      const updatedAddress = { ...updateObj.address[index], addressId: address.addressId };
      return updatedAddress;
    });

    return updatedObj;
  });
};

export function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Set up the onload event to handle the Base64 result
    reader.onload = () => {
      const readerResult = reader.result as string
      const base64String = readerResult.split(',')[1]; // Extract Base64 part
      resolve(base64String); // Resolve the Promise with Base64 string
    };

    // Handle errors
    reader.onerror = (error) => {
      reject(error); // Reject the Promise if there is an error
    };

    // Read the file as a Data URL (Base64 string)
    reader.readAsDataURL(file);
  });
}

// Return ordinal suffixes for dates, for example 21st, 30th, etc.
export function daySuffix(day: number) {
  return ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10]
};

export const getInitials = (text: string) => {
  if (text === "CCPA Appeal") {
    return text.split(' ')[0];
  }
  const sentence = text.split(' ');
  const initials = sentence.map((charac) => charac.charAt(0).toUpperCase());
  return initials.join('');
};

export const getCommissionTypeList = (): IDropdown[] => {
  return [
    { label: "NCDRC", value: 0 },
    { label: "SCDRC", value: 1 },
    { label: "DCDRC", value: 2 },
  ]
}

// Utility function to join labels with newline characters
export const createHeaderWithBreaks = (labels: string[]): string => {
  return labels.join(' /\n');
};

export function checkTypeIdIncludesMainComplainantAndRespondent(arr: any, param: string) {
  let hasOne = false;
  let hasTwo = false;

  // Iterate through the array of objects
  arr.forEach((obj: any) => {
    if (obj[param] === "1") {
      hasOne = true;
    }
    if (obj[param] === "2") {
      hasTwo = true;
    }
  });

  // Check if both values are present
  if (!hasOne || !hasTwo) {
    return false;
  }

  return true;
};

export function getComplainantRespondentRole(details: IPartyRoleDetails): string {
  let result: string;
  const sequenceNumber = details.complainantRespondentSequenceNumber ?? -1;

  switch (true) {
    case details.complainantRespondantTypeId === 1 && details.complainantRespondentSequenceNumber === 0:
      result = "Main Complainant";
      break;
    case details.complainantRespondantTypeId === 2 && details.complainantRespondentSequenceNumber === 0:
      result = "Main Respondent";
      break;
    case details.complainantRespondantTypeId === 1 && sequenceNumber > 0:
      result = "Additional Complainant";
      break;
    case details.complainantRespondantTypeId === 2 && sequenceNumber > 0:
      result = "Additional Respondent";
      break;
    case details.complainantRespondantTypeId === 3 && sequenceNumber > 0:
      result = "Additional Complainant Proforma ";
      break;
    case details.complainantRespondantTypeId === 4 && sequenceNumber > 0:
      result = "Additional Respondent Proforma ";
      break;
    default:
      result = "N/A";
      break;
  }

  return result;
}

export function isSingleDigit(number: number) {
  return number >= -9 && number <= 9;
};

export function getEnumKeyByValue(enumObj: any, value: any) {
  const label = Object.keys(enumObj).find(key => enumObj[key] === value);
  return label;
}