import { ArrowForward, Delete, Edit, Visibility } from "@mui/icons-material";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  ConfonetCard,
  ConfonetCardBody,
  ConfonetCardHeader,
  ConfonetIconButton,
  DataTable,
  Dialogbox,
  PreviewCard,
  Textbox,
} from "../../../components";
import { BasicBreadcrumbs } from "../../../components/controls/BasicBreadcrumbs";
import { BasicButton } from "../../../components/controls/BasicButton";
import {
  IGetNoticeDetailsResponse,
  IGetNoticeIssuedSoFarResponse,
  IssueNoticeFormViewModel,
} from "../../../models/IssueNotice/IssueNoticeModel";
import {
  COMMISSION_DASHBOARD,
  COMMISSION_EDIT_NOTICE,
  COMMISSION_GENERATE_NOTICE,
  DEALING_ASSISTANT_DASHBOARD,
  DEALING_ASSISTANT_GENERATE_NOTICE,
  JUDGE_NOTICE_GENERATE_PAGES,
  JUDGE_NOTICE_PAGES
} from "../../../navigation/CONSTANTS";
import { RootState } from "../../../redux";
import { CommissionCaseService } from "../../../services/CaseService/CommissionCaseService/CommissionCaseService";
import { ROLE } from "../../../utils/Enum";
import { flattenArrayToIPreview } from "../../../utils/Helper";
import { useCaseSpecificData } from "../../../hooks/useCaseSpecificData";
import ConfonetTextEditorTiny from "../../../components/controls/ConfonetTextEditorTinyMCE";

interface INoticeIssuedListRow extends IGetNoticeIssuedSoFarResponse {
  id: number;
  issueDate: string;
  status: string;
}

/**
 * Component for issue print notice page list
 */
const IssuePrintNoticeView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  const initialValues = IssueNoticeFormViewModel.init();
  const [noticeDetails, setNoticeDetails] = useState<IGetNoticeDetailsResponse[]>();
  const [noticeIssuesSoFarRows, setNoticeIssuesSoFarRows] = useState<
    INoticeIssuedListRow[]
  >([]);
  const [judgeNoticeView, setJudgeNoticeView] = useState<string>("")
  const [showJudgeNotice, setShowJudgeNotice] =
    useState<boolean>(false);
  const { complainantLabel, respondentLabel } = useCaseSpecificData({
    caseTypeId: noticeDetails?.[0].caseTypeId ?? 1,
    handleNext: () => { },
    setNewFilingReferenceNumber: () => { }
  })

  // Validations for president/member Form.
  const validationSchema = Yup.object({
    caseNumber: Yup.string().required(t("Validation.CaseNumberRequired")),
  });

  // Navigates to generate notice page in create
  const generateNewNotice = () => {
    const state = {
      noticeDetails,
      caseNumber: location.pathname.includes(JUDGE_NOTICE_PAGES) ? location.state : formik.values.caseNumber,

    };
    if (location.pathname.includes(JUDGE_NOTICE_PAGES)) {
      navigate(userDetailsData.roleId !== ROLE.DealingAssistant ? JUDGE_NOTICE_GENERATE_PAGES : DEALING_ASSISTANT_GENERATE_NOTICE, { state });

    } else {
      navigate(userDetailsData.roleId !== ROLE.DealingAssistant ? COMMISSION_GENERATE_NOTICE : DEALING_ASSISTANT_GENERATE_NOTICE, { state });

    }
  };

  // Navigates to generate notice page in edit or view mode
  const editOrViewExistingNotice = async (
    fillingReferenceNumber: number,
    noticeId: number,
    readOnly: boolean = false
  ) => {
    if (noticeDetails) {
      const generateEditData = await CommissionCaseService.getCaseNotices(
        fillingReferenceNumber,
        noticeId
      );


      const state = {
        noticeDetails,
        caseNumber: formik.values.caseNumber,
        generateEditData,
        readOnly,
      };
      if (location.pathname.includes(JUDGE_NOTICE_PAGES)) {
        setJudgeNoticeView(generateEditData[0]?.noticeTypeText)
        setShowJudgeNotice(true)
      } else {
        navigate(COMMISSION_EDIT_NOTICE, { state });
      }

    }
  };

  // Deletes notice
  const deleteExistingNotice = async (
    fillingReferenceNumber: number,
    noticeId: number
  ) => {
    await CommissionCaseService.deleteCaseNotice({
      fillingReferenceNumber,
      noticeId,
    });
    handleGetNoticesIssuedSoFar(formik.values.caseNumber);
  };

  // Get list of notices issued so far using API
  const handleGetNoticesIssuedSoFar = async (caseNumber: string) => {
    const response = await CommissionCaseService.getNoticeIssuedSoFar(
      caseNumber
    );
    if (response) {
      const noticeList = response.map(
        (
          noticeList: IGetNoticeIssuedSoFarResponse[],
          index: number
        ): INoticeIssuedListRow => {

          return {
            ...noticeList[0],
            id: index + 1,
            issueDate: noticeList[0].noticeFinalizedStatus
              ? noticeList[0].noticeGenerationdate
              : noticeList[0].noticeCreatedDate,
            issuedFor: noticeList
              // .filter((record: IGetNoticeIssuedSoFarResponse): boolean => record.)
              .map(
                (record: IGetNoticeIssuedSoFarResponse): string =>
                  record.issuedFor
              )
              .join(", "),
            status: noticeList[0].noticeFinalizedStatus === 1 ? "Not finalized" : "Finalized"
          };
        }
      );
      setNoticeIssuesSoFarRows(noticeList);
    }
  };

  // triggers when the form is submitted
  const handleSubmit = async () => {
    const detailsResponse = await CommissionCaseService.getNoticeDetails(
      formik.values.caseNumber
    );
    if (detailsResponse) {
      handleGetNoticesIssuedSoFar(formik.values.caseNumber);
      setNoticeDetails(
        [...detailsResponse].sort(
          (
            first: IGetNoticeDetailsResponse,
            second: IGetNoticeDetailsResponse
          ) => first.sequenceNumber - second.sequenceNumber
        )
      );
    }
  };
  useEffect(() => {
    if (location.pathname.includes(JUDGE_NOTICE_PAGES)) {
      // triggers when the form is submitted
      const handleSubmit = async () => {
        const detailsResponse = await CommissionCaseService.getNoticeDetails(
          location.state);
        if (detailsResponse) {
          handleGetNoticesIssuedSoFar(location.state);
          setNoticeDetails(
            [...detailsResponse].sort(
              (
                first: IGetNoticeDetailsResponse,
                second: IGetNoticeDetailsResponse
              ) => first.sequenceNumber - second.sequenceNumber
            )
          );
        }
      };
      handleSubmit()
    };
  }, [])
  // define the formik form for validation and form submission
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue } = formik
  // Get data from location when page is reloaded
  const handleReload = useCallback(async () => {
    if (location.state?.noticeDetails) {
      await setFieldValue("caseNumber", location.state.caseNumber);
      handleGetNoticesIssuedSoFar(location.state.caseNumber);
      setNoticeDetails(location.state.noticeDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue]);

  const NoticeIssuesSoFarColumns: GridColDef[] = [
    { field: "id", headerName: t("Label.SerialNo"), flex: 0.25 },
    { field: "noticeType", headerName: t("Label.NoticeType"), flex: 1 },
    {
      field: "authorisedSignatory",
      headerName: t("Label.AuthorizedSignatory"),
      flex: 1,
    },
    { field: "issuedFor", headerName: t("Label.IssuedFor"), flex: 1 },
    { field: "issueDate", headerName: t("Label.IssueDate"), flex: 1 },
    { field: "sentThrough", headerName: t("Label.SentThrough"), flex: 1 },
    {
      field: "status",
      headerName: t("Label.Status"),
      flex: 1,
      renderCell: (params) => {
        // Assuming params.value contains the status
        const isSuccess = params.row.noticeFinalizedStatus === 1; // Adjust this condition based on your status values
        return (
          <BasicButton
            text=""
            variant={"text"}
            size="small"
            color={isSuccess ? "error" : "success"}

          >
            {params.value}
          </BasicButton>

        );
      },
    },
    {
      field: "Action",
      headerName: t("Label.Action"),
      flex: 1,
      renderCell: (params) => {
        return (

          <>
            {
              location.pathname.includes(JUDGE_NOTICE_PAGES) ? <div>
                <ConfonetIconButton
                  icon={<Visibility />}
                  onClick={() =>
                    editOrViewExistingNotice(
                      params.row.fillingNumber,
                      params.row.noticeId,
                      true
                    )

                  }
                />
              </div> : <div>
                <ConfonetIconButton
                  icon={<Visibility />}
                  onClick={() =>
                    editOrViewExistingNotice(
                      params.row.fillingNumber,
                      params.row.noticeId,
                      true
                    )
                  }
                />
                {params.row.noticeFinalizedStatus === 1 && (
                  <>
                    <ConfonetIconButton
                      icon={<Edit />}
                      onClick={() =>
                        editOrViewExistingNotice(
                          params.row.fillingNumber,
                          params.row.noticeId
                        )
                      }
                    />
                    <ConfonetIconButton
                      icon={<Delete />}
                      onClick={() =>
                        deleteExistingNotice(
                          params.row.fillingNumber,
                          params.row.noticeId
                        )
                      }
                    />
                  </>
                )}
              </div>
            }

          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleReload();
  }, [handleReload]);


  return (

    <section className="section margin-top-35">
      <div className="col-lg-12 text-end margin-top-25 margin-bottom-25">
        <Dialogbox
          title={"Notice Detail"}
          maxWidth="lg"
          open={showJudgeNotice}
          onClose={() => setShowJudgeNotice(false)}
        >
          <DialogContent >
            <ConfonetTextEditorTiny
              toolbar={[]}
              id="notice"
              value={judgeNoticeView}
              height={800}
              disabled={true}
            />
          </DialogContent>
          <DialogActions className="d-flex justify-content-center align-items-center">

          </DialogActions>

        </Dialogbox>
        <div className="m-5px" >

        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <BasicBreadcrumbs
              items={[
                { to: userDetailsData.roleId !== ROLE.DealingAssistant ? COMMISSION_DASHBOARD : DEALING_ASSISTANT_DASHBOARD, label: t("Breadcrumb.Dashboard") },
                { to: "", label: t("Sidebar.IssuePrintNotice") },
              ]}
            />
          </div>
        </div>

        {!noticeDetails ? (
          <form onSubmit={formik.handleSubmit}>
            <div className="row margin-top-25">
              <div className="col-lg-12">
                <ConfonetCard>
                  <ConfonetCardHeader>
                    <div className="card-title card-primary-header-title">
                      {t("Label.GenerateModifyNotices")}
                    </div>
                  </ConfonetCardHeader>
                  <ConfonetCardBody>
                    <div className="row justify-content-center margin-top-10">
                      <div className="col-lg-6 col-sm-6 child-element-margin-bottom-0">
                        <Textbox
                          id="caseNumber"
                          name="caseNumber"
                          label={t("Label.CaseNumber") + "*"}
                          value={formik.values.caseNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.caseNumber &&
                            Boolean(formik.errors.caseNumber)
                          }
                          helperText={
                            formik.touched.caseNumber &&
                            formik.errors.caseNumber
                          }
                        />
                      </div>
                    </div>
                  </ConfonetCardBody>
                </ConfonetCard>
              </div>
              <div className="col-lg-12 text-end margin-top-25">
                <BasicButton
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForward />}
                  text={t("BtnText.Next")}
                  disabled={
                    !formik.isValid ||
                    !formik.dirty ||
                    !formik.values.caseNumber
                  }
                />
              </div>
            </div>
          </form>
        ) : (
          <>
            <div className="row margin-top-25">
              <div className="col-lg-12">
                {
                  <PreviewCard
                    heading={t("Label.CaseDetails")}
                    items={flattenArrayToIPreview({
                      caseNumber: location.pathname.includes(JUDGE_NOTICE_PAGES) ? location.state : formik.values.caseNumber,
                      filingReferenceNumber:
                        noticeDetails[0].filingReferenceNumber,
                      [complainantLabel]: noticeDetails[0].complainantName,
                      [respondentLabel]: noticeDetails[1].complainantName,
                      dateOfFiling: DateTime.fromFormat(
                        noticeDetails[0].caseFilingDate,
                        "yyyy-MM-dd"
                      ).toFormat("dd-MM-yyyy"),
                    })}
                  />
                }

              </div>
            </div>

            <div className="row margin-top-25">
              <div className="col-lg-12 margin-bottom-25">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <Typography variant="h4">
                      {t("Label.NoticeIssuesSoFar")}
                    </Typography>
                  </div>
                  <div className="col-lg-4 text-end">{
                    location.pathname.includes(JUDGE_NOTICE_PAGES) ? "" : <BasicButton
                      variant="contained"
                      text={t("Label.GenerateNewNotice")}
                      onClick={generateNewNotice}
                    />
                  }

                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <DataTable
                  // rowHeight={90}
                  rows={noticeIssuesSoFarRows}
                  columns={NoticeIssuesSoFarColumns}
                  searchBar={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default IssuePrintNoticeView;
