import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BasicBreadcrumbs, BasicButton, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Dialogbox, PreviewCard } from '../../../../components';
import ConfonetPdfViewer from '../../../../components/controls/ConfonetPdfViewer';
import { IConfonetToggleButtonProps } from '../../../../components/controls/ConfonetToggleButton';
import { ConfonetToggleButtonGroup } from '../../../../components/controls/ConfonetToggleButtonGroup';
import DataTableWithSearchAndTitle from '../../../../components/controls/ReusableComponents/DataTableWithSearchAndTitle';
import { IGetListOfIACaseByCaseNumberResponse } from '../../../../models/cases/commissionCase/ICommissionCase';
import { JUDGE_BENCH_DETAILS, JUDGE_CASE_PREVIEW_ATTACHED_CASE, JUDGE_COMPLAINT_PAGES, JUDGE_DAILY_ORDER, JUDGE_IA_FILLED, JUDGE_NOTICE_PAGES } from '../../../../navigation/CONSTANTS';
import { RootState } from '../../../../redux';
import { CaseServices } from '../../../../services/CaseService/CaseServices';
import { CommissionCaseService } from '../../../../services/CaseService/CommissionCaseService/CommissionCaseService';
import { loaderService } from '../../../../services/CommonService/loaderService';
import { handleCaseTypePartyNames, handleGetDocumentsWithContent, handleGetDocumentsWithoutContent, replaceTwoKeysWithSwitch } from '../../../../utils/CaseHelper';
import { caseTypeEnum } from '../../../../utils/Enum';
import { convertBase64ToUInt8, flattenArrayToIPreview, mergePdfUnit8Array } from '../../../../utils/Helper';
import { ICaseStatusDetailsData, ICaseStatusHearingDetails } from '../../../MainWebPages/CaseHistoryCaseStatus/components/CaseSearch';
import ButtoBack from '../Button';
import { ICaseDocumentList, IIaCaseDocumentListForCompleteDoc, IIACaseList } from '../JudgeCasePagesPreview';
import JudgeDocumentCard from './components/JudgeDocumentCard';
import CasesCardComponent from '../CasesCardComponent';
import { complainantBenchDocs } from '../../../../utils/CONSTANTS';

export interface IJudgeCaseDocumentList {
    caseFilingDocumentDetailsId: number;
    documentName: string;
    sequenceNumber: number;
    documentId: number;
};

const casesData = [
    { id: 1, title: 'View Daily Orders', type: 3 },
];

const noticeCardData = [
    { id: 4, title: 'Notice', type: 3 },
    { id: 5, title: 'Registry Report', type: 3 },
]

const JudgeCaseDetails = () => {
    const { t } = useTranslation();
    const { state, pathname } = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    // redux state
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    // states of this components
    const attachedCasePath = pathname.includes('attached');
    const [caseStatusDetails, setCaseStatusDetails] = useState<any>();
    const [caseHearingDetails, setCaseHearingDetails] = useState<ICaseStatusHearingDetails[]>([]);
    const [caseDataRow, setCaseDataRow] = useState<IIACaseList[]>([]);
    const [selectedParty, setSelectedParty] = useState<number>(0);
    const [caseCardsData, setCaseCardsData] = useState<typeof casesData>(casesData);
    const [viewMode, setViewMode] = useState<string>('grid');
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));
    const [cardClickId, setCardClickId] = useState<number>();
    const [allDocumentList, setAllDocumentList] = useState<ICaseDocumentList[]>([]);
    const [replyDocList, setReplyDocList] = useState<ICaseDocumentList[]>([]);
    const [rejoinder, setRejoinder] = useState<ICaseDocumentList[]>([]);
    const [iaDocumentList, setIaDocumentList] = useState<IIaCaseDocumentListForCompleteDoc[]>([]);
    const [judgeCaseFilingDocList, setJudgeCaseFilingDocList] = useState<IJudgeCaseDocumentList[]>([]);
    const [judgeReplygDocList, setJudgeReplyDocList] = useState<IJudgeCaseDocumentList[]>([]);
    const [judgeRejoinderDocList, setJudgeRejoinderDocList] = useState<IJudgeCaseDocumentList[]>([]);
    const [judgeSubsequentDocList, setJudgeSubsequentDocList] = useState<IJudgeCaseDocumentList[]>([]);

    // get the case information
    const getCaseData = useCallback(async () => {
        const caseStatusResponse = state?.caseDetails;
        if (caseStatusResponse && Object.keys(caseStatusResponse).length > 0) {
            // make status details to view
            const caseStatus: ICaseStatusDetailsData = {
                caseNumber: caseStatusResponse?.caseNumber,
                complainant: caseStatusResponse?.complainant,
                respondent: caseStatusResponse?.respondent,
                filingDate: caseStatusResponse?.caseFilingDate ? DateTime.fromISO(caseStatusResponse?.caseFilingDate).toFormat("dd-MM-yyyy") : "N/A",
                nextHearing: caseStatusResponse?.dateOfNextearing ? DateTime.fromISO(caseStatusResponse?.dateOfNextearing).toFormat("dd-MM-yyyy") : "N/A",
                attachedCase: caseStatusResponse?.attachedLowerCourt ? caseStatusResponse?.attachedLowerCourt[0] : undefined
            }
            const statusOfCase = replaceTwoKeysWithSwitch(caseStatus, "complainant", "respondent", caseStatusResponse.caseTypeId)

            const hearingDetails = caseStatusResponse?.caseHearingDetails?.filter((item: any) => item.dateOfHearing !== null).map((item: any, index: number) => ({
                ...item,
                id: index + 1
            }))?.reverse();
            setCaseStatusDetails(statusOfCase);
            setCaseHearingDetails(hearingDetails);

        } else {
            toast.info('Case Details not available for migrated Data');
            setCaseStatusDetails(undefined);
            setCaseHearingDetails([]);
        }
    }, [state?.caseDetails]);

    // get all IA case list attached to the case number
    const getIaCaseStageList = useCallback(async (typeId?: number) => {
        const iACaseList = await CommissionCaseService.getListOfAttachedCaseByCaseNumber(state?.caseNumber, caseTypeEnum.InterlocutoryApplication);
        let allIaCaseList: IIACaseList[] = [];
        if (iACaseList && iACaseList?.length) {
            allIaCaseList = iACaseList.map((cases: IGetListOfIACaseByCaseNumberResponse, index: number) => {
                return ({
                    id: index + 1,
                    ...cases
                })
            });
            if (!typeId) {
                setCaseDataRow(allIaCaseList);
            }
        }

        return allIaCaseList;
    }, [state?.caseNumber]);

    // get all the documents list
    const handleGetDocuments = useCallback(async () => {
        const { caseFilingDocuments, replyDocumentList, rejoinderDocumentList, subsequentDocumentList } = await handleGetDocumentsWithoutContent(state?.caseNumber, userDetailsData.commissionId as number);
        setJudgeCaseFilingDocList(caseFilingDocuments);
        setJudgeReplyDocList(replyDocumentList);
        setJudgeRejoinderDocList(rejoinderDocumentList);
        setJudgeSubsequentDocList(subsequentDocumentList);
    }, [state?.caseNumber, userDetailsData.commissionId]);

    const handleDocumentCardClick = async (id: number, callType?: number) => {
        setCardClickId(id);
        if (id > 20) {
            const fileString = await CaseServices.getDocumentContentById(id, callType as number);
            if (fileString?.fileContent?.length > 0) {
                setDocumentContent(convertBase64ToUInt8(fileString?.fileContent))
            } else {
                toast.info('Document not available')
            }
        } else {
            if (id === 0) {
                const filesList = await handleGetDocumentsWithContent(state.caseNumber, userDetailsData?.commissionId as number);
                const { documentArray, documentLabelList, replyDocumentList, rejoinderDocumentList, iaDocsList } = filesList;
                loaderService.setLoading(true);
                const mergedPDf = await mergePdfUnit8Array(documentArray);

                // set all the local state
                setAllDocumentList(documentLabelList);
                setReplyDocList(replyDocumentList);
                setRejoinder(rejoinderDocumentList);
                setIaDocumentList(iaDocsList);
                setDocumentContent(mergedPDf);
            } else if (id === 1) {
                const caseNumber = state.caseNumber;
                navigate(JUDGE_DAILY_ORDER, { state: { caseHearingDetails, caseNumber, caseStatusDetails } });
            } else if (id === 4) {
                const caseNumber = state.caseNumber;
                navigate(JUDGE_NOTICE_PAGES, { state: caseNumber });
            } else {
                toast.info('No Preview Available')
            }
        }
        loaderService.clearLoading();
    };

    const handleDocumentChange = (id: number, caseNumber?: string) => {
        const document = allDocumentList.find((item) => item.caseFilingDocumentDetailsId === id);
        const documentFromReplyList = replyDocList.find((item) => item.caseFilingDocumentDetailsId === id);
        const documentFromRejoinderList = rejoinder.find((item) => item.caseFilingDocumentDetailsId === id);

        // ia case documents handling
        let documentFromIaList: ICaseDocumentList | undefined
        let selectedCase: IIaCaseDocumentListForCompleteDoc | undefined;
        if (caseNumber) {
            selectedCase = iaDocumentList.find(caseObj => caseObj.caseNumber === caseNumber);
            if (selectedCase) {
                documentFromIaList = selectedCase.documentList.find((item) => item.caseFilingDocumentDetailsId === id);
            }
        };

        if (document) {
            const pdf = convertBase64ToUInt8(document.fileContent);
            setDocumentContent(pdf);
        } else if (documentFromReplyList) {
            const pdf = convertBase64ToUInt8(documentFromReplyList.fileContent);
            setDocumentContent(pdf);
        } else if (documentFromRejoinderList) {
            const pdf = convertBase64ToUInt8(documentFromRejoinderList.fileContent);
            setDocumentContent(pdf);
        } else if (documentFromIaList) {
            const pdf = convertBase64ToUInt8(documentFromIaList.fileContent);
            setDocumentContent(pdf);
        }

    }

    const handleNavigate = (caseNumber: string) => {
        setCaseDataRow([]);
        navigate(JUDGE_CASE_PREVIEW_ATTACHED_CASE, { state: { caseNumber, previousCaseNumber: state.caseNumber } });
    };

    const judgeDataList: GridColDef[] = [
        { field: "id", headerName: t("Label.Id"), maxWidth: 70, flex: 1 },
        {
            field: "caseNumber",
            headerName: t("IA No."),
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.documents ? (
                            <p
                                onClick={() => handleNavigate(params.row.caseNumber)}
                                style={{
                                    color: "darkblue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                            >
                                {params.row.caseNumber}
                            </p>
                        ) : (
                            <p>
                                {params.row.caseNumber}
                            </p>
                        )}
                    </>
                )
            }
        },
        // { field: "caseFilingDate", headerName: t("Label.DateOfFiling"), flex: 1 },
        {
            field: "caseFilingDate",
            headerName: t("Label.DateOfFiling"),
            flex: 1,
            valueFormatter: (params) => {
                return params.value ? DateTime.fromISO(params.value).toFormat("dd-MM-yyyy") : "N/A";
            },
        },
        { field: "complainantName", headerName: t("Filed By"), flex: 1 },
        { field: "purpose", headerName: t("Purpose"), flex: 1 },
        {
            field: "status", headerName: t("Status"), flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Typography sx={{
                            color: params.row.status.toLowerCase() === "pending" ? theme.palette.error.main : theme.palette.success.main
                        }}>
                            {params.row.status}
                        </Typography>
                    </>
                )
            }
        },
        { field: "lastHearingDate", headerName: t("Label.DateOfLastHearing"), flex: 1 },

        {
            field: "Action",
            headerName: t("Label.Action"),
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <>
                        {params.row.documents ? <div className="d-flex">
                            <BasicButton
                                className="btn-custom-view p-0 min-width-auto margin-right-10"
                                text=""
                                variant="text"
                                onClick={() => handleNavigate(params.row.caseNumber)}
                            />
                        </div> : <p>Documents not available</p>}
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getCaseData();
        if (!attachedCasePath) {
            getIaCaseStageList();
        }
        handleGetDocuments();
    }, [attachedCasePath, getCaseData, getIaCaseStageList, handleGetDocuments]);

    useEffect(() => {
        // set cases First Card list
        if ((state.caseTypeId === caseTypeEnum.FirstAppeal || state.caseTypeId === caseTypeEnum.SecondAppeal || state.caseTypeId === caseTypeEnum.RevisionPetition) && caseCardsData.some(obj => obj.id !== 2)) {
            setCaseCardsData([...caseCardsData, { id: 2, title: "Limitation Report", type: 3 }]);
        }

        if (judgeCaseFilingDocList.length === 0) {
            const cardData = caseCardsData.filter((card) => card.id !== 0);
            setCaseCardsData(cardData);
        } else {
            const cardData = [...caseCardsData];
            cardData.unshift({ id: 0, title: 'Complete Documents', type: 3 },)
            setCaseCardsData(cardData);
        }
    }, [state.caseTypeId, judgeCaseFilingDocList]);

    const toggleButtonItem: IConfonetToggleButtonProps[] = [
        {
            icon: <ViewListIcon />,
            value: "list",
        },
        {
            icon: <ViewModuleIcon />,
            value: "grid"
        },
        {
            icon: <ViewCompactIcon />,
            value: "all"
        }
    ];

    const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setViewMode(nextView);
    };

    const handleCaseDocumentCardClick = async (id: number) => {
        switch (id) {
            case 1:
                if (judgeCaseFilingDocList && judgeCaseFilingDocList.length > 0) {
                    navigate(JUDGE_COMPLAINT_PAGES, { state: { documentList: judgeCaseFilingDocList.filter((item) => item.documentId <= 8), title: "Case Documents" } })
                } else {
                    toast.info(`Documents Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 2:
                if (judgeCaseFilingDocList && judgeCaseFilingDocList.length > 0) {
                    const affidavitDocument = judgeCaseFilingDocList.filter((item => (item.documentName.toLowerCase().includes('complainant') && item.documentName.toLowerCase().includes('affidavit')) || item.documentName.toLowerCase().includes('affidavit')));
                    if (affidavitDocument.length) {
                        handleDocumentCardClick(affidavitDocument[0].caseFilingDocumentDetailsId, 1);
                    } else {
                        toast.info(`Affidavit Documents Not available for the case ${state?.caseNumber}`)
                    }
                } else {
                    toast.info(`Affidavit Documents Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 3:
                if (judgeCaseFilingDocList && judgeCaseFilingDocList.length > 0) {
                    navigate(JUDGE_COMPLAINT_PAGES, { state: { documentList: judgeCaseFilingDocList.filter((item) => item.documentId > 8), title: complainantBenchDocs(state.caseTypeId)[2].label } })
                } else {
                    toast.info(`Additional Complainant Documents Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 4:
                if (judgeRejoinderDocList && judgeRejoinderDocList.length > 0) {
                    navigate(JUDGE_COMPLAINT_PAGES, { state: { documentList: judgeRejoinderDocList, title: complainantBenchDocs(state.caseTypeId)[3].label } })
                } else {
                    toast.info(`Rejoinder Documents Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 5:
            case 14:
                const caseData = await getIaCaseStageList();
                if (caseData.length) {
                    navigate(JUDGE_IA_FILLED, { state: { caseDataRow: await getIaCaseStageList(), title: complainantBenchDocs(state.caseTypeId)[4].label } });
                } else {
                    toast.info(`No documents available for IAs filed by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel}`)
                }
                break;
            case 6:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const writtenSubmissionComplaint = judgeSubsequentDocList.filter((item => item.documentId === 804));
                    if (writtenSubmissionComplaint && writtenSubmissionComplaint.length) {
                        handleDocumentCardClick(writtenSubmissionComplaint[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Written Submission of ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Written Submission of ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 7:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const evidenceAffidavitComplainant = judgeSubsequentDocList.filter((item => item.documentId === 805));
                    if (evidenceAffidavitComplainant && evidenceAffidavitComplainant.length) {
                        handleDocumentCardClick(evidenceAffidavitComplainant[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Evidence affidavit by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Evidence affidavit by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 8:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const citationComplainant = judgeSubsequentDocList.filter((item => item.documentId === 806));
                    if (citationComplainant && citationComplainant.length) {
                        handleDocumentCardClick(citationComplainant[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Citations filed by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Citations filed by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 9:
            case 19:
                const vakalatnamaDocuments = judgeCaseFilingDocList?.filter((docs) => docs?.documentName?.toLowerCase()?.includes('vak'))
                const vakalatnamaDocumentsComp = judgeCaseFilingDocList?.filter((docs) => docs?.documentName?.toLowerCase()?.includes('vak') && !docs?.documentName?.toLowerCase()?.includes('op'))
                if (vakalatnamaDocuments && vakalatnamaDocuments?.length > 0) {
                    if (id === 19) {
                        const vakalatnamaOp = vakalatnamaDocuments.filter((docs) => docs.documentName.toLowerCase().includes('op'));
                        if (vakalatnamaOp?.length) {
                            handleDocumentCardClick(vakalatnamaOp[0].caseFilingDocumentDetailsId, 1);
                        } else {
                            toast.info(`Vakalatnama for Op Not available for the case ${state?.caseNumber}`)
                        }
                        return;
                    }
                    if (vakalatnamaDocumentsComp?.length) {
                        handleDocumentCardClick(vakalatnamaDocumentsComp[0].caseFilingDocumentDetailsId, 1);
                    } else {
                        toast.info(`Vakalatnama Not available for the case ${state?.caseNumber}`)
                    }
                } else {
                    toast.info(`Vakalatnama Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 11:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const affidavitOp = judgeSubsequentDocList.filter((item => item.documentId === 802));
                    if (affidavitOp && affidavitOp.length) {
                        handleDocumentCardClick(affidavitOp[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Affidavit of Admission/Denial of Document by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Affidavit of Admission/Denial of Document by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 12:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const writtenVersionOp = judgeSubsequentDocList.filter((item => item.documentId === 801));
                    if (writtenVersionOp && writtenVersionOp.length) {
                        handleDocumentCardClick(writtenVersionOp[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Written version of ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Written version of ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 13:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const additionalDocumentOp = judgeSubsequentDocList.filter((item => item.documentId === 803));
                    if (additionalDocumentOp && additionalDocumentOp.length) {
                        handleDocumentCardClick(additionalDocumentOp[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Additional document filed by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Additional document filed by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 15:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const writtenSubmissionOp = judgeSubsequentDocList.filter((item => item.documentId === 807));
                    if (writtenSubmissionOp && writtenSubmissionOp.length) {
                        handleDocumentCardClick(writtenSubmissionOp[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Written Submission of ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Written Submission of ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 16:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const evidenceAffidavitOp = judgeSubsequentDocList.filter((item => item.documentId === 808));
                    if (evidenceAffidavitOp && evidenceAffidavitOp.length) {
                        handleDocumentCardClick(evidenceAffidavitOp[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Evidence affidavit by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Evidence affidavit by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 17:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const citationOp = judgeSubsequentDocList.filter((item => item.documentId === 809));
                    if (citationOp && citationOp.length) {
                        handleDocumentCardClick(citationOp[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Citations filed by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Citations filed by ${handleCaseTypePartyNames(state.caseTypeId).respondentLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            case 18:
                if (judgeSubsequentDocList && judgeSubsequentDocList.length > 0) {
                    const serviceProof = judgeSubsequentDocList.filter((item => item.documentId === 810));
                    if (serviceProof && serviceProof.length) {
                        handleDocumentCardClick(serviceProof[0].caseFilingDocumentDetailsId, 2);
                    } else {
                        toast.info(`Citations filed by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                    }
                } else {
                    toast.info(`Citations filed by ${handleCaseTypePartyNames(state.caseTypeId).complainantLabel} Not available for the case ${state?.caseNumber}`);
                }
                break;
            default:
                toast.info(`Documents Not available for the case ${state?.caseNumber}`)
                break;
        }
    }


    return (
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-lg-9 col-sm-7 margin-bottom-30">
                    <BasicBreadcrumbs items={[
                        { to: JUDGE_BENCH_DETAILS, label: t("Breadcrumb.Dashboard") },
                        { to: '', label: t("Label.CaseDetails") },
                    ]} />
                </div>
            </div>
            {/* case detail card */}
            {caseStatusDetails &&
                <PreviewCard
                    className="margin-top-25"
                    heading={t("Label.CaseDetails")}
                    items={flattenArrayToIPreview(caseStatusDetails)}
                />
            }
            {!attachedCasePath && caseDataRow.length > 0 &&
                <DataTableWithSearchAndTitle
                    title={t("Label.ApplicationListed")}
                    row={caseDataRow}
                    column={judgeDataList}
                    partyDropdown={[
                        {
                            label: "Complainant/Add. Complainant",
                            value: 1
                        },
                        {
                            label: "Opposite Party/Add. Opposite Party",
                            value: 2
                        },
                    ]}
                    selectedParty={selectedParty}
                    handlePartyChange={(value) => setSelectedParty(+value)}
                />
            }
            {/* first caseCard */}
            <div>
                <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                    <Typography variant="h6"
                        style={{
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            fontWeight: 700,
                            lineHeight: '24.2px',
                            textAlign: 'left'
                        }}
                    >
                        Documents
                    </Typography>
                    <ConfonetToggleButtonGroup
                        toggleButton={toggleButtonItem}
                        value={viewMode}
                        exclusive
                        onChange={handleViewChange}
                    />
                </div>

                <Grid container spacing={3} className='mb-4'>
                    {caseCardsData.map((caseItem, index) => {
                        const idx = index + 1
                        return (
                            <JudgeDocumentCard
                                viewMode={viewMode}
                                index={idx}
                                id={caseItem.id}
                                title={caseItem.title}
                                callType={caseItem.type}
                                handleDocumentCardClick={handleDocumentCardClick}
                            />
                        )
                    })}
                </Grid>
            </div>
            {viewMode === 'all' && judgeCaseFilingDocList.length > 0 && (
                <>
                    <ConfonetCard>
                        <Grid sx={{ padding: '5px', marginBottom: '10px', width: '100%' }}>
                            <Typography
                                variant="h4"
                                style={{
                                    marginBottom: '9px',
                                    fontWeight: 'bold',
                                    width: "100%",
                                    height: "50px",
                                    padding: "15px",
                                    display: "flex"
                                }}
                            >
                                Case Documents
                            </Typography>
                            <Grid container spacing={3}>
                                {judgeCaseFilingDocList.map((doc, index) => {
                                    const idx = index + 1
                                    return (
                                        <JudgeDocumentCard
                                            viewMode={viewMode}
                                            index={idx}
                                            id={doc.caseFilingDocumentDetailsId}
                                            title={doc.documentName}
                                            callType={1}
                                            handleDocumentCardClick={handleDocumentCardClick}
                                        />
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </ConfonetCard>
                </>
            )}
            {viewMode !== 'all' && (
                <CasesCardComponent
                    caseTypeId={state.caseTypeId}
                    handleCaseDocumentCardClick={handleCaseDocumentCardClick}
                    viewMode={viewMode}
                />
            )}
            {/* Notices and Registry Report */}
            <div>
                <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                    <Typography variant="h6"
                        style={{
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            fontWeight: 700,
                            lineHeight: '24.2px',
                            textAlign: 'left'
                        }}
                    >
                        Notices and Registry Report
                    </Typography>
                </div>
                <Grid container spacing={3} className='mb-4'>
                    {noticeCardData.map((doc, index) => {
                        const idx = index + 1
                        console.log(viewMode);
                        return (
                            <JudgeDocumentCard
                                viewMode={viewMode}
                                index={idx}
                                id={doc.id}
                                title={doc.title}
                                callType={doc.type}
                                handleDocumentCardClick={handleDocumentCardClick}
                            />
                        )
                    })}
                </Grid>
            </div>
            <ButtoBack />
            <Dialogbox
                open={documentContent.length > 1}
                onClose={async () => {
                    await setDocumentContent(new Uint8Array([255]));
                }}
                maxWidth="lg"
                fullScreen
            >
                <DialogContent>
                    {documentContent.length > 1 && cardClickId !== 0 ? (
                        <ConfonetPdfViewer
                            fileUrl={documentContent}
                            onClose={() => {
                                setDocumentContent(new Uint8Array([255]));
                            }}
                            caseNumber={caseStatusDetails?.caseNumber ?? ""}
                        />
                    )
                        : (
                            documentContent.length > 1 && <ConfonetPdfViewer
                                fileUrl={documentContent}
                                documentNameList={allDocumentList}
                                replyDocumentList={replyDocList}
                                rejoinderDocumentList={rejoinder}
                                iaDocumentList={iaDocumentList}
                                handleDocumentChange={handleDocumentChange}
                                onClose={() => {
                                    setDocumentContent(new Uint8Array([255]));
                                }}
                                caseNumber={caseStatusDetails?.caseNumber ?? ""}
                            />
                        )
                    }
                </DialogContent>
            </Dialogbox>
        </div>
    )
}

export default JudgeCaseDetails