import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePickerProps, DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

type BasicDatePickerProps = DatePickerProps<DateTime> & {
  disableDays?: number[]
}

export function BasicDatePicker({ disableDays = [], format = "dd/MM/yyyy", ...rest }: BasicDatePickerProps) {

  const shouldDisableDate = (date: DateTime): boolean => {
    return disableDays.some((day: number) => day === date.weekday)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} >
      <div className="form-control-group margin-bottom-30">
        <DatePicker

          className="w-100" format={format} shouldDisableDate={shouldDisableDate}  {...rest} />
      </div>
    </LocalizationProvider>
  );
}
