import { IDropdown } from "../models/common/IDropdown";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { getCaseTypeNameByCaseTypeId, handleCaseTypePartyNames } from "./CaseHelper";
import { caseTypeEnum } from "./Enum";
import { getEnumKeyByValue, getInitials } from "./Helper";

export const Dashboard = "Dashboard";
export const languageDropdown: IDropdown[] = [
	{ label: "English", value: "en" }, // English (en)
	{ label: "हिंदी", value: "हिं" }, // HIndi (hi)
	//   { label: "मराठी", value: "मराठी" },  // Marathi (mr)
	//   { label: "ਪੰਜਾਬੀ", value: "ਪੰਜਾਬੀ" },  // Punjabi (pa)
	//   { label: "ગુજરાતી", value: "ગુજ" },  // Gujarati (gu)
	//   { label: "ಕನ್ನಡ", value: "ಕನ್ನಡ" },  // Kannada (kn)
	//   { label: "ଓଡିଆ", value: "ଓଡିଆ" },  // Odia (or)
	//   { label: "બંગાળી", value: "বাংলা" },  // Bengali (bn)
	//   { label: "മലയാളം", value: "മലയാളം" },  // Malayalam (ml)
	//   { label: "తెలుగు", value: "తెలుగు" },  // Telugu (te)
];

export const supportedLanguages = [
	"en",
	"en-US",
	"hi",
	"mr",
	"pa",
	"gu",
	"kn",
	"or",
	"bn",
	"ml",
	"te",
];

export const errorMessage = {
	USER_ALREADY_EXIST: "User is already exist",
	ROLE_DOES_NOT_EXIST: "This role is not exist",
	FIELD_ALREADY_EXIST: "Given field already exist",
	INVALID_ARGUMENT: "Invalid argument provided",
	USER_NOT_FOUND: "User not found",
	ACCED_DENIED: "You are not allowed to take this action",
	UNKNOWN: "Unknown error occured",
	EMPTY_REQUEST: "The request you are sending is empty",
	DATA_NOT_FOUND: "Data not found",
	SOMETHING_WENT_WRONG: "Something went wrong",
};

export const pdfMakeLibrary = pdfMake;
export const pdfMakeFonts = pdfFonts;

export const subsequentUploadDocumentTypeOptions: IDropdown[] = [
	{
		label: "Written version of OPs",
		value: 801
	},
	{
		label: "Affidavit of Admission/Denial of Document by OPs",
		value: 802
	},
	{
		label: "Additional document filed by OPs",
		value: 803
	},
	{
		label: "Written submission of complainant",
		value: 804
	},
	{
		label: "Evidence affidavit by complainant",
		value: 805
	},
	{
		label: "Citations filed by complainant",
		value: 806
	},
	{ 
		label: 'Written Submission of OPs', 
		value: 807 
	},
	{ 
		label: 'Evidence Affidavit by OPs', 
		value: 808 },
	{ 
		label: `Citations Filed by OPs`, 
		value: 809 },
	{
		label: "service proof",
		value: 810
	},
	{
		label: "Others",
		value: 0
	},
];

export const complainantBenchDocs = (caseTypeId: number) => {
	return [
		{ label: getCaseTypeNameByCaseTypeId(caseTypeId), value: 1 },
		{ label: `Affidavit of Admission/Denial of Documents by ${handleCaseTypePartyNames(caseTypeId).complainantLabel}`, value: 2 },
		{ label: `Additional Documents Filed by ${handleCaseTypePartyNames(caseTypeId).complainantLabel}s`, value: 3 },
		{ label: `Rejoinder by ${handleCaseTypePartyNames(caseTypeId).complainantLabel}(s)`, value: 4 },
		{ label: `IAs Filed by ${handleCaseTypePartyNames(caseTypeId).complainantLabel}(s)`, value: 5 },
		{ label: `Written Submission of ${handleCaseTypePartyNames(caseTypeId).complainantLabel}`, value: 6 },
		{ label: `Evidence Affidavit by ${handleCaseTypePartyNames(caseTypeId).complainantLabel}`, value: 7 },
		{ label: `Citations Filed by ${handleCaseTypePartyNames(caseTypeId).complainantLabel}(s)`, value: 8 },
		{ label: 'Vakalat Nama', value: 9 },
	]
};

export const oppositeBenchPartyDocs = (caseTypeId: number) => {
	return ([
		{ label: 'Affidavit of Admission/Denial of Documents by OPs', value: 11 },
		{ label: 'Written Version of OPs', value: 12 },
		{ label: `Additional Documents Filed by ${handleCaseTypePartyNames(caseTypeId).respondentLabel}`, value: 13 },
		{ label: 'IAs Filed by OPs', value: 14 },
		{ label: 'Written Submission of OPs', value: 15 },
		{ label: 'Evidence Affidavit by OPs', value: 16 },
		{ label: `Citations Filed by ${handleCaseTypePartyNames(caseTypeId).respondentLabel}(s)`, value: 17 },
		{ label: 'Service Proofs', value: 18 },
		{ label: 'Vakalat Nama', value: 19 },
	])
};

export const urlListToHideToastForNotfound = [
	'getListOfIACaseByCaseNumber',
	'getAttachedCaseListByCaseNumber'
];
