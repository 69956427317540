import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, DialogActions, DialogContent, TextField, Tooltip, Typography } from "@mui/material";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
    AddressDetails,
    BasicButton,
    BasicCheckbox,
    ConfonetAutocomplete,
    ConfonetCard,
    ConfonetCardBody,
    ConfonetCardHeader,
    DataTable,
    Dialogbox,
    Textbox,
    addressValidationSchema
} from "../..";
import { IDropdown } from "../../../models/common/IDropdown";
import { AddressDetailsModel } from "../../../models/forms/AddressDetailsModel";
import PersonalDetailsModel from "../../../models/forms/PersonalDetailsModel";
import { RootState } from "../../../redux";
import { dropdownValidationSchema, relationDropdown } from "../../../utils/Helper";
import { ValidationRegExp } from "../../../utils/ValidationRegExp";
import { GridColDef } from "@mui/x-data-grid";
import DataGridCustom from "../../../utils/CustomeDataGrid/DataGridCustom";
import { AdvocateDetailsList } from "../../../models/cases/commonCase/ICommonCaseModel";
import { MasterService } from "../../../services/MasterService/MasterService";
import { toast } from "react-toastify";
import { FileNewCaseServices } from "../../../services/CaseService/ConsumerCaseService/FileNewCaseService/FileNewCaseServices";
export const complainantPersonalValidationSchema = Yup.object({
    name: Yup.string()
        .required("Name is a required field")
        .max(99, "Only up to 99 characters are allowed")
        .matches(ValidationRegExp.NAMES, "Remove any special characters, numbers or extra spaces"),
    mobileNumber: Yup.string()
        .required("Mobile number is a required field")
        .matches(ValidationRegExp.ONLY_MOBILE_NUM, "Mobile number must be valid")
        .min(10, "Mobile number must have 10 digits")
        .max(10, "Mobile number must have 10 digits"),
    email: Yup.string()
        .matches(ValidationRegExp.ONLY_EMAIL, "Email must be valid")
        .max(50, "Only up to 50 characters are allowed"),
    isSeniorCitezen: Yup.boolean(),
    isWidow: Yup.boolean(),
    isDifferentlyAbled: Yup.boolean(),
    isHandicapped: Yup.boolean(),
    address: Yup.array()
        .min(1, "At least one address needed")
        .of(addressValidationSchema),
    handicapped: Yup.lazy((value, options) => {
        const { parent } = options;
        if (parent.isHandicapped) {
            return dropdownValidationSchema("Handicapped is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
})

export const respondentPersonalValidationSchema = Yup.object({
    name: Yup.string()
        .max(250, "Only up to 250 characters are allowed")
        .required("Name is a required field")
        .matches(ValidationRegExp.NAMES, "Remove any special characters, numbers or extra spaces"),
    mobileNumber: Yup.string()
        // .required("Mobile number is a required field")
        .matches(ValidationRegExp.ONLY_MOBILE_NUM, "Mobile number must be valid")
        .max(10, "Mobile number must have 10 digits"),
    email: Yup.string()
        .matches(ValidationRegExp.ONLY_EMAIL, "Email must be valid")
        .max(50, "Only up to 50 characters are allowed"),
    isSeniorCitezen: Yup.boolean(),
    isWidow: Yup.boolean(),
    isDifferentlyAbled: Yup.boolean(),
    isHandicapped: Yup.boolean(),
    address: Yup.array()
        .of(addressValidationSchema),
    handicapped: Yup.lazy((value, options) => {
        const { parent } = options;
        if (parent.isHandicapped) {
            return dropdownValidationSchema("Handicapped is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
})

type PersonalDetailsProps = {
    name: string,
    label: string,
    values: PersonalDetailsModel,
    touched?: FormikTouched<PersonalDetailsModel>,
    errors?: string | FormikErrors<PersonalDetailsModel>,
    singleAddress?: boolean,
    handleBlur: {
        (e: FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    },
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void,
    advocateList?: IDropdown[],
    isRespondent?: boolean,
    disabled?: boolean
}

/** Renders the Personal Details sub-form
 * @param PersonalDetailsProps
 * 
 * ### Fields
 * * Name
 * * Mobile Number
 * * Email
 * * Is Widow
 * * Is Senior Citizen
 * * Is Diffrently Abled
 * * Address
 */
export const PersonalDetails = ({ name, label, values, touched, errors, singleAddress = false, handleBlur, handleChange, setFieldValue, setFieldTouched, advocateList, isRespondent, disabled }: PersonalDetailsProps) => {
    const { t } = useTranslation();
    const { handiCapType, addressType } = useSelector((state: RootState) => state.master);
    const [addressMaxCount, setAddressMaxCount] = useState<number>(1)
    const [selectedAddressTypeOptions, setSelectedAddressTypeOptions] = useState<IDropdown[]>([]);
    const [AddAdvocate, setAddAdvocate] = useState<boolean>(false);
    const [selectedAdvocateForAdd, setSelectedAdvocateForAdd] = useState<any>();
    const prefix = `${name}${name === "" ? "" : "."}`;

    const handleAddressCount = async () => {
        setAddressMaxCount(addressType.length);
    };

    const handleSelectAddressType = () => {
        setSelectedAddressTypeOptions(values.address.reduce(
            (acc: IDropdown[], item: AddressDetailsModel, index: number) => {
                if (item.addressType !== null) {
                    acc.push(item.addressType);
                }
                return acc;
            }, [])
        )
    }

    // Add new address to the end of the array
    const addAddress = () => {
        const addressCopy = Array.from(values.address);
        addressCopy.push(AddressDetailsModel.init());
        setFieldValue(`${prefix}address`, addressCopy)
    }

    // Delete address with given from array
    const deleteAddress = (index: number) => {
        const addressCopy = Array.from(values.address);
        addressCopy.splice(index, 1);
        setFieldValue(`${prefix}address`, addressCopy)
        setFieldTouched(`${prefix}address[${index}]`, false)
    };

    const mainAdvocateColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.SerialNo"), maxWidth: 70, flex: 1 },
        { field: "advocateNameEn", headerName: "Advocate Name", flex: 2 },
        { field: "mobileNumber", headerName: t("Label.AdvocateMobileNo"), flex: 1 },
        { field: "barCouncilId", headerName: t("Label.BarId"), flex: 1 },
        { field: "emailId", headerName: "Emailid", flex: 1 },
        {
            field: 'action',
            headerName: t("Label.Action"),
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <BasicButton
                            className="btn-custom-delete min-width-auto"
                            text=""
                            variant="text"
                            type="button"
                            onClick={async () => {
                                if (params.row?.caseFilingAdvocateDetailsId) {

                                    const result = await FileNewCaseServices.DeleteAdvocateByDetailsId(params.row?.caseFilingAdvocateDetailsId);

                                    if (result === 200) {
                                        toast.success("Advocate deleted successfully");

                                        const updatedList = [...(values.advocateList || [])].filter(
                                            (item) => item.advocateId !== params.row.advocateId
                                        );
                                        setFieldValue(`${prefix}advocateList`, updatedList);
                                    }

                                } else {
                                    const filterValue = [...(values.advocateList || [])].filter(
                                        (item) => item.advocateId !== params.row.advocateId
                                    );

                                    setFieldValue(`${prefix}advocateList`, filterValue);
                                    toast.success("Advocate deleted successfully ")

                                }
                            }}

                        />
                    </div>
                );
            }
        }
    ];

    const advocateColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.SerialNo"), maxWidth: 70, flex: 1 },
        {
            field: "advocateNameEn",
            headerName: "Advocate Name",
            flex: 1.5,
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap', margin: '5px 0px 0px 0px' }}>
                        {params.value}
                    </div>
                </Tooltip>
            ),
        },
        { field: "mobileNumber", headerName: t("Label.AdvocateMobileNo"), flex: 1 },
        { field: "barCouncilId", headerName: t("Label.BarId"), flex: 1 },
        { field: "emailId", headerName: "EmailId", flex: 1 },
        {
            field: 'SelectAdvocate',
            headerName: t("Label.SelectAdvocate"),
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <BasicCheckbox
                            name={`selectedAdvocate`}
                            control={<Checkbox />}
                            label=""
                            checked={selectedAdvocateForAdd?.id === (params.row.id)}
                            onChange={(_, checked: boolean) => { setSelectedAdvocateForAdd(params.row) }}
                            onBlur={handleBlur}
                        />
                    </div>
                );
            }
        }
    ];

    const handleAddAvocate = () => {
        let advocateList: AdvocateDetailsList[] = [];
        if (values.advocateList === undefined) {
            advocateList = [selectedAdvocateForAdd]
        } else {
            advocateList = [...values.advocateList, { ...selectedAdvocateForAdd }]
        }
        setFieldValue(`${prefix}advocateList`, advocateList);
        setAddAdvocate(false)
    };


    const mainAdvocateRows: AdvocateDetailsList[] = values?.advocateList
        ?.map((details: any) => ({ ...details })) // Copy details without id
        ?.filter((value: any) => value) // Filter out invalid values
        ?.map((details: any, index: number) => ({ // Assign id after filtering
            ...details,
            // Include all details
            mobileNumber: details.mobileNumber === -1 ? "N/A" : details.mobileNumber ? details.mobileNumber : "N/A",
            barCouncilId: details.barCouncilId !== null ? details.barCouncilId : "N/A",
            emailId: details.emailId !== null ? details.emailId : "N/A",
            id: index + 1, // Sequential id
        })) ?? [];







    useEffect(() => {
        handleAddressCount();
    }, []);

    return (
        <>
            <ConfonetCard>
                <ConfonetCardHeader>
                    <div className="card-title card-primary-header-title">{label}</div>
                </ConfonetCardHeader>
                <ConfonetCardBody>
                    <div className="row">
                        <div className="col-lg-3">
                            <Textbox
                                id="Name"
                                name={`${prefix}name`}
                                className="uppercase"
                                label={t("Label.Name") + "*"}
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                inputProps={{ maxLength: 251 }}
                                error={touched?.name && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.name : false)}
                                helperText={touched?.name && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.name : ""}
                            />
                        </div>
                        <div className="col-lg-3">
                            <ConfonetAutocomplete
                                id="relation"
                                className="uppercase"
                                value={values.relation}
                                options={relationDropdown as IDropdown[]}
                                disabled={disabled}
                                renderInput={
                                    (params) => (
                                        <TextField
                                            label={t("Label.Relation") + "*"}
                                            {...params}
                                            fullWidth
                                        />
                                    )
                                }
                                onChange={(_, newValue: IDropdown | null) => { setFieldValue(`${prefix}relation`, newValue ?? { label: "", value: "" }) }}
                                onBlur={() => { setFieldTouched(`relation.label`, true) }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Textbox
                                id="relativeName"
                                name={`${prefix}relativeName`}
                                className="uppercase"
                                label={t("Label.RelativeName") + "*"}
                                value={values.relativeName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                inputProps={{ maxLength: 251 }}
                                error={touched?.relativeName && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.relativeName : false)}
                                helperText={touched?.relativeName && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.relativeName : ""}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Textbox
                                id="MobileNumber"
                                name={`${prefix}mobileNumber`}
                                className="uppercase"
                                label={t("Label.MobileNumber") + (!isRespondent ? "*" : "")}
                                value={values.mobileNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                inputProps={{ maxLength: 10 }}
                                error={touched?.mobileNumber && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.mobileNumber : false)}
                                helperText={touched?.mobileNumber && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.mobileNumber : ""}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <Textbox
                                id="Email"
                                name={`${prefix}email`}
                                className="uppercase"
                                label={t("Label.Email")}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                inputProps={{ maxLength: 51 }}
                                error={touched?.email && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.email : false)}
                                helperText={touched?.email && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.email : ""}
                            />
                        </div>
                    </div>
                    {values.isDifferentlyAbled && handiCapType && (
                        <div className="row">
                            <div className="col-lg-4">
                                <ConfonetAutocomplete
                                    id="handicapped"
                                    className="uppercase"
                                    value={values.handicapped}
                                    options={handiCapType}
                                    clearOnBlur
                                    disabled={disabled}
                                    renderInput={
                                        (params) => (
                                            <TextField
                                                {...params}
                                                label={t("Label.HandicappedType")}
                                                error={touched?.handicapped && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>).handicapped : false)}
                                                helperText={touched?.handicapped && errors ? (errors as FormikErrors<PersonalDetailsModel>).handicapped?.label : ""}
                                                onChange={(event) => setFieldValue(`${prefix}advocateText`, event.target.value)}
                                                fullWidth
                                            />
                                        )
                                    }
                                    onChange={(_, newValue: IDropdown | null) => {
                                        setFieldValue(`${prefix}handicapped`, newValue ?? { label: "", value: "" })
                                    }}
                                    onBlur={() => {
                                        setFieldTouched(`${prefix}handicapped.label`, true)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-auto">
                            <BasicCheckbox
                                control={<Checkbox />}
                                id="isSeniorCitizen"
                                name={`${prefix}isSeniorCitizen`}
                                label={t("Label.SeniorCitizen")}
                                checked={values.isSeniorCitizen}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-auto">
                            <BasicCheckbox
                                control={<Checkbox />}
                                id="isWidow"
                                name={`${prefix}isWidow`}
                                label={t("Label.Widow")}
                                checked={values.isWidow}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-auto">
                            <BasicCheckbox
                                control={<Checkbox />}
                                id="isDifferentlyAbled"
                                name={`${prefix}isDifferentlyAbled`}
                                label={t("Label.DifferentlyAbled")}
                                checked={values.isDifferentlyAbled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-auto">
                            <BasicCheckbox
                                control={<Checkbox />}
                                id="seriousAilments"
                                name={`${prefix}seriousAilments`}
                                label={t("Label.SeriousAilments")}
                                checked={values.seriousAilments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {
                        values.address.length > 0 &&
                        values.address.map((_, index: number) => {
                            return (
                                <div className={`card-text-info-block ${values.address.length > 1 ? "margin-bottom-20" : "margin-top-10"}`} key={index}>
                                    {
                                        values.address.length > 1 && index !== 0 &&
                                        <BasicButton
                                            className="btn-custom-delete min-width-auto"
                                            text=""
                                            variant="text"
                                            type="button"
                                            onClick={() => {
                                                deleteAddress(index);
                                                handleSelectAddressType()
                                            }}
                                        />
                                    }
                                    <AddressDetails
                                        name={`${prefix}address[${index}]`}
                                        label={`${t("Label.Address")} ${index + 1}`}
                                        values={values.address[index]}
                                        touched={touched?.address?.[index]}
                                        errors={errors ? (errors as FormikErrors<PersonalDetailsModel>).address?.[index] : ""}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        selectedAddressTypeOptions={selectedAddressTypeOptions}
                                        handleSelectAddressType={handleSelectAddressType}
                                        isRespondent={isRespondent}
                                        disabled={disabled && index === 0}
                                    />
                                </div>
                            )
                        })

                    }
                    {
                        ((!singleAddress || values.address.length < 1) && (values.address.length < addressMaxCount)) &&
                        <BasicButton
                            className="btn-custom btn-custom-secondary mt-lg-3"
                            type="button"
                            color="secondary"
                            text={t("Label.AddAddress")}
                            startIcon={<AddIcon />}
                            variant="text"
                            onClick={addAddress}
                        />
                    }


                    {!isRespondent && (
                        <>
                            <ConfonetCard>
                                <ConfonetCardHeader>
                                    <div className="card-title card-primary-header-title d-flex justify-content-between align-items-center">

                                        <div className="card-title card-primary-header-title">
                                            {t("Label.AdvocateDetails")}
                                        </div>
                                        <div tabIndex={0}>
                                            <BasicButton
                                                text={t("BtnText.AddAdvocate")}
                                                variant="contained"
                                                onClick={() => setAddAdvocate(!AddAdvocate)}
                                            />
                                        </div>

                                    </div>

                                </ConfonetCardHeader>
                                <ConfonetCardBody>
                                    {
                                        mainAdvocateRows && mainAdvocateRows.length > 0 &&
                                        <DataTable
                                            rows={mainAdvocateRows}
                                            columns={mainAdvocateColumns}
                                            hideFooter
                                        />
                                    }

                                    {mainAdvocateRows.length === 0 && (
                                        <>
                                            <div style={{ color: "#006699" }}>
                                                <InfoIcon
                                                    className="float-start margin-right-5"
                                                    fontSize="small"
                                                />
                                                Please add advocate to proceed further
                                            </div>
                                        </>
                                    )}
                                </ConfonetCardBody>
                            </ConfonetCard>
                        </>
                    )}
                </ConfonetCardBody>
            </ConfonetCard>
            <>
                <Dialogbox
                    open={AddAdvocate}
                    onClose={() => setAddAdvocate(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <div className="col-lg-12">
                            < DataGridCustom
                                columns={advocateColumns}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BasicButton text="Submit" onClick={() => handleAddAvocate()} variant="contained" autoFocus />
                    </DialogActions>
                </Dialogbox>
            </>
        </>
    )
};


interface IPersonalDetailsFormProps {
    formik: FormikProps<PersonalDetailsModel>,
    advocateOptions: IDropdown[],
    CRsequenceNumber: number,
    complainantLabel: string,
    respondentLabel: string,
    isRespondent?: number
}
export const PersonalDetailsForm = ({ formik, CRsequenceNumber, advocateOptions, complainantLabel, respondentLabel, isRespondent }: IPersonalDetailsFormProps) => {
    const { t } = useTranslation();
    // const label = (CRsequenceNumber > 0 ? t("Label.Additional") + " " : "") +
    //     (isRespondent ? respondentLabel : complainantLabel)
    const getLabel = () => {
        let label = complainantLabel;
        switch (isRespondent) {
            case 1:
                label = (CRsequenceNumber > 0 ? t("Label.Additional") + " " : "") + complainantLabel
                break;
            case 2:
                label = (CRsequenceNumber > 0 ? t("Label.Additional") + " " : "") + respondentLabel;
                break
            case 3:
                label = t("Label.AdditionalComplaintPerforma");
                break
            case 4:
                label = t("Label.AdditionalRespondentPerforma")
                break;
            default:
                label = label;
                break;
        }
        return label;
    }
    return (
        <PersonalDetails
            name=""
            label={getLabel()}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            advocateList={advocateOptions}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            isRespondent={(isRespondent === 2 || isRespondent === 4)}
            singleAddress={CRsequenceNumber > 0}
        />
    )
}