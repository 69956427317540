import { ReportService } from "../../../services/ReportService/ReportService";
import {
  commissionWiseCaseTypeReports,
  commissionWiseCategoryReports,
  commissionWiseStateWithCategoryReport,
  stateCategoryWiseReport,
  categoryWiseReport,
  districtWiseReport,
  filterDistrictCategoryWiseCaseType,
  ncdrcAndscdrcAnddcdrc,
  stateWiseReport,
  benchWiseReport,
  judgesWiseReport,
  stateDistrictDuration,
  customDynamicReport,
  DynamicReportWithoutRowSpans,
  courtRoomWiseCaseHearingDetails,
  ncdrcJudgeWiseReport,
  detailsOfJudgesCasesDetails,
  JudgesHearingInformation,
  detailsWiseSearchReport,
  OnclickedFetchedReportJudgeData,
} from "../Reports_Data/ReportType/DynamicReportFunction/Dynamic_Reports_Funcitons";
import {
  caseTypeWiseReport,
  regularCategoryWiseReport,
  commissionWiseReport,
  disposalMoreThenOneYearReport,
  disposalWithinOneYearReport,
  regionWiseEjagritiReport,
  registeredUserReports,
  resionWiseReport,
  stageWiseReports,
  stateStageWiseReport,
  stateCommissionDurationWisePendency,
  stateDistrictReport,
  stateDistrictWiseStageReport,
  statePendencyOneYearReport,
  stateWisePendencyReport,
  commissionWiseCategoryReport,
  eJagritiCaseDetailsReports,
  yearlyWiseCategoryReport,
} from "../Reports_Data/ReportType/RegularReportFunction/Regular_Reports_Function";

export const BodyTable = async (table_body: any) => {
  let data: any;

  //==========REGULAR REPORTS =================
  switch (table_body) {
    case "commission_wise":
      //@ Report no: 1
      data = await ReportService.getCommissionWiseReport();
      return commissionWiseReport(data);
    case "categoryWiseReport":
      //@ Report no: 2
      data = await ReportService.getCategoryReport();
      return regularCategoryWiseReport(data);
    case "StateWisePendencyReport":
      //@ Report no: 3
      data = await ReportService.getStateWiseReport();
      return stateWisePendencyReport(data);
    case "StatePendencyForOneYearReport":
      //@ Report no: 4
      data = await ReportService.getStatePendencyForOneYearReport();
      return statePendencyOneYearReport(data);
    case "StateDistrictReport":
      //@ Report no: 5
      data = await ReportService.getStatesDistrictReport();
      return stateDistrictReport(data);
    case "RegionWiseReport":
      //@ Report no: 6
      data = await ReportService.getRegionWiseReport();
      return resionWiseReport(data);
    case "DisposalMoreThanOneYearReport":
      //@ Report no: 7
      data = await ReportService.getDisposalMoreThanOneYearReport();
      return disposalMoreThenOneYearReport(data);
    case "DisposalWithin1YearReport":
      //@ Report no: 8
      data = await ReportService.getDisposalWithin1YearReport();
      return disposalWithinOneYearReport(data);
    case "StateCommissionDurationWisePendency":
      //@ Report no: 9
      data = await ReportService.getStateCommissionDurationWisePendency();
      return stateCommissionDurationWisePendency(data);
    case "CaseType_Wise_Report":
      //@ Report no: 10
      data = await ReportService.getCaseTypeWiseReport();
      return caseTypeWiseReport(data);
    case "Registered_Users_Report":
      //@ Report no: 11
      data = await ReportService.Registered_Users_Report();
      return registeredUserReports(data);
    case "e-Jagriti_Case_Detail_Report":
      //@ Report no: 12
      data = await ReportService.e_Jagriti_Case_Detail_report();
      return eJagritiCaseDetailsReports(data);
    case "StageWiseReport":
      //@ Report no: 13
      data = await ReportService.Stage_Wise_Report();
      return stageWiseReports(data);
    case "State_District_Stage_wise_report":
      //@ Report no: 14
      data = await ReportService.DynamicReportAPI(
        "statedistrictstagewise-report"
      );
      return stateDistrictWiseStageReport(data.data);
    case "StateStageWiseReport":
      //@ Report no: 15
      data = await ReportService.DynamicReportAPI("statestagewise-report");
      return stateStageWiseReport(data.data);
    case "YearlyWiseCategoryReport":
      //@ Report no: 16
      data = await ReportService.DynamicReportAPI(
        "yearlywisecategory-report"
      );
      const newdata = data.data.map((currentElement:any) =>{
        const {category_name, ...rest} = currentElement
        return rest
      })
      return yearlyWiseCategoryReport(newdata);
    case "RegionWiseEjagritiReport":
      //@ Report no: 17
      data = await ReportService.DynamicReportAPI(
        "regionwiseejagriti-report"
      );
      return regionWiseEjagritiReport(data.data);
    default:
      break;
  }

  // ===========DYNAMIC REPORTS =====================
  switch (table_body[1][0].Report_type) {
    case "Commission_wise_case_type_reports":
      return commissionWiseCaseTypeReports(table_body);
    case "Commission_wise_Category_reports":
      return commissionWiseCategoryReports(table_body[0]);
    case "commission_wise_State_with_category_Report":
      return commissionWiseStateWithCategoryReport(table_body[0]);
    case "State_Category_wise_report":
      return stateCategoryWiseReport(table_body[0]);
    case "categoryWiseReport":
      const removeCommissionType = table_body[0].map((curEle: any) => {
        const { commissionType, ...rest } = curEle;
        return rest;
      });
      return regularCategoryWiseReport(removeCommissionType);
    case "commissionWiseCategoryReport":
      return commissionWiseCategoryReport(table_body[0]);
    case "Ad-hoc_Report_Category":
      return categoryWiseReport(table_body);
    case "stateDistrictDuration":
      return stateDistrictDuration(table_body[0])
    case "DynamicReport":
      return customDynamicReport(table_body[0])
    case "DynamicReportWithoutRowSpan":
      return DynamicReportWithoutRowSpans(table_body[0])
  }

  // ==============Ad-Hoc DCDRC Report===============
  switch (table_body[1][0].Report_type) {
    case "Ad-hoc_Report_DCDRC":
      return districtWiseReport(table_body[0]);
    case "Ad-hoc_Report_SCDRC":
      return stateWiseReport(table_body[0]);
    case "NCDRC_or_SCDRC_or_DCDRC":
      return ncdrcAndscdrcAnddcdrc(table_body);
    case "Filter_District_Category_wise_case_type_report":
      return filterDistrictCategoryWiseCaseType(table_body);
    case "Bench_Wise_Report":
      return benchWiseReport(table_body);
    case "judgesReport":
      return judgesWiseReport(table_body[0]);
    case "ncdrc_judges_hearing_information":
      return JudgesHearingInformation(table_body)
    default:
      break;
  }


  //===========Judges Repots ====================
  switch(table_body[1][0].Report_type){
    case "courtRoomWiseCaseHearingDetails":
      return courtRoomWiseCaseHearingDetails(table_body)
    
    case "NcdrcJudgeWiseReport":
      return ncdrcJudgeWiseReport(table_body)

    case "detailsOfJudgesCasesDetails":
      return detailsOfJudgesCasesDetails(table_body)
  }


  //===========DetailsWiseSearchReports==========

  switch(table_body[1][0].Report_type){
    case "DetailsWiseSearchReport_CaseStage_CaseStage_Categoryid":
      return detailsWiseSearchReport(table_body)
  }

  // ============Generate Report when Click on inner link ==============
  switch(table_body[1][0].Report_type){
    case "OnclickedFetchedReportJudgeData":
      return OnclickedFetchedReportJudgeData(table_body)
  }

};
