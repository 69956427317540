import React, { useState } from 'react'
import { BasicBreadcrumbs, BasicButton } from '../../../components';
import { convertBase64ToUInt8, navigateToDashboardPath } from '../../../utils/Helper';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { ICaseFillingDocumentDetailsResponseList, IGetDocumentResponse } from '../../../models/cases/consumerCases/IConsumerCaseModel';
import { ConsumerCaseServices } from '../../../services/CaseService/ConsumerCaseService/ConsumerCaseServices';
import { toast } from 'react-toastify';
import CauseListDragDropHtml from '../../../utils/HtmlFilesForPdf/CauseListDragGDropHtml';
import { CouselistServices } from '../../../services/JudgeService/GenerateCauselistServices';
import { DateTime } from 'luxon';
import { GENERATE_COUSELIST_VIEW } from '../../../navigation/CONSTANTS';

interface ViewPageProps {
    courtId: number;
    courtName: string;
    selectedDate: string;
}

export function CauseListDataDetails({ courtId, courtName }: ViewPageProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { response, params }: { response: any, params: { dateOfNextHearing: string, courtId: number } } = location.state;
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));
    const [saveEditResponse, setSaveEditResponse] = useState([])

    const selectedDate = DateTime.fromFormat(params.dateOfNextHearing, "dd-MM-yyyy").toFormat("yyyy-MM-dd");

    //Handle Documnet
    const handleGetDocument = async (filingReferenceNumber: number, docName: string) => {
        const response: IGetDocumentResponse =
            await ConsumerCaseServices.getCaseFillingDocumentDetails(
                filingReferenceNumber
            );
        if (
            response &&
            Object.keys(response).length > 0 &&
            response?.caseFillingDocumentDetailsResponseList?.length > 0
        ) {
            // debugger
            const documentItem = response?.caseFillingDocumentDetailsResponseList?.filter((document: ICaseFillingDocumentDetailsResponseList) => document.documentName.toLowerCase().includes(docName.toLowerCase()));
            if (documentItem?.length) {
                setDocumentContent(convertBase64ToUInt8(documentItem[0].fileContent));
            } else {
                toast.warn('No document available for the selected item!!');
            }
        } else {
            toast.warn('No document available for the selected item!!');
        }
    };

    //Save the data and call another API
    const saveCauseListData = async () => {

        // Prepare the data to send in the first API call
        const data = {
            dateOfNextHearing: params.dateOfNextHearing,
            courtId: params.courtId,
            commissionId: userDetailsData.commissionId,
            userId: userDetailsData.userId,
            clData: JSON.stringify(response)  // Stringify the response for clData
        }

        try {
            // First API call - save cause list data
            const responseSavedData = await CouselistServices.getJudgeGenerateCouselistEdit(data);
            if (responseSavedData?.length) {
                toast.success('Data Save Successful');
                // After success, call the second API - generate cause list
                const paramsD = {
                    dateOfNextHearing: DateTime.fromFormat(params.dateOfNextHearing, "dd-MM-yyyy").toFormat("yyyy-MM-dd"),
                    courtId: params.courtId,
                    commissionId: userDetailsData.commissionId,
                    finalize: false
                };
                const response = await CouselistServices.getJudgeGenerateCouselist(paramsD);
                const isFinalized = response.isFinal
                const slnoData = response.slno
                const causeListDataData = JSON.parse(response.clData)
                const causeListData = response
                if (response.clData) {
                    navigate(GENERATE_COUSELIST_VIEW, { state: { causeListData, slnoData, causeListDataData, isFinalized } });

                }

            } else {
                toast.error("Failed to save cause list data");
            }

        } catch (error) {
            console.error("Error saving cause list data:", error);
        }
    };

    return (
        <section className="section margin-top-20">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-sm-7 margin-bottom-30">
                        <BasicBreadcrumbs items={[
                            { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
                            { to: '', label: t("Label.Edit") },
                        ]} />
                    </div>
                </div>
            </div>

            <CauseListDragDropHtml
                setSaveEditResponse={setSaveEditResponse}
                causelistResponse={response}
                selectedDate={selectedDate}
                handleGetDocument={handleGetDocument}
            />
            <div style={{ clear: 'both', marginTop: '25px', textAlign: 'right' }}>
                <BasicButton
                    onClick={() => saveCauseListData()}
                    text='Save'
                    variant='contained'
                />
            </div>
        </section>
    )
}


