import { DateTime } from "luxon";
import { IDropdown } from "../../common/IDropdown";

export class FaSaRpCaveatCaseDetailsViewModel {
    constructor(
        public caseSummary: string,
        public caseType?: number,
        public ImpungedOrderDate?: DateTime | null,
        public DateOfInterim?: DateTime | null,
        public demandDraft?: string | number,
        public dDno?: string,
        public bankId?: IDropdown,
        public branchId?: IDropdown,
        public amount?: string,
        public Date?: DateTime,
        

    ) {
        this.caseSummary = caseSummary;
        this.caseType = caseType
        this.ImpungedOrderDate = ImpungedOrderDate || null
        this.DateOfInterim = DateOfInterim || null
        this.demandDraft = demandDraft;
        this.dDno = dDno;
        this.bankId = bankId;
        this.branchId = branchId;
        this.amount = amount
        this.Date = Date
        
    }

    static readonly init = () => new FaSaRpCaveatCaseDetailsViewModel("", 0, null, null);
}

export class UpdateFaSaRpCaveatCaseDetailsRequest {
    constructor(
        public filingReferenceNumber: string,
        public remarks: string
    ) {
        this.filingReferenceNumber = filingReferenceNumber;
        this.remarks = remarks;
    }
}

export interface IUpdateFaSaRpCaveatCaseDetailsResponse {
    fillingReferenceNumber: string,
}

export class AppealExecutionCaseDetailsViewModel {
    constructor(
        public dateOfOrder: DateTime,
        public nationalCommissionCaseNumber: string,
        public nationalCommissionDateOfOrder: DateTime,
        public supremeCourtCaseNumber: string,
        public supremeCourtDateOfOrder: DateTime,
        public caseSummary: string,
    ) {
        this.dateOfOrder = dateOfOrder;
        this.nationalCommissionCaseNumber = nationalCommissionCaseNumber;
        this.nationalCommissionDateOfOrder = nationalCommissionDateOfOrder;
        this.supremeCourtCaseNumber = supremeCourtCaseNumber;
        this.supremeCourtDateOfOrder = supremeCourtDateOfOrder;
        this.caseSummary = caseSummary;
    }

    static readonly init = () => new AppealExecutionCaseDetailsViewModel(
        DateTime.now(),
        "",
        DateTime.now(),
        "",
        DateTime.now(),
        ""
    );
}

export class CCPAAppealCaseDetailsViewModel {
    constructor(
        public authorityName: string,
        public authorityId: string,
        public dateOfOrder: DateTime,
        public caseSummary: string,
    ) {
        this.authorityName = authorityName;
        this.authorityId = authorityId;
        this.dateOfOrder = dateOfOrder;
        this.caseSummary = caseSummary;
    }

    static readonly init = () => new CCPAAppealCaseDetailsViewModel("", "", DateTime.now(), "");
}

export interface IGetAppealTypeCaseDetailsResponse {
    filingReferenceNumber: number;
    filedInComissionId: number;
    paidAmount: number;
    claimAmount: number;
    caseFiledBy: number;
    caseCategoryId: number;
    caseCategoryNameEn: string;
    caseSubCategoryId: number;
    caseSubCategoryNameEn: string;
    caseSubSubCategoryId: number;
    caseSubSubCategoryNameEn: string;
    remarks: string;
    caseTypeId: number;
    caseTypeNameEn: string;
    previousFilingReferenceNumber: number;
    previousCaseNumber: string;
    previousCaseTypeId: number;
    previousCaseTypeName: string;
    previousCommissionId: number;
    previousCommissionName: string;
    originalFilingReferenceNumber: number;
    originalCaseNumber: string;
    previousCommissionTypeId: number;
    previousCaseOrderDate: string;
    previousCaseDateOfHearing: string;
    previousStateId: number;
    previousDistrictId: number;
    destinationCommissionId: number;
    destinationCommissionName: string;
    parentDesCommissionId: number;
    parentDesCommissionName: string;
}

export interface IGetCaveatStatusPayload {
    caseTypeId: number;
    previousFilingReferenceNumber: number;
    filedInComissionId: number;
}

export interface IGetCaveatStatusResponse {
    filingReferenceNumber: number;
    caseNumber: string;
    previousCaseNumber: string;
    caseFillingDate?: string;
}

export interface IFilingReferenceNumberPayload {
    filingReferenceNumber: number;
}


