import { TextField } from "@mui/material";
import { FormikProps } from "formik";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import {
	BasicDatePicker,
	ConfonetAutocomplete,
	ConfonetCard,
	ConfonetCardBody,
	ConfonetCardHeader,
	Textbox
} from "../../../../components";
import ConfonetTextEditorTiny from '../../../../components/controls/ConfonetTextEditorTinyMCE';
import { ICCPACaseDetailViewModel } from '../../../../models/cases/ccpaTypeCase/ICCPACaseDetailsModel';
import { IGetCaseCategory } from "../../../../models/cases/fileNewCase/IFileNewCaseModel";
import { IDropdown } from "../../../../models/common/IDropdown";
import { MasterService } from "../../../../services/MasterService/MasterService";
import { dropdownValidationSchema, makeDropdownOptions } from "../../../../utils/Helper";
import { useAppDispatch } from "../../../../redux";
import { addOtherLegalBodyList } from "../../../../redux/MasterReducer/MasterReducer";

export interface ICaseDetailFormProps {
	formik: FormikProps<ICCPACaseDetailViewModel>,
	isDraft: boolean
}

// Validations for Case Detail Form.
export const CCPACaseDetailFormValidationSchema = Yup.object({
	authority: dropdownValidationSchema("Appeal Authority is required").required(),
	authorityId: Yup.number().required("Authority Id is required"),
	caseCategory: dropdownValidationSchema("Case category is required").required(),
	subCategory: dropdownValidationSchema("Sub category is required").required(),
	// subSubCategory: dropdownValidationSchema("Sub sub category is required").required(),
	caseDetails: Yup.string()
		.max(10000, "Max 2000 character is allowed"),
});

/**
 * The CCPACaseDetailForm component represents a form for capturing case file details. It includes various
 * sections such as payment considerations, state and district of cause of action, category details, and case details.
 */
const CCPACaseDetailForm = ({ formik, isDraft }: ICaseDetailFormProps) => {
	const dispatch = useAppDispatch();
	const [appealAuthorityListOption, setAppealAuthorityListOption] = useState<IDropdown[]>([]);
	const [caseCategoryListOption, setCaseCategoryListOption] = useState<IDropdown[]>([]);
	const [caseSubCategoryListOption, setCaseSubCategoryListOption] = useState<IDropdown[]>([]);
	const [caseSubSubCategoryListOption, setCaseSubSubCategoryListOption] = useState<IDropdown[]>([]);

	const { t } = useTranslation();

	// Get all authority List 
	const handleGetAuthority = async () => {
		const response = await MasterService.getAuthorityList();
		const otherLegalBody = makeDropdownOptions(response, "otherLegalBodyNameEn", "otherLegalBodyId")
		setAppealAuthorityListOption(otherLegalBody);
		dispatch(addOtherLegalBodyList(otherLegalBody));
	}

	// Get all Case categories API Calling
	const handleGetCaseCategories = async () => {
		const caseCategoryData: IGetCaseCategory = {
			caseCategoryLevel: 1,
			parentCaseCategoryId: 0,
		};
		const caseCategoryResponse = await MasterService.getCaseCategory(caseCategoryData);
		setCaseCategoryListOption(makeDropdownOptions(caseCategoryResponse, "caseCategoryNameEn", "caseCategoryId"));
	};

	// Get all Case Sub categories API Calling
	const handleGetCaseSubCategories = async (caseCategoryId: number) => {
		const caseSubCategoryData: IGetCaseCategory = {
			caseCategoryLevel: 2,
			parentCaseCategoryId: caseCategoryId,
		};
		const caseSubCategoryResponse = await MasterService.getCaseCategory(caseSubCategoryData);
		setCaseSubCategoryListOption(makeDropdownOptions(caseSubCategoryResponse, "caseCategoryNameEn", "caseCategoryId"));
	};

	// Get all Case Sub Sub categories API Calling
	const handleGetCaseSubSubCategories = async (caseCategoryId: number) => {
		const caseSubSubCategoryData: IGetCaseCategory = {
			caseCategoryLevel: 3,
			parentCaseCategoryId: caseCategoryId,
		};
		const caseSubSubCategoryResponse = await MasterService.getCaseCategory(caseSubSubCategoryData);
		setCaseSubSubCategoryListOption(makeDropdownOptions(caseSubSubCategoryResponse, "caseCategoryNameEn", "caseCategoryId"));
	};

	const editorValueChange = (value: string) => {
		formik.setFieldValue("caseDetails", value);
	}

	useEffect(() => {
		handleGetAuthority();
		handleGetCaseCategories();
		if (formik.values.caseCategory.value !== "") {
			handleGetCaseSubCategories(formik.values.caseCategory.value);
		}
		if (formik.values.subCategory.value !== "") {
			handleGetCaseSubSubCategories(formik.values.subCategory.value);
		}
	}, [formik.values.caseCategory.value, formik.values.subCategory.value]);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="margin-top-25">
			<ConfonetCard>
				<ConfonetCardHeader>
					<div className="card-title card-primary-header-title">{t("Label.CaseDetails")}</div>
				</ConfonetCardHeader>
				<ConfonetCardBody>
					<div className="row">
						<div className="col-lg-4">
							<ConfonetAutocomplete
								id="authority"
								value={formik.values.authority}
								options={appealAuthorityListOption}
								renderInput={
									(params) => (
										<TextField
											{...params}
											label={t("Label.CCPAApealAuthoriy") + "*"}
											error={formik.touched.authority && Boolean(formik.errors.authority)}
											helperText={formik.touched.authority && formik.errors.authority?.label}
											fullWidth
										/>
									)
								}
								onChange={(_, value: IDropdown | null) => {
									formik.setFieldValue("authority", value ?? { label: "", value: "" });
								}}
								onBlur={formik.handleBlur}
							/>
						</div>
						<div className="col-lg-4">
							<Textbox
								id="authorityId"
								name={`authorityId`}
								label={t("Label.ReferenceId") + "*"}
								value={formik.values.authorityId}
								type="number"
								inputProps={{
									maxLength: 21
								}}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								placeholder={t("Label.EnterReferenceId")}
								error={formik.touched.authorityId && Boolean(formik.errors.authorityId)}
								helperText={
									<>
										{
											formik.touched.authorityId && formik.errors.authorityId
										}
									</>
								}
							/>
						</div>
						<div className="col-lg-4">
							<BasicDatePicker
								label={t("Label.DateOfOrder") + "*"}
								value={formik.values.dateOfOrder}
								className="w-100"
								onChange={(e) => {
									formik.setFieldValue("dateOfOrder", e, true)
								}}

								slotProps={{
									textField: {
										variant: "outlined",
										error: formik.touched.dateOfOrder && Boolean(formik.errors.dateOfOrder),
										helperText: (
											<>
												<span className="text-danger">
													{formik.touched.dateOfOrder && formik.errors.dateOfOrder as string}
												</span>
											</>
										),
										onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
									}
								}}
								maxDate={DateTime.now()}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<ConfonetAutocomplete
								id="caseCategory"
								value={formik.values.caseCategory}
								options={caseCategoryListOption}
								renderInput={
									(params) => (
										<TextField
											{...params}
											label={t("Label.CaseCategory") + "*"}
											error={formik.touched.caseCategory && Boolean(formik.errors.caseCategory)}
											helperText={formik.touched.caseCategory && formik.errors.caseCategory?.label}
											fullWidth
										/>
									)
								}
								onChange={(_, value: IDropdown | null) => {
									formik.setFieldValue("caseCategory", value ?? { label: "", value: "" });
									formik.setFieldValue("subCategory", { label: "", value: "" });
									formik.setFieldTouched("subCategory", false);
									formik.setFieldValue("subSubCategory", { label: "", value: "" });
									formik.setFieldTouched("subSubCategory", false);
									if (value && value.value !== "") {
										handleGetCaseSubCategories(value.value);
									}
									else {
										setCaseSubCategoryListOption([])
									}
									setCaseSubSubCategoryListOption([])
								}}
								onBlur={formik.handleBlur}
							/>
						</div>
						{(caseCategoryListOption.length > 0 || caseSubCategoryListOption.length > 0) &&
							<div className="col-lg-4">
								<ConfonetAutocomplete
									id="subCategory"
									value={formik.values.subCategory}
									options={caseSubCategoryListOption}
									renderInput={
										(params) => (
											<TextField
												{...params}
												label={t("Label.SubCategory") + "*"}
												error={formik.touched.subCategory && Boolean(formik.errors.subCategory)}
												helperText={formik.touched.subCategory && formik.errors.subCategory?.label}
												fullWidth
											/>
										)
									}
									onChange={(_, value: IDropdown | null) => {
										formik.setFieldValue("subCategory", value ?? { label: "", value: "" });
										formik.setFieldValue("subSubCategory", { label: "", value: "" });
										formik.setFieldTouched("subSubCategory", false);
										if (value && value.value !== "") {
											handleGetCaseSubSubCategories(value.value);
										}
										else {
											setCaseSubSubCategoryListOption([{ label: "", value: "" }])
										}
									}}
									onBlur={formik.handleBlur}
								/>
							</div>
						}
						{(caseSubSubCategoryListOption.length > 0 && caseCategoryListOption.length > 0) &&
							<div className="col-lg-4">
								<ConfonetAutocomplete
									id="subSubCategory"
									value={formik.values.subSubCategory}
									options={caseSubSubCategoryListOption}
									renderInput={
										(params) => (
											<TextField
												{...params}
												label={t("Label.SubSubCategory") + "*"}
												error={formik.touched.subSubCategory && Boolean(formik.errors.subSubCategory)}
												helperText={formik.touched.subSubCategory && formik.errors.subSubCategory?.label}
												fullWidth
											/>
										)
									}
									onChange={(_, value: IDropdown | null) => {
										formik.setFieldValue("subSubCategory", value ?? { label: "", value: "" })
									}}
									onBlur={formik.handleBlur}
								/>
							</div>
						}
					</div>
					{/* <div className="row">
						<div className="col-lg-12">
							<ConfonetTextEditorTiny
								id="caseDetails"
								value={formik.values.caseDetails}
								onEditorChange={editorValueChange}
								disabled
							/>

							{formik.touched.caseDetails && formik.errors.caseDetails && (
								<span className='text-danger'>{formik.errors.caseDetails}</span>
							)}
						</div>
					</div> */}
				</ConfonetCardBody>
			</ConfonetCard>
		</div>
	);
};

export default CCPACaseDetailForm;
