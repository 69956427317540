import { AxiosResponse } from "axios";
import { IGetCaseDetailsCommission, ITACaseDetailsRequest, ITACaseDetailsResponse, ITAUpdateCaseDetailsRequest } from "../../../../models/cases/transferApplicationCase/TransferpplicationCaseDetailsModel";
import { CASE_FILING_SERVICE } from "../../../CONSTANTS";
import { postCall, putCall } from "../../../api";
import { IGetPreCaseDetailsPayload, IGetPreDetailsResponse } from "../../../../models/cases/appealTypeCase/PreDetailsModel";

export class TransferApplicationService {
    /**
    * Call an API to save case details
    * @param data ITACaseDetailsRequest
    * @returns Promise<ITACaseDetailsResponse>
    */
    public static readonly saveCaseDetailsSubmit = async (data: ITACaseDetailsRequest): Promise<ITACaseDetailsResponse> => {
        const response: AxiosResponse<ITACaseDetailsResponse> = await postCall(
            `${CASE_FILING_SERVICE}/saveCaseForIaMaTa`,
            data
        );
        return response?.data;
    };

    /**
    * Call an API to get previous case details
    * @param data IGetPreCaseDetailsPayload
    * @returns Promise<IGetPreDetailsResponse>
    */
    public static readonly getPreviousCaseDetails = async (data: IGetPreCaseDetailsPayload): Promise<IGetPreDetailsResponse> => {
        const response: AxiosResponse<IGetPreDetailsResponse> = await postCall(
            `${CASE_FILING_SERVICE}/findLowerCommissionCaseDetailForTA`,
            data
        );
        return response?.data;
    };

    /**
    * Call an API to get previous case details
    * @param data IInterlocutoryApplicationCaseDetailsRequest
    * @returns Promise<IInterlocutoryApplicationCaseDetailsResponse>
    */
    public static readonly getPreviousCaseDetailsCommission = async (filingReferenceNumber: number): Promise<IGetCaseDetailsCommission> => {
        const response: AxiosResponse<IGetCaseDetailsCommission> = await postCall(
            `${CASE_FILING_SERVICE}/tAFromToCommission`,
            {filingReferenceNumber}
        );
        return response?.data;
    };

    /**
    * Call an API to save case details
    * @param data IInterlocutoryApplicationCaseDetailsRequest
    * @returns Promise<IInterlocutoryApplicationCaseDetailsResponse>
    */
    public static readonly updateTACaseDetailsSubmit = async (data: ITAUpdateCaseDetailsRequest): Promise<ITACaseDetailsResponse> => {
        const response: AxiosResponse<ITACaseDetailsResponse> = await putCall(
            `${CASE_FILING_SERVICE}/updateCommissionAndRemarkForTa`,
            data
        );
        return response?.data;
    };
}