import AddIcon from "@mui/icons-material/Add";
import { TextField, Typography } from "@mui/material";
import { FormikErrors, FormikProps } from "formik";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  BasicButton,
  ConfonetAutocomplete,
  ConfonetCard,
  ConfonetCardBody,
  ConfonetCardHeader,
  DocumentUploadContainer,
  Textbox,
} from "../../../../../components";
import { ICaseFillingDocumentDetailsResponseList } from "../../../../../models/cases/consumerCases/IConsumerCaseModel";
import {
  DocumentUploadModel,
  UploadFileModel,
} from "../../../../../models/cases/fileNewCase/DocumentUploadModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import { MasterService } from "../../../../../services/MasterService/MasterService";
import { makeDropdownOptions } from "../../../../../utils/Helper";
import { store } from "../../../../../redux";

// Define the validation schema
export const documentUploadSchema = Yup.object({
  uploadedFiles: Yup.array().of(
    Yup.object({
      documentId: Yup.number().required(),
      isMandatoryStatus: Yup.boolean().required(),
      documentNameEn: Yup.string().nullable(),
      files: Yup.array().of(Yup.mixed<File>()).when(['isMandatoryStatus', 'documentId', 'documentNameEn'], {
        is: (isMandatoryStatus: boolean, documentId: number, documentNameEn: string | null) =>
          isMandatoryStatus === true || (documentId === 0 && !!documentNameEn),
        then: (schema) => schema.min(1, 'File is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
  ),
});
export interface IDocumentUploadProps {
  formik: FormikProps<DocumentUploadModel>;
  documentDetails?: ICaseFillingDocumentDetailsResponseList[];
  showIaButton?: boolean
  showAddOtherButton?: boolean,
  documentUpdate?: ICaseFillingDocumentDetailsResponseList[]
}


/**
 * This component refers to Document upload step in file new case module
 * @param IDocumentUploadProps
 */
const DocumentUpload = ({ formik, documentDetails, showAddOtherButton = true, showIaButton, documentUpdate }: IDocumentUploadProps) => {
  const [baseFileCount, setBaseFileCount] = useState<number>(0);
  const [reasonList, setReasonList] = useState<IDropdown[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [caseDocuments, setCaseDocuments] = useState<UploadFileModel[]>(formik.values.uploadedFiles.filter((value) => value.documentId !== 0 && !value.isIA))
  const [additionalDocuments, setAdditionalDocuments] = useState<UploadFileModel[]>(formik.values.uploadedFiles.filter((value) => value.documentId === 0 && !value.isIA));
  const [iaDocuments, setIaDocuments] = useState<UploadFileModel[]>(formik.values.uploadedFiles.filter((value) => value.isIA));

  // const { country, addressType, handiCapType } = store.getState().master;
  let uploadedFile: ICaseFillingDocumentDetailsResponseList[];

  const updateState = () => {
    const state = store.getState().documentUpload;
    uploadedFile = state.uploadedFile;
  };

  store.subscribe(updateState);

  useEffect(() => {
    const baseFiles = formik.values.uploadedFiles.filter(
      (item: UploadFileModel) => item.documentId !== 0 && item.documentId !== 7
    );
    setBaseFileCount(baseFiles.length);
  }, [formik.values.uploadedFiles]);

  //It handles the updated file list of Document upload component
  const handleUpdateFileList = (index: number, newFiles: File[]) => {
    let newUploadedFiles: UploadFileModel[] = [];
    if (documentDetails && documentDetails.length > 0) {
      newUploadedFiles = formik.values.uploadedFiles.map(
        (document: UploadFileModel, idx: number) => {
          return document.documentId === index || idx === -index
            ? new UploadFileModel(
              document.documentId,
              document.documentNameEn,
              newFiles,
              document.activeStatus,
              document.documentSequenceNumber,
              document.isMandatoryStatus,
              document.isIA,
              document.caseFilingDocumentDetailsId,
              document.selectedIaReason
            )
            : document;
        }
      );
    } else {
      newUploadedFiles = formik.values.uploadedFiles.map(
        (document: UploadFileModel, idx: number) => {
          return idx === index
            ? new UploadFileModel(
              document.documentId,
              document.documentNameEn,
              newFiles,
              true,
              document.documentSequenceNumber,
              document.isMandatoryStatus,
              document.isIA,
              document.caseFilingDocumentDetailsId,
              document.selectedIaReason
            )
            : document;
        }
      );
    }
    if (newFiles.length > 0) {
      toast.success("File added successfully");
    }
    formik.setValues({ uploadedFiles: newUploadedFiles });
  };

  const addDocument = () => {
    setShowMessage(true);
    const uploadedFilesCopy = [...formik.values.uploadedFiles.filter((value) => value.documentId === 0 && !value.isIA)];
    const iaDocumentsCopy = [...formik.values.uploadedFiles.filter((value) => value.isIA)];
    const caseDocumentsCopy = [...formik.values.uploadedFiles.filter((value) => value.documentId !== 0 && !value.isIA)];
    const maxValue = uploadedFilesCopy.length > 0 ? Math.max(...uploadedFilesCopy.map(item => item.documentSequenceNumber)) : Math.max(...caseDocuments.map(item => item.documentSequenceNumber))
    uploadedFilesCopy.push(new UploadFileModel(0, "", [], true, maxValue + 1, false));
    setAdditionalDocuments(uploadedFilesCopy);
    formik.setFieldValue("uploadedFiles", [...caseDocumentsCopy, ...uploadedFilesCopy, ...iaDocumentsCopy]);
  };

  const addIaDocuments = () => {
    const uploadedFilesCopy = [...formik.values.uploadedFiles.filter((value) => value.isIA)];
    const caseDocumentsCopy = [...formik.values.uploadedFiles.filter((value) => value.documentId !== 0 && !value.isIA)];
    const additionalDocumentsCopy = [...formik.values.uploadedFiles.filter((value) => value.documentId === 0 && !value.isIA)];
    uploadedFilesCopy.push(new UploadFileModel(7, "Add Affidavit (IA)", [], true, 2, false, true));
    setIaDocuments(uploadedFilesCopy);
    formik.setFieldValue("uploadedFiles", [...caseDocumentsCopy, ...additionalDocumentsCopy, ...uploadedFilesCopy]);
  }

  const deleteDocument = (index: number, item: UploadFileModel) => {
    const caseDocumentsCopy = [...caseDocuments];
    const additionalDocumentsCopy = [...additionalDocuments];
    const iaDocumentsCopy = [...iaDocuments];
    if ((documentUpdate && documentUpdate.length > 0) || (uploadedFile && uploadedFile.length > 0) || (documentDetails && documentDetails.length > 0)) {
      if (item.isIA) {
        const idx = index - [...caseDocumentsCopy, ...additionalDocumentsCopy].length
        iaDocumentsCopy.splice(idx, 1)
        setIaDocuments(iaDocumentsCopy);
      } else {
        const idx = (index - caseDocumentsCopy.length);
        additionalDocumentsCopy[idx].activeStatus = false;
        setAdditionalDocuments(additionalDocumentsCopy);
      }
      formik.setFieldValue(`uploadedFiles[${index}].activeStatus`, false);
    } else {
      if (item.isIA) {
        const idx = index - [...caseDocumentsCopy, ...additionalDocumentsCopy].length
        iaDocumentsCopy.splice(idx, 1);
        setIaDocuments(iaDocumentsCopy);
      } else {
        const idx = index - caseDocumentsCopy.length;
        additionalDocumentsCopy.splice(idx, 1);
        additionalDocumentsCopy.length === 0 && setShowMessage(false);
        setAdditionalDocuments(additionalDocumentsCopy)
      }
      formik.setFieldValue(`uploadedFiles`, [...caseDocumentsCopy, ...additionalDocumentsCopy, ...iaDocumentsCopy]);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const items = Array.from(formik.values.uploadedFiles);
    const sourceIndex = source.index + caseDocuments.length;
    const destinationIndex = destination.index + caseDocuments.length

    // Only reorder items if they are new documents (documentId === 0)
    if (items[sourceIndex].documentId === 0 && items[destinationIndex].documentId === 0) {
      // Remove the item from source index and store it in movedItem
      const [movedItem] = items.splice(sourceIndex, 1);

      // Insert the movedItem at the destination index
      items.splice(destinationIndex, 0, movedItem);

      // Swap documentSequenceNumber between source and destination
      const updatedItems = items.map((item, index) => {
        // Swap the sequence numbers if it's the source or destination index
        if (index === sourceIndex) {
          return {
            ...item,
            documentSequenceNumber: items[destinationIndex].documentSequenceNumber, // Take the sequence number from destination
            caseFilingDocumentDetailsId: items[destinationIndex]?.caseFilingDocumentDetailsId
          };
        } else if (index === destinationIndex) {
          return {
            ...item,
            documentSequenceNumber: items[sourceIndex].documentSequenceNumber, // Take the sequence number from source
            caseFilingDocumentDetailsId: items[sourceIndex]?.caseFilingDocumentDetailsId
          };
        }
        return item;
      });
      // Update Formik field with the reordered items
      formik.setFieldValue("uploadedFiles", updatedItems);
    }
  };


  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);


  // Api call for getting list of reason
  const getReasonList = useCallback(async () => {
    const reasonList = await MasterService.getIAReasonsList();
    const reason = makeDropdownOptions(reasonList, "caseReasonTypeDisplayTextEn", "caseReasonTypeId")
    setReasonList(reason);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getReasonList();
  }, [getReasonList]);

  return (
    <div className="margin-top-25">
      <ConfonetCard>
        <ConfonetCardHeader>
          <div className="card-title card-primary-header-title">
            {t("Label.UploadCaseDocuments")}
          </div>
        </ConfonetCardHeader>
        <ConfonetCardBody>
          <div className="card-body-document-upload">
            {caseDocuments.map((item: UploadFileModel, index: number) => {
              const id = index + 1
              return (
                item.documentId !== 6 && item.documentId !== 7 ?
                  (<div key={id}>
                    <Typography
                      className="margin-bottom-10"
                      variant="body2"
                      key={id}
                    >
                      {item?.documentNameEn}
                    </Typography>

                    {item.activeStatus && (
                      <DocumentUploadContainer
                        key={`document_upload_container_${item.documentId}`}
                        index={index}
                        fileList={item.files}
                        updateFileList={handleUpdateFileList}
                        error={formik.touched.uploadedFiles?.[index]?.files && Boolean(
                          formik.errors.uploadedFiles
                            ? (
                              formik.errors
                                .uploadedFiles as FormikErrors<UploadFileModel>[]
                            )?.[index]?.files
                            : false
                        )
                        }
                        helperText={
                          formik.touched.uploadedFiles?.[index]?.files &&
                            formik.errors.uploadedFiles
                            ? ((
                              formik.errors
                                .uploadedFiles as FormikErrors<UploadFileModel>[]
                            )?.[index]?.files as string)
                            : ""
                        }
                        maxSize={26214400}
                      />
                    )}
                  </div>) : item.documentId === 6 ? (
                    <>
                      <ConfonetCard>
                        <ConfonetCardHeader>
                          <div className="card-title card-primary-header-title">
                            {t("Label.AdditionalDocuments")}
                          </div>
                        </ConfonetCardHeader>
                        <ConfonetCardBody>
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="documentUploadContainer"
                                >
                                  {/* formik will be called here */}
                                  {additionalDocuments.map((item: UploadFileModel, index: number) => {
                                    const idx = caseDocuments.length + index
                                    return (
                                      <div key={index}>
                                        <Draggable
                                          key={idx}
                                          draggableId={`draggable-${idx}`}
                                          index={index}
                                          isDragDisabled={item.documentId !== 0} // Disable dragging for existing documents
                                        >
                                          {(provided: any) => (
                                            <div
                                              className={item.documentId === 0 && item.activeStatus ? 'background_red' : ""}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              {item.activeStatus &&
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <Textbox
                                                      className="mt-4"
                                                      label="Document Name *"
                                                      name={`uploadedFiles[${idx}].documentNameEn`}
                                                      value={
                                                        formik.values.uploadedFiles[idx]?.documentNameEn
                                                      }
                                                      onChange={(e) => {
                                                        formik.handleChange(e);
                                                      }}
                                                      onBlur={formik.handleBlur}
                                                      error={
                                                        formik.touched.uploadedFiles?.[idx]
                                                          ?.documentNameEn &&
                                                        Boolean(
                                                          formik.errors.uploadedFiles
                                                            ? (
                                                              formik.errors
                                                                .uploadedFiles as FormikErrors<UploadFileModel>[]
                                                            )?.[idx]?.documentNameEn
                                                            : false
                                                        )
                                                      }
                                                      helperText={
                                                        formik.touched.uploadedFiles?.[idx]
                                                          ?.documentNameEn && formik.errors.uploadedFiles
                                                          ? ((
                                                            formik.errors
                                                              .uploadedFiles as FormikErrors<UploadFileModel>[]
                                                          )?.[idx]?.documentNameEn as string)
                                                          : ""
                                                      }

                                                    />
                                                  </div>
                                                </div>
                                              }

                                              {item.activeStatus && (
                                                <DocumentUploadContainer
                                                  key={`document_upload_container_${item.documentId}`}
                                                  index={idx}
                                                  fileList={item.files}
                                                  updateFileList={handleUpdateFileList}
                                                  error={formik.touched.uploadedFiles?.[idx]?.files && Boolean(
                                                    formik.errors.uploadedFiles
                                                      ? (
                                                        formik.errors
                                                          .uploadedFiles as FormikErrors<UploadFileModel>[]
                                                      )?.[idx]?.files
                                                      : false
                                                  )
                                                  }
                                                  helperText={
                                                    formik.touched.uploadedFiles?.[idx]?.files &&
                                                      formik.errors.uploadedFiles
                                                      ? ((
                                                        formik.errors
                                                          .uploadedFiles as FormikErrors<UploadFileModel>[]
                                                      )?.[idx]?.files as string)
                                                      : ""
                                                  }
                                                  maxSize={26214400}
                                                />
                                              )}

                                              {item.activeStatus && <div className="text-end">
                                                <BasicButton
                                                  className="btn-custom-delete min-width-auto"
                                                  text=""
                                                  variant="text"
                                                  type="button"
                                                  onClick={() => deleteDocument(idx, item)}
                                                />
                                              </div>}

                                            </div>
                                          )}
                                        </Draggable>
                                      </div>
                                    )
                                  })}
                                  {provided.placeholder}
                                </div>
                              )
                              }
                            </Droppable>
                          </DragDropContext>
                          {showMessage && <div className="message"> You can organise your documents by dragging</div>}
                          {showAddOtherButton &&
                            <BasicButton
                              type="button"
                              color="secondary"
                              text={t("Label.AddAnnexuresDocuments")}
                              startIcon={<AddIcon />}
                              variant="contained"
                              onClick={addDocument}
                            />
                          }
                        </ConfonetCardBody>
                      </ConfonetCard>
                    </>
                  ) : (
                    showIaButton &&
                    <ConfonetCard className="card card-primary h-100 margin-top-20 margin-bottom-20">
                      <ConfonetCardHeader>
                        <div className="card-title card-primary-header-title">
                          {t("Label.IADocuments")}
                        </div>
                      </ConfonetCardHeader>
                      <ConfonetCardBody>
                        {/* formik will be called here */}
                        {iaDocuments.map((item: UploadFileModel, index: number) => {
                          const idx = [...caseDocuments, ...additionalDocuments].length + index
                          return (
                            <div key={index}>
                              {item.activeStatus &&
                                <div className="row">
                                  <div className="col-lg-12">
                                    <>
                                      <ConfonetAutocomplete
                                        id="reason"
                                        className="uppercase mt-4"
                                        value={item.selectedIaReason}
                                        options={reasonList}
                                        renderInput={
                                          (params) => (
                                            <TextField
                                              label={t("Label.IAType")}
                                              {...params}
                                              // error={formik.touched.reason && Boolean(formik.errors.reason)}
                                              // helperText={formik.touched.reason && formik.errors.reason?.label}
                                              fullWidth
                                            />
                                          )
                                        }
                                        onChange={(_, newValue: IDropdown | null) => {
                                          formik.setFieldValue(`uploadedFiles[${idx}].selectedIaReason`, newValue ?? { label: "", value: "" });
                                        }}
                                        onBlur={() => { formik.setFieldTouched(`reason.label`, true) }}
                                      />
                                    </>
                                  </div>
                                </div>
                              }

                              {item.activeStatus && (
                                <DocumentUploadContainer
                                  key={`document_upload_container_${item.documentId}`}
                                  index={idx}
                                  fileList={item.files}
                                  updateFileList={handleUpdateFileList}
                                  error={formik.touched.uploadedFiles?.[idx]?.files && Boolean(
                                    formik.errors.uploadedFiles
                                      ? (
                                        formik.errors
                                          .uploadedFiles as FormikErrors<UploadFileModel>[]
                                      )?.[idx]?.files
                                      : false
                                  )
                                  }
                                  helperText={
                                    formik.touched.uploadedFiles?.[idx]?.files &&
                                      formik.errors.uploadedFiles
                                      ? ((
                                        formik.errors
                                          .uploadedFiles as FormikErrors<UploadFileModel>[]
                                      )?.[idx]?.files as string)
                                      : ""
                                  }
                                  maxSize={26214400}
                                />
                              )}

                              {item.activeStatus && <div className="text-end">
                                <BasicButton
                                  className="btn-custom-delete min-width-auto"
                                  text=""
                                  variant="text"
                                  type="button"
                                  onClick={() => deleteDocument(idx, item)}
                                />
                              </div>}

                            </div>
                          )
                        })}
                        <BasicButton
                          type="button"
                          color="secondary"
                          text={t("Label.AddApplicationIA")}
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={addIaDocuments}
                        />
                      </ConfonetCardBody>
                    </ConfonetCard>
                  )
              )
            })}

          </div>
        </ConfonetCardBody>
      </ConfonetCard>
    </div>
  );
};

export default DocumentUpload;
