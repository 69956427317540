import { TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Yup from "yup";
import { BasicDatePicker, ConfonetAutocomplete, ConfonetCard, ConfonetCardBody, ConfonetCardHeader } from '../../../../../components';
import ConfonetTextEditorTiny from '../../../../../components/controls/ConfonetTextEditorTinyMCE';
import { InterlocutoryApplicationCaseDetailsViewModel } from '../../../../../models/cases/interlocutoryApplicationCase/InterlocutoryApplicationCaseDetailsModel';
import { IDropdown } from '../../../../../models/common/IDropdown';
import { MasterService } from '../../../../../services/MasterService/MasterService';
import { caseTypeEnum } from '../../../../../utils/Enum';
import { dropdownValidationSchema, makeDropdownOptions } from '../../../../../utils/Helper';
import { useAppDispatch } from '../../../../../redux';
import { addIaMaReasonList } from '../../../../../redux/MasterReducer/MasterReducer';

interface IInterLocutoryApplicationCaseDetailsProps {
	formik: FormikProps<InterlocutoryApplicationCaseDetailsViewModel>
}


export const interlocutoryApplicationCaseDetailsValidationSchema = Yup.object({
	reason: dropdownValidationSchema("Reason is a required field").required(),
	caseSummary: Yup.string()
		.max(2000, "Max 2000 charactera are allowed"),
})

const InterLocutoryApplicationCaseDetails = ({ formik }: IInterLocutoryApplicationCaseDetailsProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const { caseTypeId } = location.state;

	const [reasonList, setReasonList] = useState<IDropdown[]>([]);

	// Api call for getting list of reason
	const getReasonList = useCallback(async (caseTypeId:number) => {
		const reasonList = await MasterService.getIAMAReasonsList(caseTypeId);
		const reason = makeDropdownOptions(reasonList, "caseReasonTypeDisplayTextEn", "caseReasonTypeId")
		setReasonList(reason);
		dispatch(addIaMaReasonList(reason));
	}, [dispatch])

	const editorValueChange = (value: string) => {
		formik.setFieldValue("caseSummary", value);
	}

	useEffect(() => {
		if (caseTypeId === caseTypeEnum.InterlocutoryApplication || caseTypeId === caseTypeEnum.MiscellaneousApplication) {
			getReasonList(caseTypeId);
		}
	}, [caseTypeId, getReasonList])

	return (
		<div className="margin-top-25">
			<ConfonetCard>
				<ConfonetCardHeader>
					<div className="card-title card-primary-header-title">{caseTypeId === caseTypeEnum.InterlocutoryApplication ? "Order/Judgement under challenged" :t("Label.CaseDetails")}</div>
				</ConfonetCardHeader>
				<ConfonetCardBody>
					<div className="row">
						<div className="col-lg-4">
							<ConfonetAutocomplete
								id="reason"
								className="uppercase"
								value={formik.values.reason}
								options={reasonList}
								renderInput={
									(params) => (
										<TextField
											label={caseTypeId === caseTypeEnum.InterlocutoryApplication ? t("Label.IAType") : t("Label.Reason") + "*"}
											{...params}
											error={formik.touched.reason && Boolean(formik.errors.reason)}
											helperText={formik.touched.reason && formik.errors.reason?.label}
											fullWidth
										/>
									)
								}
								onChange={(_, newValue: IDropdown | null) => { formik.setFieldValue(`reason`, newValue ?? { label: "", value: "" }) }}
								onBlur={() => { formik.setFieldTouched(`reason.label`, true) }}
							/>
						</div>
						{caseTypeId === caseTypeEnum.MiscellaneousApplication &&
							<div className="col-lg-4">
								<BasicDatePicker
									label={t("Label.DateOfOrder") + "*"}
									value={formik.values.dateOfOrder}
									disabled
									className="w-100"
									onChange={(e) => {
										formik.setFieldValue("dateOfOrder", e, true)
									}}

									slotProps={{
										textField: {
											variant: "outlined",
											error: formik.touched.dateOfOrder && Boolean(formik.errors.dateOfOrder),
											helperText: (
												<span className="text-danger">
													{formik.touched.dateOfOrder && formik.errors.dateOfOrder as string}
												</span>
											),
											onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
										}
									}}
								/>
							</div>}
					</div>
					{/* <div className="row">
						<div className="col-lg-12">
							<ConfonetTextEditorTiny
								id="caseDetails"
								value={formik.values.caseSummary}
								onEditorChange={editorValueChange}
								disabled
							/>
						</div>
					</div> */}
				</ConfonetCardBody>
			</ConfonetCard>
		</div>
	)
}

export default InterLocutoryApplicationCaseDetails