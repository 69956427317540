import { FormikProps } from 'formik'
import React from 'react'
import { IoArrowForwardCircleOutline } from 'react-icons/io5'
import { BasicButton, BasicDatePicker, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Textbox } from '../../../../components'
import { ImportantNotesSearchViewModel } from '../../../../models/ImportantNotes/IImportantNotes'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'


interface IImportantNotesSearchProps {
  importantNotesSearchFormik: FormikProps<ImportantNotesSearchViewModel>
};

const ImportantNotesSearch: React.FC<IImportantNotesSearchProps> = ({ importantNotesSearchFormik }) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="margin-top-25">
      <form onSubmit={importantNotesSearchFormik.handleSubmit}>
        <ConfonetCard>
          <ConfonetCardHeader>
            <div className="card-title card-primary-header-title">
            {location.pathname.includes('commission/registry') ? t("Label.RegistryReport") : t("Label.ImportantNotes")}
            </div>
          </ConfonetCardHeader>
          <ConfonetCardBody>
            <div className="row justify-content-center margin-top-10">
              <div className="col-sm-4">
                <Textbox
                  name="caseNumber"
                  placeholder={t("Label.CaseNumberOrFilingRefrenceNumber") + "*"}
                  value={importantNotesSearchFormik.values.caseNumber}
                  onChange={importantNotesSearchFormik.handleChange}
                  onBlur={importantNotesSearchFormik.handleBlur}
                  error={importantNotesSearchFormik.touched.caseNumber && Boolean(importantNotesSearchFormik.errors.caseNumber)}
                  helperText={importantNotesSearchFormik.touched.caseNumber && importantNotesSearchFormik.errors.caseNumber}
                />
              </div>
              Or
              <div className="col-sm-4">
                <BasicDatePicker
                  label={t("Label.HearingDate")}
                  value={importantNotesSearchFormik.values.date}
                  className="w-100"
                  onChange={(e) => importantNotesSearchFormik.setFieldValue("date", e, true)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: importantNotesSearchFormik.touched.date && Boolean(importantNotesSearchFormik.errors.date),
                      helperText: (
                        <>
                          <span className="text-danger">
                            {importantNotesSearchFormik.touched.date && importantNotesSearchFormik.errors.date as string}
                          </span>
                        </>
                      ),
                      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                    }
                  }}
                />
              </div>
            </div>
            <div className="text-end">
              <BasicButton
                className="btn-custom btn-custom-primary"
                type="submit"
                variant="contained"
                text={t("BtnText.Submit")}
                endIcon={<IoArrowForwardCircleOutline />}
              />
            </div>
          </ConfonetCardBody>
        </ConfonetCard>
      </form>
    </div>
  )
}

export default ImportantNotesSearch