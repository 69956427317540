import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RenderBookmarkItemProps, bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import { SpecialZoomLevel, Viewer, ViewerProps, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DescriptionIcon from '@mui/icons-material/Description';
import packageJson from '../../../package.json';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Import component or interfaces
import { useTranslation } from 'react-i18next';
import { ICaseDocumentList } from '../../pages/MainWebPages/CaseHistoryCaseStatus/CaseHistory/component/CaseHistoryTimeLine';
import { BasicButton } from './BasicButton';
import { IIaCaseDocumentListForCompleteDoc } from "../../pages/Judge/JudgeCaseDetailsPage/JudgeCasePagesPreview";


interface IConfonetPdfViewerProps extends ViewerProps {
    documentNameList?: ICaseDocumentList[],
    handleDocumentChange?: (id: number, caseNumber?:string) => void
    replyDocumentList?: ICaseDocumentList[],
    rejoinderDocumentList?: ICaseDocumentList[],
    iaDocumentList?: IIaCaseDocumentListForCompleteDoc[],
    caseNumber?: string;
    onClose?: () => void;
}

const ConfonetPdfViewer = ({ fileUrl, onClose, caseNumber, documentNameList, iaDocumentList, replyDocumentList, rejoinderDocumentList, handleDocumentChange, defaultScale = SpecialZoomLevel.PageWidth, ...rest }: IConfonetPdfViewerProps) => {
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
    const { t } = useTranslation();
    const defaultPlugin = defaultLayoutPlugin();
    const bookmarkPluginInstance = bookmarkPlugin();
    const { Bookmarks } = bookmarkPluginInstance;

    const renderBookmarkItem = (renderProps: RenderBookmarkItemProps) =>
        renderProps.defaultRenderItem(
            renderProps.onClickItem,
            <>
                {renderProps.defaultRenderToggle(<ExpandLessIcon />, <ExpandMoreIcon />)}
                {renderProps.defaultRenderTitle(renderProps.onClickTitle)}
            </>
        );


    return (
        <Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}>
            {/* <Worker workerUrl={`${window.location.origin}/pdf.worker.min.js`}>   */}
            <div className='pdf-style'>
                {documentNameList &&
                    <div className='pdf-bookmark-style'>
                        {caseNumber && <p className='document-bookmark-title'>
                            <ArrowBackIcon sx={{ marginRight: 3, cursor: "pointer" }} onClick={onClose} />
                            Complete Case Documents - {caseNumber}
                        </p>}
                        <div className="pdf-bookmark-content">
                            {/* main case documents list */}
                            {documentNameList?.length > 0 && (
                                <>

                                    {documentNameList.map((document) => (
                                        <>
                                            <p
                                                className='pdf-list-name-box'
                                                onClick={() => handleDocumentChange && handleDocumentChange(document.caseFilingDocumentDetailsId)}
                                                key={document.sequenceNumber}
                                            >
                                                <div className='margin-left-30'>
                                                    <DescriptionIcon sx={{ marginRight: 2 }} />
                                                    {document.documentName.replaceAll("*", " ")}
                                                </div>
                                            </p>
                                        </>
                                    ))}
                                </>
                            )}

                            {/* Ia Documents list */}
                            {iaDocumentList && iaDocumentList?.length > 0 && iaDocumentList?.map((iaDocs) => (
                                <>
                                    <p className='document-bookmark-title margin-top-30'>
                                        IA Documents - {iaDocs.caseNumber}
                                    </p>
                                    {iaDocs?.documentList?.map((document) => (
                                        <>
                                            <p
                                                className='pdf-list-name-box'
                                                onClick={() => handleDocumentChange && handleDocumentChange(document.caseFilingDocumentDetailsId, iaDocs.caseNumber)}
                                                key={document.sequenceNumber}
                                            >
                                                <div className='margin-left-30'>
                                                    <DescriptionIcon sx={{ marginRight: 2 }} />
                                                    {document.documentName.replaceAll("*", " ")}
                                                </div>
                                            </p>
                                        </>
                                    ))}
                                </>
                            ))}
                            {/* Reply documents list */}
                            {replyDocumentList && replyDocumentList?.length > 0 && (
                                <>
                                    <p className='document-bookmark-title margin-top-30'>
                                        Reply Documents - {caseNumber}
                                    </p>
                                    {replyDocumentList.map((document) => (
                                        <>
                                            <p
                                                className='pdf-list-name-box'
                                                onClick={() => handleDocumentChange && handleDocumentChange(document.caseFilingDocumentDetailsId)}
                                                key={document.sequenceNumber}
                                            >
                                                <div className='margin-left-30'>
                                                    <DescriptionIcon sx={{ marginRight: 2 }} />
                                                    {document.documentName.replaceAll("*", " ")}
                                                </div>
                                            </p>
                                        </>
                                    ))}
                                </>
                            )}

                            {/* Rejoinder documents list */}
                            {rejoinderDocumentList && rejoinderDocumentList?.length > 0 && (
                                <>
                                    <p className='document-bookmark-title margin-top-30'>
                                        Rejoinder Documents - {caseNumber}
                                    </p>
                                    {rejoinderDocumentList.map((document) => (
                                        <>
                                            <p
                                                className='pdf-list-name-box'
                                                onClick={() => handleDocumentChange && handleDocumentChange(document.caseFilingDocumentDetailsId)}
                                                key={document.sequenceNumber}
                                            >
                                                <div className='margin-left-30'>
                                                    <DescriptionIcon sx={{ marginRight: 2 }} />
                                                    {document.documentName.replaceAll("*", " ")}
                                                </div>
                                            </p>
                                        </>
                                    ))}
                                </>
                            )}

                        </div>
                    </div>}
                <div className='pdf-viewer-style'>
                    <Viewer
                        fileUrl={fileUrl}
                        plugins={[defaultPlugin, bookmarkPluginInstance]}
                        defaultScale={defaultScale}
                        {...rest}

                    />
                    {documentNameList?.length ? <div className='pdf-back-button'>
                        <BasicButton
                            text='Back'
                            variant='contained'
                            onClick={() => handleDocumentChange && handleDocumentChange(documentNameList ? documentNameList[0].caseFilingDocumentDetailsId : 0)}
                        />
                    </div> : <></>}
                </div>
            </div>
        </Worker >
    )
}

export default ConfonetPdfViewer