import { FormikProps } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ConfonetCard, ConfonetCardBody, ConfonetCardHeader } from "../../../../../../components";
import ConfonetTextEditorTiny from "../../../../../../components/controls/ConfonetTextEditorTinyMCE";
import { AppealExecutionCaseDetailsViewModel } from "../../../../../../models/cases/appealTypeCase/CaseDetailsViewModel";


interface IAppealExecutionCaseDetailsProps {
    formik: FormikProps<AppealExecutionCaseDetailsViewModel>
}

export const appealExecutionCaseDetailsValidationSchema = Yup.object({
    caseSummary: Yup.string()
        .max(2000, "Max 2000 charactera are allowed"),
})

export const AppealExecutionCaseDetails = ({ formik }: IAppealExecutionCaseDetailsProps) => {
    const { t } = useTranslation();

    const editorValueChange = (value:string) => {
        formik.setFieldValue("caseSummary", value);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="margin-top-25">
                <ConfonetCard>
                    <ConfonetCardHeader>
                        <div className="card-title card-primary-header-title">{t("Label.CaseDetails")}</div>
                    </ConfonetCardHeader>
                    <ConfonetCardBody>
                        {/* <div className="row">
                            <div className="col-lg-4">
                                <Textbox
                                    id="NationalCommissionCaseNumber"
                                    name={`nationalCommissionCaseNumber`}
                                    className="uppercase"
                                    label={t("Label.NationalCommissionCaseNumber")}
                                    value={formik.values.nationalCommissionCaseNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ maxLength: 30 }}
                                // error={formik.touched?.nationalCommissionCaseNumber && Boolean(formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.nationalCommissionCaseNumber : false)}
                                // helperText={formik.touched?.nationalCommissionCaseNumber && formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.nationalCommissionCaseNumber : ""}
                                />
                            </div>

                            <div className="col-lg-4">
                                <BasicDatePicker
                                    label={t("Label.DateOfOrder")}
                                    value={formik.values.nationalCommissionDateOfOrder}
                                    className="w-100"
                                    onChange={(e) => formik.setFieldValue("nationalCommissionDateOfOrder", e, true)}

                                    slotProps={{
                                        textField: {
                                            variant: "outlined",
                                            error: formik.touched.nationalCommissionDateOfOrder && Boolean(formik.errors.nationalCommissionDateOfOrder),
                                            helperText: (
                                                <>
                                                    <span className="text-danger">
                                                        {formik.touched.nationalCommissionDateOfOrder && formik.errors.nationalCommissionDateOfOrder as string}
                                                    </span>
                                                </>
                                            ),
                                            onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <Textbox
                                    id="SupremeCourtCaseNumber"
                                    name={`supremeCourtCaseNumber`}
                                    className="uppercase"
                                    label={t("Label.SupremeCourtCaseNumber")}
                                    value={formik.values.supremeCourtCaseNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ maxLength: 30 }}
                                // error={formik.touched?.supremeCourtCaseNumber && Boolean(formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.supremeCourtCaseNumber : false)}
                                // helperText={formik.touched?.supremeCourtCaseNumber && formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.supremeCourtCaseNumber : ""}
                                />
                            </div>

                            <div className="col-lg-4">
                                <BasicDatePicker
                                    label={t("Label.DateOfOrder")}
                                    value={formik.values.supremeCourtDateOfOrder}
                                    className="w-100"
                                    onChange={(e) => formik.setFieldValue("supremeCourtDateOfOrder", e, true)}

                                    slotProps={{
                                        textField: {
                                            variant: "outlined",
                                            error: formik.touched.supremeCourtDateOfOrder && Boolean(formik.errors.supremeCourtDateOfOrder),
                                            helperText: (
                                                <>
                                                    <span className="text-danger">
                                                        {formik.touched.supremeCourtDateOfOrder && formik.errors.supremeCourtDateOfOrder as string}
                                                    </span>
                                                </>
                                            ),
                                            onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                        }
                                    }}
                                />
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-12">
                                <ConfonetTextEditorTiny
                                    id="caseDetails"
                                    value={formik.values.caseSummary}
                                    onEditorChange={editorValueChange}
                                    disabled
                                />
                            </div>
                        </div>
                        <></>
                    </ConfonetCardBody>
                </ConfonetCard>
            </div>
    )
}