import React from 'react'
import { useNavigate } from 'react-router-dom';
import { JUDGE_BENCHES_LIST, JUDGE_DASHBOARD } from '../../../navigation/CONSTANTS';
import homeIcon from '../../../../src/assets/images/home.png'
import backIcon from '../../../assets/images/back.png'

function ButtoBack() {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate(JUDGE_BENCHES_LIST); // Navigate to the home page
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className='home-back-buttons'>
      <div onClick={handleHomeClick} style={{ cursor: 'pointer' }}>
        <img src={homeIcon} alt="Home" className='button_back' />
      </div>
      <div onClick={handleBackClick} style={{ cursor: 'pointer' }}>
        <img src={backIcon} alt="Back" className='button_back' />
      </div>
    </div>
  )
}

export default ButtoBack
