import { DialogActions, DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BasicButton, Dialogbox, TextArea } from "../../../../../../components";
import { IGetRevertedHistoryResponse } from "../../../../../../models/cases/commissionCase/ICommissionCase";
import { IGetCaseListResponse } from "../../../../../../models/cases/consumerCases/IConsumerCaseModel";
import { IDropdown } from "../../../../../../models/common/IDropdown";
import { ADVOCATE_APPEAL_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_FILENEWCASE_REVERTED_CASE_PREVIEW, ADVOCATE_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW, CONSUMER_APPEAL_TYPE_CASE_REVERTED_PREVIEW, CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW, CONSUMER_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW, CONSUMER_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW } from "../../../../../../navigation/CONSTANTS";
import { RootState } from "../../../../../../redux";
import { CaseServices } from "../../../../../../services/CaseService/CaseServices";
import { CommissionCaseService } from "../../../../../../services/CaseService/CommissionCaseService/CommissionCaseService";
import { caseStages, caseTypeEnum } from "../../../../../../utils/Enum";
import { createHeaderWithBreaks, navigateToDashboardPath, navigateToDifferentPath } from "../../../../../../utils/Helper";
import { CaseList } from "../../../CaseList";
import { groupCases, ICaseWithAttachedCase } from "../../../../../../utils/CaseHelper";

export interface IRevertedInScrutinyCaseRows {
    id: number;
    referenceNumber: number;
    complainantNameEn: string;
    respondantNameEn: string;
    casefillingStageId: number;
    casefillingStageNameEn: string;
    dateOfFilling: string;
    caseTypeId: number;
    previousCaseNumber: string;
    reasonId: number;
    reasonText: string;
    caseType: string;
    attachedCases: ICaseWithAttachedCase[]
}

export const RevertedInScrutinyCaseList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const [caseListRows, setCaseListRows] = useState<IRevertedInScrutinyCaseRows[]>([]);
    const [filingReferenceNumber, setFilingReferenceNumber] = useState<number>(0);
    const [casefilingStageId, setCaseFilingStageId] = useState<number>(0);
    const [revertedHistory, setRevertedHistory] = useState<IGetRevertedHistoryResponse[]>([]);
    const [caseTypeId, setCaseTypeId] = useState<number>();
    const [attachedIaCases, setAttachedIaCases] = useState<ICaseWithAttachedCase[]>([])
    const [reason, setReason] = useState<IDropdown>({ label: "", value: "" });

    const handleAction = async (referenceNumber: number, caseTypeId: number, caseNumber: string, reasonId: number, reasonText: string, iaCasesAttached: ICaseWithAttachedCase[], casefillingStageId: number) => {
        const response = await CommissionCaseService.getRevertedHistoryList(
            referenceNumber,
            casefillingStageId
        )
        if (response) {
            setRevertedHistory(response)
            setCaseTypeId(caseTypeId)
        }
        setAttachedIaCases(iaCasesAttached.length > 0 ? iaCasesAttached : []);
        setFilingReferenceNumber(referenceNumber);
        setCaseFilingStageId(casefillingStageId);
        setReason({ label: reasonText, value: reasonId });
    }

    const handleProceedToPreview = async () => {
        const revertedRemarks = await CommissionCaseService.getRevertedReason(
            filingReferenceNumber,
            casefilingStageId
        )
        if (revertedRemarks) {
            const state = {
                commission: {
                    label: `${revertedRemarks.commissionNameEn}`,
                    value: revertedRemarks.commissionId
                },
                filingReferenceNumber,
                revertedRemarks: revertedRemarks.revertedReason,
                locationTypeId: revertedRemarks.commissionTypeId,
                attachedCases: attachedIaCases
            }
            if (caseTypeId === caseTypeEnum.ConsumerCase || caseTypeId === caseTypeEnum.CCPAAppeal) {
                navigate(navigateToDifferentPath(userDetailsData?.roleId, CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW, ADVOCATE_FILENEWCASE_REVERTED_CASE_PREVIEW), { state: { ...state, caseTypeId } });
            }
            else if (caseTypeId === caseTypeEnum.InterlocutoryApplication || caseTypeId === caseTypeEnum.MiscellaneousApplication) {
                let path = navigateToDifferentPath(userDetailsData?.roleId, CONSUMER_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW)
                if (caseTypeId === caseTypeEnum.MiscellaneousApplication) {
                    path = navigateToDifferentPath(userDetailsData?.roleId, CONSUMER_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW)
                }
                navigate(path, { state: { ...state, caseTypeId, reason, commissionTypeId: revertedRemarks.commissionTypeId } })
            } else {
                navigate(navigateToDifferentPath(userDetailsData?.roleId, CONSUMER_APPEAL_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_APPEAL_TYPE_CASE_REVERTED_PREVIEW), { state: { ...state, caseTypeId, commissionTypeId: revertedRemarks.commissionTypeId } })
            }
        }
    }

    const caseListColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.No"), maxWidth: 70, flex: 1 },
        {
            field: "referenceNumber", headerName: t("Label.ReferenceDiaryNumber"), flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.row?.attachedCases?.length ? (
                            <>
                                <p className="mr-2 cell-text-wrap fw-medium">
                                    {params.row.referenceNumber}
                                    <br />
                                    WITH
                                    <br />
                                    {params.row?.attachedCases.map((cases: ICaseWithAttachedCase) => (
                                        <li className="list-unstyled" key={cases.referenceNumber}>
                                            {cases.referenceNumber}
                                        </li>
                                    ))}
                                </p>
                            </>
                        ) : (
                            <p>
                                {params.row.referenceNumber}
                            </p>
                        )}
                    </>
                )
            }
        },
        { field: "complainantNameEn", headerName: createHeaderWithBreaks([t("Label.ComplainantOrAppellant"), t("Label.Petitioner")]), description: t("Label.Complainant/Appellant/Petitioner"), flex: 1.5, headerClassName: 'multiline-header' },
        { field: "respondantNameEn", headerName: createHeaderWithBreaks([t("Label.OppositeParty"), t("Label.Respondent")]), description: t("Label.OppositeParty/Respondent"), flex: 1, headerClassName: 'multiline-header' },
        { field: "casefillingStageNameEn", headerName: t("Label.CaseStage"), flex: 1 },
        { field: "caseType", headerName: t("Label.CaseType"), flex: 1 },
        { field: "dateOfFilling", headerName: t("Label.FilingDate"), flex: 1 },
        {
            field: "Action",
            headerName: t("Label.Action"),
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                return (
                    <BasicButton
                        text={t("BtnText.View")}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleAction(params.row.referenceNumber, params.row.caseTypeId, params.row.previousCaseNumber, params.row.reasonId, params.row.reasonText, params.row?.attachedCases, params.row.casefillingStageId)}
                    />
                )
            }
        },
    ]

    const handleGetCaseList = useCallback
        (async () => {
            const caseListResponse = await CaseServices.getAllCaseList(userDetailsData?.commissionId ?? 0, userDetailsData?.roleId, userDetailsData.userId, [caseStages.revertedInScrutiny, caseStages.revertedAfterApproval]);
            setCaseListRows(groupCases(caseListResponse).revertedConsumerCaseList ?? []);
        }, [userDetailsData?.commissionId, userDetailsData?.roleId, userDetailsData.userId]);

    useEffect(() => {
        handleGetCaseList();
    }, [handleGetCaseList]);


    const breadCrumbs = [
        { to: navigateToDashboardPath(userDetailsData?.roleId), label: t("Breadcrumb.Dashboard") },
        { to: '', label: t("Breadcrumb.RevertedCases") },
    ]

    return (
        <>
            <Dialogbox
                open={!!filingReferenceNumber}
                onClose={() => {
                    setFilingReferenceNumber(0)
                }}
                maxWidth="sm"
                title={t("Label.RevertedReason")}
            >
                <DialogContent>
                    {
                        revertedHistory.map((revertData) =>
                            <>
                                <div className="row">
                                    <div className="col-lg-4">
                                        {/* <BasicDatePicker
                                                label={t("Label.CreatedOnDateTime")}
                                                value={}
                                                readOnly
                                            /> */}
                                    </div>
                                    <div className="col-lg-12">
                                        <TextArea
                                            label={DateTime.fromFormat(revertData.createdOnDateTime, "yyyy-MM-dd").toFormat("dd/MM/yyyy")}
                                            value={revertData.defectTypeText}
                                            multiline
                                            shrink
                                            disabled
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <BasicButton
                        text={t("BtnText.UpdateCase")}
                        onClick={handleProceedToPreview}
                        variant="contained"
                    />
                </DialogActions>
            </Dialogbox>

            <CaseList
                caseListRows={caseListRows}
                caseListColumns={caseListColumns}
                breadCrumbs={breadCrumbs}
            />
        </>
    )
}