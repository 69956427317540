import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BasicButton } from "../../../../../../components";
import { ADVOCATE_CASELIST_PREVIEW, CONSUMER_CASELIST_PREVIEW } from "../../../../../../navigation/CONSTANTS";
import { RootState } from "../../../../../../redux";
import { CaseServices } from "../../../../../../services/CaseService/CaseServices";
import { groupCases, ICaseWithAttachedCase } from "../../../../../../utils/CaseHelper";
import { caseStages } from "../../../../../../utils/Enum";
import { createHeaderWithBreaks, navigateToDashboardPath, navigateToDifferentPath } from "../../../../../../utils/Helper";
import { CaseList } from "../../../CaseList";


export interface IPendingForScrutinyCaseRows {
    id: number;
    referenceNumber: number;
    complainantNameEn: string;
    respondantNameEn: string;
    casefillingStageId: number;
    casefillingStageNameEn: string;
    dateOfFilling: string;
    attachedCases: ICaseWithAttachedCase[]
}

export const PendingForScrutinyCaseList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const [caseListRows, setCaseListRows] = useState<IPendingForScrutinyCaseRows[]>([]);

    const caseListColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.No"), maxWidth: 70, flex: 1 },
        {
            field: "referenceNumber", headerName: t("Label.ReferenceDiaryNumber"), flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.row?.attachedCases?.length ? (
                            <>
                                <p className="mr-2 cell-text-wrap fw-medium">
                                    {params.row.referenceNumber}
                                    <br />
                                    WITH
                                    <br />
                                    {params.row?.attachedCases.map((cases: ICaseWithAttachedCase) => (
                                        <li className="list-unstyled" key={cases.referenceNumber}>
                                            {cases.referenceNumber}
                                        </li>
                                    ))}
                                </p>
                            </>
                        ) : (
                            <p>
                                {params.row.referenceNumber}
                            </p>
                        )}
                    </>
                )
            }
        },
        { field: "complainantNameEn", headerName: createHeaderWithBreaks([t("Label.ComplainantOrAppellant"), t("Label.Petitioner")]), description: t("Label.Complainant/Appellant/Petitioner"), flex: 1.5, headerClassName: 'multiline-header' },
        { field: "respondantNameEn", headerName: createHeaderWithBreaks([t("Label.OppositeParty"), t("Label.Respondent")]), description: t("Label.OppositeParty/Respondent"), flex: 1, headerClassName: 'multiline-header' },
        { field: "casefillingStageNameEn", headerName: t("Label.CaseStage"), flex: 1 },
        { field: "dateOfFilling", headerName: t("Label.FilingDate"), flex: 1 },
        {
            field: "Action",
            headerName: t("Label.Action"),
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                return (
                    <BasicButton
                        text={t("BtnText.View")}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            const state = {
                                edit: false,
                                filingReferenceNumber: params.row.referenceNumber,
                                caseTypeId: params.row.caseTypeId,
                                attachedCases: params.row.attachedCases
                            }
                            navigate(navigateToDifferentPath(userDetailsData.roleId, CONSUMER_CASELIST_PREVIEW, ADVOCATE_CASELIST_PREVIEW), { state })
                        }}
                    />
                )
            }
        },
    ]

    const handleGetCaseList = useCallback(async () => {
        const caseListResponse = await CaseServices.getAllCaseList(userDetailsData?.commissionId ?? 0, userDetailsData.roleId, userDetailsData.userId, [caseStages.pendingForScrutiny]);
        setCaseListRows(groupCases(caseListResponse).scrutinyConsumerCaseList ?? []);
    }, [userDetailsData?.commissionId, userDetailsData.roleId, userDetailsData.userId]);

    useEffect(() => {
        handleGetCaseList();
    }, [handleGetCaseList]);


    const breadCrumbs = [
        { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
        { to: '', label: t("Breadcrumb.PendingForScrutiny") },
    ]

    return (
        <>
            <CaseList
                caseListRows={caseListRows}
                caseListColumns={caseListColumns}
                breadCrumbs={breadCrumbs}
            />
        </>
    )
}