import { Button, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import axios from "axios";
import { DateTime } from 'luxon';
import { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicBreadcrumbs, Dialogbox, PreviewCard } from "../../../components";
import ConfonetHtmlIframe from "../../../components/controls/ConfonetHtmlIframe";
import ConfonetPdfViewer from "../../../components/controls/ConfonetPdfViewer";
import { JUDGE_CASE_PREVIEW } from "../../../navigation/CONSTANTS";
import { RootState } from "../../../redux";
import { loaderService } from "../../../services/CommonService/loaderService";
import { BASE_URL, CASE_FILING_SERVICE } from "../../../services/CONSTANTS";
import { convertBase64ToUInt8, convertFileToUnit8Array, flattenArrayToIPreview, htmlStringToPdf } from "../../../utils/Helper";
import ButtoBack from "./Button";

export interface ICaseDocumentList {
    caseFilingDocumentDetailsId: number;
    documentName: string;
    fileContent: string;
    sequenceNumber: number;
}

export type ICaseStatusDeta = {
    caseNumber: string;
    filingRefrenceNumber: number;
    filingDate: string;
    filedIn: string;
    complainant: string;
    oppositeParty: string;
    nextHearing: string;
    caseStage: string;
    applicationFiled: string,
    attachedLoweCourt: string,
    purpose: string
};

export const DailyOrderData = (): JSX.Element => {
    const theme = useTheme();
    const { state } = useLocation();
    const caseHearingDetails = state?.caseHearingDetails;
    const caseStatusDetails = state?.caseStatusDetails;
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [documentContent, setDocumentContent] = useState<Uint8Array | null>(null)
    const [documentList, setDocumentList] = useState<ICaseDocumentList[]>([]);
    const [htmlString, setHtmlString] = useState<string>("");

    const { t } = useTranslation();

    const columns = [
        { id: 'id', label: 'S.No' },
        { id: 'date', label: 'Date of Hearing' },
        { id: 'hearingDate', label: 'Date of Next Hearing' },
        { id: 'caseStage', label: 'Case Stage' },
        { id: 'proceeding', label: 'Courtroom Proceedings' },
        { id: 'dailyorder', label: 'Daily Order Entry' }
    ];

    const handleGetDailyOrder = useCallback(async (dateOfHearing: string) => {
        loaderService.setLoading(true);
        axios.get(`${BASE_URL}/${CASE_FILING_SERVICE}/getDailyOrderJudgementPdf?caseNumber=${caseStatusDetails?.caseNumber}&dateOfHearing=${dateOfHearing}&orderTypeId=${1}`, {},).then((res) => {
            console.log('res.data?.data', caseStatusDetails.orderTypeId)
            if (res.data?.data.dailyOrderPdf) {
                if (res?.data?.data?.dailyOrderPdf.includes('<html>')) {
                    setHtmlString(res?.data?.data?.dailyOrderPdf)
                } else {
                    setDocumentContent(convertBase64ToUInt8(res?.data?.data?.dailyOrderPdf))
                }
            } else {
                toast.info('Daily order not available currently');
            }
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.info('Daily order not available currently');
            }
        }).finally(() => {
            loaderService.clearLoading();
        });
    }, [caseStatusDetails?.caseNumber]);

    const handleShowCourtRoomProceeding = async (proceedingText: string) => {
        const { myFile } = await htmlStringToPdf(proceedingText, "courtRoomProceeding", "portrait");
        setDocumentContent(await convertFileToUnit8Array(myFile));
    }

    const handleDocumentChange = (id: number) => {
        const document = documentList.filter((item) => item.caseFilingDocumentDetailsId === id);
        const pdf = convertBase64ToUInt8(document[0].fileContent);
        setDocumentContent(pdf);
    }

    console.log(htmlString);


    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-sm-7 margin-bottom-30">
                        <BasicBreadcrumbs items={[
                            { to: JUDGE_CASE_PREVIEW, label: t("Breadcrumb.CaseDetails") },
                            { to: '', label: t("Label.DailyOrderEntry") },
                        ]} />
                    </div>
                </div>
                {caseStatusDetails &&
                    <PreviewCard
                        className="margin-top-25"
                        heading={t("Label.CaseDetails")}
                        items={flattenArrayToIPreview(caseStatusDetails)}
                    />}

                <div className="margin-top-25">
                    <div className="card-title card-primary-header-title">
                        Daily Order List
                    </div>

                    <TableContainer component={Paper} className="margin-top-25">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} style={{ color: 'black', fontWeight: 'bold' }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {caseHearingDetails?.map((item: any, index: number) => {
                                    // Format the date using luxon
                                    const formattedDate = DateTime.fromISO(item.dateOfHearing).toFormat('dd-MM-yyyy');
                                    const formattedNextHearingDate = DateTime.fromISO(item.dateOfNextHearing).toFormat('dd-MM-yyyy');
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell style={{ color: 'black' }}>{index + 1}</TableCell>
                                            <TableCell style={{ color: 'black' }}>{formattedDate}</TableCell>
                                            <TableCell style={{ color: 'black' }}>{formattedNextHearingDate}</TableCell>
                                            <TableCell style={{ color: 'black' }}>{item.caseStage}</TableCell>
                                            <TableCell style={{ color: 'black' }}>
                                                <Button onClick={() => handleShowCourtRoomProceeding(item.proceedingText)} variant="contained" color="secondary">View</Button>
                                            </TableCell>
                                            <TableCell style={{ color: 'black' }}>
                                                {item.dailyOrderStatus ? <Button onClick={() => handleGetDailyOrder(item.dateOfHearing)} variant="contained" color="secondary">View</Button> : `Document not Available`}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div style={{ clear: 'both', marginTop: '25px', textAlign: 'right' }}>
                    <ButtoBack />
                </div>

            </div>
            <Dialogbox
                open={documentContent !== null || htmlString.length > 0}
                onClose={() => {
                    setDocumentContent(null);
                    setHtmlString('');
                    setDocumentList([]);
                }}
                maxWidth="lg"
                fullScreen
            >
                <DialogContent>
                    {documentContent ? (
                        <ConfonetPdfViewer
                            fileUrl={documentContent}
                            // documentNameList={documentList}
                            handleDocumentChange={handleDocumentChange}
                        />
                    ) : ""}
                    {htmlString.length ? (
                        <div style={{ width: '100%', height: '100%' }}>
                            <ConfonetHtmlIframe
                                htmlContent={htmlString}
                                title="Daily Order"
                            />
                        </div>
                    ) : ""}
                </DialogContent>
            </Dialogbox>
        </>
    );
};