import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useEffect } from 'react';

interface IConfonetTextEditorTinyProps extends IAllProps {
    id?: string,
    name?: string,
    height?: string | number,
}

const ConfonetTextEditorTiny = ({ id, name, height, ...rest }: IConfonetTextEditorTinyProps) => {
    useEffect(() => {
        // Create a script element
        const configScript = document.createElement('script');
        const script = document.createElement('script');

        // Set the script source to your JavaScript file
        configScript.innerHTML = `window.WEBSPELLCHECKER_CONFIG = {
            autoSearch: true,
            enableGrammar: true,
    	};`
        script.src = 'https://e-jagriti.gov.in/wscservice/wscbundle/wscbundle.js';

        // Append the script to the document body
        document.body.appendChild(configScript);
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(configScript);
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Editor
            tinymceScriptSrc={`${window.location.origin}/tinymce/js/tinymce/tinymce.min.js`}
            init={{
                height,
                branding: false,
                promotion: false,
                menubar: false,
                plugins: 'tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
                toolbar: ['bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat', 'print | undo redo | cut copy paste formatpainter | forecolor backcolor | blocks fontfamily fontsize rupee',],
                tinycomments_mode: 'embedded',
                tinycomments_author: 'e-Jagriti',
                mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                ],
                setup: (editor) => {
                    editor.on("keydown",
                        (event) => {
                            if (event.code == "Tab") {
                                editor.execCommand(event.shiftKey ? "Outdent" : "Indent")
                                event.preventDefault()
                                return false
                            }
                        }
                    )
                    editor.ui.registry.addButton('rupee', {
                        text: "₹",
                        tooltip: "Rupee symbol",
                        onAction: () => {
                            editor.insertContent('₹');
                        }
                    });
                }
            }}
            {...rest}
        />
    )
}

export default ConfonetTextEditorTiny;