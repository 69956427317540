import { FormikHelpers, useFormik } from "formik";
import { ICCPACaseDetailViewModel } from "../models/cases/ccpaTypeCase/ICCPACaseDetailsModel";
import { AdditionalComplainantViewModel } from "../models/cases/fileNewCase/AdditionalComplainantModel";
import { AdditionalRespondentViewModel } from "../models/cases/fileNewCase/AdditionalRespondentModel";
import { CaseDetailFormViewModel } from "../models/cases/fileNewCase/CaseDetailFormModel";
import { ComplainantRespondentViewModel } from "../models/cases/fileNewCase/ComplainantResondentModel";
import { DocumentUploadModel } from "../models/cases/fileNewCase/DocumentUploadModel";
import { FinalSubmitViewModel } from "../models/cases/fileNewCase/FinalSubmitModel";
import { CCPACaseDetailFormValidationSchema } from "../pages/Consumer/CCPATypeCase/components/CCPACaseDetailForm";
import { additionalComplainantValidationSchema } from "../pages/Consumer/FileNewCase/components/StepperComponents/AdditionalComplainant";
import { additionalRespondentValidationSchema } from "../pages/Consumer/FileNewCase/components/StepperComponents/AdditionalRespondent";
import { caseDetailFormValidationSchema } from "../pages/Consumer/FileNewCase/components/StepperComponents/CaseDetailForm";
import { complainantRespondentValidationSchema } from "../pages/Consumer/FileNewCase/components/StepperComponents/ComplainantRespondent";
import { documentUploadSchema } from "../pages/Consumer/FileNewCase/components/StepperComponents/DocumentUpload";
import { finalSubmitValidationSchema } from "../pages/Consumer/FileNewCase/components/StepperComponents/FinalSubmit";

export interface IUseFileNewCaseFormikProps {
    onCaseDetailsSubmit: (data: CaseDetailFormViewModel, helper: FormikHelpers<CaseDetailFormViewModel>) => Promise<void>,
    onComplainantRespondentSubmit: (data: ComplainantRespondentViewModel, helper: FormikHelpers<ComplainantRespondentViewModel>) => Promise<void>,
    onAdditionalComplainantSubmit: (data: AdditionalComplainantViewModel, helper: FormikHelpers<AdditionalComplainantViewModel>) => Promise<void>,
    onAdditionalRespondentSubmit: (data: AdditionalRespondentViewModel, helper: FormikHelpers<AdditionalRespondentViewModel>) => Promise<void>,
    onDocumentUploadSubmit: (data: DocumentUploadModel, helper: FormikHelpers<DocumentUploadModel>) => Promise<void>,
    onFinalSubmit: (data: FinalSubmitViewModel, helper: FormikHelpers<FinalSubmitViewModel>) => Promise<void>,
    onCCPADetailsSubmit: (data: ICCPACaseDetailViewModel, helper: FormikHelpers<ICCPACaseDetailViewModel>) => Promise<void> 
}

export const useFileNewCaseFormik = ({
    onCaseDetailsSubmit,
    onComplainantRespondentSubmit,
    onAdditionalComplainantSubmit,
    onAdditionalRespondentSubmit,
    onDocumentUploadSubmit,
    onFinalSubmit,
    onCCPADetailsSubmit
}: IUseFileNewCaseFormikProps) => { 
    const caseDetailsFormik = useFormik({
        initialValues: CaseDetailFormViewModel.init(),
        validationSchema: caseDetailFormValidationSchema,
        onSubmit: onCaseDetailsSubmit
    });

    const complainantRespondentFormik = useFormik({
        initialValues: ComplainantRespondentViewModel.init(),
        validationSchema: complainantRespondentValidationSchema,
        onSubmit: onComplainantRespondentSubmit
    });

    const additionalComplainantFormik = useFormik({
        initialValues: AdditionalComplainantViewModel.init(),
        validationSchema: additionalComplainantValidationSchema,
        onSubmit: onAdditionalComplainantSubmit
    });

    const additionalRespondentFormik = useFormik({
        initialValues: AdditionalRespondentViewModel.init(),
        validationSchema: additionalRespondentValidationSchema,
        onSubmit: onAdditionalRespondentSubmit
    });

    const documentUploadFormik = useFormik({
        initialValues: DocumentUploadModel.init(),
        validationSchema: documentUploadSchema,
        onSubmit: onDocumentUploadSubmit
    });

    const finalSubmitFormik = useFormik({
        initialValues: FinalSubmitViewModel.init(),
        validationSchema: finalSubmitValidationSchema,
        onSubmit: onFinalSubmit
    });

    const ccpaCaseDetailsFormik = useFormik({
        initialValues: ICCPACaseDetailViewModel.init(),
        validationSchema: CCPACaseDetailFormValidationSchema,
        onSubmit: onCCPADetailsSubmit
    });

    return {
        caseDetailsFormik,
        complainantRespondentFormik,
        additionalComplainantFormik,
        additionalRespondentFormik,
        documentUploadFormik,
        finalSubmitFormik,
        ccpaCaseDetailsFormik
    }
}