import { GridColDef } from "@mui/x-data-grid";
import { BasicBreadcrumbs, DataTable } from "../../../components";
import { BreadcrumbsProps } from "../../../components/controls/BasicBreadcrumbs";



interface ICaseListProps {
    caseListRows: any[],
    caseListColumns: GridColDef[],
    breadCrumbs: BreadcrumbsProps[],
    sortFieldName?: string
}

export const CaseList = ({ caseListRows, caseListColumns, breadCrumbs, sortFieldName }: ICaseListProps) => {
    return (
        <section className="section margin-top-35">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <BasicBreadcrumbs items={breadCrumbs} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 margin-top-25 margin-bottom-25">
                        <DataTable
                            rows={caseListRows}
                            columns={caseListColumns}
                            sortFieldName={sortFieldName}


                        />
                    </div>
                </div>
            </div>
        </section>
    )
};