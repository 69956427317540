import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BasicBreadcrumbs, BasicButton, ConfonetAutocomplete, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, PreviewCard } from "../../../../components";
import { dropdownValidationSchema, flattenArrayToIPreview, makeDropdownOptions } from "../../../../utils/Helper";
import { COMMISSION_DASHBOARD, COMMISSION_SEARCH_CASE_DETAILS, COMMISSION_SEARCH_CASE_DETAILS_FOR_COURTROOM } from "../../../../navigation/CONSTANTS";
import { MasterService } from "../../../../services/MasterService/MasterService";
import { useEffect, useState } from "react";
import { IDropdown } from "../../../../models/common/IDropdown";
import { RootState } from "../../../../redux";
import { useSelector } from "react-redux";
import { IUpdateBenchOrCourtroomPayload, UpdateBenchModel } from "../../../../models/cases/commissionCase/CourtroomHearingFormViewModel";
import { CourtroomService } from "../../../../services/CaseService/CourtroomService/CourtroomService";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { TextField } from "@mui/material";
import { DateTime } from "luxon";


/** Renders judgement upload component */
const UpdateBenchOrCourtroomView = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [courtNumberListOption, setCourtNumberListOption] = useState<IDropdown[]>([]);

    const { caseNumber, complainantName, respondentName, caseFilingDate, courtRoom, caseStageName, isPath } = location.state.response;

    // define the validation schema
    const validationSchema = Yup.object({
        courtNumber: dropdownValidationSchema("Court number is required")
            .required(),
    });

    // Get All Court Number API Calling
    const handleGetCourtNumber = async () => {
        const courtNumberResponse = await MasterService.getCourtNumberCommissionWise(userDetailsData?.commissionId);
        const courtNumberToDisplay = courtNumberResponse.filter((value) => value.courtId !== courtRoom);
        if (courtNumberResponse) {
            setCourtNumberListOption(makeDropdownOptions(courtNumberToDisplay, "courtNameEn", "courtId"));
        }
    };

    // triggers when the form is submitted
    const handleSubmit = async () => {
        const data: IUpdateBenchOrCourtroomPayload = {
            caseNumber: caseNumber,
            courtId: formik.values.courtNumber.value,
            commissionId: userDetailsData?.commissionId
        }
        const updateBenchResponse = await CourtroomService.updateBenchOrCourtroom(data);
        if (updateBenchResponse) {
            toast.success("Bench or courtroom updated successfully.");
            navigate(COMMISSION_SEARCH_CASE_DETAILS);
        }
    };

    // define the formik form for validation and form submission
    const formik = useFormik({
        initialValues: UpdateBenchModel.init(),
        validationSchema,
        onSubmit: handleSubmit
    })

    useEffect(() => {
        handleGetCourtNumber();
    }, [])

    return (
        <section className="section margin-top-35">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <BasicBreadcrumbs
                            items={[
                                {
                                    to: COMMISSION_DASHBOARD,
                                    label: t("Breadcrumb.Dashboard"),
                                },
                                {
                                    to: COMMISSION_SEARCH_CASE_DETAILS,
                                    label: t("Breadcrumb.SearchCaseDetails"),
                                },
                                {
                                    to: '',
                                    label: t("Label.UpdateBenchOrCourtroom"),
                                },
                            ]}
                        />
                    </div>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className="row margin-top-25 margin-bottom-5">
                        <div className="col-lg-12 margin-bottom-25">
                            <PreviewCard
                                heading={t("Label.CaseDetails")}
                                items={flattenArrayToIPreview({
                                    caseNumber: caseNumber,
                                    complainantName: complainantName,
                                    respondentName: respondentName,
                                    filingDate: caseFilingDate && DateTime.fromFormat(caseFilingDate, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                                    benchNumber: courtRoom,
                                    caseStage: caseStageName
                                })}
                            />
                        </div>
                    </div>

                    <div className="row margin-bottom-5">
                        <div className="col-lg-12">
                            <ConfonetCard>
                                <ConfonetCardHeader>
                                    <div className="card-title card-primary-header-title float-start">{t("Label.UpdateBenchOrCourtroom")}</div>
                                </ConfonetCardHeader>

                                <ConfonetCardBody>
                                    <div className="col-lg-4">
                                        <ConfonetAutocomplete
                                            id="courtNumber"
                                            value={formik.values.courtNumber}
                                            options={courtNumberListOption}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("Label.CourtNumber") + "*"}
                                                    error={
                                                        formik.touched.courtNumber &&
                                                        Boolean(formik.errors.courtNumber)
                                                    }
                                                    helperText={
                                                        formik.touched.courtNumber &&
                                                        formik.errors.courtNumber?.label
                                                    }
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(_, value: IDropdown | null) => {
                                                formik.setFieldValue(
                                                    "courtNumber",
                                                    value ?? { label: "", value: "" }
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </ConfonetCardBody>
                            </ConfonetCard>
                        </div>
                    </div>

                    <div className="margin-top-25 text-end margin-bottom-25">
                        <BasicButton
                            className="btn-custom btn-custom-primary"
                            type="button"
                            onClick={handleSubmit}
                            disabled={formik.values.courtNumber.value === ""}
                            variant="contained"
                            text={t("BtnText.Submit")}
                            endIcon={<IoArrowForwardCircleOutline />}
                        />
                    </div>
                </form>
            </div>
        </section>
    )
}

export default UpdateBenchOrCourtroomView;