import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import DailyOrderFinalizedOrDefinalized from "../../pages/Commissions/CaseFinalizeOrDefinalize/component/DailyOrder/DailyOrderFinalizedOrDefinalize";
import JudgementFinalizedOrDefinalized from "../../pages/Commissions/CaseFinalizeOrDefinalize/component/Judgement/JudgementFinalizedOrDefinalize";
import UpdateBenchOrCourtroomView from "../../pages/Commissions/UpdateCourtroomCase/component/UpdateBenchOrCourtroom";
import { CaseEditCommission } from "../../pages/Commissions/CaseEditCommission/CaseEditCommission";
import Preview from "../../pages/Consumer/FileNewCase/components/Preview";
import UpdateCourtroomCaseView from "../../pages/Commissions/UpdateCourtroomCase/component/UpdateCourtroomCaseView";
import RevertAfterApprovalView from "../../pages/Commissions/RevertAfterApproval/component/RevertAfterApprovalView";
import {
    ADD_ADVOCATE,
    ADD_RESPONDENT_ADVOCATE_LIST,
    ADVOCATE_LIST, COMMISSION,
    COMMISSION_ADD_REMOVE_BUNCH_CASES,
    COMMISSION_CASE_PREVIEW,
    COMMISSION_CAUSELIST,
    COMMISSION_CONSOLIDATED_FILES,
    COMMISSION_COURTROOM_HEARING,
    COMMISSION_COURTROOM_HEARING_DETAILS,
    COMMISSION_COURTROOM_MODIFICATION,
    COMMISSION_DAILY_ORDER_ENTRY,
    COMMISSION_DAILY_ORDER_VIEW,
    COMMISSION_DASHBOARD,
    COMMISSION_DISPLAYBOARD,
    COMMISSION_EDIT_NOTICE,
    COMMISSION_EDITPROFILE,
    COMMISSION_GENERATE_NOTICE,
    COMMISSION_ISSUE_PRINT_NOTICE,
    COMMISSION_JUDGMENT_PROCEEDING,
    COMMISSION_JUDGMENT_UPLOAD,
    COMMISSION_PENDING_FOR_APPEAL_TYPE_SCRUTINY_STEPPERFORM,
    COMMISSION_PENDING_FOR_APPROVAL,
    COMMISSION_PENDING_FOR_APPROVAL_VIEW,
    COMMISSION_PENDING_FOR_INTERLOCUTORY_TYPE_SCRUTINY_STEPPERFORM,
    COMMISSION_PENDING_FOR_MISCELLANEOUS_TYPE_SCRUTINY_STEPPERFORM,
    COMMISSION_PENDING_FOR_SCRUTINY,
    COMMISSION_PENDING_FOR_SCRUTINY_STEPPERFORM,
    COMMISSION_PENDING_FOR_TRANSFER_APPLICATION_TYPE_SCRUTINY_STEPPERFORM,
    COMMISSION_PENDING_PAYMENT_CASES, COMMISSION_REJOINDER,
    COMMISSION_REJOINDERDOCUMENTS,
    COMMISSION_REPLY,
    COMMISSION_REPLYDOCUMENTS,
    COMMISSION_REVERTEDINSCRUTINY_CASES,
    COMMISSION_SUBSEQUENT_FILING,
    COMMISSION_SUBSEQUENTDOCUMENTS,
    COMMISSION_UPLOAD_TYPE_JUDGMENT,
    COMMISSION_UPLOAD_TYPE_ORDER,
    COMMISSION_UPLOADREJOINDERDOCUMENTS,
    COMMISSION_UPLOADREPLYDOCUMENTS,
    COMMISSION_UPLOADSUBSEQUENTDOCUMENTS,
    FIND_CASEDETAILS,
    PAYMENT_RECONCILIATION,
    COMMISSION_SETTING_FILES,
    COMMISSION_SETTING_USER,
    COMMISSION_SETTING_ROLES,
    COMMISSION_SETTING_MODULES,
    COMMISSION_SETTING_USER_ROLE_ALLOCATION,
    COMMISSION_CASE_FINALIZE,
    COMMISSION_DAILY_ORDER_FINALIZE,
    COMMISSION_JUDGEMENT_FINALIZE,
    COMMISSION_CASE_DEFINALIZE,
    COMMISSION_DAILY_ORDER_DEFINALIZE,
    COMMISSION_JUDGEMENT_DEFINALIZE,
    COMMISSION_UPDATE_COURTROOM_BENCH,
    COMMISSION_SEARCH_CASE_DETAILS,
    COMMISSION_CASE_EDIT,
    COMMISSION_EDIT_CASE_STEPPERFORM,
    COMMISSION_SEARCH_CASE_DETAILS_FOR_COURTROOM,
    COMMISSION_UPDATE_COURTROOM_DETAILS,
    COMMISSION_SEARCH_REVERT_CASE_DETAILS,
    COMMISSION_REVERT_CASE_DETAILS,
    COMMISSION_REPORTS,
    COMMISSION_LIMITATION_REPORT,
    REGISTRY_REPORTS
} from "../CONSTANTS";
import ReportView from "../../pages/MainWebPages/Report/ReportView";
import { CaseLimitationReport } from "../../pages/Commissions/LimitationReport/CommissionLimitationReport";
import { ImportantNotesContainer } from "../../pages/Judge/ImportantNotes/ImportantNotesContainer";

// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const CommissionDashboard = lazy(() => import('../../pages/dashboard/components/CommissionDashboard/CommissionDashboard'));
const RevertedInScrutinyView = lazy(() => import('../../pages/Commissions/Reverted/Reverted'));
const PaymentPendingView = lazy(() => import('../../pages/Commissions/PaymentPending/PaymentPendingView'));
const CasePreview = lazy(() => import('../../pages/dashboard/components/CasePreview').then((module) => ({ default: module.CasePreview })));
const ScrutinyContainer = lazy(() => import('../../pages/Commissions/Scrutiny'));
const ScrutinyView = lazy(() => import('../../pages/Commissions/Scrutiny/ScrutinyView'));
const CaseScrutinyStepperForm = lazy(() => import('../../pages/Commissions/Scrutiny/components/CaseScrutinyStepperForm'));
const AppealTypeCaseScrutinyStepperForm = lazy(() => import('../../pages/Commissions/Scrutiny/components/AppealTypeCaseScrutinyStepperForm'));
const InterlocutoryTypeCaseScruitnyStepperForm = lazy(() => import('../../pages/Commissions/Scrutiny/components/InterlocutoryTypeCaseScruitnyStepperForm'));
const PendingForApprovalContainer = lazy(() => import('../../pages/Commissions/Approval'));
const PendingForApprovalView = lazy(() => import('../../pages/Commissions/Approval/ApprovalView'));
const ApproveOrRejectCase = lazy(() => import('../../pages/Commissions/Approval/ApproveCase'));
const PaymentReconciliationContainer = lazy(() => import('../../pages/Commissions/PaymentReconciliation/PaymentReconciliationContainer'));
const CourtroomHearingList = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomHearingList'));
const CourtroomModification = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomModification'));
const CourtroomHearingView = lazy(() => import('../../pages/Commissions/CourtroomHearing/component/CourtroomHearingView'));
const FindCaseDeailsContainer = lazy(() => import('../../pages/Commissions/FindCaseDetails/FindCaseDeailsContainer'));
const GenerateAndEditNoticeView = lazy(() => import('../../pages/Commissions/IssuePrintNotice/component/GenerateNotice/GenerateNoticeView'));
const JudgementSearchContainer = lazy(() => import('../../pages/Commissions/JudgementSearch'));
const DailyOrderEntry = lazy(() => import('../../pages/Commissions/DailyOrderEntry'));
const UploadTypeOrder = lazy(() => import('../../pages/Commissions/DailyOrderEntry/component/UploadTypeOrder'));
const JudgmentUpload = lazy(() => import('../../pages/Commissions/JudgmentUpload'));
const UploadTypeJudgement = lazy(() => import('../../pages/Commissions/JudgmentUpload/component/UploadTypeJudgement'));
const IssuePrintNotice = lazy(() => import('../../pages/Commissions/IssuePrintNotice'));
const JudgementProceeding = lazy(() => import('../../pages/Commissions/JudgementSearch'));
const AddRemoveBunchCases = lazy(() => import('../../pages/Commissions/AddRemoveBunchCases'));
const AddAdvocate = lazy(() => import('../../pages/Commissions/AddAdvocate'));
const AdvocateList = lazy(() => import('../../pages/Commissions/AdvocateList'));
const AddRespondentAdvocate = lazy(() => import('../../pages/Commissions/AddRespondentAdvocate'));
const Reply = lazy(() => import('../../pages/Consumer/Reply'));
const UploadReplyDocuments = lazy(() => import('../../pages/Consumer/Reply/component/UploadReplyDocuments'));
const ReplyDocuments = lazy(() => import('../../pages/Consumer/Reply/component/ReplyDocuments'));
const SubsequentFiling = lazy(() => import('../../pages/Consumer/SubsequentFiling'));
const UploadSubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/UploadSubsequentDocuments'));
const SubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/SubsequentDocuments'));
const CauseList = lazy(() => import('../../pages/Commissions/CauseList'));
const AddDisplayBoardContainer = lazy(() => import('../../pages/Commissions/DisplayBoard'));
const ConsolidatedFilesContainer = lazy(() => import('../../pages/Commissions/ConsolidatedFiles'));
const AdminUserContainer = lazy(() => import('../../pages/Commissions/CreateNewUserDetails/AdminUserContainer'));
const ModalContainer = lazy(() => import('../../pages/Commissions/Modal/modalContainer'));
const RoleTable = lazy(() => import('../../pages/Commissions/CreateNewUserDetails/RoleTable'));
const CaseFinalizeOrDefinalizeContainer = lazy(() => import('../../pages/Commissions/CaseFinalizeOrDefinalize'));
const SearchCourtroomCaseContainer = lazy(() => import('../../pages/Commissions/UpdateCourtroomCase'));
const SearchCaseForRevertContainer = lazy(() => import('../../pages/Commissions/RevertAfterApproval'));

const commissionChildrenRoute: IRoute[] = [
    {
        path: COMMISSION_EDITPROFILE,
        element: <EditProfile />
    },
    {
        path: COMMISSION,
        element: <Dashboard />,
        children: [
            {
                path: COMMISSION_DASHBOARD,
                element: <CommissionDashboard />
            }
        ]
    },
    {
        path: COMMISSION_REVERTEDINSCRUTINY_CASES,
        element: <RevertedInScrutinyView />
    },
    {
        path: COMMISSION_PENDING_PAYMENT_CASES,
        element: <PaymentPendingView />
    },
    {
        path: COMMISSION_CASE_PREVIEW,
        element: <CasePreview />
    },
    {
        path: COMMISSION_PENDING_FOR_SCRUTINY,
        element: <ScrutinyContainer />,
        children: [
            {
                path: COMMISSION_PENDING_FOR_SCRUTINY,
                element: <ScrutinyView />
            },
            {
                path: COMMISSION_PENDING_FOR_SCRUTINY_STEPPERFORM,
                element: <CaseScrutinyStepperForm />
            },
            {
                path: COMMISSION_PENDING_FOR_APPEAL_TYPE_SCRUTINY_STEPPERFORM,
                element: < AppealTypeCaseScrutinyStepperForm />
            },
            {
                path: COMMISSION_PENDING_FOR_INTERLOCUTORY_TYPE_SCRUTINY_STEPPERFORM,
                element: <InterlocutoryTypeCaseScruitnyStepperForm />
            },
            {
                path: COMMISSION_PENDING_FOR_MISCELLANEOUS_TYPE_SCRUTINY_STEPPERFORM,
                element: <InterlocutoryTypeCaseScruitnyStepperForm />
            },
            {
                path: COMMISSION_PENDING_FOR_TRANSFER_APPLICATION_TYPE_SCRUTINY_STEPPERFORM,
                element: <InterlocutoryTypeCaseScruitnyStepperForm />
            }
        ]
    },
    {
        path: COMMISSION_PENDING_FOR_APPROVAL,
        element: <PendingForApprovalContainer />,
        children: [
            {
                path: COMMISSION_PENDING_FOR_APPROVAL,
                element: <PendingForApprovalView />
            },
            {
                path: COMMISSION_PENDING_FOR_APPROVAL_VIEW,
                element: <ApproveOrRejectCase />
            }
        ]
    },
    {
        path: PAYMENT_RECONCILIATION,
        element: <PaymentReconciliationContainer />
    },
    {
        path: COMMISSION_COURTROOM_HEARING,
        element: <CourtroomHearingList />
    },
    {
        path: COMMISSION_LIMITATION_REPORT,
        element: <CaseLimitationReport />
    },
    {
        path: COMMISSION_CASE_EDIT,
        element: <CaseEditCommission />,
        // children: [

        //     {
        //         path: COMMISSION_EDIT_CASE_STEPPERFORM,
        //         element: <CaseScrutinyStepperForm />
        //     },]
    },
    {
        path: COMMISSION_EDIT_CASE_STEPPERFORM,
        element: < Preview />
    },
    {
        path: COMMISSION_COURTROOM_MODIFICATION,
        element: <CourtroomModification />
    },
    {
        path: COMMISSION_SEARCH_CASE_DETAILS,
        element: <SearchCourtroomCaseContainer />
    },
    {
        path: COMMISSION_SEARCH_CASE_DETAILS_FOR_COURTROOM,
        element: <SearchCourtroomCaseContainer />
    },
    {
        path: COMMISSION_UPDATE_COURTROOM_BENCH,
        element: <UpdateBenchOrCourtroomView />
    },
    {
        path: COMMISSION_UPDATE_COURTROOM_DETAILS,
        element: <UpdateCourtroomCaseView />
    },
    {
        path: COMMISSION_COURTROOM_HEARING_DETAILS,
        element: <CourtroomHearingView />
    },
    {
        path: COMMISSION_DAILY_ORDER_ENTRY,
        element: <DailyOrderEntry />
    },
    {
        path: COMMISSION_DAILY_ORDER_VIEW,
        element: <DailyOrderEntry />
    },
    {
        path: COMMISSION_UPLOAD_TYPE_ORDER,
        element: <UploadTypeOrder />
    },
    {
        path: COMMISSION_JUDGMENT_UPLOAD,
        element: <JudgmentUpload />
    },
    {
        path: COMMISSION_UPLOAD_TYPE_JUDGMENT,
        element: <UploadTypeJudgement />
    },
    {
        path: COMMISSION_ISSUE_PRINT_NOTICE,
        element: <IssuePrintNotice />
    },
    {
        path: FIND_CASEDETAILS,
        element: <FindCaseDeailsContainer />
    },
    {
        path: COMMISSION_GENERATE_NOTICE,
        element: <GenerateAndEditNoticeView />
    },
    {
        path: COMMISSION_EDIT_NOTICE,
        element: <GenerateAndEditNoticeView />
    },
    {
        path: COMMISSION_JUDGMENT_PROCEEDING,
        element: <JudgementSearchContainer />
    },
    {
        path: COMMISSION_JUDGMENT_PROCEEDING,
        element: <JudgementProceeding />
    },
    {
        path: COMMISSION_ADD_REMOVE_BUNCH_CASES,
        element: <AddRemoveBunchCases />
    },
    {
        path: ADD_ADVOCATE,
        element: <AddAdvocate />
    },
    {
        path: ADVOCATE_LIST,
        element: < AdvocateList />
    },
    {
        path: ADD_RESPONDENT_ADVOCATE_LIST,
        element: < AddRespondentAdvocate />
    },
    {
        path: COMMISSION_SUBSEQUENT_FILING,
        element: <SubsequentFiling />
    },
    {
        path: COMMISSION_UPLOADSUBSEQUENTDOCUMENTS,
        element: <UploadSubsequentDocuments />
    },
    {
        path: COMMISSION_SUBSEQUENTDOCUMENTS,
        element: <SubsequentDocuments />
    },
    {
        path: COMMISSION_REPLY,
        element: <Reply />
    },
    {
        path: COMMISSION_UPLOADREPLYDOCUMENTS,
        element: <UploadReplyDocuments />
    },
    {
        path: COMMISSION_REPLYDOCUMENTS,
        element: <ReplyDocuments />
    },
    {
        path: COMMISSION_CAUSELIST,
        element: <CauseList />
    },
    {
        path: COMMISSION_REJOINDER,
        element: <Reply />
    },
    {
        path: COMMISSION_UPLOADREJOINDERDOCUMENTS,
        element: <UploadReplyDocuments />
    },
    {
        path: COMMISSION_REJOINDERDOCUMENTS,
        element: <ReplyDocuments />
    },
    {
        path: COMMISSION_DISPLAYBOARD,
        element: <AddDisplayBoardContainer />
    },
    {
        path: COMMISSION_CASE_FINALIZE,
        element: <CaseFinalizeOrDefinalizeContainer />
    },
    {
        path: COMMISSION_CASE_DEFINALIZE,
        element: <CaseFinalizeOrDefinalizeContainer />
    },
    {
        path: COMMISSION_DAILY_ORDER_FINALIZE,
        element: <DailyOrderFinalizedOrDefinalized />
    },
    {
        path: COMMISSION_DAILY_ORDER_DEFINALIZE,
        element: <DailyOrderFinalizedOrDefinalized />
    },
    {
        path: COMMISSION_JUDGEMENT_FINALIZE,
        element: <JudgementFinalizedOrDefinalized />
    },
    {
        path: COMMISSION_JUDGEMENT_DEFINALIZE,
        element: <JudgementFinalizedOrDefinalized />
    },
    {
        path: COMMISSION_CONSOLIDATED_FILES,
        element: <ConsolidatedFilesContainer />
    },
    {
        path: REGISTRY_REPORTS,
        element: <ImportantNotesContainer />
    },
    {
        path: COMMISSION_SEARCH_REVERT_CASE_DETAILS,
        element: <SearchCaseForRevertContainer />
    },
    {
        path: COMMISSION_REVERT_CASE_DETAILS,
        element: <RevertAfterApprovalView />
    },
    {
        path: COMMISSION_SETTING_FILES,
        element: null
    },
    {
        path: COMMISSION_SETTING_USER,
        element: <AdminUserContainer />
    },
    {
        path: COMMISSION_SETTING_ROLES,
        element: <RoleTable />
    },
    {
        path: COMMISSION_SETTING_MODULES,
        element: < ModalContainer />
    },
    {
        path: COMMISSION_SETTING_USER_ROLE_ALLOCATION,
        element: <AdminUserContainer />
    },
    {
        path: COMMISSION_REPORTS,
        element: <ReportView />
    }
];

// list of commission Routes
export const commissionRoutes: IRoute[] = [
    {
        path: '',
        element: <PrivateRoute
            requiredRoles={[ROLE.NcdrcAdmin, ROLE.ScdrcAdmin, ROLE.DcdrcAdmin, ROLE.President]}
        />,
        children: commissionChildrenRoute
    }
];