import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { IGetPreDetailsResponse } from "../../../../models/cases/appealTypeCase/PreDetailsModel";
import { ICCPACaseDetailsModel, ICCPACaseDetailsResponseModel, IGetCCPACaseDetailsResponse } from "../../../../models/cases/ccpaTypeCase/ICCPACaseDetailsModel";
import { CaseDetailModel } from "../../../../models/cases/fileNewCase/CaseDetailFormModel";
import { CaseFilingComplainantRespondentResponse, CaseFillingComplainantRespondantDetailNew, ComplainantRespondentModel } from "../../../../models/cases/fileNewCase/ComplainantResondentModel";
import { FinalSubmitModel, ICommissionLocationModel } from "../../../../models/cases/fileNewCase/FinalSubmitModel";
import {
  GetLocationResponse,
  ICaseDetailsResponse,
  IDocumentDetail,
  ISaveCaseFillingDocumentResponse
} from "../../../../models/cases/fileNewCase/IFileNewCaseModel";
import { CASE_FILING_SERVICE } from "../../../CONSTANTS";
import { deleteCall, postCall, postCallFile, putCall } from "../../../api";

export class FileNewCaseServices {
  /**
   * Call an API to post case details
   * @param data CaseDetailModel
   * @returns Promise<ICaseDetailsResponse>
   */
  public static readonly addCaseDetails = async (data: CaseDetailModel): Promise<ICaseDetailsResponse> => {
    const response: AxiosResponse<ICaseDetailsResponse> = await postCall(
      `${CASE_FILING_SERVICE}/saveCase`,
      data
    );
    return response?.data;
  };

  /**
  * Call an API to put (update) case details
  * @param data CaseDetailModel
  * @returns Promise<ICaseDetailsResponse>
  */
  public static readonly updateCaseDetails = async (data: CaseDetailModel): Promise<ICaseDetailsResponse> => {
    const response: AxiosResponse<ICaseDetailsResponse> = await putCall(
      `${CASE_FILING_SERVICE}/updateCaseFile`,
      data
    );
    return response?.data;
  };

  /**
   * Call an API to post complainant/respondent details
   * @param data ComplainantRespondentModel
   * @returns Promise<ComplainantRespondentModel>
   */
  public static readonly addUpdateComplainantRespondentDetails = async (
    data: ComplainantRespondentModel
  ): Promise<ComplainantRespondentModel> => {
    const response: AxiosResponse<ComplainantRespondentModel> = await postCall(
      `${CASE_FILING_SERVICE}/saveComplainantDetails`,
      data
    );
    return response?.data;
  };


  /**
   * Call an API to post complainant/respondent details
   * @param data ComplainantRespondentModel
   * @returns Promise<ComplainantRespondentModel>
   */
  public static readonly addUpdateComplainantRespondentDetailsV2 = async (
    data: CaseFillingComplainantRespondantDetailNew[]
  ): Promise<CaseFilingComplainantRespondentResponse[]> => {
    const response: AxiosResponse<CaseFilingComplainantRespondentResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/saveComplainantDetails`,
      data
    );
    return response?.data;
  };

  /**
  * Call an API to delete advovcate  complainant respondant
  * @param partyId
  */
  public static readonly DeleteAdvocateByDetailsId = async (advocateId: number) => {
    const response: AxiosResponse<any> = await deleteCall(
      `${CASE_FILING_SERVICE}/deleteComplainantAdvocate?advocateDetailsId=${advocateId}`,
      {}
    );

    return response?.status
  }



  /**
   * Call an API to delete additional complainant respondant
   * @param partyId
   */
  public static readonly deleteComplainantRespondantDetailsV2 = async (partyId: number) => {
    const response: AxiosResponse<any> = await deleteCall(
      `${CASE_FILING_SERVICE}/deleteComplainantDetails?partyId=${partyId}`,
      {}
    );

    return response
  }


  /** Call an API to post case filling documents
   *
   * @returns Promise<IDocumentDetail[]>
   */
  public static readonly postUploadCaseFillingDocument = async (
    data: File
  ): Promise<IDocumentDetail[]> => {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append(`file`, data);
      // Add the file to the FormData object

      // Make the POST request with the FormData as the data
      const response: AxiosResponse<IDocumentDetail[]> = await postCallFile(
        `${CASE_FILING_SERVICE}/uploadCaseFillingDocument`,
        formData
      );
      // toast(response.message as string);
      return response?.data;
    } catch (error) {
      toast.error(error as string);

      // Handle errors here
      console.error("Error uploading document:", error);
      throw error;
    }
  };

  /**
   * Call an API to get casefilling document details
   *
   * @param fillingReferenceNumber - The reference number of the case filling
   * @returns Promise<IDocumentDetail[]>
   * @throws
   * */
  public static readonly saveCaseFillingDocumentDetails = async (
    payload: any
  ) => {
    try {
      const response: AxiosResponse<ISaveCaseFillingDocumentResponse[]> =
        await postCallFile(
          `${CASE_FILING_SERVICE}/saveCaseFillingDocumentDetails`,
          payload
        );
      if (response?.data) {
        return response?.data;
      }
    } catch (error) {
      toast.error("Error saving case filling document details:");
      throw error;
    }
  };

  /**
   * Call an API to get commission location
   */
  public static readonly getCommissionLocation = async (data: ICommissionLocationModel) => {
    const { filingReferenceNumber } = data;
    try {
      const response: AxiosResponse<GetLocationResponse> =
        await postCall(
          `${CASE_FILING_SERVICE}/getLocations`,
          {
            fillingReferenceNumber: filingReferenceNumber
          }
        );

      return response?.data;
    } catch (error) {
      toast.error("Something went wrong");
      throw error;
    }
  };

  /**
   * Call an API to save the case 
   * @param data 
   * @returns data
   */
  public static readonly saveFinalSubmit = async (data: FinalSubmitModel) => {
    try {
      const response: AxiosResponse<boolean> = await putCall(
        `${CASE_FILING_SERVICE}/finalSubmission`,
        data
      )
      return response?.data;
    } catch (error) {
      toast.error("Error saving case filling document details.");
      throw error;
    }
  };

  /**
   * Call an API to post ccpa case details
   * @param data CaseDetailModel
   * @returns Promise<ICaseDetailsResponse>
   */
  public static readonly addCCPACaseDetails = async (data: ICCPACaseDetailsModel): Promise<ICCPACaseDetailsResponseModel> => {
    const response: AxiosResponse<ICCPACaseDetailsResponseModel> = await postCall(
      `${CASE_FILING_SERVICE}/saveCaseForCcpaAppeal`,
      data
    );
    return response?.data;
  };

  /**
   * Call an API to post ccpa case details
   * @param data CaseDetailModel
   * @returns Promise<ICaseDetailsResponse>
   */
  public static readonly getCCPACaseDetails = async (fillingReferenceNumber: number): Promise<IGetCCPACaseDetailsResponse> => {
    const response: AxiosResponse<IGetCCPACaseDetailsResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getCaseForCcpaAppeal`,
      { fillingReferenceNumber }
    );
    return response?.data;
  };

  /**
 * Call an API to post acknowledgement details
 * @param fillingReferenceNumber number
 * @returns Promise<IGetPreDetailsResponse>
 */
  public static readonly getAcknowledgement = async (fillingReferenceNumber: number): Promise<IGetPreDetailsResponse> => {
    const response: AxiosResponse<IGetPreDetailsResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getAcknowledgement`,
      { caseNumberOrfilingReferenceNumber: fillingReferenceNumber }
    );
    return response?.data;
  };

}
