import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { ICaseCount, IDashboardCaseCount } from '../../models/cases/consumerCases/IConsumerCaseModel';
import { RootState, useAppDispatch } from '../../redux';
import { addCaseCounts, addMainCaseCounts } from '../../redux/CaseReducer/CaseReducer';
import { CaseServices } from '../../services/CaseService/CaseServices';
import { ROLE } from '../../utils/Enum';
import { MasterService } from '../../services/MasterService/MasterService';
import { makeDropdownOptions } from '../../utils/Helper';
import { addAddressType, addCaseCategory, addCaseTypeList, addHandiCapType, addMasterCountry, addMasterState } from '../../redux/MasterReducer/MasterReducer';
import { LandingPageBody } from '../../LandingPage';

export const DashboardContainer = () => {
	const dispatch = useAppDispatch();
	const { userDetailsData } = useSelector((state: RootState) => state.user);
	const { state, country, allCaseCategory, handiCapType, addressType, caseTypeList } = useSelector((state: RootState) => state.master);
	const [firstCallDone, setFirstCallDone] = useState<boolean>(false);

	const handleGetCaseCount = useCallback(async () => {
		if (userDetailsData.userId) {
			let dashboardCaseCount: IDashboardCaseCount[] = [];
			let dashboardCaseCountCommission: IDashboardCaseCount[] = [];
			let caseCounts: ICaseCount, mainCaseCounts = {
				draft: 0,
				pendingForScrutiny: 0,
				revertedInScrutiny: 0,
				paymentPending: 0,
				pendingForApproval: 0,
				filed: 0,
				disposed: 0,
				pending: 0,
				against_filed: 0,
				against_disposed: 0,
				against_pending: 0
			};

			if (userDetailsData?.roleId === ROLE.Consumer || userDetailsData?.roleId === ROLE.Advocate) {
				dashboardCaseCount = await CaseServices.getCaseCounts(userDetailsData.userId, 0);

			} else if (userDetailsData?.roleId === ROLE.NcdrcAdmin || userDetailsData?.roleId === ROLE.ScdrcAdmin || userDetailsData?.roleId === ROLE.DcdrcAdmin) {
				dashboardCaseCount = await CaseServices.getCaseCounts(userDetailsData?.commissionId as number, 1);
				dashboardCaseCountCommission = dashboardCaseCount?.flatMap(item => item);
			}
			else if (userDetailsData?.roleId === ROLE.President) {
				dashboardCaseCount = await CaseServices.getCaseCounts(userDetailsData.userId, 0);
			}
			if (userDetailsData?.roleId === ROLE.NcdrcAdmin || userDetailsData?.roleId === ROLE.ScdrcAdmin || userDetailsData?.roleId === ROLE.DcdrcAdmin) {

				caseCounts = {
					draft: dashboardCaseCountCommission[0]?.draft,
					pendingForScrutiny: dashboardCaseCountCommission[0]?.pendingforscrutiny,
					revertedInScrutiny: dashboardCaseCountCommission[0]?.revertedcases,
					paymentPending: dashboardCaseCountCommission[0]?.verifiedandpendingforpayment,
					pendingForApproval: dashboardCaseCountCommission[0]?.pendingforapproval,
					filed: dashboardCaseCountCommission[0]?.filed,
					disposed: dashboardCaseCountCommission[0]?.disposed,
					pending: dashboardCaseCountCommission[0]?.filed - dashboardCaseCountCommission[0]?.disposed,
					against_filed: dashboardCaseCountCommission[0]?.against_filed,
					against_disposed: dashboardCaseCountCommission[0]?.against_disposed,
					against_pending: dashboardCaseCountCommission[0]?.against_filed - dashboardCaseCountCommission[0]?.against_disposed
				}
				mainCaseCounts = {
					draft: dashboardCaseCountCommission[1]?.draft,
					pendingForScrutiny: dashboardCaseCountCommission[1]?.pendingforscrutiny,
					revertedInScrutiny: dashboardCaseCountCommission[1]?.revertedcases,
					paymentPending: dashboardCaseCountCommission[1]?.verifiedandpendingforpayment,
					pendingForApproval: dashboardCaseCountCommission[1]?.pendingforapproval,
					filed: dashboardCaseCountCommission[1]?.filed,
					disposed: dashboardCaseCountCommission[1]?.disposed,
					pending: dashboardCaseCountCommission[1]?.filed - dashboardCaseCountCommission[1]?.disposed,
					against_filed: dashboardCaseCountCommission[1]?.against_filed,
					against_disposed: dashboardCaseCountCommission[1]?.against_disposed,
					against_pending: dashboardCaseCountCommission[1]?.against_filed - dashboardCaseCountCommission[1]?.against_disposed
				}
				dispatch(addCaseCounts(caseCounts));
				dispatch(addMainCaseCounts(mainCaseCounts));
				setFirstCallDone(true);
			} else {
				dashboardCaseCountCommission = dashboardCaseCount?.flatMap(item => item);
				caseCounts = {
					draft: dashboardCaseCountCommission[0]?.draft,
					pendingForScrutiny: dashboardCaseCountCommission[0]?.pendingforscrutiny,
					revertedInScrutiny: dashboardCaseCountCommission[0]?.revertedcases,
					paymentPending: dashboardCaseCountCommission[0]?.verifiedandpendingforpayment,
					pendingForApproval: dashboardCaseCountCommission[0]?.pendingforapproval,
					filed: dashboardCaseCountCommission[0]?.filed,
					disposed: dashboardCaseCountCommission[0]?.disposed,
					pending: dashboardCaseCountCommission[0]?.filed - dashboardCaseCountCommission[0]?.disposed,
					against_filed: dashboardCaseCountCommission[0]?.against_filed,
					against_disposed: dashboardCaseCountCommission[0]?.against_disposed,
					against_pending: dashboardCaseCountCommission[0]?.against_filed - dashboardCaseCountCommission[0]?.against_disposed
				}
				dispatch(addCaseCounts(caseCounts));
				setFirstCallDone(true);
			}
		}
	}, [dispatch, userDetailsData.commissionId, userDetailsData?.roleId, userDetailsData.userId]);

	// Get All states API Calling
	const handleGetAllStates = useCallback(async () => {
		const stateListResponse = await MasterService.getAllStates();
		const stateList = makeDropdownOptions(stateListResponse, "stateNameEn", "stateId");
		dispatch(addMasterState(stateList));
	}, [dispatch]);

	// Get All country API Calling
	const handleGetAllCountry = useCallback(async () => {
		const countryListResponse = await MasterService.getAllCountry();
		const countryList = makeDropdownOptions(countryListResponse, "countryNameEn", "countryId");
		dispatch(addMasterCountry(countryList));
	}, [dispatch]);

	// Get All country API Calling
	const handleGetAllCaseCategory = useCallback(async () => {
		const caseCategoryListResponse = await MasterService.getAllCaseCategory();
		const caseCategoryList = makeDropdownOptions(caseCategoryListResponse, "case_category_name_en", "case_category_id");
		dispatch(addCaseCategory(caseCategoryList));
	}, [dispatch]);

	// handle get the list of handicapped types
	const handleGetHandicappedList = useCallback(async () => {
		const handicappedList = await MasterService.getHandicappedList();
		dispatch(addHandiCapType(makeDropdownOptions(handicappedList, 'handicapTypeNameEn', 'handicapTypeId')));
	}, [dispatch]);

	// Get all address types API calling
	const handleGetAllAddressTypes = useCallback(async () => {
		const addressTypes = await MasterService.getAllAddressTypes()
		const addressTypeList = makeDropdownOptions(addressTypes, "addressTypeNameEN", "addressTypeId");
		dispatch(addAddressType(addressTypeList))
	}, [dispatch]);

	// Get all case types API calling
	const handleGetAllCaseTypes = useCallback(async () => {
		const caseTypeResponse = await MasterService.getCaseTypes()
		const allCasesTypeList = makeDropdownOptions(caseTypeResponse, "caseTypeNameEn", "caseTypeId")
		dispatch(addCaseTypeList(allCasesTypeList))
	}, [dispatch]);

	useEffect(() => {

		if (!firstCallDone) {
			handleGetCaseCount();
		} else {
			const timerId = setInterval(() => handleGetCaseCount(), 3000);

			return () => clearInterval(timerId);
		}

		if (!state?.length) {
			handleGetAllStates();
		}

		if (!country?.length) {
			handleGetAllCountry();
		}

		if (!allCaseCategory?.length) {
			handleGetAllCaseCategory();
		}

		if (!handiCapType?.length) {
			handleGetHandicappedList();
		}

		if (!addressType?.length) {
			handleGetAllAddressTypes();
		}

		if (!caseTypeList?.length) {
			handleGetAllCaseTypes();
		}

	}, [firstCallDone, handleGetAllStates, handleGetCaseCount, handleGetAllCountry, userDetailsData.userId, state?.length, country?.length, allCaseCategory?.length, handleGetAllCaseCategory, handiCapType?.length, handleGetHandicappedList, addressType?.length, handleGetAllAddressTypes]);

	return (
		<div>
			{userDetailsData?.userId === 0 ? (
				<LandingPageBody />
			) : (
				<Outlet />
			)}
		</div>
	);
};
