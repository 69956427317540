import AddIcon from "@mui/icons-material/Add";
import { Checkbox } from "@mui/material";
import { FormikProps, useFormik } from "formik";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  BasicButton,
  BasicCheckbox,
  PersonalDetails,
} from "../../../../../components";
import { respondentPersonalValidationSchema } from "../../../../../components/controls/Forms/PersonalDetails";
import { AdditionalRespondentViewModel } from "../../../../../models/cases/fileNewCase/AdditionalRespondentModel";
import PersonalDetailsModel from "../../../../../models/forms/PersonalDetailsModel";

export interface IAdditionalRespondentProps {
  formik: FormikProps<AdditionalRespondentViewModel>;
  label: string;
}

export const additionalRespondentValidationSchema = Yup.object({
  addAdditionalRespondent: Yup.boolean(),
  additionalRespondents: Yup.array().of(respondentPersonalValidationSchema),
  addAdditionalRespondentPerforma: Yup.boolean(),
  additionalRespondentsPerforma: Yup.array().of(respondentPersonalValidationSchema),
});

/**
 * The `AdditionalRespondent` Component is used to fill the details of any additional respondents.
 *
 * @param IAdditionalRespondentProps
 *
 * @returns JSX.Element
 */
const AdditionalRespondent = ({
  formik,
  label,
}: IAdditionalRespondentProps) => {
  const { t } = useTranslation();

  // Add new respondent to list
  const addAdditionalRespondent = () => {
    const additionalRespondentsCopy = Array.from(
      formik.values.additionalRespondents
    );
    additionalRespondentsCopy.push(PersonalDetailsModel.init());
    formik.setFieldValue("additionalRespondents", additionalRespondentsCopy);
  };

  // Add new respondent to proforma list
  const addAdditionalRespondentProforma = () => {
    const additionalRespondentsProformaCopy = Array.from(
      formik.values.additionalRespondentsPerforma
    );
    additionalRespondentsProformaCopy.push(PersonalDetailsModel.init());
    formik.setFieldValue("additionalRespondentsPerforma", additionalRespondentsProformaCopy);
  };


  // Remove repondent with given index from array
  const deleteAdditionalRespondent = (index: number) => {
    const additionalRespondentsCopy = Array.from(
      formik.values.additionalRespondents
    );
    additionalRespondentsCopy.splice(index, 1);
    formik.setFieldValue("additionalRespondents", additionalRespondentsCopy);
    formik.setFieldTouched(`additionalRespondents[${index}]`, false);
  };

  const deleteAdditionalRespondentProforma = (index: number) => {
    const additionalRespondentsProformaCopy = Array.from(
      formik.values.additionalRespondentsPerforma
    );
    additionalRespondentsProformaCopy.splice(index, 1);
    formik.setFieldValue("additionalRespondentsPerforma", additionalRespondentsProformaCopy);
    formik.setFieldTouched(`additionalRespondentsPerforma[${index}]`, false);
  };

  const enableAdditionalRespondent = (
    event: ChangeEvent<any>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue("additionalRespondents", []);
      formik.setFieldTouched("additionalRespondents", false);
    } else {
      formik.setFieldValue("additionalRespondents", [
        PersonalDetailsModel.init(),
      ]);
    }
    formik.handleChange(event);
  };

  const enableAdditionalPerformaRespondent = (
    event: ChangeEvent<any>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue("additionalRespondentsPerforma", []);
      formik.setFieldTouched("additionalRespondentsPerforma", false);
    } else {
      formik.setFieldValue("additionalRespondentsPerforma", [
        PersonalDetailsModel.init(),
      ]);
    }
    formik.handleChange(event); // Ensure this handles the state correctly
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {formik.values.additionalRespondents?.length > 0 &&
        formik.values.additionalRespondents.map((_, index: number) => {
          return (
            <div
              key={`additional_respondents_${_?.isDifferentlyAbled}`}
              className="margin-top-25 position-relative card-primary-wrap"
            >
              <PersonalDetails
                name={`additionalRespondents[${index}]`}
                label={t("Label.Additional") + " " + label + " " + t("Details")}
                values={formik.values.additionalRespondents[index]}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                touched={formik.touched.additionalRespondents?.[index]}
                errors={formik.errors.additionalRespondents?.[index]}
                singleAddress
                isRespondent
              />
              {formik.values.additionalRespondents.length > 0 && (
                <BasicButton
                  className="btn-custom-delete min-width-auto"
                  text=""
                  variant="text"
                  type="button"
                  onClick={() => deleteAdditionalRespondent(index)}
                />
              )}
            </div>
          );
        })}
      <div className="margin-top-25">
        <BasicButton
          type="button"
          color="secondary"
          text={t("Label.AddAdditional") + " " + label}
          startIcon={<AddIcon />}
          variant="text"
          onClick={addAdditionalRespondent}
        />
      </div>

      { formik.values.additionalRespondentsPerforma?.length > 0 &&
        formik.values.additionalRespondentsPerforma.map((_, index: number) => {
          return (
            <div
              key={`additional_RespondentPerforma_${_?.isDifferentlyAbled}`}
              className="margin-top-25 position-relative card-primary-wrap"
            >
              <PersonalDetails
                name={`additionalRespondentsPerforma[${index}]`}
                label={t("Label.AddProformaOppositeParty") + " " + t("Details")}
                values={formik.values.additionalRespondentsPerforma[index]}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                touched={formik.touched.additionalRespondentsPerforma?.[index]}
                errors={formik.errors.additionalRespondentsPerforma?.[index]}
                singleAddress
                isRespondent
              />
              {formik.values.additionalRespondentsPerforma.length > 0 && (
                <BasicButton
                  className="btn-custom-delete min-width-auto"
                  text=""
                  variant="text"
                  type="button"
                  onClick={() => deleteAdditionalRespondentProforma(index)}
                />
              )}
            </div>
          );
        })}

      <div className="margin-top-25">
        <BasicButton
          type="button"
          color="secondary"
          text={t("Label.AddAdditionalRespondentProforma")}
          startIcon={<AddIcon />}
          variant="text"
          onClick={addAdditionalRespondentProforma}
        />
      </div>
    </>
  );
};

export default AdditionalRespondent;
