import React, { useEffect, useState } from "react";
import "./ourvision.scss";
import { AnalyticsService } from "../../services/AnalyticsService/AnalyticsService";
import { ITotalDataResponse } from "../../models/anaylticsDashboard/reponseData";
import { indian_number_system_converter } from "../../utils/converter";
const animatedImage1 = "/topbar/animatedsection1.png";
const animatedImage3 = "/topbar/animatedsection3.png";
const animatedImage4 = "/topbar/animatedsection4.png";
const animatedImage5 = "/topbar/animatedsection5.png";


const images = [
  {
    id: 1,
    image: animatedImage1,
    heading: (
      <h2 className="text-white fs-1 fw-bold">
        OUR <span className="animate-heading ">VISION</span>
      </h2>
    ),
    description:
      "eJagriti aims to achieve exemplary egovernance characterized by efficiency, transparency, accessibility thereby eliminating long pendency of disputes.",
  },
  {
    id: 2,
    image: animatedImage5,
    heading: (
      <h2 className="text-white fs-1 fw-bold">
        <span className="animate-heading">Speak </span> and
        <span className="animate-heading"> file </span> your
        <span className="animate-heading"> Complaint</span>
      </h2>
    ),
    description:
      "Report your concerns and grievances in a straightforward and  hassle-free manner, ensuring your voice is heard by the authorities.",
  },
  {
    id: 3,
    image: animatedImage3,
    heading: (
      <h2 className="text-white text-white fs-1  fw-bold">
        <span className="animate-heading ">Virtual </span>hearing in
        <span className="animate-heading "> commission </span>proceedings
      </h2>
    ),
    description:
      "Streamlining consumer complaints with Virtual Court integration for efficient and swift resolution.",
  },
  {
    id: 4,
    image: animatedImage4,
    heading: (
      <h2 className="text-white  fs-1  fw-bold">
        <span className="animate-heading ">SEAMLESS </span>COMPLAINT FILING ALL
        OVER <span className="animate-heading ">INDIA </span>
      </h2>
    ),
    description:
      "Easy-to-use online complaint submission platform for citizens to report issues and concerns to local government",
  },
];

const CaseCount = ({
  imageUrl,
  caseCount,
  caseType,
}: {
  imageUrl: string;
  caseCount: string;
  caseType: string;
}) => {
  return (
    <div className="d-flex gap-2  align-items-center countOverVisionCard">
      <div>
        <img className="landingPage-countImg" src={imageUrl} alt={caseType} />
      </div>
      <div className="landingPage-countText">
        <p className="text-dark fs-4 fw-semibold overVisionCountStyle">{caseCount}</p>
        <p className="text-dark fw-bold m-0 overVisionCountStyleDes">{caseType}</p>
      </div>
    </div>
  );
};

export default function Ourvision() {
  // const [imageFallBack, setImageFallBack] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(images);
  const [animationPause, setAnimationPause] = useState(false);
  const [totalData, setTotalData] = useState<ITotalDataResponse[]>();
  const delay = 5000;
  const [count, setCount] = useState(0);

  const AnimatedSectionTemplate = () => {
    const [imageFallBack, setImageFallBack] = React.useState(false);
    const [animationPause, setAnimationPause] = React.useState(false);

    const ImageTemplate = (image?: string) => {
      if (image) {
        return (
          <>
            {!imageFallBack && (
              <img
                alt=""
                className="w-100 h-100 object-fit-fill right_ourvision_image"
                src={image}
                onError={() => {
                  setImageFallBack(true);
                }}
              />
            )}
          </>
        );
      }
      return null;
    };

    const image = selectedImage?.image;

    return (
      <div
        key={selectedImage!.id}
        className={`h-100 animate__animated animate__fadeIn ${animationPause ? "animate__pause" : ""
          }`}
        style={{ "--animate-duration": "2s" } as React.CSSProperties}
        onMouseOver={() => setAnimationPause(true)}
        onMouseLeave={() => setAnimationPause(false)}
      >
        {ImageTemplate(image)}
      </div>
    );
  };

  useEffect(() => {
    if (images) {
      const max = images.length - 1;
      const interval = setTimeout(() => {
        if (!animationPause) {
          setCount((prevState) => {
            if (prevState > max - 1) {
              return 0;
            } else {
              return prevState + 1;
            }
          });
        }
      }, delay);

      return () => {
        clearInterval(interval);
      };
    }
  }, [count, images, animationPause]);

  const fetchTotalData = async () => {
    const result = await AnalyticsService.getTotalDataNCDRC();
    if (result?.length) {
      setTotalData(result);
    }
  };

  useEffect(() => {
    fetchTotalData();
  }, []);

  const caseCountList = [
    {
      imageUrl: "/topbar/casecount3.svg",
      caseCount: `${totalData?.map((item) =>
        indian_number_system_converter(item.no_of_cases_filed)
      )}`,
      caseType: "Filed ",
    },
    {
      imageUrl: "/topbar/casecount2.svg",
      caseCount: `${totalData?.map((item) =>
        indian_number_system_converter(item.no_of_cases_disposed)
      )}`,
      caseType: "Disposed ",
    },
    {
      imageUrl: "/topbar/casecount1.svg",
      caseCount: `${totalData?.map((item) =>
        indian_number_system_converter(item.no_of_cases_pending)
      )}`,
      caseType: "In Progress ",
    },
  ];
  const caseAllCountList = [
    {
      imageUrl: "/topbar/casecount3.svg",
      caseCount: `${totalData?.map((item) =>
        indian_number_system_converter(item.no_of_all_cases_filed)
      )}`,
      caseType: "Filed ",
    },
    {
      imageUrl: "/topbar/casecount2.svg",
      caseCount: `${totalData?.map((item) =>
        indian_number_system_converter(item.no_of_all_cases_disposed)
      )}`,
      caseType: "Disposed ",
    },
    {
      imageUrl: "/topbar/casecount1.svg",
      caseCount: `${totalData?.map((item) =>
        indian_number_system_converter(item.no_of_all_cases_pending)
      )}`,
      caseType: "In Progress ",
    },
  ];
  useEffect(() => {
    if (images) setSelectedImage(images[count]);
  }, [count]);

  return (

    <section className="bg-warning ourvision-section">
      {/* background wave image */}
      <img
        className="backGroundImageBanner"
        src="/images/lineSquire1.svg"
        alt="background Image"
      />

      {/* <div className=" col-xl-7  my-5 pl-2 child-Container-landingPage"> */}
      <div className="ourVision_left">
        <div className="overVision_left_discription">
          <h1>{selectedImage.heading}</h1>
          <h3 className="text-white overVisonDes">{selectedImage.description}</h3>
        </div>
        <span>Main Casetypes</span>
        <div className="d-flex gap-3 overVisoinCount">
          {caseCountList.map((item, index) => {
            return (
              <CaseCount
                key={index}
                imageUrl={item.imageUrl}
                caseCount={item.caseCount}
                caseType={item.caseType}
              />
            );
          })}
        </div>
        <span>All Casetypes</span>
        <div className="d-flex gap-3 overVisoinCount">
          {caseAllCountList.map((item, index) => {
            return (
              <CaseCount
                key={index}
                imageUrl={item.imageUrl}
                caseCount={item.caseCount}
                caseType={item.caseType}
              />
            );
          })}
        </div>
      </div>
      {/* </div> */}

      <div className="over_vision_right">
        <img className="background-arrow-img" src="/images/ImageBackgroundLIne.svg" alt="background line image" />
        <div className="ourVision_right">
          {AnimatedSectionTemplate()}
        </div>
      </div>
    </section>

  );
}



