import { IDropdown } from "../../common/IDropdown";

export class TransferApplicationCaseDetailsViewModel {
  constructor(
    public fromDistrict: IDropdown,
    public toDistrict: IDropdown,
    public fromState: IDropdown,
    public toState: IDropdown,
    public caseSummary: string
  ) {
    this.fromDistrict = fromDistrict;
    this.toDistrict = toDistrict;
    this.fromState = fromState;
    this.toState = toState;
  }
  public static init = () =>
    new TransferApplicationCaseDetailsViewModel(
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      ""
    );
}

export interface ITACaseDetailsRequest {
  previousFilingReferenceNumber: number;
  previousCaseNumber: string;
  previousCaseTypeId: number;
  caseFiledBy: number;
  previousCommissionId: number;
  destinationCommissionId: number;
  remarks: string;
  clientIpAddress: string;
  caseTypeId: number;
}

export interface ITAUpdateCaseDetailsRequest {
  fillingReferenceNumber: number;
  remarks: string;
  caseFiledBy: number;
  previousCommissionId: number;
  filedInComissionId: number;
  clientIpAddress: string;
}

export interface ITACaseDetailsResponse {
  fillingReferenceNumber: number;
  fileApplicationNumber: any;
  caseNumber: any;
  caseTypeId: number;
  filedInComissionId: number;
  dateOfNextHearing: any;
  claimAmount: number;
  paidAmount: number;
  compensesionAamount: any;
  stateIdOfPlaceOfCause: number;
  districtIdOfPlaceOfCause: number;
  dateOfCause: any;
  previousFillingReferenceNumber: number;
  previousCaseNumber: string;
  previousCaseTypeId: number;
  previousCommissionId: number;
  previousCaseOrderNumber: any;
  caseStageId: any;
  caseFiledBy: number;
  caseFilingDate: any;
  caseCategoryId: number;
  firstOriginalFillingReferenceNumber: any;
  firstOriginalCaseNumber: any;
  bunchCasesDetails: any;
  transactionId: any;
  recieptId: any;
  paymentAmount: any;
  paymentModeTypeId: any;
  paymentInstitutionId: any;
  paymentInstitutionReferenceNumber: any;
  paymentInstitutionResponseStatusId: any;
  offlineTransactionTypeId: any;
  offlineTransactionReferenceNumber: any;
  offlineTransactionBankId: any;
  offlineTransactionBranchId: any;
  offlineTransactionIfscCode: any;
  lastUpdationDoneBy: any;
  lastUpdationDateTime: any;
  remarks: string;
  clientIpAddress: string;
  casefillingStageId: number;
  createdOnDateTime: string;
  activeStatus: number;
  previousCaseOrderDate: any;
  caseReasonTypeId: any;
  caseReasonTypeDisplayText: any;
}

export interface ICommissionListResponse {
  commissionId: number;
  commissionNameEn: string;
}

export interface IGetCaseDetailsCommission {
  currentCommission: string;
  previousCommission: string;
}
