import React from 'react';
import { ConfonetCard, ConfonetCardBody, ConfonetCardHeader } from '../../../../components';
import { useTranslation } from 'react-i18next';
import {
    List,
    IconButton,
    Typography,
    Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { IgetNoteListResponse, ImportantNotesSearchViewModel } from '../../../../models/ImportantNotes/IImportantNotes';
import { FormikProps } from 'formik';

interface IImportantNotesSearchProps {
    importantNotesSearchFormik: FormikProps<ImportantNotesSearchViewModel>
    handleEdit: (note: IgetNoteListResponse) => void
    handleDelete: (note: IgetNoteListResponse) => void
    notesData: IgetNoteListResponse[] // Update type for clarity
};

const ImportantNotesList: React.FC<IImportantNotesSearchProps> = ({ importantNotesSearchFormik, handleEdit, handleDelete, notesData }) => {
    const { t } = useTranslation();
    const location = useLocation();
     console.log(notesData);
    return (
        <div className="margin-top-25">
            <ConfonetCard>
                <ConfonetCardHeader>
                    <div className="card-title card-primary-header-title">
                        {location.pathname.includes('commission/registry') ? t("Label.RegistryNotes") : t("Label.CreateNotes")}
                    </div>
                </ConfonetCardHeader>
                <ConfonetCardBody>
                    <List>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: '8px 0' }}>
                                <Typography sx={{ flex: 1 }}>Title</Typography>
                                <Typography sx={{ flex: 1 }}>Case Number</Typography>
                                <Typography sx={{ flex: 1 }}>Date</Typography>
                                <Typography sx={{ width: '120px', textAlign: 'center' }}>Actions</Typography>
                            </Box>
                        
                            {notesData && notesData.map((note: IgetNoteListResponse, index: React.Key | null | undefined) => (
                                <React.Fragment key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 0', borderBottom: '1px solid #f0f0f0' }}>
                                        {/* Title */}
                                        <Typography sx={{ flex: 1 }}>{note.title}</Typography>

                                        {/* Case Number */}
                                        <Typography sx={{ flex: 1 }}>
                                        {note.caseNumber ? note.caseNumber.split(' (')[0]  : 'N/A'}  {/* Fallback to 'N/A' if undefined */}
                                        </Typography>

                                        {/* Date */}
                                        <Typography sx={{ flex: 1 }}>
                                            {note.createdAt ? DateTime.fromISO(note.createdAt).toFormat('dd-MM-yy') : 'N/A'} {/* Safeguard for createdAt */}
                                        </Typography>

                                        {/* Action Buttons */}
                                        <Box sx={{ width: '120px', textAlign: 'center' }}>
                                            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(note)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(note)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </List>
                </ConfonetCardBody>
            </ConfonetCard>
        </div>
    )
}

export default ImportantNotesList;
