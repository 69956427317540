import PersonalDetailsModel from "../../forms/PersonalDetailsModel";
/**
* View model for the additional respondent form
 */
export class AdditionalRespondentViewModel{
    constructor(
        public addAdditionalRespondent: boolean,
        public addAdditionalRespondentPerforma: boolean,
        public additionalRespondents: PersonalDetailsModel[],
        public additionalRespondentsPerforma: PersonalDetailsModel[]
    ){
        this.addAdditionalRespondent = addAdditionalRespondent;
        this.additionalRespondents = additionalRespondents;
        this.addAdditionalRespondentPerforma = addAdditionalRespondentPerforma;
        this.additionalRespondentsPerforma = additionalRespondentsPerforma
    }

    static init = () => new AdditionalRespondentViewModel(true,true, [], [])
}