import React, { useEffect, useRef } from 'react';

interface HtmlIframeProps {
    htmlContent: string;
    title: string
}

const ConfonetHtmlIframe: React.FC<HtmlIframeProps> = ({ htmlContent, title }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (iframeRef.current && iframeRef.current.contentWindow) {
            const iframeDocument = iframeRef.current.contentWindow.document;
            iframeDocument.open();
            iframeDocument.write(htmlContent);
            iframeDocument.close();
        }
    }, [htmlContent]);

    return (
        <iframe
            ref={iframeRef}
            title={title}
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default ConfonetHtmlIframe;
