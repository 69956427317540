export class ScrutinyFinalActionModel {
    constructor(
        public actionTaken: number,
        public defectTypeId: string[],
        public defectTypeText: string,
        public otherDefectTypeText: string
    ) {
        this.actionTaken = actionTaken;
        this.defectTypeId = defectTypeId;
        this.defectTypeText = defectTypeText;
        this.otherDefectTypeText = otherDefectTypeText;
    }

    static init = () => new ScrutinyFinalActionModel(1, [], "", "");
}

export interface IDefectTypeResponse {
    defectTypeId: number;
    defectTypeNameEn: string;
    activeStatus: number;
}

export interface INoticeTypeResponse {
    noticeTypeId: number,
    noticeTypeNameEn: string
}