import { Info } from "@mui/icons-material";
import { Checkbox, FormHelperText, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { BasicCheckbox, ConfonetAutocomplete, ConfonetCard, ConfonetCardBody, ConfonetCardHeader } from "../../../../../components";
import { FinalSubmitViewModel } from "../../../../../models/cases/fileNewCase/FinalSubmitModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import { dropdownValidationSchema } from "../../../../../utils/Helper";

export interface IFinalSubmitProps {
    formik: FormikProps<FinalSubmitViewModel>,
    commissionOptions: IDropdown[],
    locationType: string,
    commissionName?: string,
    disableDeclare?: boolean
}

export const finalSubmitValidationSchema = Yup.object({
    commission: dropdownValidationSchema("Commission is a required field").required(),
    declaration: Yup.boolean().equals([true], "The Declaration must be accepted")
})

/**
 * Component for final submit in file new case form
 * @param IFinalSubmitProps 
 * @returns 
 */
const FinalSubmit = ({ formik, commissionOptions, locationType, commissionName, disableDeclare = false }: IFinalSubmitProps) => {
    const { t } = useTranslation();

    const {setFieldValue} = formik;

    useEffect(() => {
        setFieldValue("declaration", disableDeclare)
    }, [disableDeclare, setFieldValue]);

    return (
        <div className="margin-top-25">
            <div className="mt-4">
                <ConfonetCard>
                    <ConfonetCardHeader>
                        <div className="card-title card-primary-header-title">{t("Label.YourCaseWillBeFiledIn")}
                            {
                                // String mainpulation for displaying commission
                                ` ${locationType} ${locationType !== "NCDRC" ? t("Label.Commission") : ""}`
                            }
                        </div>
                    </ConfonetCardHeader>
                    <ConfonetCardBody>
                        <div className="row">
                            <div className="col-lg-4">
                                <ConfonetAutocomplete
                                    id="commission"
                                    className="uppercase"
                                    value={formik.values.commission}
                                    options={commissionOptions}
                                    renderInput={
                                        (params) => (
                                            <TextField
                                                {...params}
                                                label={t("Label.Commission") + "*"}
                                                error={formik.touched.commission && Boolean(formik.errors.commission)}
                                                helperText={formik.touched.commission && formik.errors.commission?.label}
                                                fullWidth
                                            />
                                        )
                                    }
                                    onChange={(_, value: IDropdown | null) => {
                                        formik.setFieldValue("commission", value ?? { label: "", value: "" });
                                    }}
                                    onBlur={formik.handleBlur}
                                    disabled={commissionName !== undefined}
                                />
                            </div>
                        </div>
                    </ConfonetCardBody>
                </ConfonetCard>
            </div>
            {
                !disableDeclare &&
                <>
                    <div className="input-form-control-help-text mt-4">
                        <Info className="float-start margin-right-5" fontSize="small" />
                        <p className="fw-bold">{t("Label.Caution")}: {t("Label.OnceFinalizeCaseCannotEdit")}</p>
                    </div>
                    <div className="margin-top-25">
                        <BasicCheckbox
                            id="declaration"
                            name="declaration"
                            label={t("Label.DecelarationForCaseSubmission")}
                            control={<Checkbox />}
                            checked={disableDeclare || formik.values.declaration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.declaration &&
                            formik.errors.declaration && (
                                <FormHelperText className="Mui-error">{formik.errors.declaration}</FormHelperText>
                            )}
                    </div>
                </>
            }
        </div>
    )

}
export default FinalSubmit;