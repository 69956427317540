import { DialogContent, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import arrow_image from '../../../assets/images/arrow_image.png';
import image from '../../../assets/images/basil_document-solid.png';
import grid from '../../../assets/images/grid.png';
import icon_list from '../../../assets/images/ion_list-sharp.png';
import { BasicBreadcrumbs, BasicButton, Dialogbox } from '../../../components';
import ConfonetPdfViewer from '../../../components/controls/ConfonetPdfViewer';
import { ICaseFillingDocumentDetailsResponseList } from '../../../models/cases/consumerCases/IConsumerCaseModel';
import { JUDGE_CASE_PREVIEW } from '../../../navigation/CONSTANTS';
import { convertBase64ToUInt8 } from '../../../utils/Helper';
import SearchBar from './SearchBar';
import ButtoBack from './Button';
import { CaseServices } from '../../../services/CaseService/CaseServices';
import { toast } from 'react-toastify';


const ComplaintPages = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const { state } = location;

    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));

    const handleDocumentView = async(id: number, callType: number = 1) => {
        const fileString = await CaseServices.getDocumentContentById(id, callType as number);
        if (fileString?.fileContent?.length > 0) {
            setDocumentContent(convertBase64ToUInt8(fileString?.fileContent))
        } else {
            toast.info('Document not available')
        }
    }

    return (
        <>
            <div className="container-fluid">
                {/* Header with Important Links and View Mode Icons */}
                <div className="row align-items-center">
                    <div className="col-lg-9 col-sm-7 margin-bottom-30">
                        <BasicBreadcrumbs items={[
                            { to: JUDGE_CASE_PREVIEW, label: t("Breadcrumb.CaseDetails") },
                            { to: '', label: t("Label.Complaint") },
                        ]} />
                    </div>
                    <div>
                        <SearchBar />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Typography variant="h6"
                        style={{
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            fontWeight: 700,
                            lineHeight: '24.2px',
                            textAlign: 'left'
                        }}>{state.title}</Typography>
                    <div>
                        <IconButton onClick={() => setViewMode('grid')} size="small">
                            <img src={grid} alt="Grid View" style={{ width: '24px', height: '24px' }} />
                        </IconButton>
                        <IconButton onClick={() => setViewMode('list')} size="small">
                            <img src={icon_list} alt="List View" style={{ width: '24px', height: '24px' }} />
                        </IconButton>
                    </div>
                </div>

                {/* Cases List/Grid Display */}
                <div className={`tab-container ${viewMode}`}>
                    <div className="row">
                        {state?.documentList?.map((cases: ICaseFillingDocumentDetailsResponseList) => (
                            <div
                                className={`tab-item ${viewMode === 'grid' ? 'col-lg-3 col-md-3 col-sm-6' : 'col-12'} mb-3`}
                                key={cases.documentId}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDocumentView(cases.caseFilingDocumentDetailsId)}
                            >
                                <div className="card h-100">
                                    <div className="important-link-card">
                                        <div className='main-image-judge'>
                                            {/* {cases.icon} */}
                                            <img
                                                src={image}
                                                alt="Document Icon"
                                                className="image-class"
                                            />
                                        </div>
                                        <div className="card-content-judge">
                                            <Typography variant="body2" className="card-title-complaint">
                                                {cases.documentName}
                                            </Typography>
                                            <img
                                                src={arrow_image}
                                                alt="Arrow Icon"
                                                className="arrow-image-judge"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ clear: 'both', marginTop: '25px', textAlign: 'right' }}>
                    <ButtoBack />
                </div>
            </div>
            <Dialogbox
                open={documentContent.length > 1}
                onClose={() => {
                    setDocumentContent(new Uint8Array([255]));
                }}
                maxWidth="lg"
                fullScreen
            >
                <DialogContent>
                    {documentContent.length > 1 && (
                        <ConfonetPdfViewer
                            fileUrl={documentContent}
                        />
                    )}
                </DialogContent>
            </Dialogbox>
        </>
    );
};

export default ComplaintPages;
