
import { Box, Typography, Button, Container, CircularProgress, DialogContent, DialogActions } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { keyframes } from '@emotion/react';
import { t } from 'i18next';
import EmailIcon from "@mui/icons-material/Email";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { BasicButton, Dialogbox } from '../../../components';
import { useEffect, useState } from 'react';
import { ReceiptPdf } from './PaymentHelper';
import { CaseStatusHistoryService } from '../../../services/CommonService/CaseStatusHistoryService';
import { PaymentService } from '../../../services/PaymentService/paymentService';
import { ROLE } from '../../../utils/Enum';
import { ADVOCATE_DASHBOARD, CONSUMER_DASHBOARD } from '../../../navigation/CONSTANTS';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { DateTime } from 'luxon';
import { convertImageToBase64 } from '../../../utils/Helper';
import imageLogo from '../../../assets/images/LogoPaymentHelper1.png';


// Keyframe animation for the checkmark icon
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

function PaymentSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const [showPaymentResponse, setShowPaymentResponse] = useState<boolean>(false);
    // generating state for razorpay
    const [caseNumber, setCaseNumber] = useState<number>(0);
    const [receiptNumber, setReceiptNumber] = useState<string | null>(null);
    const [caseNumberMain, setCaseNumberMain] = useState<string | null>("");
    const [bankRefNumber, setBankRefNumber] = useState<string | null>(null);
    const [transactionIdRazor, setTansactionIdRazor] = useState<string>("");
    const [paymentDate, setPaymentDate] = useState<string | null>(null);
    const [paymentAmount, setPaymentAmount] = useState<string>("");
    const [filedIn, setFiledIn] = useState<string>("");
    const [complainantName, setComplainantName] = useState<string>("");
    const [respondentName, setRespondentName] = useState<string>("");
    const [baseImage, setBaseImage] = useState<string>("");
    // generating state for paygov 

    useEffect(() => {
        const generatePdfWrapper = async () => {
            try {
                const base64Image = await convertImageToBase64(imageLogo);
                setBaseImage(base64Image)
            } catch (error) {
                console.error("Error converting image to base64:", error);
            }
        };

        generatePdfWrapper();
    }, []); // Empty dependency array to run only once


    // Create a URLSearchParams object from the query string
    const queryParams = new URLSearchParams(location.search);
    // Extract the transactionId from the query parameters

    useEffect(() => {
        if (queryParams.size > 0) {
            const transactionIdValue = queryParams.get('transactionId');
            const caseNumberPaygovValue = queryParams.get('caseNumber');
            const paymentDateTimeValue = queryParams.get('paymentDateTime');
            const orderIdValue = queryParams.get('orderId');
            setBankRefNumber(transactionIdValue);
            setCaseNumberMain(caseNumberPaygovValue);
            if (paymentDateTimeValue) {
                setPaymentDate(DateTime.fromFormat(decodeURIComponent(paymentDateTimeValue), "dd-MM-yyyy HH:mm:ss").toFormat("dd-MM-yyyy HH:mm"));
            } else {
                setPaymentDate('N/A');
            }
            setReceiptNumber(orderIdValue);
        }
    }, [queryParams]);

    const { order } = location.state || {};

    useEffect(() => {
        if (order) {
            setTansactionIdRazor(order?.razorpay_payment_id)
            setPaymentDate(DateTime.fromISO(order?.paymentDate).toFormat("dd-MM-yyyy HH:mm"))
            setCaseNumberMain(order?.caseNumber)
            setReceiptNumber(order?.razorpay_order_id)
            setBankRefNumber(order?.razorpay_payment_id)
        }


    }, [order])

    const GetDetails = async () => {
        if (caseNumberMain) {
            const caseStatusResponse = await CaseStatusHistoryService.getCaseStausDetails(caseNumberMain);
            if (caseStatusResponse && caseStatusResponse.caseNumber) {
                setComplainantName(caseStatusResponse?.complainant);
                setRespondentName(caseStatusResponse?.respondent);
                setFiledIn(caseStatusResponse?.filedInComissionName);
                setCaseNumber(caseStatusResponse?.fillingReferenceNumber);

                const response = await PaymentService.paymentCheckoutPreview(
                    caseStatusResponse.fillingReferenceNumber.toString()
                );
                setPaymentAmount(response?.feeAmount.toString());
            }
        }

    };



    const ReceiptPdfPrint = () => {
        ReceiptPdf(
            caseNumberMain,
            receiptNumber,
            caseNumber,
            bankRefNumber,
            paymentAmount,
            paymentDate,
            filedIn,
            complainantName,
            respondentName,
            baseImage
        );
    };
    const closeReceipt = () => {
        setShowPaymentResponse(false);

    };

    const navigateToDashboardPath = () => {
        navigate(userDetailsData.roleId === ROLE.Consumer ? CONSUMER_DASHBOARD : ADVOCATE_DASHBOARD);
    }
    useEffect(() => {
        GetDetails()
    }, [caseNumberMain])


    return (
        <Container>
            <Dialogbox
                title={t("Label.PaymentInformation")}
                maxWidth="sm"
                open={showPaymentResponse}
                onClose={() => closeReceipt()}
            >
                <DialogContent dividers>
                    <div className="row">
                        <div className="col-lg-6">
                            <p className="mb-2">{t("Label.CaseRefNumber")}</p>
                            <p className="highlight-text">{caseNumber}</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="mb-2">{t("Label.CaseNumber")}</p>
                            <p className="highlight-text">{caseNumberMain}</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="mb-2">{t("Label.ReceiptNumber")}</p>
                            <p className="highlight-text">{receiptNumber}</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="mb-2">{t("Label.BankRefNumber")}</p>
                            <p className="highlight-text">{bankRefNumber}</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="mb-2">{t("Label.PaymentDate")}</p>
                            <p className="highlight-text">{paymentDate}</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="d-flex justify-content-start">
                    <div className="w-100 text-center d-flex justify-content-between">
                        <BasicButton
                            startIcon={<FileUploadOutlinedIcon />}
                            text={t("Label.DownloadPdf")}
                            size="small"
                            color="secondary"
                            variant="text"
                            onClick={() => {
                                ReceiptPdfPrint();
                            }}
                        />
                        <BasicButton
                            startIcon={<EmailIcon />}
                            text={t("Label.GetOnEmail")}
                            size="small"
                            color="secondary"
                            variant="text"
                            onClick={() => {
                                ReceiptPdfPrint();
                            }}
                        />
                    </div>
                </DialogActions>
            </Dialogbox>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CheckCircleIcon
                    color="success"
                    sx={{ fontSize: 80, animation: `${bounce} 1s ease-in-out infinite` }}
                />
                <Typography variant="h4" color="success.main" align="center" gutterBottom>
                    Payment Successful!
                </Typography>
                <Typography variant="h4" color="success.main" align="center" gutterBottom>
                    Payment TransactionId : {bankRefNumber}
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                    Thank you for your payment. Your transaction was successful!
                </Typography>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ gap: 2, mt: 3 }} // Adjust gap and margin as needed
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowPaymentResponse(!showPaymentResponse)} // Replace with actual receipt URL
                    >
                        View Receipt
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigateToDashboardPath()} // Redirect to home or another page
                    >
                        Go to Dashboard
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

export default PaymentSuccess