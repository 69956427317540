import { GenerateNoticePersonalStatusViewModel } from "../../../../models/IssueNotice/IssueNoticeModel";
import { pdfMakeFonts, pdfMakeLibrary } from "../../../../utils/CONSTANTS";


export const NoticePdf = (
    UserCommision: string,
    userCommissionMobile: string,
    userCommissionEmail: string,
    userCommissionAddress: string,
    userCommissionPincode: number,
    caseNumber: string,
    Complainant: string,
    ComplainantAdvocate: string | undefined,
    Respondant: string,
    RespondantAdvocate: string | undefined,
    Notice: string,
    Authority: string,
    ComplainantDetail: GenerateNoticePersonalStatusViewModel[],
    respondantDetail: GenerateNoticePersonalStatusViewModel[],



) => {
    pdfMakeLibrary.fonts = {
        Poppins: {
            normal: 'Poppins-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Poppins-Regular.ttf',
            bolditalics: 'Poppins-Regular.ttf',
        },
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
        }
    }
    // playground requires you to assign document definition to a variable called dd
    let dd = {
        content: [
            {
                text: `${UserCommision.toUpperCase()}`,
                style: 'header',
                alignment: 'center'
            },
            {
                text: 'Under the Consumer Protection Act, 2019',
                style: 'subheader',
                alignment: 'center'
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            { text: `Telephone No.: ${userCommissionMobile}\nEmail: ${userCommissionEmail}`, alignment: 'left' },
                            { text: `${userCommissionAddress}\n${userCommissionPincode}`, alignment: 'right' }
                        ]
                    ]
                },
                layout: 'noBorders',
                margin: [0, 0, 0, 10]
            },
            {
                text: `Consumer Complainant No. ${caseNumber}`,
                style: 'subheader1'
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            { text: `${Complainant}`, alignment: 'left' },
                            { text: '.....Petitioner/Appellant', alignment: 'right' }
                        ],
                        [
                            { text: 'Versus', alignment: 'center', colSpan: 2 },
                            {}
                        ],
                        [
                            { text: `${Respondant}`, alignment: 'left' },
                            { text: '.....Opposite Party/Respondent(s)', alignment: 'right' }
                        ]
                    ]
                },
                layout: 'noBorders',
                margin: [0, 0, 0, 10]
            },
            { text: 'To\n', style: 'subheader' },
            { text: 'Complainant / Petitioner / Appellant Name:', bold: true },
            {
                ol: ComplainantDetail.map(item => ({
                    text: item.name,
                    // Optional: Add styling here if needed
                    margin: [0, 5, 0, 5], // [left, top, right, bottom]
                }))
            },
            { text: 'Complainant / Petitioner / Appellant Advocate Name:', bold: true },
            {
                ol: ComplainantDetail.map(item => ({
                    text: item.advocate?.name,
                    // Optional: Add styling here if needed
                    margin: [0, 5, 0, 5], // [left, top, right, bottom]
                }))
            },
            { text: 'Opposite Party: Respondent Name:', bold: true },
            {
                ol: respondantDetail.map(item => ({
                    text: item.name,
                    // Optional: Add styling here if needed
                    margin: [0, 5, 0, 5], // [left, top, right, bottom]
                }))
            },
            { text: 'Opposite Party: Respondent Advocate Name:', bold: true },
            {
                ol: respondantDetail.map(item => ({
                    text: item.advocate?.name,
                    // Optional: Add styling here if needed
                    margin: [0, 5, 0, 5], // [left, top, right, bottom]
                }))
            },
            { text: 'Notice', style: 'subheader', alignment: "center" },
            {
                text: `${Notice}`,
                margin: [0, 0, 0, 10]
            },
            {
                text: `${Authority}`,
                alignment: 'right'
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 5, 0, 5]
            },
            subheader1: {
                fontSize: 14,
                bold: true,
                margin: [0, 5, 0, 5]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            }
        }
    };

    pdfMakeLibrary.vfs = pdfMakeFonts.pdfMake.vfs;
    pdfMakeLibrary.createPdf(dd).download();
};

