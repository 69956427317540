import { DateTime } from "luxon";

export class GenerateCauselistViewModel {
	constructor(
		public courtNumber: number,
		public dateOfNextHearing: DateTime,
	) {
		this.courtNumber = courtNumber;
		this.dateOfNextHearing = dateOfNextHearing;
	}
	static readonly init = () => new GenerateCauselistViewModel(
		1, DateTime.now()
	);
}

export class GenerateCauselistViewModelSl {
	constructor(
		public clSlno: number,
	) {
		this.clSlno = clSlno;
	}
	static readonly init = () => new GenerateCauselistViewModelSl(
		1
	);
}

export interface JudgeList {
	seniority_sequence_id: string;
	judge_name: string
}

export interface CauselistComplainant {
	complainant_sequence_number: string;
	sequence_number: string;
	complainant_name: string;
}

export interface CauselistRespondent {
	respondant_sequence_number: string;
	sequence_number: string;
	respondant_name: string
}

export interface CauselistComplainantAdvocate {
	complainant_sequence_number: string;
	advocate_name: string
}


export interface CauselistRespondantAdvocate {
	respondant_sequence_number: string;
	advocate_name: string
}

export interface CauselistJudgePayload {
	dateOfNextHearing: string;
	courtId: number;
	commissionId: number | undefined;
	finalize: boolean
}

export interface CauselistJudgePayloadView {
	// dateOfNextHearing: string;
	// courtId: number;
	// commissionId: number | undefined;
	clSlno: number;
}

export interface ApiResponseData {
	message: string;
	status: number;
	data: {
		cldata: string;
		slno: number;
	}[];
	error: string;
}


export interface CauselistJudgeResponse {
	clSlno: number,
	clData: string;
	slno: number;
	printableSlno: number;
	filingReferenceNumber: number;
	courtRoomId: number;
	courtRoomName: string;
	causelistTypeId: number;
	causelistTypeName: string;
	comissionId: number;
	commissionName: string;
	caseNumber: string;
	previousCaseNumber: string;
	dateOfHearing: string;
	caseTypeId: number;
	caseTypeName: string;
	stateName: string;
	commissionType: string;
	judgeName: JudgeList[];
	complainantList: CauselistComplainant[];
	respondantList: CauselistRespondent[];
	complainantAdvocateList: CauselistComplainantAdvocate[];
	respondantAdvocateList: CauselistRespondantAdvocate[];
	attachedIaCaseList: null;
	clDate: string;
	courtId: number;
}

export interface IJudgeGenerateCauseList {
	clData: string;
	clDate: string;
	commissionId: number;
	courtId: number
	createdAt: string;
	createdBy: number;
	isFinal: boolean
	slno: number
	updatedAt: string
}