import { t } from "i18next";
import { DateTime } from "luxon";
import { AdvocateDetailsList, IComplainantRespondantDetails, IComplainantRespondantDetailsResponse } from "../models/cases/commonCase/ICommonCaseModel";
import { ICaseFillingDocumentDetailsResponseList, IGetCaseListResponse } from "../models/cases/consumerCases/IConsumerCaseModel";
import { AddressDetailsList, AddressDto, AdvocateDetailsResponseList, CaseFillingComplainantRespondantDetailNew } from "../models/cases/fileNewCase/ComplainantResondentModel";
import { IPinCodeResponse } from "../models/common/IAddress";
import { IDropdown } from "../models/common/IDropdown";
import { AddressDetailsModel, IAddressDetailModelToShow } from "../models/forms/AddressDetailsModel";
import PersonalDetailsModel, { IPersonalDetailsModelToShow } from "../models/forms/PersonalDetailsModel";
import { IScrutinyCaseList } from "../pages/Commissions/Scrutiny/ScrutinyView";
import { store } from "../redux";
import { MasterService } from "../services/MasterService/MasterService";
import { caseTypeEnum } from "./Enum";
import { b64toBlob, convertBase64ToUInt8, fileToBase64, indianRupeeInput, relationDropdown, splitName } from "./Helper";
import { IPendingApprovalCaseList } from "../pages/Commissions/Approval/ApprovalView";
import { IRevertedInScrutinyDataTable } from "../pages/Commissions/Reverted/Reverted";
import { IRevertedInScrutinyCaseRows } from "../pages/dashboard/components/ConsumerDashboard/components/ConsumerRegisteredCases/RevertedInScrutinyCaseList";
import { IPendingForScrutinyCaseRows } from "../pages/dashboard/components/ConsumerDashboard/components/ConsumerRegisteredCases/PendingForScrutinyCaseList";
import { IPendingForApprovalCaseRows } from "../pages/dashboard/components/ConsumerDashboard/components/ConsumerRegisteredCases/PendingForApprovalCaseList";
import { UploadFileModel } from "../models/cases/fileNewCase/DocumentUploadModel";
import { ISaveCaseFillingDocumentResponse } from "../models/cases/fileNewCase/IFileNewCaseModel";
import { ICaseDocumentList, IIaCaseDocumentListForCompleteDoc } from "../pages/Judge/JudgeCaseDetailsPage/JudgeCasePagesPreview";
import { CommissionCaseService } from "../services/CaseService/CommissionCaseService/CommissionCaseService";
import { IJudgeCaseDocumentList } from "../pages/Judge/JudgeCaseDetailsPage/CaseAndDocumentDetails/JudgeCaseDetails";
import { loaderService } from "../services/CommonService/loaderService";

type ReplaceKeys<T, K1 extends keyof T, K2 extends keyof T, NewKey1 extends string, NewKey2 extends string> = {
    [P in keyof T as P extends K1 ? NewKey1 : P extends K2 ? NewKey2 : P]: T[P]
};

// const { country, addressType, handiCapType } = store.getState().master;
let country: IDropdown[], addressType: IDropdown[], handiCapType: IDropdown[], caseTypeList: IDropdown[];

const updateState = () => {
    const state = store.getState().master;
    country = state.country;
    addressType = state.addressType;
    handiCapType = state.handiCapType;
    caseTypeList = state.caseTypeList
};

store.subscribe(updateState);

// Initial call to set values
updateState();
interface IComplainantRespondantData {
    complainant: PersonalDetailsModel;
    respondent: PersonalDetailsModel;
    complainantToShow: PersonalDetailsModel;
    respondentToShow: PersonalDetailsModel;
    additionalComplainant: PersonalDetailsModel[];
    additionalRespondent: PersonalDetailsModel[];
    additionalComplainantToShow: PersonalDetailsModel[];
    additionalRespondentToShow: PersonalDetailsModel[];
    allComplainant: PersonalDetailsModel[];
    allRespondant: PersonalDetailsModel[];
    allAdvocates: AdvocateDetailsList[]
    additionComplainantPerforma: PersonalDetailsModel[];
    additionalComplainantToShowPerforma: PersonalDetailsModel[];
    additionalRespondentToShowPerfroma: PersonalDetailsModel[];
    additionalRespondentPerfroma: PersonalDetailsModel[];

};

export interface ICaseWithAttachedCase extends IGetCaseListResponse {
    attachedCases: ICaseWithAttachedCase[];
}

export interface IgroupedCases {
    scrutinyCommissionCaseList: IScrutinyCaseList[],
    pendingWithPayment: IPendingApprovalCaseList[],
    pendingWithoutPayment: IPendingApprovalCaseList[],
    revertedCommissionCaseList: IRevertedInScrutinyDataTable[],
    revertedConsumerCaseList: IRevertedInScrutinyCaseRows[],
    scrutinyConsumerCaseList: IPendingForScrutinyCaseRows[],
    pendingForApprovalCaseList: IPendingForApprovalCaseRows[]
}

export const complainantRespondantDetails = async (response: IComplainantRespondantDetailsResponse, isUpdate: boolean = false, isAddress: boolean = true): Promise<IComplainantRespondantData> => {
    // fetching main, additional, all the complainants
    const mainComplainantResponse = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 1 && item.complainantRespondant.complainantRespondentSequenceNumber === 0);
    const additionalComplainantResponse = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 1 && item.complainantRespondant.complainantRespondentSequenceNumber > 0);
    const additionalComplainantResponsePerforma = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 3 && item.complainantRespondant.complainantRespondentSequenceNumber > 0);
    const additionComplainantPerforma = await generatePersonData(additionalComplainantResponsePerforma, isUpdate, isAddress);
    const additionalComplainantToShowPerforma = await generatePersonDataToShow(additionalComplainantResponsePerforma, isUpdate);
    const mainComplainant = await generatePersonData(mainComplainantResponse, isUpdate, isAddress);
    const mainComplainantToShow = await generatePersonDataToShow(mainComplainantResponse, isUpdate);
    const additionalComplainant = await generatePersonData(additionalComplainantResponse, isUpdate, isAddress);
    const additionalComplainantToShow = await generatePersonDataToShow(additionalComplainantResponse, isUpdate)
    const allComplainant = [...mainComplainant, ...additionalComplainant, ...additionComplainantPerforma];

    // fetching main, additional, all the respondants
    const mainRespondantResponse = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 2 && item.complainantRespondant.complainantRespondentSequenceNumber === 0);
    const additionalRespondantResponse = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 2 && item.complainantRespondant.complainantRespondentSequenceNumber > 0);
    const additionalRespondantResponsePerforma = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 4 && item.complainantRespondant.complainantRespondentSequenceNumber > 0);
    const mainRespondant = await generatePersonData(mainRespondantResponse, isUpdate, isAddress);
    const mainRespondentToShow = await generatePersonDataToShow(mainRespondantResponse, isUpdate);
    const additionalRespondent = await generatePersonData(additionalRespondantResponse, isUpdate, isAddress);
    const additionalRespondentToShow = await generatePersonDataToShow(additionalRespondantResponse, isUpdate);
    const additionalRespondentPerfroma = await generatePersonData(additionalRespondantResponsePerforma, isUpdate, isAddress);
    const additionalRespondentToShowPerfroma = await generatePersonDataToShow(additionalRespondantResponsePerforma, isUpdate);
    const allRespondant = [...mainRespondant, ...additionalRespondent, ...additionalRespondentPerfroma];

    // fetch all the advocate details

    const allAdvocates = response.map((complainant) => complainant.advocateDetailsList).flat();

    return {
        complainant: mainComplainant[0],
        respondent: mainRespondant[0],
        complainantToShow: mainComplainantToShow[0],
        additionalComplainantToShowPerforma: additionalComplainantToShowPerforma,
        additionalRespondentToShowPerfroma: additionalRespondentToShowPerfroma,
        respondentToShow: mainRespondentToShow[0],
        additionalComplainantToShow: additionalComplainantToShow,
        additionalRespondentToShow: additionalRespondentToShow,
        additionalComplainant,
        additionComplainantPerforma,
        additionalRespondentPerfroma,
        additionalRespondent,
        allComplainant,
        allRespondant,
        allAdvocates
    }
};

// handle generate Personal Data
const generatePersonData = (personData: IComplainantRespondantDetails[], isUpdate: boolean = false, isAddress: boolean = true): Promise<PersonalDetailsModel[]> => {
    return Promise.all(personData.map(async (data) => {
        const handicap = data.complainantRespondant.handicapTypeId ? handiCapType.filter((item) => item.value === data.complainantRespondant.handicapTypeId) : [{ label: "", value: "" }];;
        const nameSplit = splitName(data?.complainantRespondant?.complainantRespondentNameEn);
        const relation = relationDropdown.filter((salut) => salut.label === nameSplit?.delimiter);
        return {
            name: (relation.length > 0 ? nameSplit?.firstName : data.complainantRespondant.complainantRespondentNameEn) ? data.complainantRespondant.complainantRespondentNameEn : "",
            mobileNumber: data.complainantRespondant.mobileNumber?.toString() ? data.complainantRespondant.mobileNumber?.toString() === "-1" ? '' : data.complainantRespondant.mobileNumber?.toString() : "",
            email: data.complainantRespondant.emailId ? data.complainantRespondant.emailId : "",
            isSeniorCitizen: data.complainantRespondant.seniorCitizen ? data.complainantRespondant.seniorCitizen : false,
            isWidow: data.complainantRespondant.widow ? data.complainantRespondant.widow : false,
            isDifferentlyAbled: data.complainantRespondant.handicapTypeId !== null,
            seriousAilments: data.complainantRespondant?.seriousAilments && data.complainantRespondant?.seriousAilments,
            handicapped: handicap[0],
            advocate: { label: data.advocateDetailsList[0]?.advocateName ?? "", value: data.advocateDetailsList[0]?.advocateId },
            advocateList: await generateAdvocateDetails(data.advocateDetailsList),
            address: isAddress ? await generateAddress(data.addressDetailsList, isUpdate) : [],
            partyId: isUpdate ? data.complainantRespondant.partyId : undefined,
            advocateText: undefined,
            complainantRespondantTypeId: data.complainantRespondant.complainantRespondentTypeId,
            complainantRespondentSequenceNumber: data.complainantRespondant.complainantRespondentSequenceNumber,
            filingRefrenceNumber: data.complainantRespondant.filingReferenceNumber,
            relation: relation.length > 0 ? relation[0] : undefined,
            relativeName: nameSplit?.relativeName && nameSplit?.relativeName.length > 0 ? nameSplit?.relativeName : "",
        }
    }));
}

// handle generate Advocate Details
const generateAdvocateDetails = async (advocateDetails: AdvocateDetailsResponseList[]): Promise<AdvocateDetailsList[]> => {
    return Promise.all(advocateDetails.map(async (advocate, index) => {
        return {
            id: index + 1,
            ...advocate
        }
    }))
};

// handle generate address
const generateAddress = async (address: AddressDetailsList[], isUpdate: boolean = false): Promise<AddressDetailsModel[]> => {
    return Promise.all(address?.map(async (address) => {
        let getPincodeDetails: IPinCodeResponse[] = [];
        let addressTypeList: IDropdown[] = [];

        if (address.postalPinCode !== null && !address.nri && address.stateId !== -1) {
            getPincodeDetails = await MasterService.getPincodeDetails(address.postalPinCode);
        }
        addressTypeList = await addressType?.filter((item) => item.value === address.addressTypeId);
        const countryList = await country.filter((item) => item.value === address.countryId);
        return {
            addressId: isUpdate ? address?.addressDetailsId : undefined,
            addressType: { label: addressTypeList[0]?.label, value: addressTypeList[0]?.value },
            houseNumber: address.houseDoorFlatNumber ? address.houseDoorFlatNumber : "",
            street: address.areaNameNumber ? address.areaNameNumber : "",
            landmark: address.landmarkLocality ? address.landmarkLocality : "",
            pinCode: !address?.nri ? address?.postalPinCode?.toString() as string ?? "" : address?.pinCodeOfNri,
            state: getAddressParameters(getPincodeDetails, address.nri).stateDetails,
            district: getAddressParameters(getPincodeDetails, address.nri).districtDetails,
            postOffice: getAddressParameters(getPincodeDetails, address.nri).postOfficeDetails,
            country: { label: countryList[0].label, value: countryList[0].value },
            nri: address.nri,
            // Conditionally add NRI-related fields only if address.nri is true
            ...(address.nri && {
                stateOfNri: address.stateOfNri,
                districtOfNri: address.districtOfNri
            })
        };
    }));
};


const getAddressParameters = (getPincodeDetails: IPinCodeResponse[], nri: boolean) => {
    let parameterDetails = {
        stateDetails: { label: "", value: 0 },
        districtDetails: { label: "", value: 0 },
        postOfficeDetails: { label: "", value: 0 }

    }
    if (getPincodeDetails?.length) {
        if (!nri) {
            parameterDetails = {
                stateDetails: { label: getPincodeDetails[0]?.stateNameEn, value: getPincodeDetails[0]?.stateId },
                districtDetails: { label: getPincodeDetails[0]?.districtNameEn, value: getPincodeDetails[0]?.districtId },
                postOfficeDetails: { label: getPincodeDetails[0]?.postOfficeName, value: getPincodeDetails[0]?.masterPostOfficeId }
            }
        } else {
            parameterDetails = {
                stateDetails: { label: "", value: 0 },
                districtDetails: { label: "", value: 0 },
                postOfficeDetails: { label: "", value: 0 }

            }
        }
    } else {
        parameterDetails = {
            stateDetails: { label: "", value: 0 },
            districtDetails: { label: "", value: 0 },
            postOfficeDetails: { label: "", value: 0 }

        }
    }
    return parameterDetails

}

export const generatePersonDataToShow = (personData: IComplainantRespondantDetails[], isUpdate: boolean = false, isAddress: boolean = true): Promise<IPersonalDetailsModelToShow[]> => {
    return Promise.all(personData.map(async (data) => {
        const handicap = data.complainantRespondant.handicapTypeId ? handiCapType.filter((item) => item.value === data.complainantRespondant.handicapTypeId) : [{ label: "", value: "" }]
        return {
            name: data.complainantRespondant.complainantRespondentNameEn,
            mobileNumber: data.complainantRespondant.mobileNumber?.toString(),
            email: data.complainantRespondant.emailId,
            isSeniorCitizen: data.complainantRespondant.seniorCitizen,
            isWidow: data.complainantRespondant.widow,
            isDifferentlyAbled: data.complainantRespondant.handicapTypeId !== null,
            seriousAilments: data.complainantRespondant?.seriousAilments && data.complainantRespondant?.seriousAilments,
            handicapped: handicap[0],
            advocate: { label: data.advocateDetailsList[0]?.advocateName ?? "", value: data.advocateDetailsList[0]?.advocateId },
            advocateList: await generateAdvocateDetails(data.advocateDetailsList),
            address: isAddress ? await generateAddressToShow(data.addressDetailsList, isUpdate) : [],
        }
    }));
};

export const generateAddressToShow = async (address: AddressDetailsList[], isUpdate: boolean = false): Promise<IAddressDetailModelToShow[]> => {
    return Promise.all(address?.map(async (address) => {
        let getPincodeDetails: IPinCodeResponse[] = [];
        let addressTypeList: IDropdown[] = [];
        if (address.postalPinCode !== null && !address.nri && address.stateId !== -1) {
            getPincodeDetails = await MasterService.getPincodeDetails(address.postalPinCode);
        }
        addressTypeList = await addressType?.filter((item) => item.value === address.addressTypeId);
        const countryList = await country.filter((item) => item.value === address.countryId);
        return {
            addressType: { label: addressTypeList[0]?.label, value: addressTypeList[0]?.value },
            houseNumber: address.houseDoorFlatNumber as string,
            street: address.areaNameNumber as string,
            landmark: address.landmarkLocality as string,
            pinCode: !address?.nri ? address?.postalPinCode?.toString() as string ?? "" : address?.pinCodeOfNri,
            state: !address?.nri ? getAddressParameters(getPincodeDetails, address.nri).stateDetails : address?.stateOfNri,
            district: !address?.nri ? getAddressParameters(getPincodeDetails, address.nri).districtDetails : address?.districtOfNri,
            postOffice: getAddressParameters(getPincodeDetails, address.nri).postOfficeDetails,
            country: { label: countryList[0].label, value: countryList[0].value },
            nri: address.nri,
        };
    }));
};

export const generateComplainantRespondantDataToSubmit = (
    personalDetails: PersonalDetailsModel,
    complainantRespondantTypeId: number,
    complainantRespondantSequenceNumber: number,
    filingReferenceNumber: number,
    userId: number,
    caseType: caseTypeEnum = caseTypeEnum.ConsumerCase,
    selectedAdvocate?: AdvocateDetailsList[],
    ccWithIa?: boolean
): CaseFillingComplainantRespondantDetailNew => {
    const nameOutput = `${personalDetails.name?.toUpperCase()}${personalDetails.name?.includes("/O") ? "" : ` ${personalDetails.relation ? personalDetails.relation.label : ""} ${personalDetails.relativeName ? personalDetails.relativeName : ""}`}`.trim();
    let generatedDetails: CaseFillingComplainantRespondantDetailNew = {
        filingReferenceNumber: filingReferenceNumber,
        complainantRespondentTypeId: complainantRespondantTypeId,
        complainantRespondentSequenceNumber: complainantRespondantSequenceNumber,
        complainantRespondentNameEn: nameOutput,
        complainantRespondentNameLl: "",
        seniorCitizen: personalDetails.isSeniorCitizen,
        widow: personalDetails.isWidow,
        seriousAilments: personalDetails.seriousAilments,
        handicapTypeId: personalDetails.isDifferentlyAbled && personalDetails.handicapped.value ? personalDetails.handicapped.value : null,//+personalDetails.isDifferentlyAbled,
        nri: false,
        mobileNumber: parseInt(personalDetails.mobileNumber),
        phoneNumber: 0,
        emailId: personalDetails.email?.toUpperCase(),
        createdBy: userId,
        updatedBy: userId,
        remarks: "",
        addressDto: personalDetails.address.map((address: AddressDetailsModel): AddressDto => {
            return {
                addressDetailsId: address.addressId,
                addressTypeId: address.addressType.value,
                houseDoorFlatNumber: address.houseNumber?.toUpperCase(),
                landmarkLocality: address.landmark?.toUpperCase(),
                areaNameNumber: address.street?.toUpperCase(),
                postalPinCode: !address.nri ? parseInt(address.pinCode) : -1,
                districtId: !address.nri ? address.district.value : -1,
                stateId: !address.nri ? address.state.value : -1,
                countryId: address.country.value !== "" ? address.country.value : 76,
                locationTypeId: 1,
                nri: address.nri,
                stateOfNri: address.nri ? address.stateOfNri : undefined,
                districtOfNri: address.nri ? address.districtOfNri : undefined,
                pinCodeOfNri: address.nri ? address.pinCode : undefined,
            }
        }),
        advocateDto: [],
        partyId: personalDetails?.partyId ?? personalDetails?.partyId
    }
    if (personalDetails.advocateList && (caseType === caseTypeEnum.ConsumerCase || caseType === caseTypeEnum.CCPAAppeal || ccWithIa)) {
        // If personalDetails.advocate.value is an array of advocates
        generatedDetails.advocateDto = [
            ...generatedDetails.advocateDto,
            ...personalDetails.advocateList.map((advocate, index) => ({
                advocateId: advocate.advocateId,
                advocateSequenceNumber: index, // or some other logic for sequence number
                remarks: "",
            })),
        ];
    } else if (selectedAdvocate && selectedAdvocate) {
        const newAdvocateDtos = selectedAdvocate.map(advocate => ({
            advocateId: advocate.advocateId,
            advocateSequenceNumber: 0,
            remarks: "",
        })
        );

        generatedDetails.advocateDto = [
            ...generatedDetails.advocateDto,
            ...newAdvocateDtos,
        ];
    }


    return generatedDetails;
};

export const handleCaseTypePartyNames = (caseTypeId: number) => {
    if (
        caseTypeId === caseTypeEnum.FirstAppeal ||
        caseTypeId === caseTypeEnum.AppealExecution ||
        caseTypeId === caseTypeEnum.CCPAAppeal ||
        caseTypeId === caseTypeEnum.TransferApplication
    ) {
        return {
            complainantLabel: t("Label.Appellant"),
            respondentLabel: t("Label.Respondent"),
        };
    } else if (
        caseTypeId === caseTypeEnum.RevisionPetition ||
        caseTypeId === caseTypeEnum.SecondAppeal
    ) {
        return {
            complainantLabel: t("Label.Petitioner"),
            respondentLabel: t("Label.Respondent"),
        };
    } else {
        return {
            complainantLabel: t("Label.Complainant"),
            respondentLabel: t("Label.OppositeParty"),
        };
    }
};

export function replaceTwoKeysWithSwitch<T, K1 extends keyof T, K2 extends keyof T>(
    obj: T,
    key1: K1,
    key2: K2,
    switchValue: any
): ReplaceKeys<T, K1, K2, string, string> {
    const { [key1]: key1Value, [key2]: key2Value, ...rest } = obj;

    let newKey1: string;
    let newKey2: string;

    // Switch case to determine the new key names
    switch (switchValue) {
        case caseTypeEnum.FirstAppeal:
        case caseTypeEnum.AppealExecution:
        case caseTypeEnum.CCPAAppeal:
        case caseTypeEnum.TransferApplication:
        case caseTypeEnum.ReviewApplication:
            newKey1 = t("Label.Appellant");
            break;
        case caseTypeEnum.RevisionPetition:
        case caseTypeEnum.SecondAppeal:
            newKey1 = t("Label.Petitioner");
            break;
        default:
            newKey1 = key1 as string;
            break;
    }

    switch (switchValue) {
        case caseTypeEnum.InterlocutoryApplication:
        case caseTypeEnum.MiscellaneousApplication:
        case caseTypeEnum.ExecutionApplication:
        case caseTypeEnum.ReviewApplication:
        case caseTypeEnum.ConsumerCase:
            newKey2 = `${t('Label.OppositeParty')}(ies)`;
            break;
        case caseTypeEnum.FirstAppeal:
        case caseTypeEnum.SecondAppeal:
        case caseTypeEnum.RevisionPetition:
        case caseTypeEnum.AppealExecution:
        case caseTypeEnum.TransferApplication:
        case caseTypeEnum.CCPAAppeal:
        case caseTypeEnum.CaveatCases:
            newKey2 = `${t('Label.Respondent')}(s)`;
            break;
        default:
            newKey2 = key2 as string;
            break;
    }

    // Return the new object with the keys replaced
    return {
        ...(rest as Omit<T, K1 | K2>),
        [`${newKey1}(s)`]: key1Value,
        [newKey2]: key2Value
    } as unknown as ReplaceKeys<T, K1, K2, typeof newKey1, typeof newKey2>;
};

export const checkCaseNumberEligibility = (caseNumber: string, caseTyeId: number) => {
    let elegible = true;

    if (caseNumber.toLowerCase().includes('nc/')) {
        switch (caseTyeId) {
            case caseTypeEnum.FirstAppeal:
            case caseTypeEnum.SecondAppeal:
            case caseTypeEnum.RevisionPetition:
                elegible = false;
                break;

            default:
                elegible = true;
                break;
        }
    } else {
        elegible = true;
    }

    return elegible
};

export const getCaseTypeNameByCaseTypeId = (caseTypeId: number) => {
    const caseType = caseTypeList?.filter((type) => type.value === caseTypeId);

    return caseType[0].label
};

export function groupCases(data: IGetCaseListResponse[]): IgroupedCases {
    const caseMap = new Map();
    const groupedCases: ICaseWithAttachedCase[] = [];

    // Create a map of cases by referenceNumber
    data.forEach(item => {
        caseMap.set(item.referenceNumber, { ...item, attachedCases: [] });
    });

    // Populate the attachedCases arrays
    data.forEach(item => {
        if (item.previousFillingReferenceNumber !== null) {
            const parentCase = caseMap.get(item.previousFillingReferenceNumber);
            if (parentCase) {
                parentCase.attachedCases.push(caseMap.get(item.referenceNumber));
            } else {
                // If parent case is undefined, push the current case to groupedCases
                groupedCases.push(caseMap.get(item.referenceNumber));
            }
        }
    });

    // Filter out the main cases (those without previousFillingReferenceNumber) and include related cases
    caseMap.forEach(item => {
        if (item.previousFillingReferenceNumber === null && item.caseTypeId !== 10) {
            groupedCases.push(item);
        }
    });

    // list of scruitny cases for commission
    const scrutinyCommissionCaseList = getCommissionScruitnyCaseList(groupedCases);

    // list of approval cases for commission
    const { pendingWithPayment, pendingWithoutPayment } = getPendingWithPaymentCommissionCaseList(groupedCases);

    // list of reverted cases for commission
    const revertedCommissionCaseList = getCommissionRevertedCaseList(groupedCases);

    // list of reverted cases for consumer
    const revertedConsumerCaseList = getConsumerRevertedCaseList(groupedCases);

    // list of scrutiny case for consumer
    const scrutinyConsumerCaseList = getConsumerScrutinyCaseList(groupedCases);

    // list of pending for approval cases for consumer
    const pendingForApprovalCaseList = getConsumerPendingForApprovalCaseList(groupedCases);

    return {
        scrutinyCommissionCaseList,
        pendingWithPayment,
        pendingWithoutPayment,
        revertedCommissionCaseList,
        revertedConsumerCaseList,
        scrutinyConsumerCaseList,
        pendingForApprovalCaseList
    }
};

const getCommissionScruitnyCaseList = (groupedCases: ICaseWithAttachedCase[]): IScrutinyCaseList[] => {
    return groupedCases.map((caseList, index: number) => {
        const { referenceNumber, caseType, complainantNameEn, respondantNameEn, dateOfFilling, claimAmount, caseTypeId, caseReasonTypeId, caseReasonTypeDisplayText, attachedCases, defectTypeId } = caseList;
        return (
            {
                id: index + 1,
                referenceNumber: referenceNumber,
                CaseType: caseType?.toUpperCase(),
                Complainant: complainantNameEn?.toUpperCase(),
                Respondent: respondantNameEn?.toUpperCase(),
                // eslint-disable-next-line no-useless-concat
                ClaimAmount: claimAmount ? indianRupeeInput(claimAmount?.toString()).formattedValue + " " + "INR" : "N/A",
                FilingDate: DateTime.fromFormat(dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                caseTypeId: caseTypeId,
                previousCaseNumber: caseList.previousCaseNumber,
                previousFilingReferenceNumber: caseList.previousFillingReferenceNumber,
                caseReasonTypeId,
                caseReasonTypeDisplayText,
                attachedCases,
                defectTypeId
            }
        )
    });
};

const getPendingWithPaymentCommissionCaseList = (groupedCases: ICaseWithAttachedCase[]) => {
    const pendingWithPayment = groupedCases?.filter((value: IGetCaseListResponse) => value?.paymentAmount !== null).map((caseList, index: number) => {
        return {
            id: index + 1,
            ReferenceNumber: caseList.referenceNumber,
            CaseType: caseList.caseType,
            Complainant: caseList.complainantNameEn,
            Respondent: caseList.respondantNameEn,
            Amount: caseList.paidAmount,
            FilingDate: DateTime.fromFormat(caseList.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            PaymentDate: DateTime.fromFormat(caseList.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            caseTypeId: caseList.caseTypeId,
            previousCaseNumber: caseList.previousCaseNumber,
            previousFilingReferenceNumber: caseList.previousFillingReferenceNumber,
            caseReasonTypeId: caseList.caseReasonTypeId,
            caseReasonTypeDisplayText: caseList.caseReasonTypeDisplayText,
            caseNumber: caseList.caseNumber,
            paymentAmount: `${caseList.paymentAmount} INR`,
            attachedCases: caseList?.attachedCases
        }
    });

    const pendingWithoutPayment = groupedCases?.filter((value: IGetCaseListResponse) => value.paymentAmount == null).map((caseList, index: number) => {
        return {
            id: index + 1,
            ReferenceNumber: caseList.referenceNumber,
            CaseType: caseList.caseType,
            Complainant: caseList.complainantNameEn,
            Respondent: caseList.respondantNameEn,
            Amount: caseList.paidAmount,
            FilingDate: DateTime.fromFormat(caseList.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            PaymentDate: DateTime.fromFormat(caseList.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            caseTypeId: caseList.caseTypeId,
            previousCaseNumber: caseList.previousCaseNumber,
            previousFilingReferenceNumber: caseList.previousFillingReferenceNumber,
            caseReasonTypeId: caseList.caseReasonTypeId,
            caseReasonTypeDisplayText: caseList.caseReasonTypeDisplayText,
            caseNumber: caseList.caseNumber,
            paymentAmount: `N/A`,
            attachedCases: caseList?.attachedCases
        }
    });

    return { pendingWithPayment, pendingWithoutPayment }
};

const getCommissionRevertedCaseList = (groupedCases: ICaseWithAttachedCase[]): IRevertedInScrutinyDataTable[] => {
    return groupedCases.map((value, index: number) => {
        return {
            id: index + 1,
            referenceNumber: value.referenceNumber,
            CaseType: value.caseType,
            Complainant: value.complainantNameEn,
            Respondent: value.respondantNameEn,
            Amount: `${value.paidAmount} INR`,
            FilingDate: DateTime.fromFormat(value.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            caseTypeId: value.caseTypeId,
            attachedCases: value.attachedCases
        }
    })
};

const getConsumerRevertedCaseList = (groupedCases: ICaseWithAttachedCase[]): IRevertedInScrutinyCaseRows[] => {
    return groupedCases.map((value, index: number) => {
        return {
            id: index + 1,
            referenceNumber: value.referenceNumber,
            complainantNameEn: value.complainantNameEn,
            respondantNameEn: value.respondantNameEn,
            casefillingStageId: value.casefillingStageId,
            casefillingStageNameEn: value.caseStageNameEn,
            dateOfFilling: DateTime.fromFormat(value.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            caseTypeId: value.caseTypeId,
            previousCaseNumber: value.previousCaseNumber,
            reasonId: value.caseReasonTypeId,
            reasonText: value.caseReasonTypeDisplayText,
            caseType: value.caseType,
            attachedCases: value.attachedCases
        }
    })
};

const getConsumerScrutinyCaseList = (groupedCases: ICaseWithAttachedCase[]): IPendingForScrutinyCaseRows[] => {
    return groupedCases.map((value, index: number) => {
        return {
            id: index + 1,
            referenceNumber: value.referenceNumber,
            complainantNameEn: value.complainantNameEn,
            respondantNameEn: value.respondantNameEn,
            casefillingStageId: value.casefillingStageId,
            casefillingStageNameEn: value.caseStageNameEn,
            dateOfFilling: DateTime.fromFormat(value.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            caseTypeId: value.caseTypeId,
            attachedCases: value.attachedCases
        }
    });
};

const getConsumerPendingForApprovalCaseList = (groupedCases: ICaseWithAttachedCase[]): IPendingForApprovalCaseRows[] => {
    return groupedCases.map((value, index: number) => {
        return {
            id: index + 1,
            referenceNumber: value.referenceNumber,
            complainantNameEn: value.complainantNameEn,
            respondantNameEn: value.respondantNameEn,
            casefillingStageId: value.casefillingStageId,
            casefillingStageNameEn: value.caseStageNameEn,
            dateOfFilling: DateTime.fromFormat(value.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
            caseTypeId: value.caseTypeId,
            caseNumber: value.caseNumber,
            attachedCases: value.attachedCases
        }
    });
};

export const getDocumentDetailsFromGetApi = (caseFilingDocumentDetails: ICaseFillingDocumentDetailsResponseList[]): UploadFileModel[] => {
    const filesList = caseFilingDocumentDetails.map(
        (document: ICaseFillingDocumentDetailsResponseList) => {
            const file = b64toBlob(
                document.fileContent,
                "application/pdf",
                document.fileName
            );
            const reason = document?.selectedIaReason ? document.selectedIaReason : undefined
            return new UploadFileModel(
                document.documentId,
                document.documentName,
                [file],
                true,
                document.documentSequenceNumber,
                document.isMandatoryStatus,
                reason ? true : undefined,
                document.caseFilingDocumentDetailsId,
                reason,
            );
        }
    );
    return filesList;
};

export const getDocumentDetailsFromSaveApi = async (uploadedFiles: UploadFileModel[], response: ISaveCaseFillingDocumentResponse[]): Promise<ICaseFillingDocumentDetailsResponseList[]> => {
    return Promise.all(uploadedFiles
        .map(async (obj1, index) => {
            const matchedObj = response.find(obj2 => obj2.documentId === obj1.documentId); // Find matching object
            const caseFilingDocumentDetailsId = response[index]?.caseFilingDocumentDetailsId;

            return {
                caseFilingDocumentDetailsId: caseFilingDocumentDetailsId as number,
                filingReferenceNumber: matchedObj?.filingReferenceNumber as number,
                documentSequenceNumber: obj1?.documentSequenceNumber as number,
                documentUploadStageId: matchedObj?.documentUploadStageId as number,
                documentId: matchedObj?.documentId ? matchedObj?.documentId : obj1.documentId as number,
                documentReference: matchedObj?.documentReference,
                updatedAt: matchedObj?.updatedAt as string,
                remarks: matchedObj?.remarks as string,
                clientIpAddress: "",
                createdAt: matchedObj?.createdAt as string,
                activeStatus: obj1?.activeStatus as boolean,
                documentPath: matchedObj?.documentPath as string,
                fileName: obj1.files[0]?.name as string,
                documentName: obj1.documentNameEn as string,
                fileContent: obj1.documentId === 7 ? await fileToBase64(obj1.files[0]) as string : matchedObj?.documentBase64 as string,
                editFlag: "",
                isMandatoryStatus: obj1.isMandatoryStatus as boolean,
                selectedIaReason: obj1.documentId === 7 ? obj1.selectedIaReason : undefined,
                caseNumber: "",
            };
        }));
};

export const convertToIaDocumentListForCompleteDoc = (inputArray: ICaseFillingDocumentDetailsResponseList[][]): IIaCaseDocumentListForCompleteDoc[] => {
    // Create an empty object to group documents by caseNumber
    const groupedDocuments: any = {};

    // Iterate through the outer array
    inputArray.forEach((innerArray: any) => {
        // Iterate through each object in the inner array
        innerArray.forEach((item: any) => {
            const caseNumber = item.caseNumber;

            // If the caseNumber doesn't exist in the groupedDocuments, create it
            if (!groupedDocuments[caseNumber]) {
                groupedDocuments[caseNumber] = {
                    caseNumber: caseNumber,
                    documentList: []
                };
            }

            // Push the document details into the documentList
            groupedDocuments[caseNumber].documentList.push({
                caseFilingDocumentDetailsId: item.caseFilingDocumentDetailsId,
                documentName: item.documentName,
                fileContent: item.fileContent,
                sequenceNumber: item.documentSequenceNumber,
                documentId: item.documentId // optional
            });
        });
    });

    // Convert the groupedDocuments object back to an array
    return Object.values(groupedDocuments);
};

export const handleGetDocumentsWithoutContent = async (caseNumber: string, commissionId: number) => {
    const consolidatedFiles = await CommissionCaseService.getAllConsolidatedFile(caseNumber, commissionId, false);

    const caseFilingDocuments: IJudgeCaseDocumentList[] = [];
    const replyDocumentList: IJudgeCaseDocumentList[] = [];
    const rejoinderDocumentList: IJudgeCaseDocumentList[] = [];
    const subsequentDocumentList: IJudgeCaseDocumentList[] = [];


    let replySequenceIndex = 1;
    let rejoinderSequenceIndex = 1;

    // Sets to track seen document labels
    const seenReplyLabels = new Set<string>();
    const seenRejoinderLabels = new Set<string>();
    const seenSubseQuentLabels = new Set<string>();

    if (consolidatedFiles.caseFillingDocumentDetailsResponseList.length) {
        const allCaseFilingDocuments = consolidatedFiles.caseFillingDocumentDetailsResponseList
            .sort((a, b) => a.documentSequenceNumber - b.documentSequenceNumber);

        allCaseFilingDocuments.filter((doc) => doc.documentId !== 0).forEach((document) => {
            const caseList: IJudgeCaseDocumentList = {
                caseFilingDocumentDetailsId: document.caseFilingDocumentDetailsId,
                documentName: document.documentName,
                sequenceNumber: document.documentSequenceNumber,
                documentId: document.documentId,
            };
            caseFilingDocuments.push(caseList);
        });
    }

    if (consolidatedFiles.caseFillingReplyRejoinderDocumentDetailsList.length) {
        consolidatedFiles.caseFillingReplyRejoinderDocumentDetailsList
            .flatMap((replyRejoinderList) => replyRejoinderList) // Flatten the nested arrays
            .forEach((replyrejoinderDocument) => {

                const isReplyFlag = replyrejoinderDocument.replyRejoinderFlag === 1;
                const isRejoinderFlag = replyrejoinderDocument.replyRejoinderFlag === 2;
                const isSubseQuentFlag = replyrejoinderDocument.replyRejoinderFlag === 3

                if (isReplyFlag && !seenReplyLabels.has(replyrejoinderDocument.documentLabel)) {
                    const list: IJudgeCaseDocumentList = {
                        caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                        documentName: replyrejoinderDocument.documentLabel,
                        sequenceNumber: replySequenceIndex++,
                        documentId: replyrejoinderDocument.documentId
                    };
                    replyDocumentList.push(list);
                    seenReplyLabels.add(replyrejoinderDocument.documentLabel);
                } else if (isRejoinderFlag && !seenRejoinderLabels.has(replyrejoinderDocument.documentLabel)) {
                    const list: IJudgeCaseDocumentList = {
                        caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                        documentName: replyrejoinderDocument.documentLabel,
                        sequenceNumber: rejoinderSequenceIndex++,
                        documentId: replyrejoinderDocument.documentId
                    };
                    rejoinderDocumentList.push(list);
                    seenRejoinderLabels.add(replyrejoinderDocument.documentLabel);
                } else if (isSubseQuentFlag && !seenSubseQuentLabels.has(replyrejoinderDocument.documentLabel)) {
                    const list: IJudgeCaseDocumentList = {
                        caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                        documentName: replyrejoinderDocument.documentLabel,
                        sequenceNumber: rejoinderSequenceIndex++,
                        documentId: replyrejoinderDocument.documentId
                    };
                    subsequentDocumentList.push(list);
                    seenSubseQuentLabels.add(replyrejoinderDocument.documentLabel);
                }
            });
    } else {
        console.warn('No documents found in caseFillingReplyRejoinderDocumentDetailsList');
    }

    return {
        caseFilingDocuments,
        replyDocumentList,
        rejoinderDocumentList,
        subsequentDocumentList,
    }
};

export const handleGetDocumentsWithContent = async (caseNumber: string, commissionId: number) => {

    loaderService.setLoading(true);
    const consolidatedFiles = await CommissionCaseService.getAllConsolidatedFile(caseNumber, commissionId as number);
    const documentArray: Uint8Array[] = [];
    const documentLabelList: ICaseDocumentList[] = [];
    const replyDocumentList: ICaseDocumentList[] = [];
    const rejoinderDocumentList: ICaseDocumentList[] = [];
    let iaDocsList: IIaCaseDocumentListForCompleteDoc[] = [];

    let replySequenceIndex = 1;
    let rejoinderSequenceIndex = 1;

    // Sets to track seen document labels
    const seenReplyLabels = new Set<string>();
    const seenRejoinderLabels = new Set<string>();
    const seenSubseQuentLabels = new Set<string>();
    if (consolidatedFiles.caseFillingDocumentDetailsResponseList.length) {
        console.log(consolidatedFiles.caseFillingDocumentDetailsResponseList);
        const allCaseFilingDocuments = consolidatedFiles.caseFillingDocumentDetailsResponseList
            .sort((a, b) => a.documentSequenceNumber - b.documentSequenceNumber)
            .map((document) => {
                document?.fileContent && documentArray.push(convertBase64ToUInt8(document?.fileContent));
                const list: ICaseFillingDocumentDetailsResponseList = {
                    ...document,
                    filingReferenceNumber: document.filingReferenceNumber,
                    updatedAt: "",
                    createdAt: "",
                    activeStatus: document.activeStatus,
                    isMandatoryStatus: false
                };
                return list
            });

        allCaseFilingDocuments.filter((doc) => doc.documentId !== 0).forEach((document) => {
            const caseList: ICaseDocumentList = {
                caseFilingDocumentDetailsId: document.caseFilingDocumentDetailsId,
                documentName: document.documentName,
                fileContent: document.fileContent,
                sequenceNumber: document.documentSequenceNumber,
                documentId: document.documentId,
            };
            documentLabelList.push(caseList);
        });

        // ia document list details
        if (consolidatedFiles?.attachedCaseDocuments) {
            // push all the documents to document array
            consolidatedFiles?.attachedCaseDocuments?.IA?.forEach((casesDocs, index) => {
                casesDocs?.length > 0 && casesDocs?.forEach((document) => {
                    documentArray?.push(convertBase64ToUInt8(document?.fileContent));
                });
            });

            // set all the document of Ia to Ia document list
            iaDocsList = convertToIaDocumentListForCompleteDoc(consolidatedFiles?.attachedCaseDocuments?.IA as ICaseFillingDocumentDetailsResponseList[][]);
        }

        // Process reply and rejoinder documents
        if (consolidatedFiles.caseFillingReplyRejoinderDocumentDetailsList.length) {
            consolidatedFiles.caseFillingReplyRejoinderDocumentDetailsList
                .flatMap((replyRejoinderList) => replyRejoinderList) // Flatten the nested arrays
                .forEach((replyrejoinderDocument) => {
                    replyrejoinderDocument?.documentList?.forEach((docs) => {
                        documentArray.push(convertBase64ToUInt8(docs));
                    });

                    const isReplyFlag = replyrejoinderDocument.replyRejoinderFlag === 1;
                    const isRejoinderFlag = replyrejoinderDocument.replyRejoinderFlag === 2;
                    const isSubseQuentFlag = replyrejoinderDocument.replyRejoinderFlag === 3

                    if (isReplyFlag && !seenReplyLabels.has(replyrejoinderDocument.documentLabel)) {
                        const list: ICaseDocumentList = {
                            caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                            documentName: replyrejoinderDocument.documentLabel,
                            fileContent: replyrejoinderDocument.documentList[0],
                            sequenceNumber: replySequenceIndex++,
                        };
                        replyDocumentList.push(list);
                        seenReplyLabels.add(replyrejoinderDocument.documentLabel);
                    } else if (isRejoinderFlag && !seenRejoinderLabels.has(replyrejoinderDocument.documentLabel)) {
                        const list: ICaseDocumentList = {
                            caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                            documentName: replyrejoinderDocument.documentLabel,
                            fileContent: replyrejoinderDocument.documentList[0],
                            sequenceNumber: rejoinderSequenceIndex++,
                        };
                        rejoinderDocumentList.push(list);
                        seenRejoinderLabels.add(replyrejoinderDocument.documentLabel);
                    } else if (isSubseQuentFlag && !seenSubseQuentLabels.has(replyrejoinderDocument.documentLabel)) {
                        const list: ICaseDocumentList = {
                            caseFilingDocumentDetailsId: replyrejoinderDocument.replyRejoinderId,
                            documentName: replyrejoinderDocument.documentLabel,
                            fileContent: replyrejoinderDocument.documentList[0],
                            sequenceNumber: rejoinderSequenceIndex++,
                        };
                        documentLabelList.push(list);
                        seenSubseQuentLabels.add(replyrejoinderDocument.documentLabel);
                    }
                });
        } else {
            console.warn('No documents found in caseFillingReplyRejoinderDocumentDetailsList');
        }
    }

    return {
        documentArray,
        documentLabelList,
        replyDocumentList,
        rejoinderDocumentList,
        iaDocsList
    }
}