export class ValidationRegExp {
    static readonly ONLY_OTP_NUM = /^[0-9]{6,6}$/;
    static readonly ONLY_MOBILE_NUM = /^$|^[6-9]\d{1,3}?\d{3,4}?\d{5}$/;
    static readonly ONLY_PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    static readonly ONLY_ALPHABETS = /^[a-zA-Z,./'&\-#\s]{1,}(?: [a-zA-Z,./'&\-#]+){0,2}$/;
    static readonly ONLY_EMAIL = /^$|^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    static readonly ONLY_NUMBER = /^[0-9]*$/
    static readonly NUMBER_AND_COMMA = /^[-,0-9]+$/;
    static readonly MAILFORMAT = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10,10}$)+$/;
    static readonly NAMES = /^[A-Za-z&'/.# ]+$/;
    static readonly ALPHABETS_SPACES_NUMBERS_AND_SPECIAL = /^[a-zA-Z0-9\s,-/.#]*$/;
}