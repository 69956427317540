import React, { useEffect, useState } from 'react';
import { BasicBreadcrumbs } from '../../../components';
import { convertBase64ToUInt8, navigateToDashboardPath } from '../../../utils/Helper';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { CauselistResponse } from '../../../models/causelist/CauselistModel';
import { ICaseFillingDocumentDetailsResponseList, IGetDocumentResponse } from '../../../models/cases/consumerCases/IConsumerCaseModel';
import { ConsumerCaseServices } from '../../../services/CaseService/ConsumerCaseService/ConsumerCaseServices';
import { toast } from 'react-toastify';
import CauseListDragDropHtml from '../../../utils/HtmlFilesForPdf/CauseListDragGDropHtml';
import ButtoBack from './Button';
import ConfonetOverlay from '../../../components/controls/ConfonetOverlay';
import ConfonetPdfViewer from '../../../components/controls/ConfonetPdfViewer';
import CauseListHtmlpdf from '../../../utils/HtmlFilesForPdf/causeListHtmlpdf';
import { DateTime } from 'luxon';

interface ViewPageProps {
    courtId: number;
    courtName: string;
    selectedDate: string;
}

export function ViewPage({ courtId, courtName, selectedDate }: ViewPageProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const responseData = location.state as CauselistResponse[];
    const originalDate = responseData?.[0]?.dateOfHearing;
    console.log('originalDate', originalDate);

    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));

    const convertIntoDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
        return formattedDate;
    };

    const handleGetDocument = async (filingReferenceNumber: number, docName: string) => {
        const response: IGetDocumentResponse =
            await ConsumerCaseServices.getCaseFillingDocumentDetails(
                filingReferenceNumber
            );
        if (
            response &&
            Object.keys(response).length > 0 &&
            response?.caseFillingDocumentDetailsResponseList?.length > 0
        ) {
            const documentItem = response?.caseFillingDocumentDetailsResponseList?.filter((document: ICaseFillingDocumentDetailsResponseList) => document.documentName.toLowerCase().includes(docName.toLowerCase()));
            if (documentItem?.length) {
                setDocumentContent(convertBase64ToUInt8(documentItem[0].fileContent));
            } else {
                toast.warn('No document available for the selected item!!');
            }
        } else {
            toast.warn('No document available for the selected item!!');
        }
    };

    const closeOverlay = () => {
        if (documentContent.length !== 1) {
            setDocumentContent(new Uint8Array([255]));
        }
    };

    return (
        <section className="section margin-top-20">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-sm-7 margin-bottom-30">
                        <BasicBreadcrumbs items={[
                            { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
                            { to: '', label: t("Label.View") },
                        ]} />
                    </div>
                </div>
            </div>

            <CauseListHtmlpdf
                causelistResponse={responseData}
                selectedDate={DateTime.fromFormat(originalDate, "yyyy-MM-dd")}
                handleGetDocument={handleGetDocument}
            />
            <ConfonetOverlay
                isOpen={documentContent?.length > 1}
                onClose={closeOverlay}
            >
                {documentContent?.length > 1 ?
                    <ConfonetPdfViewer
                        fileUrl={documentContent}
                    /> : <></>
                }
            </ConfonetOverlay>
            <div className='right-corner-button'>
                <ButtoBack />
            </div>
        </section>
    );
}

