import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BasicBreadcrumbs, BasicButton, ConfonetCard, ConfonetIconButton, DataTable, PreviewCard } from "../../../components";

import { GridCellParams, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { JUDGE_BENCHES_LIST, JUDGE_CASE_PREVIEW_ATTACHED_CASE, JUDGE_DASHBOARD } from "../../../navigation/CONSTANTS";
import SearchBar from "./SearchBar";
import DataTableWithSearchAndTitle from "../../../components/controls/ReusableComponents/DataTableWithSearchAndTitle";
import ButtoBack from "./Button";


function IaFilledDocu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const handleNavigate = (caseNumber: string) => {
    navigate(JUDGE_CASE_PREVIEW_ATTACHED_CASE, { state: { caseNumber } });
  };

  const judgeDataList: GridColDef[] = [
    { field: "id", headerName: t("Label.Id"), maxWidth: 70, flex: 1 },
    {
      field: "caseNumber",
      headerName: t("IA No."),
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <p
              onClick={() => handleNavigate(params.row.caseNumber)}
              style={{
                color: "darkblue",
                textDecoration: "underline",
                cursor: "pointer"
              }}
            >
              {params.row.caseNumber}
            </p>
          </>
        )
      }
    },
    { field: "caseFilingDate", headerName: t("Label.DateOfFiling"), flex: 1 },
    { field: "complainantName", headerName: t("Filed By"), flex: 1 },
    { field: "purpose", headerName: t("Purpose"), flex: 1 },
    { field: "status", headerName: t("Status"), flex: 1 },
    { field: "lastHearingDate", headerName: t("Label.DateOfLastHearing"), flex: 1 },

    {
      field: "Action",
      headerName: t("Label.Action"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <div className="d-flex">
              <BasicButton
                className="btn-custom-view p-0 min-width-auto margin-right-10"
                text=""
                variant="text"
                onClick={() => handleNavigate(params.row.caseNumber)}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="container-fluid">
      <div className="margin-top-25">
        <div className="row align-items-center">
          <div className="col-lg-9 col-sm-7 margin-bottom-30">
            <BasicBreadcrumbs items={[
              { to: JUDGE_BENCHES_LIST, label: t("Breadcrumb.Benchonce") },
              { to: '', label: t("Label.CaseDetails") },
            ]} />
          </div>
        </div>
         <SearchBar />
        <DataTableWithSearchAndTitle
          title={state.title}
          row={state.caseDataRow}
          column={judgeDataList}
        />
      </div>
      <div style={{ clear: 'both', marginTop: '25px', textAlign: 'right' }}>
         <ButtoBack />
      </div>
    </div>
  )
}

export default IaFilledDocu
