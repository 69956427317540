import { DateTime } from "luxon";
import { IDropdown } from "../../common/IDropdown";
export class CourtroomHearingFormViewModel {
    constructor(
        public dateOfHearing: DateTime,
        public causelistCategory: IDropdown,
        public courtNumber: IDropdown

    ) {
        this.dateOfHearing = dateOfHearing;
        this.causelistCategory = causelistCategory;
        this.courtNumber = courtNumber;
    }
    static readonly init = () => new CourtroomHearingFormViewModel(
        DateTime.now().plus({ days: 15 }),
        { label: "", value: "" },
        { label: "", value: "" }
    );
}

export class CourtroomHearingModel {
    constructor(
        public filingReferenceNumber: number,
        public commissionId: number | null | undefined,
        public dateOfHearing: string,
        public causeListCategory: number,
        public courtId: number
    ) {
        this.filingReferenceNumber = filingReferenceNumber;
        this.commissionId = commissionId;
        this.dateOfHearing = dateOfHearing;
        this.causeListCategory = causeListCategory;
        this.courtId = courtId;
    }
}

export interface IGetAllCourtResponse {
    courtId: number;
    courtNameEn: string;
}


export interface IGetCourtDetailsResponse {
    courtDetailsMap: object
}

export interface IGetAllCauselistCategoryResponse {
    causelistTypeId: number;
    causelistTypeNameEn: string;
}

export interface IGetAllCaseStageeResponse {
    caseStageid: number;
    caseStageNameEn: string;
}

export interface IGetAllAdmissionStatusResponse {
    admissionStatusId: number;
    admissionStatusNameEn: string;
}

export interface IGetAllNoticeNumberResponse {
    noticeTypeId: number,
    noticeTypeNameEn: string,
    noticeTypeNameLl: string,
    noticeTypeFormat: string,
    authorisedSignatory: string,
    caseTypeId: number
}

export interface IGetCourtroomDetailsByIdResponse {
    filingReferenceNumber: number,
    hearingSequenceNumber: number,
    caseNumber: string,
    dateOfHearing: DateTime,
    dateOfNextHearing: string,
    caseStageId: number,
    courtRoomId: number,
    activeStatus: number,
    causelistTypeId: number,
    noticeTypeId: number,
    admissionStatusId: number,
    admissionDate: string,
    commissionId: number
}

export class UploadDailyOrderFormViewModel {
    constructor(
        public dailyOrderGenerateMethod: string,
        public proceedingText: string,
        public dailyOrderOrJudgementFlag: boolean,
        public dailyOrderFile: File[]
    ) {
        this.dailyOrderGenerateMethod = dailyOrderGenerateMethod;
        this.proceedingText = proceedingText;
        this.dailyOrderOrJudgementFlag = dailyOrderOrJudgementFlag;
        this.dailyOrderFile = dailyOrderFile
    }

    static readonly init = () => new UploadDailyOrderFormViewModel(
        "", "", false, []
    );
}

export interface UploadDailyOrderPayload {
    fillingReferenceNumber: number
    hearingSequenceNumber: number
    proceedingText: string
    proceedingTextDate: string
    dailyOrderUploadedBy: number
    dailyOrderAvailabilityStatusId: number
    dailyOrderOrJudgementFlag: number
}

export class JudgementFormViewModel {
    constructor(
        public judgementMethod: string,
        public judgementDate: DateTime,
        public judgementText: string,
        public judgementAvailabilityStatusId: boolean,
        public judgementFile: File[],

    ) {
        this.judgementMethod = judgementMethod;
        this.judgementDate = judgementDate;
        this.judgementText = judgementText;
        this.judgementAvailabilityStatusId = judgementAvailabilityStatusId;
        this.judgementFile = judgementFile
    }

    static readonly init = () => new JudgementFormViewModel(
        "",
        DateTime.now(),
        "",
        false,
        []
    );
}
export class CaseNumberSearchModel {
    constructor(
        public caseNumber: string
      ) {
        this.caseNumber = caseNumber;
      }
    
      static readonly init = () => new CaseNumberSearchModel("");
}

export class CourtroomModificationModel {
    constructor(
        public caseNumber: string,
        public dateOfHearing: string
      ) {
        this.caseNumber = caseNumber;
        this.dateOfHearing = dateOfHearing;
      }
    
      static readonly init = () => new CourtroomModificationModel("", "");
}

export interface IGetCourtRoomModificationPayload {
    caseNumber: string,
    dateOfHearing: string,
}

export interface IGetCourtRoomDateOfHearingResponse {
    dateOfHearing: string,
    hearingSequenceNumber: number,
    filingReferenceNumber: number,
    caseNumber: string,
    dateOfNextHearing: string,
    caseStageId: number,
    complainant: string,
    respondent: string,
    caseStageNameEn: string,
    dailyOrderAvailabilityStatusId: number,
    isMaster: number,
    iaCaseList: string[]
}

export interface IGetAllHearingModeResponse {
    hearingModeTypeId: number;
    hearingModeTypeNameEn: string;
}

export interface IGetAllDisposalTypeResponse {
    disposalMethodTypeId: number;
    disposalMethodTypeNameEn: string;
}


export class UpdateBenchModel {
    constructor(
        public courtNumber: IDropdown,
      ) {
        this.courtNumber = courtNumber;
      }
    
      static readonly init = () => new UpdateBenchModel({ label: "", value: "" });
}


export class UpdateCourtroomCaseModel {
    constructor(
        public courtNumber: IDropdown,
        public dateOfNextHearing: DateTime,
        public caseStage: IDropdown,
        public causelistCategory: IDropdown,
      ) {
        this.courtNumber = courtNumber;
        this.dateOfNextHearing = dateOfNextHearing;
        this.caseStage = caseStage;
        this.causelistCategory = causelistCategory;
      }
    
      static readonly init = () => new UpdateCourtroomCaseModel(
        { label: "", value: "" },
        DateTime.now(),
        { label: "", value: "" },
        { label: "", value: "" },
    );
}


export interface IUpdateBenchOrCourtroomPayload {
    caseNumber: string,
    courtId: number,
    commissionId?: number,
}

export interface IUpdateCourtroomCasePayload {
    caseNumber: string,
    courtId: number,
    caseStageId: number,
    causeListTypeId: number,
    dateOfNextHearing: string,
    commissionId?: number,
}


export interface IGetCaseDetailsForBenchResponse {
    filingReferenceNumber: number,
    caseNumber: string,
    commissionId: number,
    courtRoom: number,
    caseFilingDate: string,
    complainantName: string,
    respondentName: string,
    caseTypeId: number
}


export interface IGetCaseDetailResponse {
    filingReferenceNumber: number,
    caseNumber: string,
    commissionId: number,
    courtRoom: number,
    complainantName: string,
    respondentName: string,
    causeListTypeName: string,
    causeListTypeId: number,
    caseStageName: string,
    caseStageId: number,
    caseTypeId: number,
    caseFilingDate: string,
    dateOfHearing: string,
    dateOfNextHearing: string,
}

export interface IGetCaseDetailPayload {
    caseNumber: string,
    commissionId: number | undefined,
}