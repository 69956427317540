import { FormikProps, useFormik } from "formik";
import { t } from "i18next";
import { SetStateAction } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AppealExecutionCaseDetailsViewModel,
  CCPAAppealCaseDetailsViewModel,
  FaSaRpCaveatCaseDetailsViewModel,
} from "../models/cases/appealTypeCase/CaseDetailsViewModel";
import {
  IInterlocutoryApplicationCaseDetailsRequest,
  InterlocutoryApplicationCaseDetailsViewModel,
} from "../models/cases/interlocutoryApplicationCase/InterlocutoryApplicationCaseDetailsModel";
import {
  ITACaseDetailsRequest,
  TransferApplicationCaseDetailsViewModel,
} from "../models/cases/transferApplicationCase/TransferpplicationCaseDetailsModel";
import { RootState } from "../redux";
import { AppealTypeCaseServices } from "../services/CaseService/ConsumerCaseService/AppealTypeCaseService/AppealTypeCaseService";
import { InterlocutoryCaseService } from "../services/CaseService/ConsumerCaseService/InterlocutoryCaseService/InterlocutoryCaseService";
import { TransferApplicationService } from "../services/CaseService/ConsumerCaseService/TransferApplicationService/TransferApplicationService";
import { caseTypeEnum, commissionTypeEnum } from "../utils/Enum";
import InterLocutoryApplicationCaseDetails, {
  interlocutoryApplicationCaseDetailsValidationSchema,
} from "../pages/Consumer/InterlocutoryApplicationCase/components/stepperComponents/InterLocutoryApplicationCaseDetails";
import TransferApplicationCaseDetails, {
  transferApplicationCaseDetailsSchema,
} from "../pages/Consumer/TransferApplicationCase/components/TransferApplicationCaseDetail";
import {
  AppealExecutionCaseDetails,
  appealExecutionCaseDetailsValidationSchema,
} from "../pages/Consumer/AppealTypeCases/components/stepperComponents/appealTypeCaseDetails/AppealExecutionCaseDetails";
import {
  CCPAAppealCaseDetails,
  CPAAppealCaseDetailsValidationSchema,
} from "../pages/Consumer/AppealTypeCases/components/stepperComponents/appealTypeCaseDetails/CCPAAppealCaseDetails";
import {
  FaSaRpCaveatCaseDetails,
  commonFaSaRpCaveatCaseDetailsValidationSchema,
} from "../pages/Consumer/AppealTypeCases/components/stepperComponents/appealTypeCaseDetails/FaSaRpCaveatCaseDetails";

interface IUseCaseSpecificData {
  caseTypeId: number;
  handleNext: () => void;
  setNewFilingReferenceNumber: (value: SetStateAction<number>) => void;
  preReferenceNumber?: number;
  preCaseNumber?: string;
  preCaseTypeId?: number;
  preCommissionId?: number;
  newFilingReferenceNumber?: number;
  commisionTypeId?: number;
  preCommissionName?: string;
  setFromDraft?: (value: SetStateAction<boolean>) => void;
}

export const useCaseSpecificData = ({
  caseTypeId,
  handleNext,
  setNewFilingReferenceNumber,
  preReferenceNumber,
  preCaseNumber,
  preCaseTypeId,
  preCommissionId,
  newFilingReferenceNumber,
  commisionTypeId,
  preCommissionName,
  setFromDraft
}: IUseCaseSpecificData) => {
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  const { clientIpAddress } = useSelector((state: RootState) => state.clientIp);
  const handlePreCaseTypeNames = (preCaseTypeId: number) => {
    if (
      preCaseTypeId === caseTypeEnum.FirstAppeal ||
      preCaseTypeId === caseTypeEnum.AppealExecution ||
      preCaseTypeId === caseTypeEnum.CCPAAppeal ||
      preCaseTypeId === caseTypeEnum.SecondAppeal
    ) {
      return {
        complainantLabel: t("Label.Appellant"),
        respondentLabel: t("Label.Respondent"),
        complainantOrRespondentLabel: t("Label.AppellantOrRespondent"),
        additionalComplainantLabel: t("Label.AdditionalAppellant"),
      };
    } else if (
      preCaseTypeId === caseTypeEnum.RevisionPetition ||
      preCaseTypeId === caseTypeEnum.TransferApplication
    ) {
      return {
        complainantLabel: t("Label.Petitioner"),
        respondentLabel: t("Label.Respondent"),
        complainantOrRespondentLabel: t("Label.PetitionerOrRespondent"),
        additionalComplainantLabel: t("Label.AdditionalPetitioner"),
      };
    }else if (
      preCaseTypeId === caseTypeEnum.ExecutionApplication
    ) {
      return {
        complainantLabel: t("Label.DecreeHolder"),
        respondentLabel: t("Label.JudgementDebtor"),
        complainantOrRespondentLabel: t("Label.DecreeHolderOrJudgementDebtor"),
        additionalComplainantLabel: t("Label.AdditionalDecreeHolder"),
      };
    }else if (
      preCaseTypeId === caseTypeEnum.ReviewApplication
    ) {
      return {
        complainantLabel: t("Label.Applicant"),
        respondentLabel: t("Label.Respondent"),
        complainantOrRespondentLabel: t("Label.ApplicantOrRespondent"),
        additionalComplainantLabel: t("Label.AdditionalApplicant"),
      };
    } else {
      return {
        complainantLabel: t("Label.Complainant"),
        respondentLabel: t("Label.OppositeParty"),
        complainantOrRespondentLabel: t("Label.ComplainantOrOppositeParty"),
        additionalComplainantLabel: t("Label.AdditionalComplainant"),
      };
    }
  };

  // Handle save case detail API
  const handleFaSaRpCaveatCaseDetailSubmit = async (data: FaSaRpCaveatCaseDetailsViewModel) => {
    const dateStringImpunge = data.ImpungedOrderDate ? data.ImpungedOrderDate.toFormat("dd-MM-yyyy") : undefined;
    const dateInterim = data.DateOfInterim ? data.DateOfInterim.toFormat("dd-MM-yyyy") : undefined;

    let caseDetailData = {
      caseNumberOrfilingReferenceNumber: preReferenceNumber?.toString() || newFilingReferenceNumber?.toString(),
      caseTypeId,
      remarks: commonFaSaRpCaveatFormik.values.caseSummary,
      caseFiledBy: userDetailsData.userId,
      clientIpAddress,
      dDno: data.dDno ? data.dDno.replaceAll(",", "") : undefined,
      bankId: data.bankId ? data.bankId.value : undefined,
      branchId: data.branchId ? data.branchId.value : undefined,
      amount: data.amount ? +data.amount.replaceAll(',', '') : undefined,
      ImpungedOrderDate: dateStringImpunge,
      DateOfInterim : dateInterim,

    };
    const saveCaseDetailsResponse =
      await AppealTypeCaseServices.saveCaseDetailsSubmit(caseDetailData);
    if (saveCaseDetailsResponse) {
      setNewFilingReferenceNumber(
        saveCaseDetailsResponse.filingReferenceNumber
      );
      setFromDraft && setFromDraft(saveCaseDetailsResponse.existing);
      toast.success(`Your case details have been successfully submitted`);
      handleNext();
    }
  };

  const commonFaSaRpCaveatFormik = useFormik({
    initialValues: FaSaRpCaveatCaseDetailsViewModel.init(),
    validationSchema: commonFaSaRpCaveatCaseDetailsValidationSchema,
    onSubmit: handleFaSaRpCaveatCaseDetailSubmit,
  });

  const handleAppealExecutionCaseDetailSubmit = async () => {
    let caseDetailData = {
      caseNumberOrfilingReferenceNumber: preReferenceNumber?.toString(),
      caseTypeId,
      remarks: appealExecutionFormik.values.caseSummary,
      caseFiledBy: userDetailsData.userId,
      clientIpAddress,
    };
    const saveCaseDetailsResponse =
      await AppealTypeCaseServices.saveCaseDetailsSubmit(caseDetailData);
    if (saveCaseDetailsResponse) {
      setNewFilingReferenceNumber(
        saveCaseDetailsResponse.filingReferenceNumber
      );
      toast.success(`Your case details have been successfully submitted`);
      handleNext();
    }
  };

  const appealExecutionFormik = useFormik({
    initialValues: AppealExecutionCaseDetailsViewModel.init(),
    validationSchema: appealExecutionCaseDetailsValidationSchema,
    onSubmit: handleAppealExecutionCaseDetailSubmit,
  });

  const handleCCPAAppealCaseDetailSubmit = () => {
    handleNext();
  };

  const cCPAAppealFormik = useFormik({
    initialValues: CCPAAppealCaseDetailsViewModel.init(),
    validationSchema: CPAAppealCaseDetailsValidationSchema,
    onSubmit: handleCCPAAppealCaseDetailSubmit,
  });

  const handleIACaseDetailSubmit = async () => {
    const caseDetailsData: IInterlocutoryApplicationCaseDetailsRequest = {
      caseFiledBy: userDetailsData.userId,
      caseReasonTypeDisplayText:
        interlocutoryApplicationFormik.values.reason.label,
      caseReasonTypeId: parseInt(
        interlocutoryApplicationFormik.values.reason.value
      ),
      previousCaseNumber: preCaseNumber as string,
      previousCaseTypeId: preCaseTypeId as number,
      previousFilingReferenceNumber: preReferenceNumber as number,
      previousCommissionId: preCommissionId as number,
      remarks: interlocutoryApplicationFormik.values.caseSummary,
      caseTypeId,
      clientIpAddress,
    };
    const response = await InterlocutoryCaseService.saveCaseDetailsSubmit(
      caseDetailsData
    );
    if (response && Object.keys(response).length) {
      setNewFilingReferenceNumber(response.filingReferenceNumber);
      toast.success(`Your case details have been successfully submitted`);
      handleNext();
    }
  };

  const interlocutoryApplicationFormik = useFormik({
    initialValues: InterlocutoryApplicationCaseDetailsViewModel.init(),
    validationSchema: interlocutoryApplicationCaseDetailsValidationSchema,
    onSubmit: handleIACaseDetailSubmit,
  });

  const handleTransferCaseDetailSubmit = async () => {
    if (
      commisionTypeId === commissionTypeEnum.DISTRICT_COMMISSION &&
      !transferApplicationFormik.values.toDistrict.value
    ) {
      transferApplicationFormik.setFieldError(
        "toDistrict",
        "To District is required"
      );
      return;
    }
    const caseDetailsData: ITACaseDetailsRequest = {
      previousFilingReferenceNumber: preReferenceNumber as number,
      previousCaseNumber: preCaseNumber as string,
      previousCaseTypeId: preCaseTypeId as number,
      caseFiledBy: userDetailsData.userId,
      previousCommissionId: preCommissionId as number,
      destinationCommissionId:
        commisionTypeId === commissionTypeEnum.DISTRICT_COMMISSION
          ? transferApplicationFormik.values.toDistrict.value
          : transferApplicationFormik.values.toState.value,
      remarks: transferApplicationFormik.values.caseSummary,
      clientIpAddress,
      caseTypeId
    };
    const response = await TransferApplicationService.saveCaseDetailsSubmit(
      caseDetailsData
    );
    if (response && Object.keys(response).length) {
      setNewFilingReferenceNumber(response.fillingReferenceNumber);
      toast.success(`Your case details have been successfully submitted`);
      handleNext();
    }
  };

  const transferApplicationFormik = useFormik({
    initialValues: TransferApplicationCaseDetailsViewModel.init(),
    validationSchema: transferApplicationCaseDetailsSchema,
    onSubmit: handleTransferCaseDetailSubmit,
  });

  let preDetailsLabel = t("Label.ImpugnedOrderDetails")
  let complainantLabel = t("Label.Complainant");
  let respondentLabel = t("Label.OppositeParty");
  let complainantOrRespondentLabel = t("Label.ComplainantOrOppositeParty");
  let additionalComplainantLabel = t("Label.AdditionalComplainant");
  let formik: FormikProps<any> = useFormik({
    initialValues: FaSaRpCaveatCaseDetailsViewModel.init(),
    validationSchema: commonFaSaRpCaveatCaseDetailsValidationSchema,
    onSubmit: handleFaSaRpCaveatCaseDetailSubmit,
  });
  let CaseDetailForm: JSX.Element = <FaSaRpCaveatCaseDetails formik={formik} />;

  if (caseTypeId) {
    switch (caseTypeId) {
      case caseTypeEnum.FirstAppeal:
        preDetailsLabel = t("Label.ImpugnedOrderDetails");
        complainantLabel = t("Label.Appellant");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.AppellantOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalAppellant");
        formik = commonFaSaRpCaveatFormik;
        CaseDetailForm = (
          <FaSaRpCaveatCaseDetails formik={commonFaSaRpCaveatFormik} />
        );
        break;

      case caseTypeEnum.SecondAppeal:
        preDetailsLabel = t("Label.ImpugnedOrderDetails");
        complainantLabel = t("Label.Appellant");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.AppellantOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalAppellant");
        formik = commonFaSaRpCaveatFormik;
        CaseDetailForm = (
          <FaSaRpCaveatCaseDetails formik={commonFaSaRpCaveatFormik} />
        );
        break;

      case caseTypeEnum.RevisionPetition:
        preDetailsLabel = t("Label.ImpugnedOrderDetails");
        complainantLabel = t("Label.Petitioner");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.PetitionerOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalPetitioner");
        formik = commonFaSaRpCaveatFormik;
        CaseDetailForm = <FaSaRpCaveatCaseDetails formik={formik} />;
        break;

      case caseTypeEnum.CaveatCases:
        preDetailsLabel = t("Label.ImpugnedOrderDetails");
        complainantLabel = t("Label.Caveator");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.CaveatorOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalCaveator");
        formik = commonFaSaRpCaveatFormik;
        CaseDetailForm = <FaSaRpCaveatCaseDetails formik={formik} />;
        break;

      case caseTypeEnum.AppealExecution:
        preDetailsLabel = t("Label.ImpugnedOrderDetails");
        complainantLabel = t("Label.Appellant");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.AppellantOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalAppellant");
        formik = appealExecutionFormik;
        CaseDetailForm = <AppealExecutionCaseDetails formik={formik} />;
        break;

      case caseTypeEnum.CCPAAppeal:
        complainantLabel = t("Label.Appellant");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.AppellantOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalAppellant");
        formik = cCPAAppealFormik;
        CaseDetailForm = <CCPAAppealCaseDetails formik={formik} />;
        break;

      case caseTypeEnum.TransferApplication:
        preDetailsLabel = t("Label.ImpugnedOrderDetails");
        complainantLabel = t("Label.Petitioner");
        respondentLabel = t("Label.Respondent");
        complainantOrRespondentLabel = t("Label.PetitionerOrRespondent");
        additionalComplainantLabel = t("Label.AdditionalPetitioner");
        formik = transferApplicationFormik;
        CaseDetailForm = (
          <TransferApplicationCaseDetails
            formik={transferApplicationFormik}
            commissionTypeId={commisionTypeId as number}
            preCommisionName={preCommissionName}
          />
        );
        break;

      case caseTypeEnum.InterlocutoryApplication:
      case caseTypeEnum.MiscellaneousApplication:
        preDetailsLabel = t("Label.MainCaseDetails");
        if (preCaseTypeId) {
          const names = handlePreCaseTypeNames(preCaseTypeId);
          complainantLabel = names.complainantLabel;
          respondentLabel = names.respondentLabel;
          complainantOrRespondentLabel = names.complainantOrRespondentLabel;
          additionalComplainantLabel = names.additionalComplainantLabel;
        }
        formik = interlocutoryApplicationFormik;
        CaseDetailForm = (
          <InterLocutoryApplicationCaseDetails
            formik={interlocutoryApplicationFormik}
          />
        );
        break;

      case caseTypeEnum.ReviewApplication:
        preDetailsLabel = t("Label.MainCaseDetails");
        if (preCaseTypeId) {
          const names = handlePreCaseTypeNames(preCaseTypeId);
          complainantLabel = names.complainantLabel;
          respondentLabel = names.respondentLabel;
          complainantOrRespondentLabel = names.complainantOrRespondentLabel;
          additionalComplainantLabel = names.additionalComplainantLabel;
        }
        formik = commonFaSaRpCaveatFormik;
        CaseDetailForm = (
          <FaSaRpCaveatCaseDetails formik={commonFaSaRpCaveatFormik} />
        );
        break;

        case caseTypeEnum.ExecutionApplication:
        preDetailsLabel = t("Label.MainCaseDetails");
        if (preCaseTypeId) {
          const names = handlePreCaseTypeNames(preCaseTypeId);
          complainantLabel = names.complainantLabel;
          respondentLabel = names.respondentLabel;
          complainantOrRespondentLabel = names.complainantOrRespondentLabel;
          additionalComplainantLabel = names.additionalComplainantLabel;
        }
        formik = commonFaSaRpCaveatFormik;
        CaseDetailForm = (
          <FaSaRpCaveatCaseDetails formik={commonFaSaRpCaveatFormik} />
        );
        break;
    }
  }

  return {
    formik,
    preDetailsLabel,
    complainantLabel,
    complainantOrRespondentLabel,
    additionalComplainantLabel,
    respondentLabel,
    CaseDetailForm,
  };
};
