import { IDropdown } from "../../common/IDropdown";

export class FinalSubmitViewModel {
    constructor(
        public commission: IDropdown,
        public declaration: boolean
    ) {
        this.commission = commission;
        this.declaration = declaration;
    }

    public static init = (declare: boolean = false) => new FinalSubmitViewModel({ label: "", value: "" }, declare);
    
};

export class FinalSubmitModel {
    constructor(
        public fillingReferenceNumber: number,
        public commissionId: number,
    ) {
        this.fillingReferenceNumber = fillingReferenceNumber;
        this.commissionId = commissionId;
    }
}

export interface ICommissionLocationModel {
    filingReferenceNumber: number,
}