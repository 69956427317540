import { Grid, Typography } from '@mui/material';
import image from '../../../../../assets/images/basil_document-solid.png';
import arrow_image from '../../../../../assets/images/arrow_image.png';


export interface IJugdeDocumentCardProps {
    viewMode: string;
    index: number;
    handleDocumentCardClick: (id: number, calltype?: number) => void;
    title: string;
    id: number;
    callType?: number 
}

const JudgeDocumentCard = ({ viewMode, index, id, handleDocumentCardClick, title, callType }: IJugdeDocumentCardProps) => {
    return (
        <Grid item xs={12} md={(viewMode !== 'list') ? 4 : 12} key={index}>
            <div className="card h-100" style={{ width: '100%', cursor: "pointer" }} onClick={() => handleDocumentCardClick(id, callType)}>
                <div className="important-link-card">
                    <div className='main-image-judge'>
                        <img src={image} alt="Document Icon" className="image-class" />
                    </div>
                    <div className="card-content-judge">
                        <Typography variant="body2" className="card-title-judge">
                            {title}
                        </Typography>
                        <img src={arrow_image} alt="Arrow Icon" className="arrow-image-judge" />
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default JudgeDocumentCard