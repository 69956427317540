import { FormikProps } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { BasicDatePicker, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Textbox } from "../../../../../../components";
import ConfonetTextEditorTiny from "../../../../../../components/controls/ConfonetTextEditorTinyMCE";
import { CCPAAppealCaseDetailsViewModel } from "../../../../../../models/cases/appealTypeCase/CaseDetailsViewModel";


interface ICCPAAppealCaseDetailsProps {
    formik: FormikProps<CCPAAppealCaseDetailsViewModel>
}

export const CPAAppealCaseDetailsValidationSchema = Yup.object({
    // dateOfOrder: Yup.date()
    //     .required("Date of Order is a required field"),
    caseSummary: Yup.string()
        .max(2000, "Max 2000 charactera are allowed"),
})

export const CCPAAppealCaseDetails = ({ formik }: ICCPAAppealCaseDetailsProps) => {
    const { t } = useTranslation();

    const editorValueChange = (value:string) => {
        formik.setFieldValue("caseDetails", value);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="margin-top-25">
                <ConfonetCard>
                    <ConfonetCardHeader>
                        <div className="card-title card-primary-header-title">{t("Label.CaseDetails")}</div>
                    </ConfonetCardHeader>
                    <ConfonetCardBody>
                        <div className="row">
                            <div className="col-lg-4">
                                <Textbox
                                    id="AuthorityName"
                                    name={`authorityName`}
                                    className="uppercase"
                                    label={t("Label.AuthorityName") + "*"}
                                    value={formik.values.authorityName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ maxLength: 30 }}
                                // error={formik.touched?.authorityName && Boolean(formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.authorityName : false)}
                                // helperText={formik.touched?.authorityName && formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.authorityName : ""}
                                />
                            </div>

                            <div className="col-lg-4">
                                <Textbox
                                    id="AuthorityId"
                                    name={`authorityId`}
                                    className="uppercase"
                                    label={t("Label.AuthorityId") + "*"}
                                    value={formik.values.authorityId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ maxLength: 30 }}
                                // error={formik.touched?.authorityId && Boolean(formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.authorityId : false)}
                                // helperText={formik.touched?.authorityId && formik.errors ? (formik.errors as FormikErrors<AppealExecutionCaseDetailsViewModel>)?.authorityId : ""}
                                />
                            </div>

                            <div className="col-lg-4">
                                <BasicDatePicker
                                    label={t("Label.DateOfOrder") + "*"}
                                    value={formik.values.dateOfOrder}
                                    className="w-100"
                                    onChange={(e) => formik.setFieldValue("dateOfOrder", e, true)}

                                    slotProps={{
                                        textField: {
                                            variant: "outlined",
                                            error: formik.touched.dateOfOrder && Boolean(formik.errors.dateOfOrder),
                                            helperText: (
                                                <>
                                                    <span className="text-danger">
                                                        {formik.touched.dateOfOrder && formik.errors.dateOfOrder as string}
                                                    </span>
                                                </>
                                            ),
                                            onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                        }
                                    }}
                                />
                            </div>
                        </div>


                        {/* <div className="row">
                            <div className="col-lg-12">
                                <ConfonetTextEditorTiny
                                    id="caseDetails"
                                    value={formik.values.caseSummary}
                                    onEditorChange={editorValueChange}

                                />
                            </div>
                        </div> */}
                    </ConfonetCardBody>
                </ConfonetCard>
            </div>
        </>
    )
}