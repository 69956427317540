import { DialogContent, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BasicBreadcrumbs, BasicButton, BasicDatePicker, ConfonetAutocomplete, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Dialogbox } from "../../../../components";
import { DocumentFillIcon } from "../../../../components/controls/BasicIcons";
import ConfonetPdfViewer from "../../../../components/controls/ConfonetPdfViewer";
import { IUpdateCourtroomCasePayload, UpdateCourtroomCaseModel } from "../../../../models/cases/commissionCase/CourtroomHearingFormViewModel";
import { IDropdown } from "../../../../models/common/IDropdown";
import { COMMISSION_DASHBOARD, COMMISSION_SEARCH_CASE_DETAILS_FOR_COURTROOM } from "../../../../navigation/CONSTANTS";
import { RootState } from "../../../../redux";
import { CourtroomService } from "../../../../services/CaseService/CourtroomService/CourtroomService";
import { loaderService } from "../../../../services/CommonService/loaderService";
import { BASE_URL, ORDER_SERVICE } from "../../../../services/CONSTANTS";
import { MasterService } from "../../../../services/MasterService/MasterService";
import { convertBase64ToUInt8, dropdownValidationSchema, makeDropdownOptions } from "../../../../utils/Helper";


/** Renders judgement upload component */
const UpdateCourtroomCaseView = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [courtNumberListOption, setCourtNumberListOption] = useState<IDropdown[]>([]);
    const [caseStageListOption, setCaseStageListOption] = useState<IDropdown[]>([]);
    const [causelistCategoryListOption, setCauselistCategoryListOption] = useState<IDropdown[]>([]);
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));
    const { caseNumber, complainantName, respondentName, caseFilingDate, courtRoom, caseStageName, dateOfHearing, causeListTypeName, isPath, dailyOrderPdf } = location.state.response;


    // define the validation schema
    const validationSchema = Yup.object({
        courtNumber: dropdownValidationSchema("Court number is required")
            .required(),
        dateOfNextHearing: Yup.date()
            .required('Date of next hearing is required'),
        caseStage: dropdownValidationSchema("Case stage is required")
            .required(),
        causelistCategory: dropdownValidationSchema("Causelist category is required")
            .required(),
    });

    // Get All Court Number API Calling
    const handleGetCourtNumber = async () => {
        const courtNumberResponse = await MasterService.getCourtNumberCommissionWise(userDetailsData?.commissionId);
        if (courtNumberResponse) {
            setCourtNumberListOption(makeDropdownOptions(courtNumberResponse, "courtNameEn", "courtId"));
        }
    };

    // Get all Case Stage API Calling
    const handleGetCaseStage = async () => {
        const caseStageResponse = await MasterService.getAllCaseStage();
        setCaseStageListOption(
            makeDropdownOptions(caseStageResponse, "caseStageNameEn", "caseStageid")
        );
    };

    // Get all causelist category API Calling
    const handleGetCauselistCategory = async () => {
        const causelistCategoryResponse =
            await MasterService.getAllCauselistCategory();
        setCauselistCategoryListOption(
            makeDropdownOptions(causelistCategoryResponse, "causeListTypeNameEn", "causeListTypeId"));
    };

    // triggers when the form is submitted
    const handleSubmit = async () => {
        const data: IUpdateCourtroomCasePayload = {
            caseNumber: caseNumber,
            courtId: formik.values.courtNumber.value,
            caseStageId: formik.values.caseStage.value,
            causeListTypeId: formik.values.causelistCategory.value,
            dateOfNextHearing: formik.values.dateOfNextHearing.toFormat("yyyy-MM-dd"),
            commissionId: userDetailsData?.commissionId
        }
        const updateBenchResponse = await CourtroomService.updateCourtRoomCauseList(data);
        if (updateBenchResponse) {
            toast.success("Courtroom details updated successfully.");
            navigate(COMMISSION_SEARCH_CASE_DETAILS_FOR_COURTROOM);
        }
    };

    // define the formik form for validation and form submission
    const formik = useFormik({
        initialValues: UpdateCourtroomCaseModel.init(),
        validationSchema,
        onSubmit: handleSubmit
    })

    // get the daily order pdf
    const handleGetDailyOrder = async (caseNumber: string, dateOfHearing: string) => {
        loaderService.setLoading(true);
        axios.get(`${BASE_URL}/${ORDER_SERVICE}/getDailyOrderPdf?caseNumber=${caseNumber}&dateOfHearing=${dateOfHearing}`, {},).then((res) => {
            if (res?.data?.data?.dailyOrderPdf) {
                setDocumentContent(convertBase64ToUInt8(res?.data?.data?.dailyOrderPdf))
            }
        }).catch((err) => {
            if (err.response.status === 500) {
                toast.error('Daily order not available currently');
            }
        }).finally(() => {
            loaderService.clearLoading();
        });
    };

    useEffect(() => {
        handleGetCourtNumber();
        handleGetCaseStage();
        handleGetCauselistCategory();
    }, [])

    return (
        <section className="section margin-top-35">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <BasicBreadcrumbs
                            items={[
                                {
                                    to: COMMISSION_DASHBOARD,
                                    label: t("Breadcrumb.Dashboard"),
                                },
                                {
                                    to: COMMISSION_SEARCH_CASE_DETAILS_FOR_COURTROOM,
                                    label: t("Breadcrumb.SearchCaseDetails"),
                                },
                                {
                                    to: '',
                                    label: t("Label.UpdateCourtroomCase"),
                                },
                            ]}
                        />
                    </div>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className="row margin-top-25 margin-bottom-5">
                        <div className="col-lg-12 margin-bottom-25">
                            <ConfonetCard>
                                <ConfonetCardHeader>
                                    <div className="card-title card-primary-header-title float-start">{t("Label.CaseDetails")}</div>
                                </ConfonetCardHeader>
                                <ConfonetCardBody>
                                    <div className="row">
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.CaseNumber")}</Typography>
                                            <Typography className="fw-bold">{caseNumber}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.ComplainantName")}</Typography>
                                            <Typography className="fw-bold">{complainantName}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.RespondentName")}</Typography>
                                            <Typography className="fw-bold">{respondentName}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.FilingDate")}</Typography>
                                            <Typography className="fw-bold">{caseFilingDate && DateTime.fromFormat(caseFilingDate, "yyyy-MM-dd").toFormat("dd-MM-yyyy")}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.HearingDate")}</Typography>
                                            <Typography className="fw-bold">{dateOfHearing && DateTime.fromFormat(dateOfHearing, "yyyy-MM-dd").toFormat("dd-MM-yyyy")}</Typography>
                                        </div>
                                        <div className="col-lg-4">
                                            <Typography>{t("Label.CaseStage")}</Typography>
                                            <Typography className="fw-bold">{caseStageName}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.CauselistType")}</Typography>
                                            <Typography className="fw-bold">{causeListTypeName}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.BenchNo")}</Typography>
                                            <Typography className="fw-bold">{courtRoom}</Typography>
                                        </div>
                                        <div className="col-lg-4 margin-bottom-20">
                                            <Typography>{t("Label.DailyOrder")}</Typography>
                                            <DocumentFillIcon
                                                className="img-fluid pointer"
                                                onClick={() => handleGetDailyOrder(caseNumber, dateOfHearing)}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </ConfonetCardBody>
                            </ConfonetCard>

                            {/* <PreviewCardWithPdf
                                className="margin-top-25"
                                heading={t("Label.CaseDetails")}
                                items={flattenArrayToIPreview({
                                    caseNumber: caseNumber,
                                    complainantName: complainantName,
                                    respondentName: respondentName,
                                    filingDate: caseFilingDate && DateTime.fromFormat(caseFilingDate, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                                    hearingDate: dateOfHearing && DateTime.fromFormat(dateOfHearing, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                                    caseStage: caseStageName,
                                    causeListType: causeListTypeName,
                                    benchNumber: courtRoom,
                                    judgementPdf: dailyOrderPdf
                                })}
                            /> */}
                        </div>
                    </div>

                    <div className="row margin-bottom-5">
                        <div className="col-lg-12">
                            <ConfonetCard>
                                <ConfonetCardHeader>
                                    <div className="card-title card-primary-header-title float-start">{t("Label.UpdateCourtroomCase")}</div>
                                </ConfonetCardHeader>

                                <ConfonetCardBody>
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-6">
                                            <ConfonetAutocomplete
                                                id="courtNumber"
                                                value={formik.values.courtNumber}
                                                options={courtNumberListOption}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("Label.CourtNumber") + "*"}
                                                        error={
                                                            formik.touched.courtNumber &&
                                                            Boolean(formik.errors.courtNumber)
                                                        }
                                                        helperText={
                                                            formik.touched.courtNumber &&
                                                            formik.errors.courtNumber?.label
                                                        }
                                                        fullWidth
                                                    />
                                                )}
                                                onChange={(_, value: IDropdown | null) => {
                                                    formik.setFieldValue(
                                                        "courtNumber",
                                                        value ?? { label: "", value: "" }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-sm-6">
                                            <ConfonetAutocomplete
                                                id="caseStage"
                                                value={formik.values.caseStage}
                                                options={caseStageListOption}
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t("Label.CaseStage") + "*"}
                                                            error={formik.touched.caseStage && Boolean(formik.errors.caseStage)}
                                                            helperText={formik.touched.caseStage && formik.errors.caseStage?.label}
                                                            fullWidth
                                                        />
                                                    )
                                                }
                                                onChange={(_, value: IDropdown | null) => {
                                                    formik.setFieldValue("caseStage", value ?? { label: "", value: "" })
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-sm-6">
                                            <ConfonetAutocomplete
                                                id="causelistCategory"
                                                value={formik.values.causelistCategory}
                                                options={causelistCategoryListOption}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("Label.CauselistCategory") + "*"}
                                                        error={
                                                            formik.touched.causelistCategory &&
                                                            Boolean(formik.errors.causelistCategory)
                                                        }
                                                        helperText={
                                                            formik.touched.causelistCategory &&
                                                            formik.errors.causelistCategory?.label
                                                        }
                                                        fullWidth
                                                    />
                                                )}
                                                onChange={(_, value: IDropdown | null) => {
                                                    formik.setFieldValue("causelistCategory", value ?? { label: "", value: "" });
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-sm-6">
                                            <BasicDatePicker
                                                label={t("Label.DateOfNextHearing") + "*"}
                                                value={formik.values.dateOfNextHearing}
                                                className="w-100"
                                                onChange={(e) => formik.setFieldValue("dateOfNextHearing", e, true)}

                                                slotProps={{
                                                    textField: {
                                                        variant: "outlined",
                                                        error: formik.touched.dateOfNextHearing && Boolean(formik.errors.dateOfNextHearing),
                                                        helperText: (
                                                            <span className="text-danger">
                                                                {formik.touched.dateOfNextHearing && formik.errors.dateOfNextHearing as string}
                                                            </span>
                                                        ),
                                                        onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                </ConfonetCardBody>
                            </ConfonetCard>
                        </div>
                    </div>

                    <div className="margin-top-25 text-end margin-bottom-25">
                        <BasicButton
                            className="btn-custom btn-custom-primary"
                            type="button"
                            onClick={handleSubmit}
                            disabled={formik.values.courtNumber.value === ""}
                            variant="contained"
                            text={t("BtnText.Submit")}
                            endIcon={<IoArrowForwardCircleOutline />}
                        />
                    </div>
                </form>
            </div>

            <Dialogbox
                open={documentContent.length > 1}
                onClose={() => {
                    setDocumentContent(new Uint8Array([255]));
                }}
                maxWidth="lg"
                fullScreen
            >
                <DialogContent>
                    {documentContent.length > 1 && (
                        <ConfonetPdfViewer
                            fileUrl={documentContent}
                        />
                    )}
                </DialogContent>
            </Dialogbox>
        </section>
    )
}

export default UpdateCourtroomCaseView;