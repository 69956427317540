import { TextField } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  BasicButton, 
  BasicDatePicker, 
  ConfonetAutocomplete, 
  ConfonetCard, 
  ConfonetCardBody, 
  ConfonetCardHeader, 
  FileUploader, 
  Textbox 
} from '../../../../components';
import ConfonetTextEditorTiny from '../../../../components/controls/ConfonetTextEditorTinyMCE';
import { ImportantNotesCreateViewModel, ImportantNotesSearchViewModel } from '../../../../models/ImportantNotes/IImportantNotes';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { IDropdown } from '../../../../models/common/IDropdown';

interface ICreateOrUpdateImportantNotesProps {
  importantNotesCreateOrUpdateFormik: FormikProps<ImportantNotesCreateViewModel>;
  importantNotesSearchFormik: FormikProps<ImportantNotesSearchViewModel>;
  caseupdateoption: IDropdown[]
}

const CreateOrUpdateImportantNotes: React.FC<ICreateOrUpdateImportantNotesProps> = ({
  importantNotesCreateOrUpdateFormik,
  importantNotesSearchFormik,
  caseupdateoption
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const editorValueChange = (value: string) => {
    importantNotesCreateOrUpdateFormik.setFieldValue('notes', value);
  };

  return (
    <div className="margin-top-25">
      <form onSubmit={importantNotesCreateOrUpdateFormik.handleSubmit}>
        <ConfonetCard>
          <ConfonetCardHeader>
            <div className="card-title card-primary-header-title">
            {location.pathname.includes('commission/registry') ? t("Label.RegistryNotes") : t("Label.CreateNotes")}
            </div>
          </ConfonetCardHeader>
          <ConfonetCardBody>
            <div className="row justify-content-center margin-top-10">
              <div className="col-sm-12">
                <Textbox
                  name="title"
                  placeholder={t("Label.Title") + "*"}
                  value={importantNotesCreateOrUpdateFormik.values.title}
                  onChange={importantNotesCreateOrUpdateFormik.handleChange}
                  onBlur={importantNotesCreateOrUpdateFormik.handleBlur}
                  error={importantNotesCreateOrUpdateFormik.touched.title && Boolean(importantNotesCreateOrUpdateFormik.errors.title)}
                  helperText={importantNotesCreateOrUpdateFormik.touched.title && importantNotesCreateOrUpdateFormik.errors.title}
                />
              </div>
            </div>
            <div className='row'>
              <div className="col-sm-12">
                <BasicDatePicker
                  label={t("Label.HearingDate")}
                  value={importantNotesCreateOrUpdateFormik.values.date}
                  className="w-100"
                  onChange={(e) => importantNotesCreateOrUpdateFormik.setFieldValue("date", e, true)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: importantNotesCreateOrUpdateFormik.touched.date && Boolean(importantNotesCreateOrUpdateFormik.errors.date),
                      helperText: (
                        <>
                          <span className="text-danger">
                            {importantNotesCreateOrUpdateFormik.touched.date && importantNotesCreateOrUpdateFormik.errors.date as string}
                          </span>
                        </>
                      ),
                      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                    }
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className="col-sm-12">
              <ConfonetAutocomplete
                id="caseNumber"
                value={importantNotesCreateOrUpdateFormik.values.caseNumber}
                options={caseupdateoption}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Case Number *"
                    error={
                      importantNotesCreateOrUpdateFormik.touched.caseNumber &&
                      Boolean(importantNotesCreateOrUpdateFormik.errors.caseNumber)
                    }
                    helperText={
                      importantNotesCreateOrUpdateFormik.touched.caseNumber &&
                      importantNotesCreateOrUpdateFormik.errors.caseNumber
                    }
                    fullWidth
                  />
                )}
                onChange={(_, value: IDropdown | null) => {
                  console.log(value);
                  if (value) {
                    importantNotesCreateOrUpdateFormik.setFieldValue('caseNumber', value);
                  } else {
                    importantNotesCreateOrUpdateFormik.setFieldValue('caseNumber', {label: "", value: ""});
                  }
                }}
                onBlur={(e) => {
                  importantNotesCreateOrUpdateFormik.handleBlur(e);
                }}
              />
              </div>
            </div>
            <div className="row">
              <div className='col-sm-12 remarks-style'>
                {t("Label.RemarksOrPrayer")}
              </div>
              <div className="col-lg-12">
                <ConfonetTextEditorTiny
                  id="notes"
                  value={importantNotesCreateOrUpdateFormik.values.notes}
                  onEditorChange={editorValueChange}
                />
                {importantNotesCreateOrUpdateFormik.touched.notes && importantNotesCreateOrUpdateFormik.errors.notes && (
                  <span className='text-danger'>{importantNotesCreateOrUpdateFormik.errors.notes}</span>
                )}
              </div>
            </div>
            <div className="row">
            <div className='col-sm-12 remarks-style'>
            <FileUploader
              onFileDrop={(file) => {importantNotesCreateOrUpdateFormik.setFieldValue('document', file)}}
              dropzoneClassName='dropzone'
              text='Drag and Drop Files here or Click to upload (pdf only)'
              accept={{ "application/pdf": [".pdf"]}}
              fileList={importantNotesCreateOrUpdateFormik.values.document}
            />
               </div>
            </div>
            <div className="text-end margin-top-25">
              <BasicButton
                className="btn-custom btn-custom-primary"
                type="submit"
                variant="contained"
                text={location.pathname.includes('commission/registry') ? t("BtnText.RegistryNotes") : t("BtnText.CreateNotes")}
                endIcon={<IoArrowForwardCircleOutline />}
              />
            </div>
          </ConfonetCardBody>
        </ConfonetCard>
      </form>
    </div>
  );
};

export default CreateOrUpdateImportantNotes;
