import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICaseFillingDocumentDetailsResponseList } from "../../models/cases/consumerCases/IConsumerCaseModel";

type IDocumentUploadReducer = {
    uploadedFile: ICaseFillingDocumentDetailsResponseList[];
}

const initialState: IDocumentUploadReducer  = {
    uploadedFile: []
}

export const documentUploadSlice = createSlice({
    name: 'documentUpload',
    initialState,
    reducers: {
        addDocumentDetails: (state, action: PayloadAction<ICaseFillingDocumentDetailsResponseList[]>) => {
            state.uploadedFile = action.payload;
        },
        clearDocumentDetails: (state) => {
            state.uploadedFile = [];
        }
    }
});

export const { addDocumentDetails, clearDocumentDetails } = documentUploadSlice.actions;
export default documentUploadSlice.reducer