import React, { useState } from 'react'
import { BasicBreadcrumbs, BasicButton } from '../../../components';
import { convertBase64ToUInt8, navigateToDashboardPath } from '../../../utils/Helper';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { ICaseFillingDocumentDetailsResponseList, IGetDocumentResponse } from '../../../models/cases/consumerCases/IConsumerCaseModel';
import { ConsumerCaseServices } from '../../../services/CaseService/ConsumerCaseService/ConsumerCaseServices';
import { toast } from 'react-toastify';
import CauseListDragDropHtml from '../../../utils/HtmlFilesForPdf/CauseListDragGDropHtml';
import { CauselistJudgeResponse } from './GenerateModel';
import { DateTime } from 'luxon';
import { CauselistResponse } from '../../../models/causelist/CauselistModel';
import { CouselistServices } from '../../../services/JudgeService/GenerateCauselistServices';
import { GENERATE_COUSELIST_VIEW } from '../../../navigation/CONSTANTS';

interface ViewPageProps {
    courtId: number;
    courtName: string;
    selectedDate: string;
}
interface ITableRowData extends CauselistJudgeResponse {
    id?: number;
}

export function EditPage({ courtId, courtName, selectedDate }: ViewPageProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { editAllResponseData, causeListData } = location.state;
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));
    const [saveEditResponse, setSaveEditResponse] = useState([editAllResponseData])
    const originalDate = editAllResponseData[0].dateOfHearing;

    //Handle Documnet
    const handleGetDocument = async (filingReferenceNumber: number, docName: string) => {
        const response: IGetDocumentResponse =
            await ConsumerCaseServices.getCaseFillingDocumentDetails(
                filingReferenceNumber
            );
        if (
            response &&
            Object.keys(response).length > 0 &&
            response?.caseFillingDocumentDetailsResponseList?.length > 0
        ) {
            // debugger
            const documentItem = response?.caseFillingDocumentDetailsResponseList?.filter((document: ICaseFillingDocumentDetailsResponseList) => document.documentName.toLowerCase().includes(docName.toLowerCase()));
            if (documentItem?.length) {
                setDocumentContent(convertBase64ToUInt8(documentItem[0].fileContent));
            } else {
                toast.warn('No document available for the selected item!!');
            }
        } else {
            toast.warn('No document available for the selected item!!');
        }
    };

    const UpdateEdit = async () => {
        try {
            const params = {
                dateOfNextHearing: DateTime.fromISO(causeListData.clDate).toFormat("dd-MM-yyyy"),
                courtId: causeListData.courtId,
                commissionId: userDetailsData.commissionId,
                userId: userDetailsData.userId,
                clData: JSON.stringify(editAllResponseData),
                crhClId: causeListData.slno
            }
            const response = await CouselistServices.getJudgeGenerateCouselistEdit(params)
            if (response) {
                toast.success('Update Successful')
                const paramsD = {
                    dateOfNextHearing: DateTime.fromFormat(params.dateOfNextHearing, "dd-MM-yyyy").toFormat("yyyy-MM-dd"),
                    courtId: params.courtId,
                    commissionId: userDetailsData.commissionId,
                    finalize: false
                };

                const response = await CouselistServices.getJudgeGenerateCouselist(paramsD);
                const causeListDataData = JSON.parse(response.clData)
                const causeListData = response
                if (response.clData) {
                    navigate(GENERATE_COUSELIST_VIEW, { state: { causeListData, causeListDataData } });

                }
            }
        } catch (error) {
            console.error("Error fetching PDF data:", error);
        }

    }


    return (
        <section className="section margin-top-20">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-sm-7 margin-bottom-30">
                        <BasicBreadcrumbs items={[
                            { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
                            { to: '', label: t("Label.Edit") },
                        ]} />
                    </div>
                </div>
            </div>

            <CauseListDragDropHtml
                setSaveEditResponse={setSaveEditResponse}
                causelistResponse={editAllResponseData}
                selectedDate={originalDate}
                handleGetDocument={handleGetDocument}
            />
            <div style={{ clear: 'both', marginTop: '25px', textAlign: 'right' }}>
                <BasicButton
                    text={"Update"}
                    variant="contained"
                    onClick={() => UpdateEdit()}
                    autoFocus
                />
            </div>
        </section>
    )
}

