import { AxiosResponse } from "axios";
import { IPreviousCaseDetailsResponse } from "../../models/cases/commonCase/ICommonCaseModel";
import { IDashboardCaseCount, IGetCaseListResponse, IGetCaseNumberForPendingForApprovalResponse, IGetCommissionResponse, IListForJudgeResponse } from "../../models/cases/consumerCases/IConsumerCaseModel";
import { caseStages } from "../../utils/Enum";
import { BASE_URL, CASE_FILING_SERVICE } from "../CONSTANTS";
import { deleteCall, getCall, postCall } from "../api";
import { AddDisplayBoardPayload } from "../../models/displayBoard/DisplayBoardModel";
import { IStatusResponse } from "../../models/cases/commissionCase/ICommissionCase";
import { IsaveAndUpdateNotesRequestModel} from "../../models/ImportantNotes/IImportantNotes";
import { IsaveAndUpdateNotesResponse } from "../../models/ImportantNotes/IImportantNotes";
import { IgetNoteListResponse } from "../../models/ImportantNotes/IImportantNotes";

export class CaseServices {
    /**
     * Call an API to get list of cases for specific user
     * @param userId number
     * @returns Promise<IGetCaseListResponse[]>
     */
    public static readonly getAllCaseList = async (commissionId: number | undefined, roleId: number, userId: number, caseFillingStageId: caseStages[]):
        Promise<IGetCaseListResponse[]> => {

        const params = { commissionId, roleId, userId, caseFillingStageId: caseFillingStageId.join(",") };
        const responses: AxiosResponse<IGetCaseListResponse[]> = await getCall(
            `${CASE_FILING_SERVICE}/getDashboardList`,
            params
        );

        return responses?.data;
    };



    /**
     * Call an API to get previous case details
     * @param id IPreviousCaseDetailsRequest
     * @returns Promise<IPreviousCaseDetailsResponse[]>
     */
    public static readonly getPreviousCaseDetails = async (fillingReferenceNumber: number): Promise<IPreviousCaseDetailsResponse> => {
        const response: AxiosResponse<IPreviousCaseDetailsResponse> = await getCall(
            `${CASE_FILING_SERVICE}/getDetailsForApproval`,
            { fillingReferenceNumber }
        );

        return response?.data
    }

    /**
     * Call an API to get the Counts in the dashboard
     * @param id number
     * @param isCommission boolean
     * @returns Promise<IDashboardCaseCount[]>
     */
    public static readonly getCaseCounts = async (id: number, isCommission: 0 | 1): Promise<IDashboardCaseCount[]> => {
        const params = {
            id,
            isCommission
        }

        const response = await getCall(
            `${BASE_URL}/${CASE_FILING_SERVICE}/getDashboardFileCount`,
            params,
        );
        return response?.data;
    };

    /**
     * Call an API to get the Counts in the dashboard
     * @param id number
     * @param isCommission boolean
     * @returns Promise<IDashboardCaseCount[]>
     */
    public static readonly getDashboardJudges = async (userId: number, hearingDate: string): Promise<IListForJudgeResponse[]> => {
        const params = {
            userId,
            hearingDate
        }

        const response: AxiosResponse<IListForJudgeResponse[]> = await getCall(
            `${BASE_URL}/${CASE_FILING_SERVICE}/getDashboardListForJudges`,
            params,
        );
        return response?.data;
    };



    /**
    * Call an API to get the Counts in the dashboard
    * @param id number
    * @param isCommission boolean
    * @returns Promise<IDashboardCaseCount[]>
    */
    public static readonly getCaseCountsJudges = async (id: number, isCommission: 0 | 1): Promise<any[]> => {
        const params = {
            id,
            isCommission
        }

        const response = await getCall(
            `${BASE_URL}/${CASE_FILING_SERVICE}/getDashboardFileCount`,
            params,
        );
        return response?.data;
    };

    /**
    * Call an API to get the Counts in the dashboard
    * @param id number
    * @param isCommission boolean
    * @returns Promise<IDashboardCaseCount[]>
    */
    public static readonly getCaseCountsCourtRoom = async (userId: number): Promise<any[]> => {
        const params = {
            userId,
        }

        const response = await getCall(
            `${BASE_URL}/${CASE_FILING_SERVICE}/getDashboardFileCountCourtMaster`,
            params,
        );
        return response?.data;
    };

    /**
     * Call an API to get the Counts in the dashboard
     * @param id number
     * @param isCommission boolean
     * @returns Promise<IDashboardCaseCount[]>
     */
    public static readonly getCaseNumberForPendingForApproval = async (id: number, isCommission: 0 | 1) => {
        const params = {
            id,
            isCommission
        };

        const responses: AxiosResponse<IGetCaseNumberForPendingForApprovalResponse[]> = await getCall(
            `${CASE_FILING_SERVICE}/getDashboardListPendingForApproval`,
            params
        );

        return responses?.data;
    };

    /**
     * call an API to get commission name according to filing refrence number
     * @param filingReferenceNumber
     */
    public static readonly getCommissionByFillingReferenceNumber = async (filingReferenceNumber: number): Promise<IGetCommissionResponse> => {
        const params = {
            fillingReferenceNumber: filingReferenceNumber,
        }
        const response: AxiosResponse<IGetCommissionResponse> = await getCall(
            `${CASE_FILING_SERVICE}/getcommissionByFillingReferenceNumber`,
            params
        );
        return response?.data
    }

    // master advocate api call
    /** Call an API to get a list of all Advocates
*
* @returns Promise<IAdvocateResponse[]>
*/
    public static readonly getAllAdvocatesbyBarCouncil = async (activeStatus: boolean): Promise<any[]> => {
        const params = { activeStatus };
        const response: AxiosResponse<any> = await getCall(
            `${CASE_FILING_SERVICE}/getBarcouncil`,
            params

        );
        return response?.data;
    };

    public static readonly addDisplayBoard = async (data: AddDisplayBoardPayload): Promise<IStatusResponse> => {
		const response: AxiosResponse<IStatusResponse> = await postCall(
			`${CASE_FILING_SERVICE}/addCourtroomHearingDisplayBoardDeatails`,
			data
		)
		return response?.data;
	};


    /**
     * call an API to get document according to filingDocumentId and documentCallType
     * @param filingDocumentId
     * @param documentCallType
     */
    public static readonly getDocumentContentById = async (filingDocumentId: number, documentCallType: number): Promise<{fileContent: string}> => {
        const params = {
          filingDocumentId,
          documentCallType  
        }
		const response: AxiosResponse<{fileContent: string}> = await getCall(
			`${CASE_FILING_SERVICE}/getDocumentContentById`,
			params
		)
		return response?.data;
	};
}