import { lazy } from 'react';
import { IRoute } from '../../models/common/IRoutes';
import {
  ABOUT_EDAAKHIL,
  CASE_HISTORY_CASE_STATUS,
  CAUSE_LIST,
  COMPLETE_PROFILE,
  CONTROL_VIEW,
  DISCLAIMER,
  DISPLAY_BOARD,
  DOWNLOAD_REPORTS_WITH_QR,
  FAQ,
  FEEDBACK,
  FORGOTPASSWORD,
  HELP,
  JUDGEMENT,
  JUDGESPUBLIC,
  LOGIN,
  PRIVACYPOLICY,
  REGISTER,
  REPORTS,
  ROOT,
  TERMSOFSERVICE,
  TERMS_AND_CONDITIONS,
  TUTORIALS,
  WEBSITE_POLICIES,
  FETCHEDREPORTDATA,
} from '../CONSTANTS';
import JudgesDashboard from '../../pages/AnalyticsDashboard/components/JudgesDashboard';

// import the components using React lazy
const ControlContainer = lazy(() => import("../../pages/Common/controls"));
const Causelist = lazy(() => import("../../pages/MainWebPages/Causelist"));
const DisplayBoard = lazy(() => import("../../pages/MainWebPages/DisplayBoard"));
const Disclaimer = lazy(() => import("../../pages/MainWebPages/FooterPages/Disclaimer/Disclaimer"));
const Faq = lazy(() => import("../../pages/MainWebPages/FooterPages/FAQ/Faq"));
const Feedback = lazy(() => import("../../pages/MainWebPages/FooterPages/Feedback/Feedback"));
const Help = lazy(() => import("../../pages/MainWebPages/FooterPages/Help/Help"));
const TermsAndConditions = lazy(() => import("../../pages/MainWebPages/FooterPages/TermsAndConditions/TermsAndConditions"));
const WebsitePolicies = lazy(() => import("../../pages/MainWebPages/FooterPages/WebsitePolicies/WebsitePolicies"));
const Judgement = lazy(() => import("../../pages/MainWebPages/Judgement"));
const PrivacyPolicy = lazy(() => import("../../pages/MainWebPages/PrivacyPolicy"));
const Reports = lazy(() => import("../../pages/MainWebPages/Report"));
const TermsOfService = lazy(() => import("../../pages/MainWebPages/TermsOfService"));
const Tutorials = lazy(() => import("../../pages/MainWebPages/Tutorials"));
const ReportsDownloads = lazy(() => import("../../utils/PdfReports/Template/ReportsDownloads"));
const ForgotPassword = lazy(() => import("../auth/ForgotPassword/ForgotPassword"));
const Register = lazy(() => import("../auth/Register/Register"));
const CaseHistoryCaseStatus = lazy(() => import("../../pages/MainWebPages/CaseHistoryCaseStatus"));
const AboutEdaakhil = lazy(() => import("../../pages/MainWebPages/AboutEdaakhil"));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const CompleteProfile = lazy(() => import("../../pages/Common/CompleteProfile"));
const Login = lazy(() => import("../auth/Login/Login"));
const OpenLinkGeneratePdfReport = lazy(() => import("../../pages/MainWebPages/Report/components/OnPdfLinkGenerateReport/OpenLinkGeneratePdfReport"))

export const mainPagesRoutes: IRoute[] = [
  {
    path: ROOT,
    element: <Dashboard />,
  },
  {
    path: ABOUT_EDAAKHIL,
    element: <AboutEdaakhil />,
  },
  {
    path: CASE_HISTORY_CASE_STATUS,
    element: <CaseHistoryCaseStatus />,
  },
  {
    path: CAUSE_LIST,
    element: <Causelist />,
  },
  {
    path: JUDGEMENT,
    element: <Judgement />,
  },
  {
    path: DISPLAY_BOARD,
    element: <DisplayBoard />,
  },
  {
    path: TUTORIALS,
    element: <Tutorials />,
  },
  {
    path: JUDGESPUBLIC,
    element: <JudgesDashboard />,
  },
  {
    path: LOGIN,
    element: <Login />,
  },
  {
    path: REGISTER,
    element: <Register />,
  },
  {
    path: FORGOTPASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: CONTROL_VIEW,
    element: <ControlContainer />,
  },
  {
    path: COMPLETE_PROFILE,
    element: <CompleteProfile />,
  },
  {
    path: PRIVACYPOLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: TERMSOFSERVICE,
    element: <TermsOfService />,
  },
  {
    path: REPORTS,
    element: <Reports />,
  },
  {
    path: DOWNLOAD_REPORTS_WITH_QR,
    element: <ReportsDownloads />,
  },
  {
    path: WEBSITE_POLICIES,
    element: <WebsitePolicies />,
  },
  {
    path: HELP,
    element: <Help />,
  },
  {
    path: FAQ,
    element: <Faq />,
  },
  {
    path: DISCLAIMER,
    element: <Disclaimer />,
  },
  {
    path: TERMS_AND_CONDITIONS,
    element: <TermsAndConditions />,
  },
  {
    path: FEEDBACK,
    element: <Feedback />,
  },
  {
    path: FETCHEDREPORTDATA,
    element: <OpenLinkGeneratePdfReport/>
  }
];