import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { ConfonetToggleButton, IConfonetToggleButtonProps } from './ConfonetToggleButton';

interface IConfonetToggleButtonGroupProps extends ToggleButtonGroupProps {
    toggleButton: Array<IConfonetToggleButtonProps>
}

export const ConfonetToggleButtonGroup = ({ toggleButton, ...rest }: IConfonetToggleButtonGroupProps) => {
    return (
        <ToggleButtonGroup
            {...rest}
        >
            {toggleButton.map((item) => {
                const { icon, ...toggleButtonRest } = item
                return (
                    <ConfonetToggleButton icon={icon} {...toggleButtonRest} />
                )
            })}
        </ToggleButtonGroup>
    )
};
