import { TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Yup from "yup";
import { ConfonetAutocomplete, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Textbox } from '../../../../components';
import ConfonetTextEditorTiny from '../../../../components/controls/ConfonetTextEditorTinyMCE';
import { TransferApplicationCaseDetailsViewModel } from '../../../../models/cases/transferApplicationCase/TransferpplicationCaseDetailsModel';
import { IDropdown } from '../../../../models/common/IDropdown';
import { MasterService } from '../../../../services/MasterService/MasterService';
import { caseTypeEnum, commissionTypeEnum } from '../../../../utils/Enum';
import { dropdownValidationSchema, makeDropdownOptions } from '../../../../utils/Helper';

interface TransferApplicationCaseDetailsProps {
	formik: FormikProps<TransferApplicationCaseDetailsViewModel>,
	commissionTypeId: number,
	preCommisionName?: string
}


export const transferApplicationCaseDetailsSchema = Yup.object({
	toState: dropdownValidationSchema("To state is required").required(),
	caseSummary: Yup.string()
		.max(2000, "Max 2000 charactera are allowed"),
});

const TransferApplicationCaseDetails = ({ formik, commissionTypeId, preCommisionName }: TransferApplicationCaseDetailsProps) => {
	const { t } = useTranslation();
	const location = useLocation();

	const { caseTypeId } = location.state;

	const [toStateList, setToStateList] = useState<IDropdown[]>([]);
	const [toDistrictList, setToDistrictList] = useState<IDropdown[]>([]);

	const getCommissionList = useCallback(async (commissionId?: number) => {
		if (commissionId) {
			const districtList = await MasterService.getCommissionByCommissionId(commissionId);
			const filteredDistrict = districtList.filter((item) => item.commissionNameEn.toLowerCase() !== preCommisionName?.toLowerCase());
			setToDistrictList(makeDropdownOptions(filteredDistrict, "commissionNameEn", "commissionId"))
		} else {
			const stateList = await MasterService.getCommissionByCommissionId(commissionId);
			setToStateList(makeDropdownOptions(stateList, "commissionNameEn", "commissionId"));
		}
	}, [preCommisionName]);

	const editorValueChange = (value: string) => {
		formik.setFieldValue("caseSummary", value);
	}

	useEffect(() => {
		if (formik.values.toState.value) {
			getCommissionList(formik.values.toState.value)
		}
	}, [formik.values.toState.value, getCommissionList]);

	useEffect(() => {
		if (caseTypeId === caseTypeEnum.TransferApplication) {
			getCommissionList();
		}
	}, [caseTypeId, getCommissionList])

	return (
		<div className="margin-top-25">
			<ConfonetCard>
				<ConfonetCardHeader>
					<div className="card-title card-primary-header-title">{t("Label.CaseDetails")}</div>
				</ConfonetCardHeader>
				<ConfonetCardBody>
					<div className="row">
						<div className="col-lg-4">
							<Textbox
								value={preCommisionName}
								disabled
								label={commissionTypeId === commissionTypeEnum.STATE_COMMISSION ? "From State Commision *" : "From District Commission"}
							/>
						</div>

						<div className="col-lg-4">
							<ConfonetAutocomplete
								id="toState"
								className="uppercase"
								value={formik.values.toState}
								options={toStateList}
								renderInput={
									(params) => (
										<TextField
											label={"To State Commision*"}
											{...params}
											error={formik.touched.toState && Boolean(formik.errors.toState)}
											helperText={formik.touched.toState && formik.errors.toState?.label}
											fullWidth
										/>
									)
								}
								onChange={(_, newValue: IDropdown | null) => {
									formik.setFieldValue(`toState`, newValue ?? { label: "", value: "" });
									formik.setFieldValue(`toDistrict`, { label: "", value: "" });
								}}
								disabled={location.pathname.includes('reverted')}
								onBlur={() => { formik.setFieldTouched(`toState.label`, true) }}
							/>
						</div>
						{commissionTypeId === commissionTypeEnum.DISTRICT_COMMISSION ?
							<div className="col-lg-4">
								<ConfonetAutocomplete
									id="toDistrict"
									className="uppercase"
									value={formik.values.toDistrict}
									options={toDistrictList}
									renderInput={
										(params) => (
											<TextField
												label={"To District Commision*"}
												{...params}
												error={formik.touched.toDistrict && Boolean(formik.errors.toDistrict)}
												helperText={formik.touched.toDistrict && formik.errors.toDistrict?.label}
												fullWidth
											/>
										)
									}
									disabled={location.pathname.includes('reverted')}
									onChange={(_, newValue: IDropdown | null) => { formik.setFieldValue(`toDistrict`, newValue ?? { label: "", value: "" }) }}
									onBlur={() => { formik.setFieldTouched(`toDistrict.label`, true) }}
								/>
							</div> : null}
					</div>
					{/* <div className="row">
						<div className="col-lg-12">
							<ConfonetTextEditorTiny
								id="caseDetails"
								value={formik.values.caseSummary}
								onEditorChange={editorValueChange}
								disabled
							/>
						</div>
					</div> */}
				</ConfonetCardBody>
			</ConfonetCard>
		</div>
	)
}

export default TransferApplicationCaseDetails