import PersonalDetailsModel from "../../forms/PersonalDetailsModel";

/**
 * View model for the additional complainant form
 */
export class AdditionalComplainantViewModel {
    constructor(
        public addAdditionalComplainant: boolean,
        public addAdditionalComplainantPerforma:boolean,
        public additionalComplainants: PersonalDetailsModel[],
        public additionalComplainantPerforma: PersonalDetailsModel[],
    ) {
        this.addAdditionalComplainant = addAdditionalComplainant;
        this.additionalComplainants = additionalComplainants;
        this.addAdditionalComplainantPerforma = addAdditionalComplainantPerforma;
        this.additionalComplainantPerforma = additionalComplainantPerforma;
    }

    static init = () => new AdditionalComplainantViewModel(false,false, [], [])
}

