import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { TextField, Box } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { MasterService } from '../../services/MasterService/MasterService';
import { IGetAdvocateResponse } from '../../models/cases/fileNewCase/IFileNewCaseModel';

interface CustomDataGridProps<T> {
    columns: GridColDef[];
}

const DataGridCustom = <T extends { id: number | string }>({
    columns,

}: CustomDataGridProps<T>) => {
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await MasterService.getAllAdvocatesbySearchPage(page, searchQuery);
                // setRows(result);
                setRows(
                    result.map((value: any, index: number) => {
                        return {
                            id: index + 1,
                            ...value,
                            mobileNumber: value.mobileNumber === -1 ? "N/A" : value.mobileNumber ? value.mobileNumber : "N/A",
                            barCouncilId: value.barCouncilId !== null ? value.barCouncilId : "N/A",
                            emailId: value.emailId !== null ? value.emailId : "N/A",
                        };
                    })
                );
                // setRowCount(response.data.rowCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (searchQuery.length >= 5 || searchQuery.length === 0) {
            loadData();
        }
    }, [searchQuery, page, pageSize,]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0); // Reset to the first page on new search
    };

    const handlePageChange = (paginationModel: GridPaginationModel) => {
        setPage(paginationModel.page);
    };

    const handlePageSizeChange = (paginationModel: GridPaginationModel) => {
        setPageSize(paginationModel.pageSize);
        setPage(rows.length); // Reset to the first page on page size change
    };


    return (
        <Box>
            <div><h3>Advocate Search</h3>
            </div>
            <TextField
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                margin="normal"
            />
            {
                rows.length > 0 &&
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pagination

                    paginationModel={{ page, pageSize }}
                    paginationMode="server"
                    rowCount={rowCount}
                    onPaginationModelChange={(model) => {
                        handlePageChange(model);
                        handlePageSizeChange(model);
                    }}
                    getRowId={(row) => row.id} // 
                />
            }

        </Box>
    );
};

export default DataGridCustom;
