import { DialogActions, DialogContent, Typography } from "@mui/material";
import { useFormik } from "formik";
import debounce from 'lodash.debounce';
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BasicButton,
  ConfonetCard,
  ConfonetCardBody,
  ConfonetCardHeader,
  Dialogbox,
  PreviewCard,
} from "../../../../components";
import ConfonetPdfViewer from "../../../../components/controls/ConfonetPdfViewer";
import { DocumentPreviewCard } from "../../../../components/controls/Forms/DocumentPreviewCard";
import {
  PersonalDetailsForm,
  complainantPersonalValidationSchema,
  respondentPersonalValidationSchema,
} from "../../../../components/controls/Forms/PersonalDetails";
import { useCaseSpecificData } from "../../../../hooks/useCaseSpecificData";
import { useFileNewCaseFormik } from "../../../../hooks/useFileNewCaseFormik";
import {
  ICCPACaseDetailViewModel,
  ICCPACaseDetailsModel,
} from "../../../../models/cases/ccpaTypeCase/ICCPACaseDetailsModel";
import { IComplainantRespondantDetailsResponse } from "../../../../models/cases/commonCase/ICommonCaseModel";
import {
  ICaseFillingDocumentDetailsResponseList,
  IGetDocumentResponse
} from "../../../../models/cases/consumerCases/IConsumerCaseModel";
import {
  CaseDetailFormViewModel,
  CaseDetailModel,
} from "../../../../models/cases/fileNewCase/CaseDetailFormModel";
import {
  DocumentUpdateRequestPayload,
  DocumentUploadModel,
  UploadFileModel,
} from "../../../../models/cases/fileNewCase/DocumentUploadModel";
import {
  FinalSubmitModel,
  FinalSubmitViewModel,
} from "../../../../models/cases/fileNewCase/FinalSubmitModel";
import { IDropdown } from "../../../../models/common/IDropdown";
import PersonalDetailsModel, { IPersonalDetailsModelToShow } from "../../../../models/forms/PersonalDetailsModel";
import {
  ADVOCATE_DASHBOARD,
  COMMISSION_EDIT_CASE_STEPPERFORM,
  CONSUMER_DASHBOARD,
  CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW,
} from "../../../../navigation/CONSTANTS";
import { RootState } from "../../../../redux";
import { ConsumerCaseServices } from "../../../../services/CaseService/ConsumerCaseService/ConsumerCaseServices";
import { FileNewCaseServices } from "../../../../services/CaseService/ConsumerCaseService/FileNewCaseService/FileNewCaseServices";
import { MasterService } from "../../../../services/MasterService/MasterService";
import { complainantRespondantDetails, generateComplainantRespondantDataToSubmit } from "../../../../utils/CaseHelper";
import { ROLE, caseTypeEnum } from "../../../../utils/Enum";
import {
  b64toBlob,
  caseDetailsResponseToView,
  ccpaCaseDetailsResponseToView,
  convertFileToUnit8Array,
  flattenArrayToIPreview,
  getCommissionType,
  htmlStringToPdf,
  isSameObjectExceptIdAndAddressId,
  makeDropdownOptions,
  updateSelectedIaPartydetails
} from "../../../../utils/Helper";
import AcknowledgementTemplate from "../../../../utils/Templates/AcknowledgementTemplate";
import { IPersonalDetailWithAdvocateDetails } from "../../../dashboard/components/CasePreview";
import CCPACaseDetailForm from "../../CCPATypeCase/components/CCPACaseDetailForm";
import CaseDetailForm from "./StepperComponents/CaseDetailForm";
import DocumentUpload from "./StepperComponents/DocumentUpload";
import FinalSubmit from "./StepperComponents/FinalSubmit";
import useIaWithConsumerCaseSubmit from "../../../../hooks/FileNewCaseHooks/useIaWithConsumerCaseSubmit";
import { IIaDocumentList } from "../../../Commissions/Scrutiny/components/CaseScrutinyStepperForm";

/**
 * Renders preview of the entire file new case form
 *
 * @returns JSX.Element
 */
const Preview = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { filingReferenceNumber, caseTypeId, locationTypeName, locationTypeId, iaDocs, complainantRespondentdetailsForIa, attachedCases } = location.state;
  const navigate = useNavigate();
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  const [interApplicationDocuments, setInterApplicationDocuments] = useState<UploadFileModel[]>((iaDocs && iaDocs.length > 0) ? iaDocs : []);

  const { complainantLabel, respondentLabel } = useCaseSpecificData({
    caseTypeId,
    setNewFilingReferenceNumber: () => { },
    handleNext: () => { },
  });

  const { handleIaCaseFiling, iaFilingRefrenceNumberList } = useIaWithConsumerCaseSubmit({
    iaDocs: interApplicationDocuments.filter((value) => value.activeStatus),
    ccFilingRefrenceNumber: filingReferenceNumber,
    ccCaseTypeId: caseTypeEnum.ConsumerCase,
    complainantRespondentdetailsForIa: complainantRespondentdetailsForIa
  });

  const [finishDialogueVisible, setFinishDialogueVisible] = useState<number>(0);
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [editStep, setEditStep] = useState<number>(0);
  const [advocateOptions, setAdvocateOptions] = useState<IDropdown[]>([]);
  const [commissionOptions, setCommissionOptions] = useState<IDropdown[]>([]);
  const [locationType, setLocationType] = useState<string>(locationTypeName ?? "");
  const [locationTypeValue, setLocationTypeValue] = useState<number>(locationTypeId ?? 0);
  const [isRespondent, setIsRespondent] = useState<number>(0);
  const [crSequenceNumber, setCrSequenceNumber] = useState<number>(0);

  const [caseDetails, setCaseDetails] = useState<CaseDetailFormViewModel>();
  const [ccpaCaseDetails, setCcpaCaseDetails] = useState<ICCPACaseDetailViewModel>();
  const [complainantDetails, setComplainantDetails] = useState<IPersonalDetailWithAdvocateDetails>();
  const [complainantDetailsToShow, setComplainantDetailsToShow] = useState<IPersonalDetailsModelToShow>();
  const [respondentDetails, setRespondentDetails] = useState<PersonalDetailsModel>();
  const [respondentDetailsToShow, setRespondentDetailsToShow] = useState<IPersonalDetailsModelToShow>();
  const [additionalComplainantDetails, setAdditionalComplainantDetails] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [additionalComplainantDetailsToShow, setAdditionalComplainantDetailsToShow] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [additionalRespondentDetails, setAdditionalRespondentDetails] = useState<PersonalDetailsModel[]>([]);
  const [additionalRespondentDetailsToShow, setAdditionalRespondentDetailsToShow] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [documentDetails, setDocumentDetails] = useState<ICaseFillingDocumentDetailsResponseList[]>();
  const [finalSubmitDetails, setFinalSubmitDetails] = useState<FinalSubmitViewModel>();
  const [documentContent, setDocumentContent] = useState<Uint8Array>(new Uint8Array([255]));
  const [additionalComplainantResponsePerforma, setAdditionalComplainantResponsePerforma] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [additionalRespondantResponsePerforma, setAdditionalRespondantResponsePerforma] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [additionalComplainantCaseDetails, setAdditionalComplainantCaseDetails] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [additionalRespondentProformaCaseDetails, setAdditionalRespondentProformaCaseDetails] = useState<IPersonalDetailWithAdvocateDetails[]>([]);
  const [caseFiledBy, setCaseFiledBy] = useState<number>(0);

  // all IA with cc related states
  const [docsOfIa, setDocsOfIa] = useState<UploadFileModel[][]>([]);
  const [iaAllPartyDetails, setIaAllPartyDetails] = useState<PersonalDetailsModel[]>([]);
  const [selectedIaPartyDetails, setSelectedIaPartyDetails] = useState<PersonalDetailsModel[]>([]);
  const [iaRefNumber, setIaRefNumber] = useState<number[]>(iaFilingRefrenceNumberList);
  const [showOtherButton, setShowOtherButton] = useState<boolean>(true);
  const [selectedIADocumentIndex, setSelectedIADocumentIndex] = useState<number>(-1);

  const isRevertedCase = location.pathname.includes(
    CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW
  );

  // handle case detail submit
  const handleCaseDetailSubmit = async (data: CaseDetailFormViewModel) => {
    const dateString = data.dateOfCause.toFormat("dd-MM-yyyy");
    const paidConsiderationOriginalValue = data.paidConsideration.replaceAll(
      ",",
      ""
    );
    const claimConsiderationOriginalValue = data.claimConsideration.replaceAll(
      ",",
      ""
    );

    let caseDetailData: CaseDetailModel = {
      caseTypeId: caseDetails?.caseTypeId as number,
      paidAmount: paidConsiderationOriginalValue,
      claimAmount: claimConsiderationOriginalValue,
      dateOfCause: dateString,
      caseStageId: 117,
      stateIdOfPlaceOfCause: parseInt(data.stateOfCauseOfAction.value),
      districtIdOfPlaceOfCause: parseInt(data.districtOfCauseOfAction.value),
      caseCategoryId: data.subSubCategory?.value ? parseInt(data.subSubCategory.value)
        : data.subCategory?.value ? parseInt(data.subCategory.value)
          : parseInt(data.caseCategory?.value),
      remarks: data.caseDetails,
      caseFiledBy: caseFiledBy,
      updatedBy: userDetailsData.userId,
    };
    if (filingReferenceNumber) {
      caseDetailData.filingReferenceNumber = filingReferenceNumber.toString();
      const updateCaseDetailsResponse =
        await FileNewCaseServices.updateCaseDetails(caseDetailData);
      if (updateCaseDetailsResponse) {
        toast.success(
          `Your case details have been updated with reference number ${updateCaseDetailsResponse.filingReferenceNumber}`
        );
        setEditDialogVisible(false);
      }
      if (!location.pathname.includes("reverted")) {
        handleGetCommission();
        editFinalSubmitDetails(true);
      }
    }
  };

  //update CCPA case Details
  const handleCCPACaseDetailsSubmit = async (
    data: ICCPACaseDetailViewModel
  ) => {
    const dateOfOrder = data.dateOfOrder.toFormat("yyyy-MM-dd");

    const caseDetailsData: ICCPACaseDetailsModel = {
      caseDetails: {
        caseCategoryId: data.subSubCategory.value,
        caseTypeId: caseTypeEnum.CCPAAppeal,
        caseFiledBy: userDetailsData.userId,
        filingReferenceNumber: parseInt(data.fillingReferenceNumber as string),
      },
      otherLegalBodyId: data.authority.value,
      otherLegalBodyOrderDate: dateOfOrder,
      authorityId: data.authorityId,
      remarks: data.caseDetails,
    };
    if (filingReferenceNumber) {
      const updateCaseDetailsResponse =
        await FileNewCaseServices.addCCPACaseDetails(caseDetailsData);
      if (updateCaseDetailsResponse) {
        toast.success(
          `Your case details have been updated with reference number ${updateCaseDetailsResponse?.caseDetails?.filingReferenceNumber}`
        );
        setEditDialogVisible(false);
      }
    }
  };

  const handlePersonalDetailsSubmit = async (data: PersonalDetailsModel) => {
    const caseFillingComplainantRespondantDetails = [generateComplainantRespondantDataToSubmit(data, isRespondent, crSequenceNumber, filingReferenceNumber, userDetailsData.userId)]
    await FileNewCaseServices.addUpdateComplainantRespondentDetailsV2(caseFillingComplainantRespondantDetails);
    if (iaAllPartyDetails.length > 0) {
      await handleIaPersonalDetailsSubmit(updateSelectedIaPartydetails(selectedIaPartyDetails, data))
    }
    toast.success("Details updated");
    setEditDialogVisible(false);
  };

  const handleIaPersonalDetailsSubmit = async (data: PersonalDetailsModel[]) => {
    for (const iaCase of data) {
      try {
        const caseFillingComplainantRespondantDetails = [generateComplainantRespondantDataToSubmit(iaCase, isRespondent, crSequenceNumber, iaCase.filingRefrenceNumber as number, userDetailsData.userId)]
        await FileNewCaseServices.addUpdateComplainantRespondentDetailsV2(caseFillingComplainantRespondantDetails);
      } catch (error) {
        console.log('error:', error);
        break;
      }
    }
  }

  const closeEditDialog = () => {
    setEditDialogVisible(false);
    if (editStep === 3) {
      handleSetCommission()
    }
    setAdvocateOptions([]);
    personalDetailsFormik.resetForm();
  };

  const handlePersonalDetailsDelete = async () => {
    const partyId = personalDetailsFormik.values?.partyId;
    if (partyId) {
      await FileNewCaseServices.deleteComplainantRespondantDetailsV2(partyId);
    }
    toast.success("Details updated");
    setEditDialogVisible(false);
    handleComplainantRespondentDetails();
  };

  // Handle document details submit
  const handleDocumentSubmit = async (data: DocumentUploadModel) => {
    if (!showOtherButton) {
      let allIaDocuments = docsOfIa;
      allIaDocuments[selectedIADocumentIndex] = data.uploadedFiles;

      // Step 1: Flatten the nested array
      let flattenedArray = allIaDocuments.flat();

      // Step 2: Remove duplicates based on the `id` field
      const uniqueDocuments = [];
      const seenIds: any = []; // Array to track the seen caseFilingDocumentDetailsId

      for (const doc of flattenedArray) {
        // If caseFilingDocumentDetailsId is undefined, just push it into uniqueDocuments
        if (doc.caseFilingDocumentDetailsId === undefined) {
          uniqueDocuments.push(doc);
        }
        // Otherwise, check if the combination of caseFilingDocumentDetailsId and files already exists
        else {
          const isDuplicate = seenIds.some((entry: any) =>
            entry.id === doc.caseFilingDocumentDetailsId && JSON.stringify(entry.files) === JSON.stringify(doc.files)
          );

          if (!isDuplicate) {
            // If no duplicate is found, add the caseFilingDocumentDetailsId and files to seenIds
            seenIds.push({ id: doc.caseFilingDocumentDetailsId, files: doc.files });
            uniqueDocuments.push(doc); // Add the unique document to uniqueDocuments
          }
        }
      }
      setInterApplicationDocuments(uniqueDocuments);
      setEditDialogVisible(false);
    } else {
      const documentDetailsList = data.uploadedFiles.map((item): DocumentUpdateRequestPayload => {
        return {
          filingReferenceNumber: filingReferenceNumber,
          documentSequenceNumber: item.documentSequenceNumber,
          documentUploadStageId: 1,
          documentId: item.documentId,
          documentNameEn: item.documentNameEn,
          createdBy: userDetailsData.userId,
          updatedBy: userDetailsData.userId,
          activeStatus: item.activeStatus,
          caseTypeId: caseTypeId,
          caseFilingDocumentDetailsId: item.caseFilingDocumentDetailsId
        }
      })

      const files: File[] = data.uploadedFiles.flatMap((item, index) => {
        return item.files;
      });

      if (documentDetailsList) {
        //saveCasefilling in  FileNewCaseServices to make the API call
        const jsonData = JSON.stringify(documentDetailsList);

        let formData = new FormData();
        formData.append("details", jsonData);

        files.forEach((file: File) => {
          formData.append("files", file);
        });

        const response = await FileNewCaseServices.saveCaseFillingDocumentDetails(
          formData
        );
        if (response) {
          toast.success("Documents saved successfully!");
          setEditDialogVisible(false);
        }
      }
    }
  };

  const handleFinalSubmit = async (data: FinalSubmitViewModel) => {
    setFinalSubmitDetails(data);
    setEditDialogVisible(false);
  };

  const handlePrint = async (referenceNumber: number) => {
    // api calling
    const response = await FileNewCaseServices.getAcknowledgement(
      referenceNumber
    );
    const template = (
      <AcknowledgementTemplate
        headerMain="Acknowledgement"
        complainantHeader={complainantLabel}
        respondentHeader={respondentLabel}
        referenceNumber={referenceNumber}
        complainant={response.complainentName}
        respondent={response.respondentName}
        category={response.caseCategoryNameEnPrev}
        dateOfFiling={
          response?.caseFilingDatePrev &&
          DateTime.fromFormat(
            response.caseFilingDatePrev,
            "yyyy-MM-dd"
          ).toFormat("dd-MM-yyyy")
        }
        commission={response.commissionNameEn}
        commissionTypeAbbreviation={response.commissionTypeAbbreviation}
      />
    );
    const acknowledgementHtml = ReactDOMServer.renderToString(template);
    const acknowledgementHtmlFile = await htmlStringToPdf(
      acknowledgementHtml,
      "Acknowledgement",
      "portrait"
    );
    setDocumentContent(
      await convertFileToUnit8Array(acknowledgementHtmlFile.myFile)
    );
  };

  const {
    caseDetailsFormik,
    documentUploadFormik,
    finalSubmitFormik,
    ccpaCaseDetailsFormik,
  } = useFileNewCaseFormik({
    onCaseDetailsSubmit: handleCaseDetailSubmit,
    onComplainantRespondentSubmit: () => new Promise((resolve) => resolve()),
    onAdditionalComplainantSubmit: () => new Promise((resolve) => resolve()),
    onAdditionalRespondentSubmit: () => new Promise((resolve) => resolve()),
    onDocumentUploadSubmit: handleDocumentSubmit,
    onFinalSubmit: handleFinalSubmit,
    onCCPADetailsSubmit: handleCCPACaseDetailsSubmit,
  });

  const personalDetailsFormik = useFormik({
    initialValues: PersonalDetailsModel.init(),
    validationSchema: (isRespondent === 2 || isRespondent === 4)
      ? respondentPersonalValidationSchema
      : complainantPersonalValidationSchema,
    onSubmit: handlePersonalDetailsSubmit,
  });

  const editElements: React.ReactNode[] = [
    <CaseDetailForm
      key={1}
      formik={caseDetailsFormik}
      isDraft={!isRevertedCase}
    />,
    <PersonalDetailsForm
      key={2}
      formik={personalDetailsFormik}
      advocateOptions={advocateOptions}
      CRsequenceNumber={crSequenceNumber}
      isRespondent={isRespondent}
      complainantLabel={complainantLabel}
      respondentLabel={respondentLabel}
    />,
    <DocumentUpload
      key={3}
      formik={documentUploadFormik}
      documentDetails={documentDetails}
      showIaButton={false}
      showAddOtherButton={showOtherButton}
    />,
    <FinalSubmit
      key={4}
      formik={finalSubmitFormik}
      commissionOptions={commissionOptions}
      locationType={locationType}
      disableDeclare
    />,
    <CCPACaseDetailForm
      key={5}
      formik={ccpaCaseDetailsFormik}
      isDraft={!isRevertedCase}
    />,
  ];

  const handleSubmit = async () => {
    if (finalSubmitDetails?.commission) {
      const data: FinalSubmitModel = {
        commissionId: finalSubmitDetails?.commission.value,
        fillingReferenceNumber: filingReferenceNumber,
      };
      const response = await FileNewCaseServices.saveFinalSubmit(data);
      if (response) {
        if (iaDocs?.length) {
          const iaRefs = await handleIaCaseFiling(finalSubmitDetails?.commission?.value);
          setIaRefNumber(iaRefs);
        } else if (attachedCases?.length > 0) {
          await handleIaFinalSubmit(finalSubmitDetails?.commission.value);
        }

        setFinishDialogueVisible(2);
      }
    }
  };

  const handleIaFinalSubmit = async (commission: number) => {
    let iaFilingRefrenceNumber: number[] = []
    if (commission) {
      await handleIaCaseDocumentSubmit();
      for (const iaCase of attachedCases) {
        const data: FinalSubmitModel = {
          commissionId: commission,
          fillingReferenceNumber: iaCase.referenceNumber,
        }
        await FileNewCaseServices.saveFinalSubmit(data);
        iaFilingRefrenceNumber.push(iaCase.referenceNumber);
      }
    }
    setIaRefNumber(iaFilingRefrenceNumber);
  };

  const handleIaCaseDocumentSubmit = async () => {
    for (const iaCaseDoc of docsOfIa) {
      try {
        const documentDetailsList = iaCaseDoc.map((item): DocumentUpdateRequestPayload => {
          return {
            filingReferenceNumber: item.filingRefrenceNumber ? item.filingRefrenceNumber : 0,
            documentSequenceNumber: item.documentSequenceNumber,
            documentUploadStageId: 1,
            documentId: item.documentId,
            documentNameEn: item.documentNameEn,
            createdBy: userDetailsData.userId,
            updatedBy: userDetailsData.userId,
            activeStatus: item.activeStatus,
            caseTypeId: caseTypeEnum.InterlocutoryApplication,
            parentFilingReferenceNumber: filingReferenceNumber,
            caseFilingDocumentDetailsId: item?.caseFilingDocumentDetailsId
          }
        });

        const files: File[] = iaCaseDoc.flatMap((item, index) => {
          return item.files;
        });

        if (documentDetailsList) {
          //saveCasefilling in  FileNewCaseServices to make the API call
          const jsonData = JSON.stringify(documentDetailsList);

          let formData = new FormData();
          formData.append("details", jsonData);
          for (let file of files) {
            formData.append("files", file);
          }

          await FileNewCaseServices.saveCaseFillingDocumentDetails(
            formData
          );
        }
      } catch (error) {
        console.log('error:', error);
        break;
      }
    }
  }

  // get path for preview page according to role
  const getPathforPreviewForm = useMemo(() => {
    if (userDetailsData.roleId === ROLE.Consumer) {
      return CONSUMER_DASHBOARD;
    } else {
      return ADVOCATE_DASHBOARD;
    }
  }, [userDetailsData.roleId]);

  const handleFinish = () => {
    setFinishDialogueVisible(0);
    navigate(getPathforPreviewForm);
  };

  const onDialogClose = () => {
    if (finishDialogueVisible === 1) {
      setFinishDialogueVisible(0);
    } else {
      handleFinish();
    }
  };

  // Gets case details using API
  const handleGetCaseDetails = useCallback(async () => {
    if (caseTypeId === caseTypeEnum.ConsumerCase) {
      const response = await ConsumerCaseServices.getCaseDetails(
        filingReferenceNumber,
        userDetailsData?.userId,
        userDetailsData?.commissionId
      );
      if (response) {
        setCaseFiledBy(response.caseFiledBy);
        setCaseDetails(caseDetailsResponseToView(response));
      }
    } else {
      const response = await FileNewCaseServices.getCCPACaseDetails(
        filingReferenceNumber
      );
      if (response) {
        setCcpaCaseDetails(ccpaCaseDetailsResponseToView(response));
      }
    }
  }, [caseTypeId, filingReferenceNumber, userDetailsData?.commissionId, userDetailsData?.userId]);

  // Gets all complainants and all respondents data (including additional complainant and additional respondent) using API
  const handleComplainantRespondentDetails = useCallback(async () => {
    const response =
      await ConsumerCaseServices.getComplainantRespondentDetail(
        filingReferenceNumber
      );

    if (response) {
      const {
        complainant,
        respondent,
        additionalComplainant,
        additionalRespondent,
        additionComplainantPerforma,
        additionalRespondentPerfroma
      } = await complainantRespondantDetails(response, true);
      console.log(complainant);
      setRespondentDetails(respondent);
      setComplainantDetails({ ...complainant });
      setAdditionalComplainantDetails(additionalComplainant);
      await handleSetComplainantdetails(response)
      setAdditionalRespondentDetails(additionalRespondent);
      setAdditionalComplainantCaseDetails(additionComplainantPerforma);
      setAdditionalRespondentProformaCaseDetails(additionalRespondentPerfroma)
    }
  }, [filingReferenceNumber]);

  const handleComplainantRespondentDetailsOfIa = useCallback(async () => {
    let partyDetails: PersonalDetailsModel[][] = []
    if (isRevertedCase) {
      if (attachedCases?.length) {
        for (const iaCase of attachedCases) {
          try {
            const allPartyDetails = await handleIaPartyDetails(iaCase.referenceNumber);
            partyDetails.push(allPartyDetails);
          } catch (error) {
            console.log('error:', error);
            break;
          }
        }
      }
    } else {
      if (iaDocs.length) {
        for (const iaRefNumber of iaFilingRefrenceNumberList) {
          try {
            const allPartyDetails = await handleIaPartyDetails(iaRefNumber);
            partyDetails.push(allPartyDetails);
          } catch (error) {
            console.log('error:', error);
            break;
          }
        }
      }
    }
    const iaPartydetails = partyDetails.flatMap((value) => value);
    setIaAllPartyDetails(iaPartydetails);
  }, []);

  const handleIaPartyDetails = async (referenceNumber: number) => {
    const response =
      await ConsumerCaseServices.getComplainantRespondentDetail(
        referenceNumber
      );
    const {
      allComplainant,
      allRespondant,
    } = await complainantRespondantDetails(response, true);

    return [...allComplainant, ...allRespondant]
  };

  const handleSetComplainantdetails = async (response: IComplainantRespondantDetailsResponse) => {

    const {
      complainantToShow,
      respondentToShow,
      additionalComplainantToShow,
      additionalRespondentToShow,
      additionalComplainantToShowPerforma,
      additionalRespondentToShowPerfroma

    } = await complainantRespondantDetails(response, true);
    setRespondentDetailsToShow({ ...respondentToShow })
    setComplainantDetailsToShow({ ...complainantToShow });
    setAdditionalComplainantDetailsToShow(additionalComplainantToShow);
    setAdditionalRespondentDetailsToShow(additionalRespondentToShow);
    setAdditionalComplainantResponsePerforma(additionalComplainantToShowPerforma)
    setAdditionalRespondantResponsePerforma(additionalRespondentToShowPerfroma)
  };

  // Gets document details using API
  const handleGetDocumentDetails = useCallback(async () => {
    const response: IGetDocumentResponse =
      await ConsumerCaseServices.getCaseFillingDocumentDetails(
        filingReferenceNumber
      );
    if (
      response &&
      Object.keys(response).length > 0 &&
      response?.caseFillingDocumentDetailsResponseList?.length > 0
    ) {
      setDocumentDetails(response.caseFillingDocumentDetailsResponseList);
    }
  }, [filingReferenceNumber]);

  const handleGetIaCaseDocuments = async () => {
    let modifiedIaDocs: UploadFileModel[][] = [];
    if (attachedCases && attachedCases?.length > 0) {
      for (const iaCase of attachedCases) {
        try {
          const response = await ConsumerCaseServices.getCaseFillingDocumentDetails(iaCase.referenceNumber);
          const iaDocumentsList = response?.caseFillingDocumentDetailsResponseList?.map(
            (document: ICaseFillingDocumentDetailsResponseList) => {
              const file = b64toBlob(
                document.fileContent,
                "application/pdf",
                document.fileName
              );
              return new UploadFileModel(
                document.documentId,
                document.documentName,
                [file],
                true,
                document.documentSequenceNumber,
                document.isMandatoryStatus,
                undefined,
                document.caseFilingDocumentDetailsId,
                { label: iaCase?.caseReasonTypeDisplayText, value: iaCase?.caseReasonTypeId },
                document.filingReferenceNumber
              );
            });
          modifiedIaDocs.push(iaDocumentsList)
        } catch (error) {
          console.log('error while fetching IA case documents', error);
          break;
        }
      }
    }
    setDocsOfIa(modifiedIaDocs)
  }

  // Get final submit details using formik
  const handleGetFinalSubmitDetails = () => {
    setFinalSubmitDetails(finalSubmitFormik.values);
  };

  // Handles get commission list
  const handleGetCommission = useCallback(async () => {
    if (caseTypeId === caseTypeEnum.ConsumerCase) {
      const commisionLocationData = {
        filingReferenceNumber: filingReferenceNumber,
      };
      const commissionList = await FileNewCaseServices.getCommissionLocation(
        commisionLocationData
      );
      setLocationType(commissionList?.locationType);
      setLocationTypeValue(commissionList?.commissionTypeId)
      setCommissionOptions(
        makeDropdownOptions(
          commissionList?.locationNames,
          "commissionName",
          "commissionId"
        )
      );
    } else if (caseTypeId === caseTypeEnum.CCPAAppeal) {
      setLocationType("NCDRC");
      setLocationTypeValue(1);
      setCommissionOptions(
        makeDropdownOptions(
          [{ commissionName: "NCDRC", commissionId: 11000000 }],
          "commissionName",
          "commissionId"
        )
      );
    }
  }, [filingReferenceNumber, caseTypeId]);

  // Edit functions for all the forms
  const editCaseDetails = async () => {
    if (caseDetails) {
      await caseDetailsFormik.setValues(caseDetails);
      await caseDetailsFormik.setFieldValue(
        "fillingReferenceNumber",
        filingReferenceNumber
      );
      setEditStep(0);
      setEditDialogVisible(true);
    } else if (ccpaCaseDetails) {
      await ccpaCaseDetailsFormik.setValues(ccpaCaseDetails);
      await ccpaCaseDetailsFormik.setFieldValue(
        "fillingReferenceNumber",
        filingReferenceNumber
      );
      setEditStep(4);
      setEditDialogVisible(true);
    }
  };

  const editPersonalDetails = async (
    data: PersonalDetailsModel,
    CRTypeId: number,
    CRsequenceNumber: number
  ) => {
    if (data) {
      await personalDetailsFormik.setValues(data);
      const filteredParty = iaAllPartyDetails.filter(obj => isSameObjectExceptIdAndAddressId(obj, data));
      setSelectedIaPartyDetails(filteredParty);
      setIsRespondent(CRTypeId);
      setCrSequenceNumber(CRsequenceNumber);
      setEditStep(1);
      setEditDialogVisible(true);
    }
  };

  const editDocumentDetails = async (iaDocs: UploadFileModel[], index?: number) => {
    let uploadedFiles: UploadFileModel[] = [];
    if (iaDocs.length > 0) {
      uploadedFiles = iaDocs.map((doc) => new UploadFileModel(
        doc.documentId,
        doc.documentNameEn,
        doc.files,
        doc.activeStatus,
        doc.documentSequenceNumber,
        doc.isMandatoryStatus,
        doc.isIA,
        doc.caseFilingDocumentDetailsId,
        doc.selectedIaReason
      ));
      documentUploadFormik.setValues({ uploadedFiles: uploadedFiles });
      setSelectedIADocumentIndex(index as number);
      setShowOtherButton(false);
      setEditStep(2);
      setEditDialogVisible(true);
      return;
    }
    if (documentDetails) {
      setShowOtherButton(true);
      uploadedFiles = documentDetails.map(
        (document: ICaseFillingDocumentDetailsResponseList) => {
          const file = b64toBlob(
            document.fileContent,
            "application/pdf",
            document.fileName
          );
          return new UploadFileModel(
            document.documentId,
            document.documentName,
            [file],
            true,
            document.documentSequenceNumber,
            document.isMandatoryStatus,
            undefined,
            document.caseFilingDocumentDetailsId
          );
        }
      );
      documentUploadFormik.setValues({ uploadedFiles: uploadedFiles });
      setEditStep(2);
      setEditDialogVisible(true);
    }
  };

  const editFinalSubmitDetails = async (blank: boolean = false) => {
    if (finalSubmitDetails) {
      if (blank) {
        setFinalSubmitDetails(FinalSubmitViewModel.init(true));
      }
      await finalSubmitFormik.setValues(
        blank ? FinalSubmitViewModel.init(true) : finalSubmitDetails
      );
      setEditStep(3);
      setEditDialogVisible(true);
    }
  };

  const formikSubmit = async () => {
    switch (editStep) {
      case 0:
        await caseDetailsFormik.submitForm();
        if (caseDetailsFormik.isValid) {
          handleGetCaseDetails();
        }
        break;
      case 1:
        await personalDetailsFormik.submitForm();
        if (personalDetailsFormik.isValid) {
          handleComplainantRespondentDetails();
        }
        break;
      case 2:
        await documentUploadFormik.submitForm();
        if (documentUploadFormik.isValid) {
          handleGetDocumentDetails();
        }
        break;
      case 3:
        await finalSubmitFormik.submitForm();
        if (finalSubmitFormik.isValid) {
          handleGetFinalSubmitDetails();
        }
        break;
      case 4:
        await ccpaCaseDetailsFormik.submitForm();
        if (ccpaCaseDetailsFormik.isValid) {
          handleGetCaseDetails();
        }
    }
  };

  useEffect(() => {
    handleGetCaseDetails();
    handleComplainantRespondentDetails();
    handleGetDocumentDetails();
    handleGetCommission();
    handleComplainantRespondentDetailsOfIa();
    setFinalSubmitDetails(
      new FinalSubmitViewModel(location.state?.commission, true)
    );
  }, [handleGetCaseDetails, handleComplainantRespondentDetails, handleGetCommission, handleGetDocumentDetails, location.state?.commission]);

  const getCommissionLabel = () => {
    if (caseTypeId === caseTypeEnum.CCPAAppeal) {
      return `${finalSubmitDetails?.commission.label}`
    } else {
      if (location.pathname.includes('reverted')) {
        return `${finalSubmitDetails?.commission.label} ${getCommissionType(locationTypeValue)}`
      } else {
        if (locationTypeValue === 1) {
          return `${finalSubmitDetails?.commission.label} commission`
        } else {
          return `${finalSubmitDetails?.commission.label} ${locationType} commission`
        }
      }
    }
  };

  // set commission after closing the dialog for final submit
  const handleSetCommission = () => {
    if (!finalSubmitFormik.values.commission.value || !finalSubmitDetails?.commission.value) {
      if (!finalSubmitFormik.values.commission.value) {
        toast.error('Please Select any commission to continue');
      }
      if (finalSubmitFormik.values.commission.value && !finalSubmitDetails?.commission.value) {
        toast.error('Please submit the form to continue')
      }
      setEditDialogVisible(true);
    }
  };

  const handleGetAdvocateQuery = async (query: string) => {
    try {
      const advocateList = await MasterService.getAllAdvocatesbySearch(query);
      const listOfAdvocates = advocateList.slice(0, 5000).map((advocates) => ({
        ...advocates,
        advocateList: `${advocates.advocateNameEn}, ${advocates?.mobileNumber !== -1 ? advocates.mobileNumber : "N/A"}`,
      }))
      setAdvocateOptions(makeDropdownOptions(listOfAdvocates, "advocateList", "advocateId"));
    } catch (error) {
      console.error('Error fetching advocate options:', error);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAdvocateQuery = useCallback(
    debounce((query) => {
      handleGetAdvocateQuery(query);
    }, 300),
    []
  );

  useEffect(() => {
    if (personalDetailsFormik.values.advocateText?.length as number > 4) {
      debouncedGetAdvocateQuery(personalDetailsFormik.values.advocateText);
    }
  }, [personalDetailsFormik.values.advocateText, debouncedGetAdvocateQuery]);

  useEffect(() => {
    if (iaDocs?.length > 0) {
      let modifiedIaDocs: UploadFileModel[][] = [];
      for (let i = 1; i < iaDocs.length; i++) {
        modifiedIaDocs.push([iaDocs.filter((doc: UploadFileModel) => doc.documentId === 1)[0], iaDocs[i]]);
      }
      setDocsOfIa(modifiedIaDocs);
    } else if (attachedCases && attachedCases?.length > 0) {
      handleGetIaCaseDocuments()
    }
  }, [iaDocs]);

  return (
    <section className="section margin-top-20">
      <div className="container-fluid">
        <Dialogbox
          title=""
          open={documentContent.length > 1}
          onClose={() => {
            setDocumentContent(new Uint8Array([255]));
          }}
        >
          <DialogContent>
            {documentContent.length > 1 && (
              <ConfonetPdfViewer fileUrl={documentContent} />
            )}
          </DialogContent>
        </Dialogbox>

        {/* Final submit dialog box */}
        <Dialogbox
          title="Final Submission Details"
          open={finishDialogueVisible > 0}
          onClose={onDialogClose}
        >
          <DialogContent>
            {finishDialogueVisible === 1 ? (
              <p>
                Are you sure you want to submit? You will not be able to edit
                the form after submission.
              </p>
            ) : (
              <>
                {caseDetails?.paidConsideration &&
                  caseDetails.paidConsideration >= "500000" ? (
                  <p>
                    Your case has been submitted to{" "}
                    <b>
                      {!location.pathname.includes(COMMISSION_EDIT_CASE_STEPPERFORM) && getCommissionLabel()}
                    </b>{" "}
                    with reference number. Keep the reference number as{" "}
                    <b>{filingReferenceNumber}</b> {iaRefNumber?.length > 0 && (
                      <>
                        along with this Interlocutory Application(IA) filing reference number(s)
                        <b>{iaRefNumber.join(', ')}</b>
                      </>
                    )} for all your future reference
                    until the case is admitted.{caseTypeId === caseTypeEnum.ConsumerCase && <>
                      After scrutiny, a payment link
                      will be provided. Please make the payment in order to
                      complete the case registration procedure.</>}
                  </p>
                ) : (
                  <p>
                    Your case has been submitted to{" "}
                    <b>
                      {!location.pathname.includes(COMMISSION_EDIT_CASE_STEPPERFORM) && getCommissionLabel()}
                    </b>{" "}
                    with reference number. Keep the reference number as{" "}
                    <b>{filingReferenceNumber}</b> {iaRefNumber?.length > 0 && (
                      <>
                        along with this Interlocutory Application(IA) filing reference number(s)
                        <b>{iaRefNumber.join(', ')}</b>
                      </>
                    )} for all your future reference
                    until the case is admitted.
                  </p>
                )}
              </>
            )}
          </DialogContent>
          {finishDialogueVisible === 1 ? (
            <DialogActions>
              <BasicButton
                text={t("BtnText.No")}
                onClick={() => setFinishDialogueVisible(0)}
                variant="outlined"
              />
              <BasicButton
                text={t("BtnText.Yes")}
                onClick={handleSubmit}
                variant="contained"
                autoFocus
              />
            </DialogActions>
          ) : (
            <DialogActions>
              <BasicButton
                text={t("BtnText.Print")}
                onClick={() => handlePrint(filingReferenceNumber)}
                variant="outlined"
                autoFocus
              />
              <BasicButton
                text={t("BtnText.Continue")}
                onClick={handleFinish}
                variant="contained"
                autoFocus
              />
            </DialogActions>
          )}
        </Dialogbox>

        {/* Edit dialog box form */}
        <form>
          <Dialogbox
            title="Edit"
            open={editDialogVisible}
            onClose={closeEditDialog}
            maxWidth="lg"
          >
            <DialogContent>
              {editElements.map((element: React.ReactNode, index: number) => {
                const id = index + 1;
                return (
                  <div key={id} hidden={index !== editStep}>
                    {element}
                  </div>
                );
              })}
            </DialogContent>
            <DialogActions>
              {editStep === 1 && crSequenceNumber > 0 && (
                <BasicButton
                  text={t("BtnText.Delete")}
                  onClick={handlePersonalDetailsDelete}
                  variant="outlined"
                />
              )}
              <BasicButton
                text={t("BtnText.Submit")}
                onClick={formikSubmit}
                variant="contained"
                autoFocus
              />
            </DialogActions>
          </Dialogbox>
        </form>

        {location?.state?.revertedRemarks && (
          <ConfonetCard>
            <ConfonetCardHeader>
              <div className="card-title card-primary-header-title float-start">
                {t("Label.RevertedReason")}
              </div>
            </ConfonetCardHeader>
            <ConfonetCardBody>
              <div className="margin-bottom-20 col-lg-12">
                {location.state?.revertedRemarks
                  .split("\n")
                  .map((line: string, index: number) => {
                    const id = index + 1;
                    return (
                      <Typography key={id} className="" variant="body2">
                        {line}
                      </Typography>
                    );
                  })}
              </div>
            </ConfonetCardBody>
          </ConfonetCard>
        )}
        {
          // Case details
          caseDetails && (
            <PreviewCard
              className="margin-top-25"
              heading={t("Label.CaseDetails")}
              items={flattenArrayToIPreview({
                fillingReferenceNumber: caseDetails.fillingReferenceNumber,
                claimConsideration: caseDetails.claimConsideration,
                paidConsideration: caseDetails.paidConsideration,
                dateOfCause: caseDetails.dateOfCause,
                stateOfCauseOfAction: caseDetails.stateOfCauseOfAction,
                districtOfCauseOfAction: caseDetails.districtOfCauseOfAction,
                caseCategory: caseDetails.caseCategory,
                subCategory: caseDetails.subCategory,
                ...(caseDetails.subSubCategory.value ? { subSubCategory: caseDetails.subSubCategory } : null),
                // caseDetails: caseDetails.caseDetails
                //   .replaceAll(/<[^>]*>/g, "")
                //   .replaceAll("&nbsp;", " ")
                //   .replaceAll("&amp;", "&"),
              })}
              edit={editCaseDetails}
            />
          )
        }
        {
          //ccpa Case Details
          ccpaCaseDetails && (
            <PreviewCard
              className="margin-top-25"
              heading={t("Label.CaseDetails")}
              items={flattenArrayToIPreview({
                fillingReferenceNumber: filingReferenceNumber,
                authorityName: ccpaCaseDetails.authority,
                authortityId: ccpaCaseDetails.authorityId,
                dateOfOrder: ccpaCaseDetails.dateOfOrder,
                caseCategory: ccpaCaseDetails.caseCategory,
                subCategory: ccpaCaseDetails.subCategory,
                ...(ccpaCaseDetails.subSubCategory.value ? { subSubCategory: ccpaCaseDetails.subSubCategory } : null),
                // caseDetails: ccpaCaseDetails.caseDetails
                //   .replaceAll(/<[^>]*>/g, "")
                //   .replaceAll("&nbsp;", " ")
                //   .replaceAll("&amp;", "&"),
              })}
              edit={editCaseDetails}
            />
          )
        }
        {
          // Complainant
          complainantDetails && complainantDetailsToShow && (
            <PreviewCard
              className="margin-top-25"
              heading={t("Label.ComplainantDetails")}
              items={flattenArrayToIPreview(complainantDetailsToShow)}
              edit={() => editPersonalDetails(complainantDetails, 1, 0)}
            />
          )
        }
        {
          // Respondent
          respondentDetails && respondentDetailsToShow && (
            <PreviewCard
              className="margin-top-25"
              heading={t("Label.OppositeParty") + " " + t("Label.Details")}
              items={flattenArrayToIPreview(respondentDetailsToShow)}
              edit={() => editPersonalDetails(respondentDetails, 2, 0)}
            />
          )
        }
        {
          // Additional Complainant
          !!additionalComplainantDetailsToShow?.length &&
          additionalComplainantDetailsToShow.length > 0 &&
          additionalComplainantDetailsToShow.map(
            (value: PersonalDetailsModel, index: number) => {
              return (
                <PreviewCard
                  key={value.mobileNumber}
                  className="margin-top-25"
                  heading={
                    t("Label.AdditionalComplainantDetails") + ` ${index + 1}`
                  }
                  items={flattenArrayToIPreview(value)}
                  edit={() => editPersonalDetails(additionalComplainantDetails[index] as PersonalDetailsModel, 1, index + 1)}
                />
              );
            }
          )
        }
        {
          // Additional Respondent
          !!additionalRespondentDetailsToShow?.length &&
          additionalRespondentDetailsToShow.length > 0 &&
          additionalRespondentDetailsToShow.map(
            (value: PersonalDetailsModel, index: number) => {
              return (
                <PreviewCard
                  key={value.mobileNumber}
                  className="margin-top-25"
                  heading={
                    t("Label.AdditionalOppositePartyDetails") +
                    ` ${index + 1}`
                  }
                  items={flattenArrayToIPreview(value)}
                  edit={() => editPersonalDetails(additionalRespondentDetails[index], 2, index + 1)}
                />
              );
            }
          )
        }

        {
          // Additional Complainant
          !!additionalComplainantResponsePerforma?.length &&
          additionalComplainantResponsePerforma.length > 0 &&
          additionalComplainantResponsePerforma.map(
            (value: PersonalDetailsModel, index: number) => {
              return (
                <PreviewCard
                  key={value.mobileNumber}
                  className="margin-top-25"
                  heading={
                    t("Label.AdditionalComplaintPerforma") + ` ${index + 1}`
                  }
                  items={flattenArrayToIPreview(value)}
                  edit={() => editPersonalDetails(additionalComplainantCaseDetails[index] as PersonalDetailsModel, 3, index + 1)}
                />
              );
            }
          )
        }

        {
          !!additionalRespondantResponsePerforma?.length &&
          additionalRespondantResponsePerforma.length > 0 &&
          additionalRespondantResponsePerforma.map(
            (value: PersonalDetailsModel, index: number) => {
              return (
                <PreviewCard
                  key={value.mobileNumber}
                  className="margin-top-25"
                  heading={
                    t("Label.AdditionalRespondentPerforma") + ` ${index + 1}`
                  }
                  items={flattenArrayToIPreview(value)}
                  edit={() => editPersonalDetails(additionalRespondentProformaCaseDetails[index] as PersonalDetailsModel, 4, index + 1)}
                />
              );
            }
          )
        }
        {
          docsOfIa && docsOfIa?.length > 0 && !location.pathname.includes(COMMISSION_EDIT_CASE_STEPPERFORM)
          && (
            docsOfIa.map((doc, index: number) => (
              <>
                <DocumentPreviewCard
                  className="margin-top-25"
                  heading={`${t("Label.DocumentDetails")} of Interlocutory Application (IA) -> ${doc[1]?.filingRefrenceNumber ? doc[1]?.filingRefrenceNumber : ""} (${doc[1].selectedIaReason?.label})`}
                  iaDocuments={doc}
                  documentDetails={[]}
                  edit={() => editDocumentDetails(doc, index)}
                />
              </>
            ))
          )
        }
        {

          // Document details
          documentDetails && !location.pathname.includes(COMMISSION_EDIT_CASE_STEPPERFORM)
          && (
            <DocumentPreviewCard
              className="margin-top-25"
              heading={t("Label.DocumentDetails")}
              documentDetails={documentDetails}
              edit={() => editDocumentDetails([])}
            />
          )
        }
        {finalSubmitDetails && !location.pathname.includes(COMMISSION_EDIT_CASE_STEPPERFORM) && (
          !isRevertedCase ? (
            <PreviewCard
              className="margin-top-25"
              heading={t("Label.FinalSubmission")}
              items={flattenArrayToIPreview({
                commission: `${finalSubmitDetails?.commission.label} ${locationTypeValue === 1 ? "" : getCommissionType(locationTypeValue)}`,
              })}
              edit={() => {
                if (!isRevertedCase) {
                  editFinalSubmitDetails(false)
                }
              }}
            />
          ) : (
            <PreviewCard
              className="margin-top-25"
              heading={t("Label.FinalSubmission")}
              items={flattenArrayToIPreview({
                commission: `${finalSubmitDetails.commission.label} ${getCommissionType(locationTypeValue)}`,
              })}
            />
          )
        )}
        {
          !location.pathname.includes(COMMISSION_EDIT_CASE_STEPPERFORM) &&
          <BasicButton
            className="float-end margin-top-25"
            text={t("BtnText.FinalSubmit")}
            variant="contained"
            onClick={() => setFinishDialogueVisible(1)}
          />

        }

      </div>
    </section>
  );
};

export default Preview;
