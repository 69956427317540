import "./header.scss";
import "../landingPageGloble.scss"
import { GoSearch } from "react-icons/go";
import { FaRegUser } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { LinkRoute } from "../../components";
import { ADD_ADVOCATE, ADVOCATE_LIST, CASE_HISTORY_CASE_STATUS, CAUSE_LIST, DISPLAY_BOARD, FORGOTPASSWORD, JUDGEMENT, LOGIN, REGISTER, ROOT, TUTORIALS } from "../../navigation/CONSTANTS";
import { useTranslation } from "react-i18next";
import { labels } from "../../Provider.text";
import { Link, useLocation } from "react-router-dom";
import LanguageSelection from "../../utils/LanguageSelection";
import { useState } from "react";

const ashokSthambLogo = "/header/ejagriti-ashok-sthamb.svg";

const Header = () => {
  const [rightSidebar, setRightSidebar] = useState(true)
  const [mobileLoginBar, setMobileLoginBar] = useState(true)
  const { t } = useTranslation();

  const { pathname } = useLocation();
  if ([LOGIN, REGISTER, FORGOTPASSWORD, ADD_ADVOCATE, ADVOCATE_LIST].includes(pathname)) {
    return null;
  }

  const menuList = [
    {
      component: <LinkRoute className="nav-link" to={ROOT}>{t(labels.dashboard).toUpperCase()}</LinkRoute>,
      onClick: () => { },
    },
    {
      component: <LinkRoute className="nav-link" to={CASE_HISTORY_CASE_STATUS}>{t("CASEHISTORYCASESTATUS").toUpperCase()}</LinkRoute>,
      onClick: () => { },
    },
    {
      component: <LinkRoute className="nav-link" to={JUDGEMENT}>{t(labels.judgement).toUpperCase()}</LinkRoute>,
      onClick: () => { },
    },
    {
      component: <LinkRoute className="nav-link" to={DISPLAY_BOARD}>{t(labels.displayBoard).toUpperCase()}</LinkRoute>,
      onClick: () => { },
    },
    {
      component: <LinkRoute className="nav-link" to={CAUSE_LIST}>{t(labels.causeList).toUpperCase()}</LinkRoute>,
      onClick: () => { },
    },
    // {
    //   component: <LinkRoute className="nav-link" to={REPORTS}>{t(labels.reports).toUpperCase()}</LinkRoute>,
    //   onClick: () => { },
    // },
    {
      component: <LinkRoute className="nav-link" to={TUTORIALS}>{t(labels.tutorials).toUpperCase()}</LinkRoute>,
      onClick: () => { },
    },
    {
      component: <LinkRoute to={LOGIN}><button className="login-button">{t(labels.login).toUpperCase()} / {t(labels.signUp).toUpperCase()}</button>
      </LinkRoute>,
      onClick: () => { },
    },
  ];

  const menuList2 = [
    {
      component: <GoSearch className="" />,
      onClick: () => { },
    },
    {
      component: (
        <div className="dropdown">
          <button
            className="btn "
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => setMobileLoginBar(!mobileLoginBar)}
          >
            <FaRegUser className="" />
          </button>
          <ul className={mobileLoginBar ? "dropdown-menu mobileLoginCard" : "dropdown-menu show mobileLoginCard"}>
            <li>
              <Link className="dropdown-item" to={LOGIN}>
                Login In
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={REGISTER}>
                Sign Up
              </Link>
            </li>
          </ul>
        </div>
      ),
      onClick: () => { },
    },
    {
      component: (
        <p className="d-inline-flex  my-auto p-0">
          <button
            className="btn p-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => setRightSidebar(!rightSidebar)}
          >
            <RxHamburgerMenu />
          </button>
        </p>
      ),
      onClick: () => { },
    },
  ];

  return (
    <header>
      <div className="d-flex header-container px-2 py-1 justify-content-md-between">
        <div className="d-flex align-items-center gap-3">
          <p className="header-text my-0 text-white">
            Department of Consumer Affairs, Ministry of Consumer Affairs, Food &
            Public Distribution | GOVERNMENT OF INDIA
          </p>
        </div>
        <div>
          {/* <MenuHeader /> */}
          <LanguageSelection />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center header-container-bottom">
        <div className="d-flex align-items-center padding-left-10">
          <div>
            <img src={ashokSthambLogo} alt="Ashok Sthamb" height={40} />
          </div>
          <div className="divider-logo"></div>
          <Link to={window.location.origin}>
            <img
              className="e-jagriti-logo-header"
              src="/header/e-jagritiLogo.gif"
              alt="E-Jagriti"
            />
          </Link>
        </div>
        <div>
          <ul className="d-none d-xl-flex menu-bottom-list my-0 align-items-center margin-right-10">
            {menuList.map((item, index) => (
              <li key={index} onClick={item.onClick}>
                {item.component}
              </li>
            ))}
          </ul>
          <ul className="d-flex d-xl-none gap-4  menu-bottom-list my-0 align-items-center">
            {menuList2.map((item, index) => (
              <li key={index} onClick={item.onClick}>
                {item.component}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={rightSidebar ? "collapse" : "collapse-show"} id="collapseExample">
        <div className="card card-body">
          <ul className="d-flex d-xl-none flex-column p-0 menu-bottom-list my-0 align-items-center">
            {menuList.slice(0, 7).map((item, index) => (
              <li className="ham-burger-list-item" key={index} onClick={item.onClick}>
                {item.component}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
