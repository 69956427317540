
import { pdfMakeFonts, pdfMakeLibrary } from "../../../utils/CONSTANTS";

export const ReceiptPdf = (
  caseNumberMain: string | null,
  receiptNumber: string | null,
  caseNumber: number,
  bankRefNumber: string | null,
  paymentAmount: string,
  paymentDate: string | null | undefined,
  filedIn: string,
  complainantName: string,
  respondentName: string,
  baseImage: string,
) => {

  // playground requires you to assign document definition to a variable called dd
  var dd = {
    pageOrientation: 'landscape',
    background: {
      image: baseImage,
      width: 350,
      height: 350,
      margin: [250, 50, 0, 0], // You can adjust the margins if needed
      rotate: 75,
      opacity: 0.3,
      angle: 70
    },
    // footer: {
    //   columns: ["Left part", { text: "Right part", alignment: "right" }],
    // },
    content: [
      {
        text: "Online Payment Receipt",
        style: 'header',
        alignment: 'center'
      },
      {
        text: `Case No : ${caseNumberMain}`,
        style: 'header1',
        alignment: 'center'
      },
      // {
      //   text: `Date : ${paymentDate}`,
      //   style: 'header2',
      //   alignment: 'right'
      // },

      {
        style: 'tableExample1',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Receipt No : ', { text: receiptNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Name: ', { text: complainantName, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],
            [{ text: ['Transaction Id : ', { text: bankRefNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Case Referance No : ', { text: caseNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],

          ]

        }
      },
      {
        style: 'tableExample2',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Bank Referance  : ', { text: bankRefNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Amount : ', { text: `${paymentAmount} INR `, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],
            [{ text: ['Payment Date : ', { text: paymentDate, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Mode Of Payment : ', { text: 'Online', style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],

          ]

        }
      },
      {
        style: 'tableExample3',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Payment Status : ', { text: 'Success', style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Case Filed In : ', { text: filedIn, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],

          ]

        }
      },
      {
        style: 'tableExample3',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Respondent : ', { text: respondentName, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 2, }]

          ]

        }
      },
    ],
    styles: {
      header: {
        decoration: "underline",
        color: "#006699",
        fontSize: 18,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10]
      },
      header1: {

        color: "#006699",
        fontSize: 18,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10]
      },
      header2: {


        fontSize: 18,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 20]
      },
      tableExample1: {

        margin: [0, 0, 0, -1]

      },
      tableExample2: {
        margin: [0, 0, 0, -1]

      },
      tableHeader: {
        margin: [0, 7, 0, 0],
        bold: true
      },
      tableHeadersub: {
        bold: false
      }
    }

  }
  pdfMakeLibrary.vfs = pdfMakeFonts.pdfMake.vfs;
  pdfMakeLibrary.createPdf(dd).download();
};

export const ReceiptPaymentPdf = (
  caseNumberMain: string,
  receiptNumber: string,
  caseNumber: number,
  bankRefNumber: string,
  paymentAmount: string,
  paymentDate: string,
  filedIn: string,
  complainantName: string,
  respondentName: string
) => {

  // playground requires you to assign document definition to a variable called dd
  var dd = {
    pageOrientation: 'landscape',
    watermark: {
      text: "e-JAGRITI",
      color: "blue",
      opacity: 0.1,
      bold: true,
      italics: false,
    },
    // footer: {
    //   columns: ["Left part", { text: "Right part", alignment: "right" }],
    // },
    content: [
      {
        text: "Online Payment Receipt",
        style: 'header',
        alignment: 'center'
      },
      {
        text: `Case No : ${caseNumberMain}`,
        style: 'header1',
        alignment: 'center'
      },
      {
        text: `Date : ${paymentDate}`,
        style: 'header2',
        alignment: 'right'
      },

      {
        style: 'tableExample1',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Receipt No : ', { text: receiptNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Name: ', { text: complainantName, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],
            [{ text: ['Transaction Id : ', { text: bankRefNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Case Referance No : ', { text: caseNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],

          ]

        }
      },
      {
        style: 'tableExample2',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Bank Referance  : ', { text: bankRefNumber, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Amount : ', { text: `${paymentAmount} INR `, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],
            [{ text: ['Payment Date : ', { text: paymentDate, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Mode Of Payment : ', { text: 'Online', style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],

          ]

        }
      },
      {
        style: 'tableExample3',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Payment Status : ', { text: 'Success', style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, },
            { text: ['Case Filed In : ', { text: filedIn, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 1, }],

          ]

        }
      },
      {
        style: 'tableExample3',
        table: {
          widths: [360, 360],
          heights: 30,
          body: [
            [{ text: ['Respondent : ', { text: respondentName, style: 'tableHeadersub' },], style: 'tableHeader', colSpan: 2, }]

          ]

        }
      },
    ],
    styles: {
      header: {
        decoration: "underline",
        color: "#006699",
        fontSize: 18,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10]
      },
      header1: {

        color: "#006699",
        fontSize: 18,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10]
      },
      header2: {


        fontSize: 18,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 20]
      },
      tableExample1: {

        margin: [0, 0, 0, -1]

      },
      tableExample2: {
        margin: [0, 0, 0, -1]

      },
      tableHeader: {
        margin: [0, 7, 0, 0],
        bold: true
      },
      tableHeadersub: {
        bold: false
      }
    }

  }
  pdfMakeLibrary.vfs = pdfMakeFonts.pdfMake.vfs;
  pdfMakeLibrary.createPdf(dd).download();
};


export interface IPaymentPendingCaseRows {
  id: number;
  referenceNumber: number;
  complainantNameEn: string;
  respondantNameEn: string;
  casefillingStageId: number;
  casefillingStageNameEn: string;
  dateOfFilling: string;
  claimAmount: number | string;
}

export type IReceiptDataTable = {
  id: number;
  ReferenceNumber: any;
  ReceiptNumber: any;
  BankRefNumber: any;
  PaymentDate: any;
  CaseNumberMain: string
};