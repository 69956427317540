import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BasicBreadcrumbs, BasicButton, BasicDatePicker, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Textbox } from "../../../components";
import { navigateToDashboardPath } from "../../../utils/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { DialogActions } from "@mui/material";
import { CouselistServices } from "../../../services/JudgeService/GenerateCauselistServices";
import { useState } from "react";
import { GenerateCauselistViewModel } from "./GenerateModel";
import { GENERATE_COUSELIST_CAUSELIST } from "../../../navigation/CONSTANTS";
import { CourtroomService } from "../../../services/CaseService/CourtroomService/CourtroomService";
import { CauselistResponse } from "../../../models/causelist/CauselistModel";

/** Renders judgement upload component */
const GeneateCouseList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  const [causelistResponse, setCauselistResponse] = useState<CauselistResponse[]>([]);

  // Validations for judgment upload form.
  const validationSchema = Yup.object({
    courtNumber: Yup.string()
      .required(t("Validation.CaseNumberRequired")),
    dateOfNextHearing: Yup.date()
      .required(t("Validation.DateOfHearingRequired")),
  })

  // Triggered when the form is submitted
  const generateCauseList = async (paramsData: GenerateCauselistViewModel) => {
    const paramsD = {
      dateOfNextHearing: paramsData.dateOfNextHearing.toFormat("yyyy-MM-dd"),
      courtId: paramsData.courtNumber,
      commissionId: userDetailsData.commissionId,
      finalize: false
    };
    const response = await CouselistServices.getJudgeGenerateCouselist(paramsD);
    if (response.isFinal === true) {
      toast.info('Causelist Already Generated');
    } else {
      const params = {
        dateOfNextHearing: paramsData.dateOfNextHearing.toFormat("dd-MM-yyyy"),
        courtId: paramsData.courtNumber,
        commissionId: userDetailsData.commissionId,
      };
      try {
        const response = await CourtroomService.getCauseListDataForJudge(params);
        if (response?.length) {
          navigate(GENERATE_COUSELIST_CAUSELIST, { state: { response, params } });
          setCauselistResponse(response);
        } else {
          toast.warn('No Data Available');
        }
      } catch (error) {
        console.error("Error fetching PDF data:", error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const responseCauseList = await generateCauseList(formik.values);
    } catch (error) {
      toast.warn("Failed to generate cause list.");
    }
  };

  const formik = useFormik({
    initialValues: GenerateCauselistViewModel.init(),
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <section className="section margin-top-35">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <BasicBreadcrumbs items={[
              { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
              { to: '', label: t("Breadcrumb.GeneateCouseList") },
            ]} />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row margin-top-25 margin-bottom-25">
            <div className="col-lg-12">
              <ConfonetCard>
                <ConfonetCardHeader>
                  <div className="card-title card-primary-header-title">{t("Label.GeneateCouseList")}</div>
                </ConfonetCardHeader>
                <ConfonetCardBody>
                  <div className="row margin-top-10">
                    <div className="col-lg-4 col-sm-4 child-element-margin-bottom-0">
                      <Textbox
                        id="courtNumber"
                        name={"courtNumber"}
                        label={t("Label.Courtnumber") + "*"}
                        value={formik.values.courtNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.courtNumber && Boolean(formik.errors.courtNumber)}
                        helperText={formik.touched.courtNumber && formik.errors.courtNumber}
                      />
                    </div>

                    <div className="col-lg-4 col-sm-4 child-element-margin-bottom-0">
                      <BasicDatePicker
                        label={t("Label.Date") + "*"}
                        value={formik.values.dateOfNextHearing}
                        className="w-100"
                        onChange={(e) => formik.setFieldValue("dateOfNextHearing", e)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error: formik.touched.dateOfNextHearing && Boolean(formik.errors.dateOfNextHearing),
                            helperText: (
                              <span className="text-danger">
                                {formik.touched.dateOfNextHearing && formik.errors.dateOfNextHearing as string}
                              </span>
                            ),
                            onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-4 child-element-margin-bottom-0">
                      <DialogActions>
                        <BasicButton
                          sx={{ marginRight: "250px", marginTop: "-6px" }}
                          variant="contained"
                          text="Generate"
                          type="submit"

                        />
                      </DialogActions>
                    </div>
                  </div>
                </ConfonetCardBody>
              </ConfonetCard>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default GeneateCouseList;