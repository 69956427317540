import { IDropdown } from "../common/IDropdown";

export class IssueNoticeFormViewModel {
  constructor(public caseNumber: string) {
    this.caseNumber = caseNumber;
  }
  static readonly init = () => new IssueNoticeFormViewModel("");
}

export class GenerateNoticeViewModel {
  constructor(
    public noticeType: IDropdown,
    public notice: string,
    public authorisedSignatory: string,
    public complainantStatuses: GenerateNoticePersonalStatusViewModel[],
    public respondentStatuses: GenerateNoticePersonalStatusViewModel[],
    public isNoticeFinalized: boolean
  ) {
    this.noticeType = noticeType;
    this.notice = notice;
    this.authorisedSignatory = authorisedSignatory;
    this.complainantStatuses = complainantStatuses;
    this.respondentStatuses = respondentStatuses;
    this.isNoticeFinalized = isNoticeFinalized;
  }

  static readonly init = () =>
    new GenerateNoticeViewModel(
      { label: "", value: "" },
      "",
      "",
      [],
      [],
      false
    );
}

export class GenerateNoticePersonalStatusViewModel {
  constructor(
    public name: string,
    public status: boolean,
    public sequenceNumber: number,
    public partyId: string,
    public complainantRespondentTypeId: number,
    public advocate?: GenerateNoticeAdvocateStatusViewModel
  ) {
    this.name = name;
    this.status = status;
    this.sequenceNumber = sequenceNumber;
    this.partyId = partyId;
    this.complainantRespondentTypeId = complainantRespondentTypeId
    this.advocate = advocate;
  }

  static readonly init = () =>
    new GenerateNoticePersonalStatusViewModel("", false, 0, "", 0);
}

export class GenerateNoticeAdvocateStatusViewModel {
  constructor(public name: string, public status: boolean) {
    this.name = name;
    this.status = status;
  }
  static readonly init = () =>
    new GenerateNoticeAdvocateStatusViewModel("", false);
}

export interface IGetNoticeDetailsResponse {
  caseNumber: string;
  filingReferenceNumber: number;
  caseFilingDate: string;
  complainantName: string;
  advocateNameOfComplainant: string;
  sequenceNumber: number;
  complainantRespondentTypeId: number;
  partyId: string;
  caseTypeId: number;
}

export interface IGetNoticeIssuedSoFarResponse {
  fillingNumber: number;
  noticeType: string;
  authorisedSignatory: string;
  issuedFor: string;
  noticeGenerationdate: string;
  noticeCreatedDate: string;
  sentThrough: string;
  noticeFinalizedStatus: number;
  noticeId: number;
}

export interface IGetSavedNoticeResponse {
  recordNumber: number;
  noticeId: number;
  fillingReferenceNumber: number;
  hearingSequenceNumber: number;
  complainantRespondantTypeId: number;
  complainantRespondantSequenceNumber: number;
  responseTypeId: number;
  noticeFinalisedStatus: number;
  noticeSignedStatus: number;
  noticeServedToPartyStatus: number;
  noticeTypeId: number;
  noticeTypeText: string;
  messageSourceTypeId: number;
  noticeServedToAdvocateStatus: number;
  noticeGenerationDate: string;
  noticeGeneratedBy: number;
  lastUpdationDoneBy: number;
  lastUpdationDateTime: string;
  clientIpAddress: string;
  createdOnDateTime: string;
  createdBy: number;
  remarks: null;
  activeStatus: number;
}

export interface ISaveNoticeRequest {
  noticeId?: number;
  fillingReferenceNumber: number;
  responseTypeId: number; // 0
  isNoticeFinalized: number;
  noticeSignedStatus: number; // 1
  noticeTypeId: number;
  noticeTypeText: string;
  userId: number;
  activeStatus: number;
  complainantRespondantRequestData: ComplainantRespondantRequestDatum[];
  messageSourceTypeId?: number;
  clientIpAddress: string;
}

export interface ComplainantRespondantRequestDatum {
  complainantRespondantTypeId: number;
  complainantRespondantSequenceNumber: number;
  noticeServedToPartyStatus: number;
  noticeServedToAdvocateStatus: number;
  partyId: string
}

export interface SaveNoticeComplainantRespondantRequest {
  complainantRespondantTypeId: number;
  complainantRespondantSequenceNumber: number;
  noticeServedToAdvocateStatus: number;
}

export interface IDeleteNoticeRequest {
  fillingReferenceNumber: number;
  noticeId: number;
}
