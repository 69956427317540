import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IgetNoteListResponse, ImportantNotesCreateViewModel, ImportantNotesSearchViewModel } from '../../../models/ImportantNotes/IImportantNotes';
import { JUDGE_DASHBOARD } from '../../../navigation/CONSTANTS';
import ImportantNotesView from './ImportantNotesView';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RegistryReportAndNotesService } from '../../../services/JudgeService/RegistryReportAndNotesService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { DateTime } from 'luxon';
import { IDropdown } from '../../../models/common/IDropdown';
import { b64toBlob, makeDropdownOptions } from '../../../utils/Helper';

export const ImportantNotesContainer = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [notesData, setNotesData] = useState<IgetNoteListResponse[]>([]);
    const [caseupdateoption, setCaseupdateOption] = useState<IDropdown[]>([]);
    const { userDetailsData } = useSelector((state: RootState) => state.user);


    const breadCrumbs = [
        { to: JUDGE_DASHBOARD, label: t("Breadcrumb.Dashboard") },
        { to: '', label: location.pathname.includes('commission/registry') ? t("Breadcrumb.RegistryReport") : t("Breadcrumb.ImportantNotes") },
    ];

    const getTypeId = () => (location.pathname.includes('commission/registry') ? "1" : "2");

    const handleSearchSubmit = (data: ImportantNotesSearchViewModel) => {
        const { caseNumber } = data;
        const typeId = getTypeId();
        fetchnotes(data, typeId);
    };



    const fetchnotes = async (data: ImportantNotesSearchViewModel, typeId: string, onlyList: boolean = false) => {
        try {
            let caseNumberOptions: IDropdown[] = [];
            const newNotes = await RegistryReportAndNotesService.getNoteList(
                data.caseNumber,
                data.date.toFormat('yyyy-MM-dd').toString(),
                typeId
            );
            if(newNotes?.length) {
                setNotesData(newNotes.filter((note) => note.activeStatus));
            }
            if(!onlyList) {
                if (newNotes.length) {
                    // remove all the duplicate caseNumber and make dropdown options
                    const uniqueCaseNumberList = newNotes.filter((note, index, self) =>
                        index === self.findIndex((n) => n.caseNumber === note.caseNumber)
                    );
                    caseNumberOptions = makeDropdownOptions(uniqueCaseNumberList, 'caseNumber', 'caseNumber');
                } else {
                    caseNumberOptions = [{ label: data.caseNumber, value: data.caseNumber }];
                }
                setCaseupdateOption(caseNumberOptions);
                importantNotesCreateOrUpdateFormik.setFieldValue('caseNumber', { label: caseNumberOptions[0].label, value: caseNumberOptions[0].value });
            }

        } catch (error) {
            console.error("Error fetching notes:", error);
        }
    };

    const handleCreateOrUpdateNotes = async (data: ImportantNotesCreateViewModel) => {
        const formData = new FormData();
        const typeId = getTypeId();

       
        try {
            const notesData: any = {
                userId: userDetailsData.userId,
                notes: data.notes,
                typeId,
                title: data.title,
                ...(data.caseNumber?.value?.includes('/')
                    ? { caseNumber: data.caseNumber?.value.split(' ')[0] }
                    : { filingReferenceNumber: parseInt(data.caseNumber?.value) }),
                dateOfHearing: data.date?.toFormat("yyyy-MM-dd"),
            };
            if (data.noteId) {
                notesData.noteId = data.noteId;
            }

            const jsonPersonalData = JSON.stringify(notesData);
            formData.append("details", jsonPersonalData);

            if (importantNotesCreateOrUpdateFormik.values.document?.length > 0) {
                formData.append("files", data.document[0]);
            }

            const response = await RegistryReportAndNotesService.saveAndUpdateNotes(formData);

            if (response?.noteId) {
                importantNotesCreateOrUpdateFormik.resetForm(); // Reset form after successful save/update.
                importantNotesCreateOrUpdateFormik.setFieldValue('document', []);
                // call the list API
                const notesListData = new ImportantNotesSearchViewModel(response.caseNumber, DateTime.fromFormat(response.dateOfHearing, 'yyyy-MM-dd'), response.typeId.toString());
                fetchnotes(notesListData, response.typeId.toString(), true);
            }
        } catch (error) {
            console.error("Error in saving/updating note:", error);
        }
    };

    const importantNotesSearchFormik = useFormik({
        initialValues: ImportantNotesSearchViewModel.init(),
        onSubmit: handleSearchSubmit,
    });

    const importantNotesCreateOrUpdateFormik = useFormik({
        initialValues: ImportantNotesCreateViewModel.init(),
        onSubmit: handleCreateOrUpdateNotes,
    });

    const handleEditNote = async (note: IgetNoteListResponse) => {
        const data = await RegistryReportAndNotesService.getCaseNotes(note.noteId);
        //const {documne  } = data
        const file = b64toBlob(
            data.documentNames[0].fileContent,
            "application/pdf",
            data.documentNames[0].fileName
          );
        const notesData = new ImportantNotesCreateViewModel(
            data.title,
            DateTime.fromISO(data.createdAt),
            { label: data.caseNumber, value: data.caseNumber },
            data.notes,
            data.typeId,
            data.noteId,
            [file]
        );

       

        importantNotesCreateOrUpdateFormik.setValues(notesData);
        setCaseupdateOption([{ label: data.caseNumber, value: data.caseNumber }]);
    }


    const handleDelete = async (note: IgetNoteListResponse) => {
        try {
            const response = await RegistryReportAndNotesService.deleteCaseNotes(note.noteId);

            if (response?.status === 200) {
                setNotesData((prevNotes) => prevNotes.filter((n) => n.noteId !== note.noteId));
            } else {
                console.error("Failed to delete note", response);
            }
        } catch (error) {
            console.error("Error deleting note:", error);
        }
    };



    return (
        <ImportantNotesView
            breadCrump={breadCrumbs}
            importantNotesSearchFormik={importantNotesSearchFormik}
            importantNotesCreateOrUpdateFormik={importantNotesCreateOrUpdateFormik}
            handleEdit={handleEditNote}
            handleDelete={handleDelete}
            notesData={notesData}
            caseupdateoption={caseupdateoption}
        />
    );
};
