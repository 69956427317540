import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BasicButton } from "../../../components";
import { COMMISSION_EDIT_CASE_STEPPERFORM } from "../../../navigation/CONSTANTS";
import { RootState } from "../../../redux";
import { CaseServices } from "../../../services/CaseService/CaseServices";
import { caseStages } from "../../../utils/Enum";
import { createHeaderWithBreaks, getExpirationLimit, indianRupeeInput, navigateToDashboardPath } from "../../../utils/Helper";
import { CaseList } from "../../dashboard";

interface IScrutinyCaseList {
    id: number,
    ReferenceNumber: number,
    CaseType: string | undefined,
    Complainant: string,
    Respondent: string,
    ClaimAmount: string,
    FilingDate: string,
    caseTypeId: number,
    previousCaseNumber: string,
    previousFilingReferenceNumber: number,
    caseReasonTypeId: number,
    caseReasonTypeDisplayText: string
}

export const CaseEditCommission = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const [editCase, setEditCase] = useState<IScrutinyCaseList[]>([]);
    const [expiredScrutinyCase, setExpiredScrutinyCase] = useState<IScrutinyCaseList[]>([]);


    const checkIfCaseExpired = (filingDate: string, roleId: number, caseTypeId: number): boolean => {
        const currentDate = DateTime.now();
        const expirationLimit = getExpirationLimit(roleId, caseTypeId);
        if (expirationLimit === 0) {
            return false
        }
        const filingDateTime = DateTime.fromFormat(filingDate, "dd-MM-yyyy");
        return currentDate.diff(filingDateTime, 'days').days > expirationLimit;
    };
    const caseListColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.SerialNo"), maxWidth: 70, flex: 1 },
        { field: "ReferenceNumber", headerName: t("Label.ReferenceDiaryNumber"), flex: 1 },
        { field: "CaseType", headerName: t("Label.CaseType"), flex: 1 },
        { field: "Complainant", headerName: createHeaderWithBreaks([t("Label.ComplainantOrAppellant"), t("Label.Petitioner")]), description: t("Label.Complainant/Appellant/Petitioner"), flex: 1.5, headerClassName: 'multiline-header' },
        { field: "Respondent", headerName: createHeaderWithBreaks([t("Label.OppositeParty"), t("Label.Respondent")]), description: t("Label.OppositeParty/Respondent"), flex: 1, headerClassName: 'multiline-header' },
        { field: "ClaimAmount", headerName: t("Label.ClaimAmount"), flex: 0.8 },
        { field: "FilingDate", headerName: t("Label.FilingDate"), flex: 0.5 },
        {
            field: "Action",
            headerName: t("Label.Action"),
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                return (
                    <BasicButton
                        text={t("BtnText.Edit")}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            navigate(COMMISSION_EDIT_CASE_STEPPERFORM, {
                                state: {
                                    filingReferenceNumber: params.row.ReferenceNumber, caseTypeId: params.row.caseTypeId,
                                }
                            });
                        }}
                    />
                )
            }
        },
    ]

    const handleGetCaseList = useCallback(async () => {
        const caseListResponse = await CaseServices.getAllCaseList(userDetailsData?.commissionId ?? 0, userDetailsData.roleId, userDetailsData.userId, [caseStages.pendingForScrutiny]);
        const data: IScrutinyCaseList[] = caseListResponse.map((caseList, index) => {
            const { referenceNumber, caseType, complainantNameEn, respondantNameEn, dateOfFilling, claimAmount, caseTypeId, caseReasonTypeId, caseReasonTypeDisplayText } = caseList;
            return (
                {
                    id: index + 1,
                    ReferenceNumber: referenceNumber,
                    CaseType: caseType?.toUpperCase(),
                    Complainant: complainantNameEn?.toUpperCase(),
                    Respondent: respondantNameEn?.toUpperCase(),
                    // eslint-disable-next-line no-useless-concat
                    ClaimAmount: claimAmount ? indianRupeeInput(claimAmount?.toString()).formattedValue + " " + "INR" : "N/A",
                    FilingDate: DateTime.fromFormat(dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                    caseTypeId: caseTypeId,
                    previousCaseNumber: caseList.previousCaseNumber,
                    previousFilingReferenceNumber: caseList.previousFillingReferenceNumber,
                    caseReasonTypeId,
                    caseReasonTypeDisplayText

                }
            )
        });
        const expiredCases = data?.filter(caseItem =>
            checkIfCaseExpired(caseItem.FilingDate, userDetailsData?.roleId, caseItem.caseTypeId)
        );
        const nonExpiredCases = data?.filter(caseItem =>
            !checkIfCaseExpired(caseItem.FilingDate, userDetailsData.roleId, caseItem.caseTypeId)
        )
        setExpiredScrutinyCase(expiredCases)
        setEditCase(nonExpiredCases)
    }, [userDetailsData?.commissionId, userDetailsData.roleId, userDetailsData.userId])

    useEffect(() => {
        handleGetCaseList();
    }, [handleGetCaseList]);

    const breadCrumbs = [
        { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
        { to: '', label: t("Breadcrumb.EditCase") },
    ]
    return (
        <>
            <CaseList
                caseListRows={editCase}
                caseListColumns={caseListColumns}
                breadCrumbs={breadCrumbs}
            />
        </>
    )
}


