import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BasicButton } from "../../../../../../components";
import { IGetCaseListResponse } from "../../../../../../models/cases/consumerCases/IConsumerCaseModel";
import { ADVOCATE_CASELIST_PREVIEW, CONSUMER_CASELIST_PREVIEW } from "../../../../../../navigation/CONSTANTS";
import { RootState } from "../../../../../../redux";
import { CaseServices } from "../../../../../../services/CaseService/CaseServices";
import { caseStages } from "../../../../../../utils/Enum";
import { createHeaderWithBreaks, navigateToDashboardPath, navigateToDifferentPath } from "../../../../../../utils/Helper";
import { CaseList } from "../../../CaseList";


interface IPaymentPendingCaseRows {
    id: number;
    referenceNumber: number;
    complainantNameEn: string;
    respondantNameEn: string;
    casefillingStageId: number;
    casefillingStageNameEn: string;
    dateOfFilling: string;
}

export const PaymentPendingCaseList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const [caseListRows, setCaseListRows] = useState<IPaymentPendingCaseRows[]>([]);

    const caseListColumns: GridColDef[] = [
        { field: "id", headerName: t("Label.No"), maxWidth: 70, flex: 1 },
        { field: "referenceNumber", headerName: t("Label.ReferenceDiaryNumber"), flex: 1 },
        { field: "complainantNameEn", headerName: createHeaderWithBreaks([t("Label.ComplainantOrAppellant"), t("Label.Petitioner")]), description: t("Label.Complainant/Appellant/Petitioner"), flex: 1.5, headerClassName: 'multiline-header' },
        { field: "respondantNameEn", headerName: createHeaderWithBreaks([t("Label.OppositeParty"), t("Label.Respondent")]), description: t("Label.OppositeParty/Respondent"), flex: 1, headerClassName: 'multiline-header' },
        { field: "casefillingStageNameEn", headerName: t("Label.CaseStage"), flex: 1 },
        { field: "dateOfFilling", headerName: t("Label.FilingDate"), flex: 1 },
        {
            field: "Action",
            headerName: t("Label.Action"),
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                function proceedToPay(referenceNumber: any) {
                    throw new Error("Function not implemented.");
                }

                return (<>
                    <BasicButton
                        text={t("BtnText.View")}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            const state = {
                                edit: false,
                                filingReferenceNumber: params.row.referenceNumber
                            }
                            navigate(navigateToDifferentPath(userDetailsData.roleId, CONSUMER_CASELIST_PREVIEW, ADVOCATE_CASELIST_PREVIEW), { state })
                        }}
                    />
                    <BasicButton
                        text={t("BtnText.Pay")}
                        size="small"
                        className="margin-left-10"
                        color="secondary"
                        variant="contained"
                        onClick={() => { proceedToPay(params.row.referenceNumber) }}
                    />
                </>
                )
            }
        },
    ]

    const handleGetCaseList = useCallback
        (async () => {
            const caseListResponse = await CaseServices.getAllCaseList(userDetailsData?.commissionId ?? 0, userDetailsData.roleId, userDetailsData.userId, [caseStages.paymentPending]);
            setCaseListRows(caseListResponse.map((value: IGetCaseListResponse, index: number) => {
                return {
                    id: index + 1,
                    referenceNumber: value.referenceNumber,
                    complainantNameEn: value.complainantNameEn,
                    respondantNameEn: value.respondantNameEn,
                    casefillingStageId: value.casefillingStageId,
                    casefillingStageNameEn: value.caseStageNameEn,
                    dateOfFilling: DateTime.fromFormat(value.dateOfFilling, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                }
            }) ?? []);
        }, [userDetailsData?.commissionId, userDetailsData.roleId, userDetailsData.userId]);

    useEffect(() => {
        handleGetCaseList()
    }, [handleGetCaseList]);


    const breadCrumbs = [
        { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
        { to: '', label: t("Breadcrumb.Payment") },
    ]

    return (
        <>
            <CaseList
                caseListRows={caseListRows}
                caseListColumns={caseListColumns}
                breadCrumbs={breadCrumbs}
            />
        </>
    )
}