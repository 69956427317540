import React from "react";
import "./ConfonetOverlay.scss"; // Add your custom styles here

interface IConfonetOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    children: JSX.Element
}

const ConfonetOverlay: React.FC<IConfonetOverlayProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="confonet-overlay">
            <div className="overlay-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="margin-top-20">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ConfonetOverlay;