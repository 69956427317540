import { AdvocateDetailsList } from "../cases/commonCase/ICommonCaseModel";
import { IDropdown } from "../common/IDropdown";
import { AddressDetailsModel, IAddressDetailModelToShow } from "./AddressDetailsModel";

/**
 * Stores personal details including multipe addresses
 */
class PersonalDetailsModel {
    constructor(

        public name: string,
        public mobileNumber: string,
        public email: string,
        public isSeniorCitizen: boolean,
        public isWidow: boolean,
        public isDifferentlyAbled: boolean,
        public seriousAilments: boolean,
        public address: AddressDetailsModel[],
        public advocate: IDropdown,
        public handicapped: IDropdown,
        public partyId?: number,
        public advocateText?: string,
        public complainantRespondantTypeId?: number,
        public filingRefrenceNumber?: number,
        public relation?: IDropdown,
        public relativeName?: string,
        public complainantRespondentSequenceNumber?: number,
        public advocateList?: AdvocateDetailsList[]
    ) {
        this.name = name;
        this.mobileNumber = mobileNumber;
        this.email = email;
        this.isSeniorCitizen = isSeniorCitizen;
        this.isWidow = isWidow;
        this.isDifferentlyAbled = isDifferentlyAbled;
        this.seriousAilments = seriousAilments;
        this.address = address;
        this.advocate = advocate;
        this.handicapped = handicapped
        this.partyId = partyId
        this.advocateText = advocateText
        this.complainantRespondantTypeId = complainantRespondantTypeId
        this.filingRefrenceNumber = filingRefrenceNumber
        this.relation = relation
        this.relativeName = relativeName
        this.complainantRespondentSequenceNumber = complainantRespondentSequenceNumber
    }
    static readonly init = (address = [AddressDetailsModel.init()]) => new PersonalDetailsModel("", "", "", false, false, false, false, address, { label: "", value: "" }, { label: "", value: "", })
}

export default PersonalDetailsModel;

export class RespondantAdvocateModel {
    constructor(
        public advocate: IDropdown,
    ) {
        this.advocate = advocate;
    }

    static init = () => new RespondantAdvocateModel({ label: "", value: "" });
};

export interface IPersonalDetailsModelToShow {
    name: string,
    mobileNumber: string,
    email: string,
    isSeniorCitizen: boolean,
    isWidow: boolean,
    isDifferentlyAbled: boolean,
    seriousAilments: boolean,
    address: IAddressDetailModelToShow[],
    advocate: IDropdown,
    handicapped: IDropdown,
}