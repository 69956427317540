import React from 'react'
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../assets/images/back.png'

function ButtoBack() {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <div className='home-back-buttons'>
            <div onClick={handleBackClick} style={{ cursor: 'pointer' }}>
                <img src={backIcon} alt="Back" className='button_back' />
            </div>
        </div>
    )
}

export default ButtoBack
