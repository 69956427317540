import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FormikProps } from "formik";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { PersonalDetails } from "../../../../../components";
import {
  complainantPersonalValidationSchema,
  respondentPersonalValidationSchema
} from "../../../../../components/controls/Forms/PersonalDetails";
import { ComplainantRespondentViewModel } from "../../../../../models/cases/fileNewCase/ComplainantResondentModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import PersonalDetailsModel from "../../../../../models/forms/PersonalDetailsModel";
import { caseTypeEnum } from "../../../../../utils/Enum";

export interface IComplainantRespondentProps {
  formik: FormikProps<ComplainantRespondentViewModel>;
  advocateOptions: IDropdown[];
  userComplainantDetails?: PersonalDetailsModel;
  setAdvocateOptions: (value: SetStateAction<IDropdown[]>) => void,
  setFieldsDisabled: (value: SetStateAction<boolean>) => void,
  fieldsDisabled: boolean
}

// Validations for complainant/respondent form
export const complainantRespondentValidationSchema = Yup.object({
  iAmComplainant: Yup.string(),
  complainant: complainantPersonalValidationSchema,
  respondent: respondentPersonalValidationSchema,
});

/**
 *
 * The `ComplainantRespondent` Component is used to fill details of one Complainant and one respondent
 * as well as one advocate.
 *
 * @param IComplainantRespondentProps
 *
 * For Additional Complainants and respondents see the following:
 * * [Additional Complainant](./AdditionalComplainant.tsx)
 * * [Additional Respondent](./AdditionalRespondent.tsx)
 */
const ComplainantRespondent = ({
  formik,
  advocateOptions,
  userComplainantDetails,
  fieldsDisabled,
  setFieldsDisabled
}: IComplainantRespondentProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { caseTypeId } = location.state;
  const [selectedRole, setSelectedRole] = useState<string>("0")

  // Fill complainant details when "I am complainant" button is pressed
  const complainantFillValues = (event: ChangeEvent<any>, value: string) => {
    localStorage.setItem("userRole", JSON.stringify(value))
    formik.setFieldValue("iAmComplainant", value); // Update the Formik state

    if (parseInt(value) === 0 && userComplainantDetails) {
      if (formik.values.complainant.partyId) {
        formik.setFieldValue("complainant", formik.values.complainant);
      } else {
        formik.setFieldValue("complainant", userComplainantDetails);
      }
      setFieldsDisabled(true);
    } else if (parseInt(value) === 2) {
      if (formik.values.complainant.partyId) {
        formik.setFieldValue("complainant", formik.values.complainant);
      } else {
        formik.setFieldValue("complainant", PersonalDetailsModel.init());
      }
      setFieldsDisabled(false);
    } else {
      if (formik.values.complainant.partyId) {
        formik.setFieldValue("complainant", formik.values.complainant);
      } else {
        formik.setFieldValue("complainant", PersonalDetailsModel.init());
      }
      setFieldsDisabled(false);
    }

    formik.setFieldTouched("complainant", false);
    formik.handleChange(event); // Ensure Formik handles the change
  };

  // Restore initial state based on formik values (e.g., when navigating back)
  useEffect(() => {
    if (parseInt(formik.values.iAmComplainant) === 0) {
      setFieldsDisabled(true);
      if (formik.values.complainant.partyId) {
        formik.setFieldValue("complainant", formik.values.complainant);
      } else {
        formik.setFieldValue("complainant", userComplainantDetails);
      }
    } else {
      setFieldsDisabled(false);
    }
    formik.setFieldValue("iAmComplainant", JSON.parse(localStorage.getItem("userRole")!) )
  }, [formik.values.iAmComplainant, userComplainantDetails, setFieldsDisabled]);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
      <div className="margin-top-25 d-flex">
        <RadioGroup
          value={parseInt(JSON.parse(localStorage.getItem("userRole")!))}
          name="iAmComplainant"
          onChange={complainantFillValues}
          onBlur={formik.handleBlur}
          row
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label={t("Label.IAmComplainant")}
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={t("Label.IAmAuthorizedRep")}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={t("Label.IAmAdvocate")}
          />
        </RadioGroup>
      </div>
      <div className="margin-top-25">
        <PersonalDetails
          name="complainant"
          label={
            caseTypeId === caseTypeEnum.ConsumerCase
              ? `${t("Label.Complainant")} ${t("Label.Details")}`
              : `${t("Label.Appellant")} ${t("Label.Details")}`
          }
          values={formik.values.complainant}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
          setFieldTouched={formik.setFieldTouched}
          touched={formik.touched.complainant}
          errors={formik.errors.complainant}
          advocateList={advocateOptions}
          disabled={fieldsDisabled}
        />
      </div>
      <div className="margin-top-25">
        <PersonalDetails
          name="respondent"
          label={`${t("Label.OppositeParty")} ${t("Label.Details")}`}
          values={formik.values.respondent}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
          setFieldTouched={formik.setFieldTouched}
          touched={formik.touched.respondent}
          errors={formik.errors.respondent}
          isRespondent
        />
      </div>
    </>
  );
};

export default ComplainantRespondent;
