import { Autocomplete, AutocompleteProps } from '@mui/material';
import { IDropdown } from '../../models/common/IDropdown';

/** Renders Autocomplete Component with given options
 * 
 * @param AutoCompleteProps
 * 
 * @returns JSX.Element
 */
interface ConfonetAutocompleteProps extends AutocompleteProps<IDropdown, false, false, false> {
    optionStyle?: React.CSSProperties;
}

export const ConfonetAutocomplete = ({ options, optionStyle, ...props }: ConfonetAutocompleteProps) => {
    return (
        <div className="form-control-group margin-bottom-30">
            <Autocomplete
                autoComplete={false}
                {...props}
                options={[...options]}
                isOptionEqualToValue={(option: IDropdown, value: IDropdown) => option?.value === value?.value}
            />
        </div>
    )
};