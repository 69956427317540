import { IDropdown } from "../../common/IDropdown";

export class DocumentUploadModel {
    constructor(
        public uploadedFiles: UploadFileModel[]
    ) {
        this.uploadedFiles = uploadedFiles;
    }

    public static init = () => new DocumentUploadModel([])
}

export class UploadFileModel {
    constructor(
        public documentId: number,
        public documentNameEn: string,
        public files: File[],
        public activeStatus: boolean,
        public documentSequenceNumber: number,
        public isMandatoryStatus: boolean,
        public isIA?: boolean,
        public caseFilingDocumentDetailsId?: number,
        public selectedIaReason?: IDropdown,
        public filingRefrenceNumber?: number,
    ) {
        this.documentId = documentId;
        this.documentNameEn = documentNameEn;
        this.files = files;
        this.activeStatus = activeStatus;
        this.documentSequenceNumber = documentSequenceNumber;
        this.isMandatoryStatus = isMandatoryStatus;
        this.isIA = isIA;
        this.caseFilingDocumentDetailsId = caseFilingDocumentDetailsId;
        this.selectedIaReason = selectedIaReason;
        this.filingRefrenceNumber = filingRefrenceNumber
    }

    public static init = () => new UploadFileModel(0, "", [], true, 0, false);
}

export interface DocumentUpdateRequestPayload {
    caseFilingDocumentDetailsId?: number;
    filingReferenceNumber: number;
    documentSequenceNumber: number;
    documentUploadStageId: number;
    documentId: number;
    documentNameEn: string;
    documentReference?: string;
    documentPath?: string;
    createdBy: number;
    updatedBy: number;
    updatedAt?: Date;
    remarks?: string;
    clientIpAddress?: string;
    createdAt?: Date;
    activeStatus: boolean;
    caseTypeId: number;
    parentFilingReferenceNumber?: number;
}
