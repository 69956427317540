import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicLayout from "../layout/PublicLayout";
import { IRoute } from "../models/common/IRoutes";
import UnderProgress from "../pages/MainWebPages/UndeProgress/UnderProgress";
import { ROOT, UNAUTHORIZED, UNDERPROGRESS } from "./CONSTANTS";
import { NotFound } from "./NotFound";
import { advocateRoutes } from "./routeFiles/advocateRoutes";
import { analyticAdminRoutes } from "./routeFiles/analyticAdminRoutes";
import { commissionRoutes } from "./routeFiles/commissionRoutes";
import { consumerRoutes } from "./routeFiles/consumerRoutes";
import { publicRoutes } from "./routes";
import Unauthorized from "./Unauthorized";
import { Suspense } from "react";
import { ConfonetLoader } from "../components";
import { presidentRoutes } from "./routeFiles/presidentRoutes";
import { dealingAssistantRoutes } from "./routeFiles/DealingAssistantRoutes";
import { courtMasterRoutes } from "./routeFiles/courtMasterRoutes";
import { DocaRoutes } from "./routeFiles/DocaUserRoutes";


const renderRoutes = (routes: IRoute[]) => {
    return routes.map((route, index) => {
        const id = index + 1;
        if (route.children) {
            return (
                <Route key={id} path={route.path} element={route.element}>
                    {renderRoutes(route.children)}
                </Route>
            );
        }
        return <Route key={id} path={route.path} element={route.element} />;
    })
}

export const RoutingConfig = () => (
    <Router>
        <Suspense fallback={<><ConfonetLoader /></>}>
            <Routes>
                {/* List all public routes here */}
                < Route path={ROOT} element={< PublicLayout />}>
                    {
                        publicRoutes.map((route) => (
                            <Route key={route.path} path={route.path} element={route.element} />
                        ))
                    }
                </Route >
                {renderRoutes(consumerRoutes)}
                {renderRoutes(advocateRoutes)}
                {renderRoutes(commissionRoutes)}
                {renderRoutes(analyticAdminRoutes)}
                {renderRoutes(presidentRoutes)}
                {renderRoutes(dealingAssistantRoutes)}
                {renderRoutes(courtMasterRoutes)}
                {renderRoutes(DocaRoutes)}

                {/* List a generic 404-Not Found route here */}
                <Route path={UNAUTHORIZED} Component={Unauthorized} />
                <Route path={UNDERPROGRESS} Component={UnderProgress} />
                <Route path="*" Component={NotFound} />
            </Routes>
        </Suspense>
    </Router>
);