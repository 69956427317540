import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  IDeleteNoticeRequest,
  IGetNoticeDetailsResponse,
  IGetNoticeIssuedSoFarResponse,
  IGetSavedNoticeResponse,
  ISaveNoticeRequest,
} from "../../../models/IssueNotice/IssueNoticeModel";
import {
  ICheckRejoinderPresentResponse,
  IGetCaseFillingReplyRejoinderDocumentDetailsResponse,
  IGetReplyRejoinderListResponse,
  IReplyRejoinderAction,
  IReplyRejoinderActionResponse,
  IReplyRejoinderUploadResponse,
  ISubsequentListResponse,
} from "../../../models/ReplyRejoinder/IReplyRejoinder";
import {
  AddRemoveSlaveModel,
  IStatusResponse,
  IFinalActionResponse,
  IGetMasterSlaveStatusResponse,
  IGetRevertedHistoryResponse,
  IGetRevertedReasonResponse,
  CommissionCaseListPayload,
  IFinalActionPayload,
  ICaseListWithoutRespondantAdvocate,
  IGetListOfIACaseByCaseNumberResponse,
} from "../../../models/cases/commissionCase/ICommissionCase";
import { IGetCaseListResponse, IGetConsolidateFileResponse } from "../../../models/cases/consumerCases/IConsumerCaseModel";
import { CASE_FILING_SERVICE } from "../../CONSTANTS";
import {
  deleteCall,
  getCall,
  postCall,
  postCallFile,
  putCall,
} from "../../api";
export class CommissionCaseService {
  /**
   * Call an API to get commission case list
   */
  public static readonly getCommissionCaseList = async (
    data: CommissionCaseListPayload
  ): Promise<IGetCaseListResponse[]> => {
    const response: AxiosResponse<IGetCaseListResponse[]> = await getCall(
      `${CASE_FILING_SERVICE}/getCommisionCaseList`,
      data
    );
    return response?.data;
  };

  /**
   * Call an API to final action submit
   * @param data
   * @returns
   */
  public static readonly finalActionSubmit = async (
    data: IFinalActionPayload
  ): Promise<IFinalActionResponse> => {
    const response: AxiosResponse<IFinalActionResponse> = await putCall(
      `${CASE_FILING_SERVICE}/actionForScruitny`,
      data
    );
    return response?.data;
  };

  /**
   *  Call an API to get reason for reverting the case
   * @param filingReferenceNumber number
   * @param caseStageId number
   * @returns Promise<IGetRevertedReasonResponse>
   */
  public static readonly getRevertedReason = async (
    filingReferenceNumber: number,
    caseStageId: number
  ): Promise<IGetRevertedReasonResponse> => {
    const data = {
      filingReferenceNumber,
      caseStageId,
    };
    const response: AxiosResponse<IGetRevertedReasonResponse> = await postCall(
      `${CASE_FILING_SERVICE}/revertedReason`,
      data
    );

    return response?.data;
  };

  /**
   *  Call an API to get revert history of a case
   * @param filingReferenceNumber number
   * @param caseStageId number
   * @returns Promise<IGetRevertedHistoryResponse[]>
   */
  public static readonly getRevertedHistoryList = async (
    filingReferenceNumber: number,
    caseStageId: number
  ): Promise<IGetRevertedHistoryResponse[]> => {
    const data = {
      filingReferenceNumber,
      caseStageId,
    };
    const response: AxiosResponse<IGetRevertedHistoryResponse[]> =
      await postCall(
        `${CASE_FILING_SERVICE}/revertedReasonTrace`,
        data
      );

    return response?.data;
  };
  /**
   * Call an API to get general notice details
   * @param caseNumber string
   * @returns Promise<IGetNoticeDetailsResponse>
   */
  public static readonly getNoticeDetails = async (
    caseNumber: string
  ): Promise<IGetNoticeDetailsResponse[]> => {
    const data = { caseNumber };
    const response: AxiosResponse<IGetNoticeDetailsResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/getNoticeDetail`,
      data
    );

    return response?.data;
  };

  /**
   * Call an API to get list of previously issued notices
   * @returns Promise<IGetNoticeIssuedSoFarResponse[]>
   */
  public static readonly getNoticeIssuedSoFar = async (
    caseNumber: string
  ): Promise<IGetNoticeIssuedSoFarResponse[][]> => {
    const data = { caseNumber };
    const response: AxiosResponse<IGetNoticeIssuedSoFarResponse[][]> =
      await postCall(
        `${CASE_FILING_SERVICE}/listOfNoticeIssued`,
        data
      );

    return response?.data;
  };

  /**
   * Call an API to get notice information
   * @param fillingReferenceNumber number
   * @returns Promise<IGetSavedNoticeResponse[]>
   */
  public static readonly getCaseNotices = async (
    fillingReferenceNumber: number,
    noticeId: number
  ): Promise<IGetSavedNoticeResponse[]> => {
    const params = { fillingReferenceNumber, noticeId };
    const response: AxiosResponse<IGetSavedNoticeResponse[]> = await getCall(
      `${CASE_FILING_SERVICE}/getCaseNotices`,
      params
    );

    return response?.data;
  };

  /**
   * Call an API to save the details of a notice
   * @param data ISaveCaseNoticeRequest
   * @returns Promise<IGetSavedNoticeResponse[]>
   */
  public static readonly saveCaseNotices = async (
    data: ISaveNoticeRequest
  ): Promise<IGetSavedNoticeResponse[]> => {
    const response: AxiosResponse<IGetSavedNoticeResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/saveCaseNotices`,
      data
    );

    return response?.data;
  };

  /**
   * Call an API to update the details of a notice
   * @param data ISaveCaseNoticeRequest
   * @returns Promise<IGetSavedNoticeResponse[]>
   */
  public static readonly updateCaseNotices = async (
    data: ISaveNoticeRequest
  ): Promise<IGetSavedNoticeResponse[]> => {
    const response: AxiosResponse<IGetSavedNoticeResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/updateCaseNotices`,
      data
    );

    return response?.data;
  };

  /**
   * Call an API to delete a notice
   * @param data IDeleteNoticeRequest
   * @returns Promise<string>
   */
  public static readonly deleteCaseNotice = async (
    params: IDeleteNoticeRequest
  ): Promise<string> => {
    const response: AxiosResponse<string> = await deleteCall(
      `${CASE_FILING_SERVICE}/deleteCaseNotice`,
      {},
      params
    );

    return response?.data;
  };

  /** Call an API to get list of reply rejoinder list
   * @param data number
   */
  public static readonly getReplyRejoinderForConsumer = async (
    userId: number,
    path: string
  ): Promise<IGetReplyRejoinderListResponse[]> => {
    if (path.includes("/rejoinder")) {
      const response: AxiosResponse<IGetReplyRejoinderListResponse[]> =
        await getCall(
          `${CASE_FILING_SERVICE}/getCaseFillingRejoinderList?userId=${userId}`,
          {}
        );
      return response?.data;
    } else {
      const response: AxiosResponse<IGetReplyRejoinderListResponse[]> =
        await getCall(
          `${CASE_FILING_SERVICE}/getCaseFillingReplyList?userId=${userId}`,
          {}
        );
      return response?.data;
    }
  };

  /** Call an API to get list of Sunsequent List
   * @param data number
   */
  public static readonly getSunsequentforConsumer = async (
    userId: number
  ): Promise<ISubsequentListResponse[]> => {
    const response: AxiosResponse<ISubsequentListResponse[]> = await getCall(
      `${CASE_FILING_SERVICE}/getCaseFillingSubsequentList?userId=${userId}`,
      {}
    );
    return response?.data;
  };
  /** Call an API to sumbit action for reply and Rejoinder
   * @param data IReplyRejoinderAction
   */
  public static readonly submitActionForReplyRejoinder = async (
    data: IReplyRejoinderAction
  ): Promise<IReplyRejoinderActionResponse> => {
    const response: AxiosResponse<IReplyRejoinderActionResponse> =
      await putCall(
        `${CASE_FILING_SERVICE}/actionForReplyOrRejoinder`,
        data
      );
    return response?.data;
  };

  /** Call an API to sumbit action for reply and Rejoinder
   * @param data fillingReferenceNumber & replyRejoinderFlag
   */
  public static readonly getReplyRejoinderDocuments = async (
    fillingReferenceNumber: number,
    replyRejoinderFlag: number,
    userId: number,
  ): Promise<IGetCaseFillingReplyRejoinderDocumentDetailsResponse> => {
    const response: AxiosResponse<IGetCaseFillingReplyRejoinderDocumentDetailsResponse> =
      await getCall(
        `${CASE_FILING_SERVICE}/getCaseFillingReplyRejoinderDocumentDetailsNew?fillingReferenceNumber=${fillingReferenceNumber}&replyRejoinderFlag=${replyRejoinderFlag}&userId=${userId}`
      );
    return response?.data;
  };

  /** Call an API to sumbit action for reply and Rejoinder
   * @param data fillingReferenceNumber & replyRejoinderFlag
   */
  public static readonly uploadReplyDocuments = async (payload: any) => {
    try {
      const response: AxiosResponse<IReplyRejoinderUploadResponse> =
        await postCallFile(
          `${CASE_FILING_SERVICE}/saveCaseFillingReplyRejoinder`,
          payload
        );
      if (response?.data) {
        return response?.data;
      }
    } catch (error) {
      toast.error("Error saving case filling document details:");
      throw error;
    }
  };

  /** Call an API to check if the rejoinder already exists
   * @param replyRejoinderId
   */
  public static readonly checkIfRejoinderExists = async (
    replyRejoinderId: number
  ): Promise<ICheckRejoinderPresentResponse> => {
    const response: AxiosResponse<ICheckRejoinderPresentResponse> =
      await getCall(
        `${CASE_FILING_SERVICE}/checkRejoinderPresent?replyRejoinderId=${replyRejoinderId}`
      );

    return response.data;
  };

  /**
   * Call an API to fetch master slave data for a case
   * @param commissionId number
   * @param caseNumber string
   * @returns Promise<IGetMasterSlaeStatusResponse>
   */
  public static readonly getMasterSlaveStatus = async (
    caseNumber: string,
    commissionId: number
  ): Promise<IGetMasterSlaveStatusResponse> => {
    const data = { commissionId, caseNumber };
    const response: AxiosResponse<IGetMasterSlaveStatusResponse> =
      await postCall(
        `${CASE_FILING_SERVICE}/getMasterSlaveStatus`,
        data
      );

    return response?.data;
  };

  /**
   * Call an API to check eligibility for a slave case
   * @param commissionId number
   * @param caseNumber string
   * @returns Promise<IGetMasterSlaeStatusResponse>
   */
  public static readonly checkSlaveEligibility = async (
    caseNumber: string,
    commissionId: number
  ): Promise<IGetMasterSlaveStatusResponse> => {
    const data = { caseNumber, commissionId, masterAndSlaveFlag: 0 };
    const response: AxiosResponse<IGetMasterSlaveStatusResponse> =
      await postCall(
        `${CASE_FILING_SERVICE}/checkSlaveEligibility`,
        data
      );

    return response?.data;
  };

  /**
   * Call an API to add or remove a slave case from master case
   * @param data AddRemoveSlaveModel
   */
  public static readonly addRemoveSlaveCases = async (
    data: AddRemoveSlaveModel
  ): Promise<IStatusResponse> => {
    const response: AxiosResponse<IStatusResponse> = await postCall(
      `${CASE_FILING_SERVICE}/addOrRemoveSlaveCases`,
      data
    );
    return response?.data;
  };

  public static readonly getAdvocateComplainantRespondant = async (commissionId: number) => {
    const response: AxiosResponse<ICaseListWithoutRespondantAdvocate[]> = await getCall(
      `${CASE_FILING_SERVICE}/getAdvocateComplainantRespondant`,
      { commissionId }
    );

    return response?.data;
  };

  /**
     * Call an API to get all the consolidated file
     * @param caseNumberOrFilingRefrenceNumber
     * @param commissionId
     */
  public static readonly getAllConsolidatedFile = async (caseOrFillingNumber: string, commissionId: number, withDocument: boolean = true) => {
    const params = {
      caseOrFillingNumber,
      commissionId,
      withDocument
    };
    const response: AxiosResponse<IGetConsolidateFileResponse> = await getCall(
      `${CASE_FILING_SERVICE}/getDocumentDetailsInReplyRejoinderSubsequent`,
      params
    );
    return response?.data
  };

  /**
    * Call an API to get all the consolidated file
    * @param caseNumberOrFilingRefrenceNumber
    * @return 
    */
  public static readonly getListOfIACaseByCaseNumber = async (caseNumber: string, typeId?:number) => {

    const response: AxiosResponse<IGetListOfIACaseByCaseNumberResponse[]> = await getCall(
      `${CASE_FILING_SERVICE}/getListOfIACaseByCaseNumber`,
      { caseNumber, typeId}
    );

    return response?.data;
  }

  /**
    * Call an API to get all the consolidated file
    * @param caseNumberOrFilingRefrenceNumber
    * @return 
    */
  public static readonly getListOfAttachedCaseByCaseNumber = async (caseNumber: string, caseTypeId:number) => {

    const response: AxiosResponse<IGetListOfIACaseByCaseNumberResponse[]> = await getCall(
      `${CASE_FILING_SERVICE}/getAttachedCaseListByCaseNumber`,
      { caseNumber, caseTypeId}
    );

    return response?.data;
  }
}
