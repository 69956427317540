import { AxiosResponse } from "axios";
import { IGetAppealTypeComplainantRespondentDraftPayload, IGetCommissionPayload, IGetCommissionResponse, IGetCommissionsPayload } from "../../../../models/cases/appealTypeCase/AppealTypeComplainantRespondentModel";
import { IFilingReferenceNumberPayload, IGetAppealTypeCaseDetailsResponse, IGetCaveatStatusPayload, IGetCaveatStatusResponse, IUpdateFaSaRpCaveatCaseDetailsResponse, UpdateFaSaRpCaveatCaseDetailsRequest } from "../../../../models/cases/appealTypeCase/CaseDetailsViewModel";
import { IGetAppealTypeCaseDetailsPayload, IGetPreCaseDetailsPayload, IGetPreDetailsResponse, ISaveCaseDetailsResponse } from "../../../../models/cases/appealTypeCase/PreDetailsModel";
import { IGetComplainantRespondentDetailsResponse } from "../../../../models/cases/consumerCases/IConsumerCaseModel";
import { CASE_FILING_SERVICE, ORDER_SERVICE } from "../../../CONSTANTS";
import { postCall, putCall } from "../../../api";

export class AppealTypeCaseServices {
  /**
   * Call an API to get pre case details
   * @param data IGetPreCaseDetailsPayload
   * @returns Promise<IGetPreDetailsResponse>
   */
  public static readonly getPreCaseDetails = async (data: IGetPreCaseDetailsPayload): Promise<IGetPreDetailsResponse> => {
    const response: AxiosResponse<IGetPreDetailsResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getLowerCommissionCaseDetail`,
      data
    );
    return response?.data;
  };

  /**
* Call an API to save case details
* @param data IGetPreCaseDetailsPayload
* @returns Promise<ISaveCaseDetailsResponse>
*/
  public static readonly saveCaseDetailsSubmit = async (data: IGetAppealTypeCaseDetailsPayload): Promise<ISaveCaseDetailsResponse> => {
    const response: AxiosResponse<ISaveCaseDetailsResponse> = await putCall(
      `${CASE_FILING_SERVICE}/addAppealCaseDetails`,
      data
    );
    return response?.data;
  };

  public static readonly updateCaseDetails = async (data: UpdateFaSaRpCaveatCaseDetailsRequest): Promise<IUpdateFaSaRpCaveatCaseDetailsResponse> => {
    const response: AxiosResponse<IUpdateFaSaRpCaveatCaseDetailsResponse> = await putCall(
      `${CASE_FILING_SERVICE}/updateRemarksDetail`,
      data
    )

    return response?.data;
  }

  /**
  * Call an API to post case details
  * @param data CaseDetailModel
  * @returns Promise<ICaseDetailsResponse>
  */
  public static readonly saveComplainantRespondentSubmit = async (data: any): Promise<any> => {
    const response: AxiosResponse<any> = await postCall(
      `${CASE_FILING_SERVICE}/saveComplainaintRespondentForFirstAppel`,
      data
    );
    return response?.data;
  };

  /**
 * Call an API to get commissions by stateId and commissionTypeId
 * @param data IGetCommissionsPayload
 * @returns Promise<{ commissionId: string, commissionNameEn: string }>
 */
  public static readonly getCommissionsByStateIdAndCommissionTypeId = async (data: IGetCommissionsPayload): Promise<IGetCommissionResponse[]> => {
    const response: AxiosResponse<IGetCommissionResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/getCommissionIdByStateIdAndCommissionTypeId`,
      data
    );
    return response?.data;
  };

  /**
* Call an API to get commissions by commissionId
* @param data IGetCommissionsPayload
* @returns Promise<{ commissionId: string, commissionNameEn: string }>
*/
  public static readonly getCommissionDetailsByCommissionId = async (data: IGetCommissionPayload): Promise<IGetCommissionResponse> => {
    const response: AxiosResponse<IGetCommissionResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getCommissionDetailsByCommissionId`,
      data
    );
    return response?.data;
  };

  /**
     * Call an API to get case details for fresh/new case
     * @param fillingReferenceNumber number
     * @param loginId number
     * @returns Promise<IGetAppealTypeCaseDetailsResponse>
     */
  public static readonly getAppealTypeCaseDetails = async (fillingReferenceNumber: number, loginId: number): Promise<IGetAppealTypeCaseDetailsResponse> => {
    const data = { filingReferenceNumber: fillingReferenceNumber, caseFiledBy: loginId }
    const response: AxiosResponse<IGetAppealTypeCaseDetailsResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getAppealPreview`,
      data
    );
    return response?.data;
  }

  /**
* Call an API to get commissions by commissionId
* @param data IGetCommissionsPayload
* @returns Promise<{ commissionId: string, commissionNameEn: string }>
*/
  public static readonly getCaveatStatusDetails = async (data: IGetCaveatStatusPayload): Promise<IGetCaveatStatusResponse> => {
    const response: AxiosResponse<IGetCaveatStatusResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getCaveatStatus`,
      data
    );
    return response?.data;
  };

    /**
* Call an API to get commissions by commissionId
* @param data IGetCommissionsPayload
* @returns Promise<{ commissionId: string, commissionNameEn: string }>
*/
public static readonly getCaveatStatusDetailsNew = async (data: IFilingReferenceNumberPayload): Promise<IGetCaveatStatusResponse> => {
  const response: AxiosResponse<IGetCaveatStatusResponse> = await postCall(
    `${ORDER_SERVICE}/getCaveatCaseList`,
    data
  );
  return response?.data;
};

  /**
   * Call an API to get appeal type complainant respondent details
   * @param data IGetAppealTypeComplainantRespondentDraftPayload
   * @returns Promise<IGetComplainantRespondentDetailsResponse>
   */
  public static readonly getAppealTypeComplainantRespondentDraft = async (data: IGetAppealTypeComplainantRespondentDraftPayload):
    Promise<IGetComplainantRespondentDetailsResponse> => {
    const response: AxiosResponse<IGetComplainantRespondentDetailsResponse> = await postCall(
      `${CASE_FILING_SERVICE}/getAllComplainentRespondentDetailsDraft`,
      data
    );
    return response?.data;
  }
}
