import { AxiosResponse } from "axios";
import { ICaseHistoryDetailsResponse, ICaseStatusDetailsResponse } from "../../models/cases/commonCase/ICommonCaseModel";
import { getCall, postCall } from "../api";
import { CASE_FILING_SERVICE } from "../CONSTANTS";
import { KnowYourCasePayload, KnowYourCaseResponse } from "../../models/KnowYourCaseNumber/KnowYourCaseNumberModel";

export class CaseStatusHistoryService {
    /**
     * API Calling for fetching case status Details 
     * @param caseNumber
     */
    public static readonly getCaseStausDetails = async (caseNumber: string | null): Promise<ICaseStatusDetailsResponse> => {
        const response: AxiosResponse<ICaseStatusDetailsResponse> = await getCall(
            `${CASE_FILING_SERVICE}/getCaseStatus`,
            { caseNumber }
        );

        return response?.data;
    }

    /**
     * API Calling for fetching case history Details 
     * @param caseNumber
     */
    public static readonly getCaseHistoryDetails = async (caseNumber: string): Promise<ICaseHistoryDetailsResponse[]> => {
        const response: AxiosResponse<ICaseHistoryDetailsResponse[]> = await getCall(
            `${CASE_FILING_SERVICE}/getCaseDetailForHistoryDashBoard`,
            { caseNumber }
        );

        return response?.data;
    }

    /** Calls an API to know your case number
    *
    * @param data KnowYourCasePayload
    * @returns KnowYourCaseResponse<any>
    */
    public static readonly KnowYourCaseNumber = async (data: KnowYourCasePayload): Promise<KnowYourCaseResponse> => {
        const response: AxiosResponse<KnowYourCaseResponse> = await postCall(
            `${CASE_FILING_SERVICE}/knowYourCase`,
            data
        );

        return response?.data;
    };
}