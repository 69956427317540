import { useEffect, useRef } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import "./onboarding.scss"

gsap.registerPlugin(MotionPathPlugin);

const Onboarding = () => {
    const boxRef = useRef(null);
  const stepBoxRefs = useRef([useRef(null), useRef(null), useRef(null)]);
  const steps = [
    {
      text: "Register Yourself ",
      color: "#aaffb2",
      position: "1%",
      circleColor: "#00ff18",
      top: "5.5rem",
      size: "54px",
      desc:"Begin by registering on eJagriti to create account."
    },
    {
      text: "Complete  Profile ",
      color: "#ffe1af",
      position: "36%",
      circleColor: "#e4aa46",
      top: "9rem",
      size: "74px",
      desc:"Once registered, login to the account and complete the profile."
    },
    {
      text: "File Complaint",
      color: "#c1e7ff",
      position: "70%",
      circleColor: "#004669",
      top: "6rem",
      size: "54px",
      desc:"Login to the account and file complaint"
    },
  ];

  useEffect(() => {
    const tl = gsap.timeline({
        delay:1
    });

    // Animate the circle along the path
    tl.to(boxRef.current, {
      motionPath: {
        path: "#path",
        align: "#path",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      transformOrigin: "50% 50%",
      duration: 9,
      ease: "power1.inOut",
    });

    // Animate each step box to appear at the appropriate time
    steps.forEach((step, index) => {
      tl.fromTo(
        stepBoxRefs.current[index].current,
        { opacity: 0 },
        { opacity: 1, duration: 1 },
        (index + 1) * (7 / steps.length) - 1 // Timing for each step box to appear
      );
    });
  }, []);
  return (
    <section className="section-padding onboarding-container position-relative">
  <img
    className="d-none d-md-block position-absolute"
    style={{ top: "-5rem", left: "-5rem", transform: "rotate(-90deg)" }}
    src="/onboarding/Group 1000004191.svg"
    alt=""
  />
  <img
    className="d-none d-md-block position-absolute"
    style={{ top: "31%", left: "13%" }}
    src="/onboarding/Ellipse 42.svg"
    alt=""
  />
  <img
    className="d-none d-md-block position-absolute"
    style={{ right: "13%" }}
    src="/onboarding/Ellipse 43.svg"
    alt=""
  />
  <img
    className="d-none d-md-block position-absolute"
    style={{ bottom: "-50%", left: "0" }}
    src="/onboarding/Group 1000004370 (1).svg"
    alt=""
  />

  <h2 className="text-center simple-onboarding-heading fs-1 fw-bold text-capitalize">
    simple easy <span>onboarding</span> process
  </h2>
  <div className=" d-flex justify-content-center onboarding-path-container mx-auto position-relative">
    <div className='onboarding-path'>
      <svg

        viewBox="0 0 904 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="path"
          d="M0 36C47.0165 9.58818 174.602 -27.3884 308.813 36C443.024 99.3884 569.5 57 619.764 36C669 19.5 781.757 -21.7717 904 36"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="3 5"
        />
        <rect
          id="div"
          ref={boxRef}
          width="12"
          height="12"
          fill="#368FC7"
          rx="4.5"
          ry="4.5"
        />
      </svg>
    </div>
    {steps.map((stepObj, index) => (
      <div
        key={index}
        ref={stepBoxRefs.current[index]}
        className="position-absolute p-4 rounded-lg"
        style={{
          top: stepObj.top, // Adjust this value to position the box correctly
          left: stepObj.position,
          backgroundColor: stepObj.color,
          opacity: 0, // Initially hidden
          borderRadius: "8px"
        }}
      >
        <div className="position-relative">
          <div
            className="position-absolute border border-warning p-2 rounded-circle"
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "4.5rem",
            }}
          >
            <div
              style={{
                backgroundColor: stepObj.circleColor,
              }}
              className="rounded-circle d-flex justify-content-center align-items-center text-white  checkpoint-circle"
            >
              {index + 1}
            </div>
          </div>
        </div>
        <p className="checkpoint-text-heading text-center text-black  fw-medium">
          {stepObj.text}
        </p>
        <p className="d-none d-md-block text-center text-black fs-6 fw-normal mt-4 mx-auto" style={{ width: "20ch" }}>
          {stepObj.desc}
        </p>
      </div>
    ))}
  </div>
</section>

  )
}

export default Onboarding