import { Grid, Typography } from '@mui/material';
import React from 'react';
import arrow_image from '../../../assets/images/arrow_image.png';
import image from '../../../assets/images/basil_document-solid.png';
import { complainantBenchDocs, oppositeBenchPartyDocs } from '../../../utils/CONSTANTS';
import { IIACaseList } from './JudgeCasePagesPreview';
import { IDropdown } from '../../../models/common/IDropdown';
import { handleCaseTypePartyNames } from '../../../utils/CaseHelper';

interface CasesCardComponentProps {
  viewMode: string;
  handleCaseDocumentCardClick: (id: number) => void;
  caseTypeId: number
};

export const RenderDocsSection: React.FC<{ title: string, docs: IDropdown[], titleColor: string, viewMode: string, handleCaseDocumentCardClick: (id: number) => void }> = ({ title, docs, titleColor, viewMode, handleCaseDocumentCardClick }) => (
  <Grid sx={{ padding: '5px', marginBottom: '10px', width: '100%' }}>
    <Typography
      variant="h6"
      style={{
        backgroundColor: titleColor, marginBottom: '9px', fontWeight: 'bold', alignItems: "center",
        justifyContent: "center", width: "100%", height: "50px", padding: "15px", display: "flex"
      }}
    >
      {title}
    </Typography>
    <Grid container spacing={2}>
      {docs.map((doc, index) => (
        <Grid item xs={12} md={viewMode === 'grid' ? 6 : 12} key={index}>
          <div className="card h-100" style={{ width: '100%', cursor: "pointer" }} onClick={() => handleCaseDocumentCardClick(+doc.value)}>
            <div className="important-link-card">
              <div className={titleColor === "#F4F3F8" ? 'main-image-judge' : 'main-image-judge-secondary'}>
                <img src={image} alt="Document Icon" className="image-class" />
              </div>
              <div className="card-content-judge">
                <Typography variant="body2" className="card-title-judge">
                  {doc.label}
                </Typography>
                <img src={arrow_image} alt="Arrow Icon" className="arrow-image-judge" />
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

const CasesCardComponent: React.FC<CasesCardComponentProps> = ({ viewMode, handleCaseDocumentCardClick, caseTypeId }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <RenderDocsSection
          title={`${handleCaseTypePartyNames(caseTypeId).complainantLabel}(s) Documents`}
          docs={complainantBenchDocs(caseTypeId)}
          titleColor="#F4F3F8"
          viewMode={viewMode}
          handleCaseDocumentCardClick={handleCaseDocumentCardClick}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RenderDocsSection
          title="Opposite Party(ies) Documents"
          docs={oppositeBenchPartyDocs(caseTypeId)}
          titleColor="#F9EFE8"
          viewMode={viewMode}
          handleCaseDocumentCardClick={handleCaseDocumentCardClick}
        />
      </Grid>
    </Grid>
  );
};

export default CasesCardComponent;
