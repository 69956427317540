import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { BasicDatePicker, ConfonetAutocomplete, Textbox } from '../../../../../../components';
import { caseTypeEnum } from '../../../../../../utils/Enum';
import { DateTime } from 'luxon';
import { FormikProps } from 'formik';
import { FaSaRpCaveatCaseDetailsViewModel } from '../../../../../../models/cases/appealTypeCase/CaseDetailsViewModel';
import { TextField } from '@mui/material';
import { IDropdown } from '../../../../../../models/common/IDropdown';


interface IFaSaRpCaveatCaseDetailsProps {
    formik: FormikProps<FaSaRpCaveatCaseDetailsViewModel>,
}

const FaBankDetails = ({ formik }: IFaSaRpCaveatCaseDetailsProps) => {
    const { t } = useTranslation();

    // Options for BankName and BranchName
    const bankOptions = [
        { value: 1, label: 'Bank 01' },
        { value: 2, label: 'Bank 02' },
        { value: 3, label: 'Bank 03' }
    ];

    const branchOptions = [
        { value: 1, label: 'Branch 01' },
        { value: 2, label: 'Branch 02' },
        { value: 3, label: 'Branch 03' }
    ];

    useEffect(() => {
        formik.setFieldValue('caseType', caseTypeEnum.FirstAppeal);
    }, [])

    return (
        <>

            <form onSubmit={formik.handleSubmit}>
                <div className="row d-flex">
                    <div className="col-lg-4">
                        <BasicDatePicker
                            label={t("Label.ImpungedOrderDate")}
                            value={formik.values.ImpungedOrderDate}
                            className="w-100"
                            onChange={(e) => formik.setFieldValue("ImpungedOrderDate", e, true)}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    error: formik.touched.ImpungedOrderDate && Boolean(formik.errors.ImpungedOrderDate),
                                    helperText: (
                                        <span className="text-danger">
                                            {formik.touched.ImpungedOrderDate && formik.errors.ImpungedOrderDate as string}
                                        </span>
                                    ),
                                    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                }
                            }}
                            maxDate={DateTime.now()}
                        />
                    </div>
                    <div className="col-lg-4">
                        <BasicDatePicker
                            label={t("Label.DateOfInterim")}
                            value={formik.values.DateOfInterim}
                            className="w-100"
                            onChange={(e) => formik.setFieldValue("DateOfInterim", e, true)}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    error: formik.touched.DateOfInterim && Boolean(formik.errors.DateOfInterim),
                                    helperText: (
                                        <span className="text-danger">
                                            {formik.touched.DateOfInterim && formik.errors.DateOfInterim as string}
                                        </span>
                                    ),
                                    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                }
                            }}
                            maxDate={DateTime.now()}
                        />
                    </div>
                </div>

                <div className="row">
                    <label
                        style={{
                            fontSize: "var(--size-18)",
                            lineHeight: "var(--size-21)",
                            fontWeight: "var(--weight-700)",
                            color: "var(--heading)",
                            margin: "var(--size-0)",
                            marginBottom: '15px'
                        }}
                    >
                        DD Details
                    </label>
                    <div className="col-lg-4 input-box-spacing">

                        <Textbox
                            id="dDno"
                            name="dDno"
                            className="uppercase"
                            label={t("Label.DDNO")}
                            value={formik.values.dDno}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.dDno && Boolean(formik.errors.dDno)}
                            helperText={formik.touched.dDno && formik.errors.dDno}
                        />
                    </div>
                    <div className="col-lg-4 input-box-spacing">
                        <BasicDatePicker
                            label={t("Label.Date")}
                            value={formik.values.Date}
                            className="w-100"
                            onChange={(e) => formik.setFieldValue("Date", e, true)}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    error: formik.touched.Date && Boolean(formik.errors.Date),
                                    helperText: (
                                        <span className="text-danger">
                                            {formik.touched.Date && formik.errors.Date as string}
                                        </span>
                                    ),
                                    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => e.preventDefault()
                                }
                            }}
                            maxDate={DateTime.now()}
                        />
                    </div>
                    <div className="col-lg-4 input-box-spacing">
                        <Textbox
                            id="amount"
                            name="amount"
                            className="uppercase"
                            label={t("Label.Amount")}
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                        />
                    </div>
                    <div className="col-lg-4 input-box-spacing">
                        <ConfonetAutocomplete
                            id="bankId"
                            value={formik.values.bankId ?? { label: "", value: "" }}
                            options={bankOptions}
                            renderInput={
                                (params) => (
                                    <TextField
                                        {...params}
                                        label={t("Label.BankName") + "*"}
                                        error={formik.touched.bankId && Boolean(formik.errors.bankId)}
                                        helperText={formik.touched.bankId && formik.errors?.bankId}
                                        fullWidth
                                    />
                                )
                            }
                            onChange={(_, value: IDropdown | null) => formik.setFieldValue("bankId", value ?? { label: "", value: "" })}
                            onBlur={formik.handleBlur}
                        // disabled={!isDraft}
                        />
                    </div>
                    <div className="col-lg-4 input-box-spacing">
                        <ConfonetAutocomplete
                            id="branchId"
                            value={formik.values.branchId ?? { label: "", value: "" }}
                            options={branchOptions}
                            renderInput={
                                (params) => (
                                    <TextField
                                        {...params}
                                        label={t("Label.BranchName") + "*"}
                                        error={formik.touched.branchId && Boolean(formik.errors.branchId)}
                                        helperText={formik.touched.branchId && formik.errors?.branchId}
                                        fullWidth
                                    />
                                )
                            }
                            onChange={(_, value: IDropdown | null) => formik.setFieldValue("branchId", value ?? { label: "", value: "" })}
                            onBlur={formik.handleBlur}
                        // disabled={!isDraft}
                        />
                    </div>
                </div>
            </form>

        </>
    );
};

export default FaBankDetails;
