import { FormikProps } from 'formik';
import React from 'react';
import { BasicBreadcrumbs } from '../../../components';
import { BreadcrumbsProps } from '../../../components/controls/BasicBreadcrumbs';
import { IgetNoteListResponse, ImportantNotesCreateViewModel, ImportantNotesSearchViewModel } from '../../../models/ImportantNotes/IImportantNotes';
import CreateOrUpdateImportantNotes from './components/CreateOrUpdateImportantNotes';
import ImportantNotesList from './components/ImportantNotesList';
import ImportantNotesSearch from './components/ImportantNotesSearch';
import { IDropdown } from '../../../models/common/IDropdown';

interface IImportantNotesViewProps {
    breadCrump: BreadcrumbsProps[],
    importantNotesSearchFormik: FormikProps<ImportantNotesSearchViewModel>,
    importantNotesCreateOrUpdateFormik: FormikProps<ImportantNotesCreateViewModel>
    handleEdit: (note: IgetNoteListResponse) => void
    handleDelete: (note: IgetNoteListResponse) => void
    notesData: IgetNoteListResponse[]
    caseupdateoption: IDropdown[]

}

const ImportantNotesView: React.FC<IImportantNotesViewProps> = ({ breadCrump, importantNotesSearchFormik, importantNotesCreateOrUpdateFormik, handleEdit, handleDelete, notesData, caseupdateoption }) => {
    return (
        <section className="section margin-top-35">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-6">
                        <BasicBreadcrumbs items={breadCrump} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='row'>
                            <ImportantNotesSearch
                                importantNotesSearchFormik={importantNotesSearchFormik}
                            />
                        </div>
                        <div className='row'>
                            <ImportantNotesList
                                importantNotesSearchFormik={importantNotesSearchFormik}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                notesData={notesData}
                            />
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <CreateOrUpdateImportantNotes
                            importantNotesCreateOrUpdateFormik={importantNotesCreateOrUpdateFormik}
                            importantNotesSearchFormik={importantNotesSearchFormik}
                            caseupdateoption={caseupdateoption}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImportantNotesView