import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import { JUDGE, JUDGE_CASE_HISTORY, JUDGE_CASE_PREVIEW, JUDGE_CASE_VIEW, JUDGE_CAUSELIST, JUDGE_CONSOLIDATED_FILES, JUDGE_COURTROOM_HEARING, JUDGE_COURTROOM_HEARING_DETAILS, JUDGE_COURTROOM_MODIFICATION, JUDGE_DAILY_ORDER_ENTRY, JUDGE_DAILY_ORDER_VIEW, JUDGE_DASHBOARD, JUDGE_EDITPROFILE, JUDGE_FIND_CASEDETAILS, JUDGE_IMPORTANT_NOTES, JUDGE_JUDGMENT_PROCEEDING, JUDGE_JUDGMENT_UPLOAD, JUDGE_UPLOAD_TYPE_JUDGMENT, JUDGE_UPLOAD_TYPE_ORDER, JUDGE_ACT_RULES, JUDGE_BENCH_DETAILS, JUDGE_BENCHES_LIST, JUDGE_COMPLAINT_PAGES, JUDGE_DAILY_ORDER, JUDGE_IA_FILLED, JUDGE_CASE_PREVIEW_ATTACHED_CASE, JUDGE_NOTICE_PAGES, JUDGE_NOTICE_GENERATE_PAGES, GENERATE_COUSELIST, GENERATE_COUSELIST_VIEW, GENERATE_COUSELIST_VIEW_PAGE, GENERATE_COUSELIST_EDIT_PAGE, GENERATE_COUSELIST_CAUSELIST } from "../CONSTANTS";
import { JudgeCasePagesPreview } from "../../pages/Judge/JudgeCaseDetailsPage/JudgeCasePagesPreview";
import ActRulesCombined from "../../pages/Judge/Benches/components/Act_rules_combined";
import ComplaintPages from "../../pages/Judge/JudgeCaseDetailsPage/ComplaintPages";
import { DailyOrderData } from "../../pages/Judge/JudgeCaseDetailsPage/DailyOrderData";
import IaFilledDocu from "../../pages/Judge/JudgeCaseDetailsPage/IaFilledDocu";
import IaCaseDetailsForBench from "../../pages/Judge/JudgeCaseDetailsPage/IaCaseDetailsForBench";
import IssuePrintNoticeView from "../../pages/Commissions/IssuePrintNotice/IssuePrintNoticeView";
import GenerateAndEditNoticeView from "../../pages/Commissions/IssuePrintNotice/component/GenerateNotice/GenerateNoticeView";
import GeneateCouseList from "../../pages/Judge/GeneateCauseList/GeneateCouseList";
import { GeneratePageView } from "../../pages/Judge/GeneateCauseList/GeneratePageView";
import { ViewPage } from "../../pages/Judge/GeneateCauseList/ViewPage";
import { EditPage } from "../../pages/Judge/GeneateCauseList/EditPage";
import JudgeCaseDetails from "../../pages/Judge/JudgeCaseDetailsPage/CaseAndDocumentDetails/JudgeCaseDetails";
import { CauseListDataDetails } from "../../pages/Judge/GeneateCauseList/CauseListDataDetails";


// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const CommissionDashboard = lazy(() => import('../../pages/dashboard/components/CommissionDashboard/CommissionDashboard'));
const CourtroomHearingList = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomHearingList'));
const CourtroomModification = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomModification'));
const CourtroomHearingView = lazy(() => import('../../pages/Commissions/CourtroomHearing/component/CourtroomHearingView'));
const FindCaseDeailsContainer = lazy(() => import('../../pages/Commissions/FindCaseDetails/FindCaseDeailsContainer'));
const JudgementSearchContainer = lazy(() => import('../../pages/Commissions/JudgementSearch'));
const DailyOrderEntry = lazy(() => import('../../pages/Commissions/DailyOrderEntry'));
const UploadTypeOrder = lazy(() => import('../../pages/Commissions/DailyOrderEntry/component/UploadTypeOrder'));
const JudgmentUpload = lazy(() => import('../../pages/Commissions/JudgmentUpload'));
const UploadTypeJudgement = lazy(() => import('../../pages/Commissions/JudgmentUpload/component/UploadTypeJudgement'));
const JudgementProceeding = lazy(() => import('../../pages/Commissions/JudgementSearch'));
const ConsolidatedFilesContainer = lazy(() => import('../../pages/Commissions/ConsolidatedFiles'));
const ImportantNotesContainer = lazy(() => import('../../pages/Judge/ImportantNotes'));
const CauseList = lazy(() => import('../../pages/Commissions/CauseList'));
const CaseHistoryForJudgeContainer = lazy(() => import('../../pages/Judge/CaseHistoryForJudge'));
const BenchesContainer = lazy(() => import('../../pages/Judge/Benches'));
const BenchDetails = lazy(() => import('../../pages/Judge/Benches/components/BenchDetails'));
const CasePreview = lazy(() => import('../../pages/dashboard/components/CasePreview').then((module) => ({ default: module.CasePreview })));

const presidentChildrenRoute: IRoute[] = [
    {
        path: JUDGE_EDITPROFILE,
        element: <EditProfile />
    },
    {
        path: JUDGE,
        element: <Dashboard />,
        children: [
            {
                path: JUDGE_DASHBOARD,
                element: <CommissionDashboard />
            }
        ]
    },
    {
        path: JUDGE_COURTROOM_HEARING,
        element: <CourtroomHearingList />
    },
    {
        path: JUDGE_COURTROOM_MODIFICATION,
        element: <CourtroomModification />
    },
    {
        path: JUDGE_COURTROOM_HEARING_DETAILS,
        element: <CourtroomHearingView />
    },
    {
        path: JUDGE_DAILY_ORDER_ENTRY,
        element: <DailyOrderEntry />
    },
    {
        path: JUDGE_DAILY_ORDER_VIEW,
        element: <DailyOrderEntry />
    },
    {
        path: JUDGE_UPLOAD_TYPE_ORDER,
        element: <UploadTypeOrder />
    },
    {
        path: JUDGE_JUDGMENT_UPLOAD,
        element: <JudgmentUpload />
    },
    {
        path: JUDGE_UPLOAD_TYPE_JUDGMENT,
        element: <UploadTypeJudgement />
    },
    {
        path: JUDGE_FIND_CASEDETAILS,
        element: <FindCaseDeailsContainer />
    },
    {
        path: JUDGE_JUDGMENT_PROCEEDING,
        element: <JudgementSearchContainer />
    },
    {
        path: JUDGE_JUDGMENT_PROCEEDING,
        element: <JudgementProceeding />
    },
    {
        path: JUDGE_CONSOLIDATED_FILES,
        element: <ConsolidatedFilesContainer />
    },
    {
        path: JUDGE_IMPORTANT_NOTES,
        element: <ImportantNotesContainer />
    },
    {
        path: JUDGE_CAUSELIST,
        element: <CauseList />
    },
    {
        path: JUDGE_CASE_HISTORY,
        element: <CaseHistoryForJudgeContainer />
    },
    {
        path: JUDGE_CASE_VIEW,
        element: <CasePreview />
    },
    {
        path: JUDGE_CASE_PREVIEW,
        element: <JudgeCaseDetails />
    },
    {
        path: JUDGE_CASE_PREVIEW_ATTACHED_CASE,
        element: <IaCaseDetailsForBench />
    },
    {
        path: JUDGE_BENCHES_LIST,
        element: <BenchesContainer />
    },
    {
        path: JUDGE_BENCH_DETAILS,
        element: <BenchDetails />
    },
    {
        path: JUDGE_ACT_RULES,
        element: <ActRulesCombined />
    },
    {
        path: JUDGE_NOTICE_PAGES,
        element: <IssuePrintNoticeView />
    },
    {
        path: JUDGE_NOTICE_GENERATE_PAGES,
        element: <GenerateAndEditNoticeView />
    },
    {
        path: JUDGE_COMPLAINT_PAGES,
        element: <ComplaintPages />
    },
    {
        path: JUDGE_DAILY_ORDER,
        element: <DailyOrderData />
    },
    {
        path: JUDGE_IA_FILLED,
        element: <IaFilledDocu />
    },
    {
        path: GENERATE_COUSELIST,
        element: <GeneateCouseList />
    },
    {
        path: GENERATE_COUSELIST_VIEW,
        element: <GeneratePageView courtId={0} courtName={""} selectedDate={""} />
    },
    {
        path: GENERATE_COUSELIST_VIEW_PAGE,
        element: <ViewPage courtId={0} courtName={""} selectedDate={""} />
    },
    {
        path: GENERATE_COUSELIST_CAUSELIST,
        element: <CauseListDataDetails courtId={0} courtName={""} selectedDate={""} />
    },
    {
        path: GENERATE_COUSELIST_EDIT_PAGE,
        element: <EditPage courtId={0} courtName={""} selectedDate={""} />
    },
];

// list of commission Routes
export const presidentRoutes: IRoute[] = [
    {
        path: '',
        element: <PrivateRoute
            requiredRoles={[ROLE.President]}
        />,
        children: presidentChildrenRoute
    }
];