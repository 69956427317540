import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BasicBreadcrumbs, BasicTabs, DataTable } from "../../../components";
import { BasicButton } from "../../../components/controls/BasicButton";
import { RootState } from "../../../redux";
import { navigateToDashboardPath } from "../../../utils/Helper";
import { CauselistJudgeResponse, GenerateCauselistViewModel } from "./GenerateModel";
import { CouselistServices } from "../../../services/JudgeService/GenerateCauselistServices";
import { GENERATE_COUSELIST_EDIT_PAGE, GENERATE_COUSELIST_VIEW_PAGE } from "../../../navigation/CONSTANTS";
import { useFormik } from "formik";
import * as Yup from 'yup';
import ButtoBack from "./Button";

interface ITableRowData extends CauselistJudgeResponse {
  id?: number;
}

//from validation
const validationSchema = Yup.object({
  dateOfNextHearing: Yup.string().required("Date of next hearing is required"),
  caseNumber: Yup.string().required("Case number is required"),
});

/** Renders daily order entry component */
export const GeneratePageView: React.FC<{ courtId: number, courtName: string, selectedDate: string }> = ({ courtName, selectedDate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetailsData } = useSelector((state: RootState) => state.user);

  const { causeListData, isFinalized } = location?.state;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [dailyOrderEntryListDraftView, setDailyOrderEntryListDraftView] = useState<ITableRowData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      // Assuming causeListData is available from props, location, or state
      if (causeListData) {
        const couseListData = [causeListData]?.map((item: CauselistJudgeResponse, index: number) => {
          return {
            ...item,
            dateOfHearing: item.clDate,
            courtRoomName: item.courtId.toString(),
            id: index + 1,
          };
        });

        // Set the data into the draft view
        setDailyOrderEntryListDraftView(couseListData);
      }
    };

    fetchData();
  }, [causeListData]);

  const dailyOrderEntryColumns: GridColDef[] = [
    { field: "id", headerName: t("Label.No"), maxWidth: 70, flex: 1 },
    {
      field: "dateOfHearing", headerName: t("Label.Date"), flex: 1,
      valueGetter: (params) => {
        return DateTime.fromISO(params.value).toFormat("dd-MM-yyyy");
      }
    },
    { field: "courtRoomName", headerName: t("Label.CourtRoom"), flex: 1 }, // Make sure this matches your data key
    {
      field: 'Action',
      headerName: t("Label.Action"),
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <BasicButton
              text={t("BtnText.View")}
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => handleAction(params.row, "view")}
            />
            {currentTab === 0 && (
              <BasicButton
                text={t("BtnText.Edit")}
                size="small"
                color="primary"
                sx={{ margin: "0px 5px" }}
                variant="contained"
                onClick={() => handleEdit(params.row)}
              />
            )}
          </>
        );
      }
    }
  ];


  const tabs = [
    {
      value: 0,
      label: t("Dashboard.Draft"),
      children: (
        <DataTable
          rows={isFinalized ? [] : dailyOrderEntryListDraftView}
          columns={dailyOrderEntryColumns}
          getRowId={(row) => row.slno}
        />
      )
    },
    {
      value: 1,
      label: t("Label.FinalizedOrder"),
      children: (
        <DataTable
          rows={isFinalized ? dailyOrderEntryListDraftView : []}
          columns={dailyOrderEntryColumns}
          getRowId={(row) => row.slno}
        />
      )
    }
  ];

  const handleViewData = async (paramsData: CauselistJudgeResponse) => {
    try {
      const params = {
        clSlno: causeListData?.slno

      };
      const response = await CouselistServices.getJudgeGenerateCouselistView(params);
      if (response?.length) {
        const viewData = {
          responseData: response
        };
        navigate(GENERATE_COUSELIST_VIEW_PAGE, { state: viewData.responseData });

      } else {
        // toast.warn('No Data Available');
      }
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
  }

  const handleAction = async (row: any, action: any) => {
    await handleViewData(row)

  };

  const handleEdit = async (paramsData: CauselistJudgeResponse) => {
    try {
      const params = {
        clSlno: causeListData?.slno

      };
      const response = await CouselistServices.getJudgeGenerateCouselistView(params);
      if (response?.length) {
        const editData = {
          responseData: response
        };
        navigate(GENERATE_COUSELIST_EDIT_PAGE, { state: { editAllResponseData: editData.responseData, causeListData }, });

      } else {
        // toast.warn('No Data Available');
      }
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
  }

  const handleSubmit = async () => {
    // dailyOrderEntryListDraftView
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: GenerateCauselistViewModel.init(),
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <section className="section margin-top-35">
        <div className="container-fluid padding-top-10">
          <div className="row align-items-center">
            <div className="col-lg-9 col-sm-7 margin-bottom-30">
              <BasicBreadcrumbs items={[
                { to: navigateToDashboardPath(userDetailsData.roleId), label: t("Breadcrumb.Dashboard") },
                { to: '', label: t("Label.Draft") },
              ]} />
            </div>
          </div>
          <div className="row margin-bottom-25">
            <div className="col-lg-12">
              <BasicTabs
                tabs={tabs}
                value={currentTab}
                onChange={(event, newValue) => setCurrentTab(newValue)}
              />
            </div>
          </div>
        </div>
        <div>
        </div>
        {/* <div className='right-corner-button'>
          <ButtoBack />
        </div> */}
      </section>
    </>
  );
};
