import { AxiosResponse } from "axios";
import {
  IGetCaseDetailsResponse,
  IGetCaseListResponse,
  IGetComplainantRespondentDetailsResponse,
  IGetDisposedCaseResponse,
  IGetDocumentResponse,
  IGetFiledCaseResponse,
  IGetPendingCaseResponse,
} from "../../../models/cases/consumerCases/IConsumerCaseModel";
import { CaseFillingAdvocateDetails } from "../../../models/cases/fileNewCase/ComplainantResondentModel";
import { CASE_FILING_SERVICE } from "../../CONSTANTS";
import { getCall, postCall } from "../../api";
import { IComplainantRespondantDetailsResponse } from "../../../models/cases/commonCase/ICommonCaseModel";

export class ConsumerCaseServices {
  /**
   * Call an API to get list of cases for specific user
   * @param userId number
   * @returns Promise<IGetCaseListResponse[]>
   */
  public static readonly getCaseList = async (
    userId: number
  ): Promise<IGetCaseListResponse[]> => {
    const params = { userId };
    const response: AxiosResponse<IGetCaseListResponse[]> = await getCall(
      `${CASE_FILING_SERVICE}/getCaseList`,
      params
    );
    return response?.data;
  };

  /**
   * Call an API to get case details for fresh/new case
   * @param fillingReferenceNumber number
   * @param loginId number
   * @returns Promise<IGetCaseDetailsResponse>
   */
  public static readonly getCaseDetails = async (
    filingReferenceNumber: number,
    loginId: number,
    commissionId?: number
  ): Promise<IGetCaseDetailsResponse> => {
    const params = { filingReferenceNumber, loginId, commissionId };
    const response: AxiosResponse<IGetCaseDetailsResponse> = await getCall(
      `${CASE_FILING_SERVICE}/getPreviewCases`,
      params
    );
    return response?.data;
  };

  public static readonly getComplainantRespondentDetails = async (
    filingReferenceNumber: number
  ): Promise<IGetComplainantRespondentDetailsResponse> => {
    const params = {
      filingReferenceNumber,
    };
    const response: AxiosResponse<IGetComplainantRespondentDetailsResponse> =
      await getCall(
        `${CASE_FILING_SERVICE}/getAllComplainantRespondantDetails`,
        params
      );
    return response?.data;
  };

  public static readonly getComplainantRespondentDetail = async (
    filingReferenceNumber: number
  ): Promise<IComplainantRespondantDetailsResponse> => {
    const params = {
      filingReferenceNumber,
    };
    const response: AxiosResponse<IComplainantRespondantDetailsResponse> =
      await getCall(
        `${CASE_FILING_SERVICE}/getAllComplainantRespondantDetails`,
        params
      );
    return response?.data;
  };


  /**
   * Call an API to get list of advocates
   * @param fillingReferenceNumber string
   * @param complainantRespondantTypeId number
   * @param complainantRespondantSequenceNumber number
   * @returns IGetAdvocateResponse
   */
  public static readonly getAdvocateDetails = async (
    fillingReferenceNumber: string,
    complainantRespondantTypeId: number,
    complainantRespondantSequenceNumber: number
  ): Promise<CaseFillingAdvocateDetails[]> => {
    const data = {
      fillingReferenceNumber,
      complainantRespondantTypeId,
      complainantRespondantSequenceNumber,
    };
    const response: AxiosResponse<CaseFillingAdvocateDetails[]> =
      await postCall(
        `${CASE_FILING_SERVICE}/getAdvocateDetail`,
        data
      );
    return response?.data;
  };

  public static readonly getCaseFillingDocumentDetails = async (
    filingReferenceNumber: number
  ): Promise<IGetDocumentResponse> => {
    const params = { fillingReferenceNumber: filingReferenceNumber };
    const response: AxiosResponse<IGetDocumentResponse> = await getCall(
      `${CASE_FILING_SERVICE}/getCaseFillingDocumentDetails`,
      params
    );

    // sort the documents according to docment sequence number
    const sortedData = response?.data?.caseFillingDocumentDetailsResponseList.sort((a, b) => a?.documentSequenceNumber - b?.documentSequenceNumber);
    const data: IGetDocumentResponse = {
      ...response?.data,
      caseFillingDocumentDetailsResponseList: sortedData,
    }
    return data;
  };

  public static readonly getFiledCases = async (
    userId: number,
    isRespondant = false
  ): Promise<IGetFiledCaseResponse[]> => {
    const params = { userId, isRespondant };
    const response: AxiosResponse<IGetFiledCaseResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/getApprovedList`,
      {},
      params
    );
    return response?.data;
  };

  /** Call an API to get list of consumer disposed cases (judgement upload)
   * @param data string
   */
  public static readonly getConsumerDashboardDisposedJU = async (
    userId: number,
    isRespondant = false
  ): Promise<IGetDisposedCaseResponse[]> => {
    const params = { userId, isRespondant };
    const response: AxiosResponse<IGetDisposedCaseResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/getConsumerDashboardDisposedJudgementUploaded`,
      {},
      params
    );
    return response?.data;
  };

  /** Call an API to get list of consumer pending cases
   * @param data string
   */
  public static readonly getConsumerDashboardPending = async (
    userId: number,
    isRespondant = false
  ): Promise<IGetPendingCaseResponse[]> => {
    const params = { userId, isRespondant };
    const response: AxiosResponse<IGetPendingCaseResponse[]> = await postCall(
      `${CASE_FILING_SERVICE}/getConsumerDashboardPending`,
      {},
      params
    );
    return response?.data;
  };
}
