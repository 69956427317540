import { AxiosResponse } from "axios";
import { getCall, postCall } from "../api";
import { CASE_FILING_SERVICE } from "../CONSTANTS";
import { CauselistFormPayloadEdit, CauselistResponse } from "../../models/causelist/CauselistModel";
import { CauselistJudgePayload, CauselistJudgePayloadView, CauselistJudgeResponse, IJudgeGenerateCauseList } from "../../pages/Judge/GeneateCauseList/GenerateModel";

export class CouselistServices {


    /**
    * API Calling for fetching bench list
    * @param data
    */
    public static readonly getJudgeGenerateCouselist = async (data: CauselistJudgePayload): Promise<IJudgeGenerateCauseList> => {
        const { courtId, commissionId, dateOfNextHearing, finalize } = data
        const response: AxiosResponse<IJudgeGenerateCauseList> = await getCall(
            `${CASE_FILING_SERVICE}/getAllEjclData?commissionId=${commissionId}&courtId=${courtId}&clDate=${dateOfNextHearing}&finalize=${finalize}`,
        );
        return response?.data
    }

    /**
    * API Calling for fetching bench list
    * @param data
    */
    public static readonly getJudgeGenerateCouselistView = async (data: CauselistJudgePayloadView): Promise<CauselistResponse[]> => {
        const { clSlno } = data
        const response: AxiosResponse<CauselistResponse[]> = await getCall(
            `${CASE_FILING_SERVICE}/getEjclDataById?clSlno=${clSlno}`
        );

        return [...response?.data];
    }

    /**
    * API Calling for fetching bench list
    * @param data
    */
    public static readonly getJudgeGenerateCouselistEdit = async (data: CauselistFormPayloadEdit): Promise<CauselistResponse[]> => {
        const response: AxiosResponse<CauselistResponse[]> = await postCall(
            `${CASE_FILING_SERVICE}/saveUpdateEjclData`,
            data
        );

        return response?.data
    }

}