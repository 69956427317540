import { IOnclickedFetchedReportJudgeData } from "../../../../models/common/IReport";
import { IDetailsOfJudgesCasesDetails } from "../../../../models/reports/ReportModel";

// Pending message
export const pendingMessage = {
  text: [
    {
      text: "*Note:- ",
      italics: true,
      color: "red",
      fontSize: 10,
    },
    {
      text: "Pending status is based on cases which are not in case stage disposed off, disposed off/compliance and consigned to recordroom.",
      italics: true,
      fontSize: 10,
    },
  ],
};

// Calculating report total
export const calculatingTotalData = (data: any, parameter: any) => {
  return data.reduce((total: any, item: any) => total + item[parameter], 0);
};

// Iterate through the data and format the in Indian numbers
export const convertNumber = (data: any[]): any[] => {
  return data.map((item) => {
    return Object.entries(item).reduce((acc: any, [key, value]) => {
      acc[key] =
        typeof value === "number" ? value.toLocaleString("en-IN") : value;
      return acc; 
    }, {});
  });
};

// format data into PDF format to support pdfmake package.
export const transformData = (formattedData: any[]) => {
  return formattedData?.map((obj: Record<string, any>, index: number) => {
    const transformedObj: { text: string; alignment: string }[] = [];
    transformedObj.push({
      text: formattedData.length - 1 === index ? "" : (index + 1).toString(),
      alignment: "center",
    });
    Object.entries(obj).forEach(([key, value]) => {
      const alignment = !isNaN(Number(value.split(",").join(value[0])))
        ? "right"
        : "left";
      const formattedValue =
        typeof value === "number" ? value.toLocaleString() : value;
      transformedObj.push({ text: formattedValue, alignment });
    });
    return transformedObj;
  });
};

// format data into PDF format to support pdfmake package.
export const transformDataWithoutTotal = (formattedData: any[]) => {
  return formattedData?.map((obj: Record<string, any>, index: number) => {
    const transformedObj: { text: string; alignment: string }[] = [];
    transformedObj.push({
      text: (index + 1).toString(),
      alignment: "center",
    });
    Object.entries(obj).forEach(([key, value]) => {
      const alignment = !isNaN(Number(value.split(",").join(value[0])))
        ? "right"
        : "left";
      const formattedValue = typeof value === "number" ? value.toLocaleString() : value;
      transformedObj.push({ text: formattedValue, alignment });
    });
    return transformedObj;
  });
};

// Blank column if repeated words comes in column.
export const updateRowSpans = (inputData: any) => {
  let Find_Data;
  let Column_Count = 1;
  const transformedData = [...inputData]; // Make a copy to avoid modifying the original array

  for (let i = 0; i <= transformedData.length - 2; i++) {
    // Check if Find_Data is different or undefined
    if (!Find_Data || Find_Data !== transformedData[i][1].text) {
      let Repeat = 0;
      Find_Data = transformedData[i][1].text; // Update Find_Data

      for (let j = 0; j <= transformedData.length - 1; j++) {
        if (Find_Data === transformedData[j][1].text) {
          Repeat++;
        }
      }

      // Update rowSpan for the current group
      transformedData[i][0].rowSpan = Repeat; // For s.no
      transformedData[i][1].rowSpan = Repeat; // For state
    }

    if (transformedData[i][0].rowSpan) {
      transformedData[i][0].text = Column_Count;
      Column_Count++;
    } else {
      transformedData[i][0].text = "";
    }
  }

  return transformedData;
};

// Dynamic width find for report body
export const findingWidthOfReportTable2 = (reportData:any) => {
  const reportColumn = Object.keys(reportData).length;

  let totalColumn = [28];

  let i = 0;
  while (i !== reportColumn) {
    totalColumn.push(662 / reportColumn);
    i++;
  }
  return totalColumn
}

export const functionIncludingInnerLink = (tableData2: IDetailsOfJudgesCasesDetails[]) =>{
  const baseUrl = window.location.origin
  let reportTotalData:any = []
  
    tableData2.map((current:IDetailsOfJudgesCasesDetails, index: number)=>{
    reportTotalData.push([
    {text: index+1},
    {text: current.judges_name_en}, 
    {text: current.casesheared, link: `${baseUrl}/get-reports/fetched-report-data?getlinkjudgedetail&judgeId=${current.judge_id}`, color: "blue"},
    {text: current.totalcasesdisposed, link: `${baseUrl}/get-reports/fetched-report-data?getlinkjudgedisposal&judgeId=${current.judge_id}`, color: "blue"},
    {text: current.totalcasespending, link: `${baseUrl}/get-reports/fetched-report-data?getlinkjudgepending&judgeId=${current.judge_id}`, color: "blue"},
    {text: current.disposalrate}
    ])
    }) 
    return reportTotalData
}

export const functionIncludingLinkForCaseNo = (data: IOnclickedFetchedReportJudgeData[]):IOnclickedFetchedReportJudgeData[] =>{
  const baseUrl = window.location.origin
  let reportTotalData:any = []
  
  data.map((current:IOnclickedFetchedReportJudgeData, index: number)=>{
  reportTotalData.push([
  {text: index+1},
  {text: current.case_number, link: `${baseUrl}/get-reports/fetched-report-data?case-history=${current.case_number}`, color: "blue"}, 
  {text: current.complainant_name},
  {text: current.respondent_name},
  {text: current.filing_date},
  {text: current.case_category_name},
  {text: current.stage_name},
  {text: current.attachtocasenumber},
  ])
  }) 
  return reportTotalData
}