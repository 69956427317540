import React from "react";
import Plot from "react-plotly.js";
import {
  indian_number_system_converter,
  formatNumber,
  findMaxValue,
} from "../../../../utils/converter";

interface DataItem {
  noofcases: string;
  noofhearings: string;
  orders: string;
  judgementgiven: string;
  final_judgement: string
}

interface Props {
  data: DataItem[];
}

const JudgesPerformanceBarchart: React.FC<Props> = ({ data }) => {
  const traces = data.map((category) => {
    const values = [
      parseInt(category.noofcases),
      parseInt(category.noofhearings),
      parseInt(category.orders),
      parseInt(category.judgementgiven),
      parseInt(category.final_judgement),
    ];

    const formattedValues = values.map((value) =>
      value > 0 ? indian_number_system_converter(value.toString()) : ""
    );

    return {
      x: ["Cases Heard", "Hearings", "Orders", "Judgements Given", "Final Judgments"],
      y: values,
      hovertemplate: "%{x}<br>%{hovertext}<extra></extra>",
      type: "bar",
      marker: {
        color: ['#114232', '#87A922', '#FFCC29', '#F58634', '#FF4949'],
      },
      hovertext: formattedValues.map(value => `${value}`)
    };
  });

  const max = findMaxValue(
    data.flatMap((category) => [
      parseInt(category.noofcases),
      parseInt(category.noofhearings),
      parseInt(category.orders),
      parseInt(category.judgementgiven),
    ])
  );

  return (
    <Plot
      className="plotlyChart"
      data={traces as any}
      layout={{
        title: {
          text: "<b>Courtroom Activities</b>",
          font: {
            size: 18,
            family: "'Inter', sans-serif",
            color: "#4B5563",
          },
          x: 0.04,
          y: 0.94,
        },
        yaxis: {
          range: [0, max * 1.1],
          autorange: true,
          tickformat: ",.0f",
          tickvals: [0, max / 4, max / 2, (3 * max) / 4, max],
          ticktext: [
            "0",
            formatNumber(max / 4),
            formatNumber(max / 2),
            formatNumber((3 * max) / 4),
            formatNumber(max),
          ],
        },
        autosize: true,
      }}
      config={{ displaylogo: false }}
    />
  );
};

export default JudgesPerformanceBarchart;