import { AxiosResponse } from "axios";
import { deleteCall, getCall, postCall, postCallFile } from "../api";
import { CASE_FILING_SERVICE } from "../CONSTANTS";
import { IgetNoteListResponse, IsaveAndUpdateNotesRequestModel, IsaveAndUpdateNotesResponse,IgetCaseNotesResponse } from "../../models/ImportantNotes/IImportantNotes";

export class RegistryReportAndNotesService {

    public static readonly saveAndUpdateNotes =async (data:any) :Promise<IsaveAndUpdateNotesResponse> =>{
        console.log(data);
        const response :AxiosResponse<IsaveAndUpdateNotesResponse> =await postCallFile(
            `${CASE_FILING_SERVICE}/saveAndUpdateNotes`,
            data
        )
        return response?.data;
    }

    public static readonly deleteCaseNotes = async (notesId: number) => {
        const response: AxiosResponse<any> = await deleteCall(
          `${CASE_FILING_SERVICE}/deleteCaseNotes?notesId=${notesId}`,
          {}
        );
    
        return response
      }

      public static readonly getNoteList = async (caseNumberOrReferenceNumber: number|string, dateOfHearing: string, typeId: string): Promise<IgetNoteListResponse[]> => {
        const params = {
            //caseNumberOrReferenceNumber,
            ...(caseNumberOrReferenceNumber && { caseNumberOrReferenceNumber }),
            dateOfHearing,
            typeId
        }
        const response: AxiosResponse<IgetNoteListResponse[]> = await getCall(
            `${CASE_FILING_SERVICE}/getNoteList`,
            params
        );
       // console.log(response)
        return response?.data
    }


    public static readonly getCaseNotes =async (notesId: number) :Promise<IgetCaseNotesResponse> =>{
        
        const response :AxiosResponse<IgetCaseNotesResponse> =await getCall(
            `${CASE_FILING_SERVICE}/getCaseNotes?noteId=${notesId}`,
            
        )
        return response?.data;
    }


}
