import { Checkbox, DialogActions, DialogContent, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BasicBreadcrumbs, BasicButton, ConfonetCard, ConfonetCardBody, ConfonetCardHeader, Dialogbox, PreviewCard, TextArea } from "../../../../components";
import { IFinalActionPayload } from "../../../../models/cases/commissionCase/ICommissionCase";
import { ScrutinyFinalActionModel } from "../../../../models/cases/commissionCase/ScrutinyModels";
import { IDropdown } from "../../../../models/common/IDropdown";
import { COMMISSION_DASHBOARD, COMMISSION_SEARCH_REVERT_CASE_DETAILS } from "../../../../navigation/CONSTANTS";
import { RootState } from "../../../../redux";
import { MasterService } from "../../../../services/MasterService/MasterService";
import { flattenArrayToIPreview, makeDropdownOptions } from "../../../../utils/Helper";
import { CourtroomService } from "../../../../services/CaseService/CourtroomService/CourtroomService";


/** Renders revert after approval component */
const RevertAfterApprovalView = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const { clientIpAddress } = useSelector((state: RootState) => state.clientIp);
    const [finishDialogueBox, setFinishDialogueBox] = useState<boolean>(false);
    const [defectTypeResponseListOption, setDefectTypeResponseListOption] = useState<IDropdown[]>([]);

    const { filingReferenceNumber, caseNumber, complainantName, respondentName, caseFilingDate, courtRoom, caseStageName, caseTypeId } = location.state.response;

    // define the validation schema
    const validationSchema = Yup.object({
        actionTaken: Yup.number().required("Action is required"),
        defectTypeId: Yup.array()
            .of(Yup.string())
            .when("actionTaken", {
                is: (value: number) => value === 0,
                then: (schema) => schema.min(1, "Remarks are required"),
                otherwise: (schema) => schema.notRequired(),
            }),
        defectTypeText: Yup.string().max(3000, "Maximum length is 3000 characters"),
        otherDefectTypeText: Yup.string()
            .max(3000, "Maximum length is 3000 characters")
            .when("defectTypeId", {
                is: (val: string[]) => val.includes("0"),
                then: (schema) => schema.required("Other remarks are required"),
                otherwise: (schema) => schema.notRequired(),
            })
            .when("actionTaken", {
                is: (value: number) => value === 0,
                then: (schema) => schema.min(1, "Remarks are required"),
                otherwise: (schema) => schema.notRequired(),
            }),
    });

    // Get all Defect Type API Calling
    const handleGetDefectType = async () => {
        const defectTypeResponse = await MasterService.getAllDefectType();
        setDefectTypeResponseListOption(
            makeDropdownOptions(
                defectTypeResponse,
                "defectTypeNameEn",
                "defectTypeId"
            )
        );
    };

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value
        const selectedDefects = defectTypeResponseListOption.filter((defects) => selectedValues.indexOf(defects.value.toString()) >= 0);
        const defectTextListToDisplay = selectedDefects.map((defects, index) => {
            return defects.value === 0 ? "" : `${index + 1}. ${defects.label}\n`
        });
        formik.setFieldValue('defectTypeText', defectTextListToDisplay.toString().replaceAll(',', ""));
        formik.setFieldValue('defectTypeId', selectedValues);
    };

    // triggers when the form is submitted
    const handleSubmit = async () => {
        const selectedDefects = defectTypeResponseListOption.filter(
            (defects) =>
                formik.values.defectTypeId.indexOf(
                    defects.value.toString()
                ) >= 0
        );
        let defectTextListToDisplay: string = "";

        selectedDefects.forEach((defects) => {
            if (defects.value) {
                defectTextListToDisplay =
                    defectTextListToDisplay + `${defects.label}\n`;
            }
        });
        const data: IFinalActionPayload = {
            action: 0,
            commissionId: userDetailsData.commissionId as number,
            filingReferenceNumber: filingReferenceNumber,
            userId: userDetailsData.userId,
            defectTypeId: formik.values.defectTypeId.toString(),
            defectTypeText: formik.values.defectTypeId.includes("0") ? formik.values.otherDefectTypeText : "",
            caseTypeId: caseTypeId,
            clientIpAddress,
        };
        setFinishDialogueBox(false);
        const response = await CourtroomService.revertAfterApproval(data);
        if (response) {
            toast.success("Case reverted successfully.");
            navigate(COMMISSION_SEARCH_REVERT_CASE_DETAILS);
        }
    };

    const handleDailog = () => {
        setFinishDialogueBox(true);
    };

    // define the formik form for validation and form submission
    const formik = useFormik({
        initialValues: ScrutinyFinalActionModel.init(),
        validationSchema,
        onSubmit: handleDailog
    })

    useEffect(() => {
        handleGetDefectType();
    }, [])

    return (
        <>
            <section className="section margin-top-35">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <BasicBreadcrumbs
                                items={[
                                    {
                                        to: COMMISSION_DASHBOARD,
                                        label: t("Breadcrumb.Dashboard"),
                                    },
                                    {
                                        to: COMMISSION_SEARCH_REVERT_CASE_DETAILS,
                                        label: t("Breadcrumb.SearchCaseDetails"),
                                    },
                                    {
                                        to: '',
                                        label: t("Label.RevertAfterApproval"),
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="row margin-top-25 margin-bottom-5">
                            <div className="col-lg-12 margin-bottom-25">
                                <PreviewCard
                                    heading={t("Label.CaseDetails")}
                                    items={flattenArrayToIPreview({
                                        caseNumber: caseNumber,
                                        complainantName: complainantName,
                                        respondentName: respondentName,
                                        filingDate: caseFilingDate && DateTime.fromFormat(caseFilingDate, "yyyy-MM-dd").toFormat("dd-MM-yyyy"),
                                        benchNumber: courtRoom,
                                        caseStage: caseStageName
                                    })}
                                />
                            </div>
                        </div>

                        <div className="row margin-bottom-5">
                            <div className="col-lg-12">
                                <ConfonetCard>
                                    <ConfonetCardHeader>
                                        <div className="card-title card-primary-header-title float-start">{t("Label.RevertCase")}</div>
                                    </ConfonetCardHeader>

                                    <ConfonetCardBody>
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12">
                                                <FormControl className="input-form-control" fullWidth>
                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                        {t("Label.RevertRemarks") + "*"}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="defectTypeId" // Ensure ID matches Formik field name
                                                        name="defectTypeId" // Add name attribute for Formik
                                                        multiple
                                                        value={formik.values.defectTypeId}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        input={<OutlinedInput label={t("Label.RevertRemarks") + "*"} />}
                                                        renderValue={(selected) => selected.join(", ")}
                                                        error={formik.touched.defectTypeId && Boolean(formik.errors.defectTypeId)}
                                                    >
                                                        {defectTypeResponseListOption.map((item) => (
                                                            <MenuItem key={item.value.toString()} value={item.value.toString()}>
                                                                <Checkbox
                                                                    checked={formik.values.defectTypeId.indexOf(item.value.toString()) > -1}
                                                                />
                                                                <ListItemText primary={item.label} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <span className="text-danger">
                                                        {formik.touched.defectTypeId && (formik.errors.defectTypeId as string)}
                                                    </span>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-12 margin-top-10">
                                                <TextArea
                                                    id="defectTypeText" // Ensure ID matches Formik field name
                                                    name="defectTypeText" // Add name attribute for Formik
                                                    value={formik.values.defectTypeText}
                                                    multiline
                                                    maxRows={""}
                                                    onBlur={formik.handleBlur}
                                                    disabled
                                                />
                                                {formik.values.defectTypeId.includes("0") && (
                                                    <TextArea
                                                        label={t("Label.Other") + "*"}
                                                        id="otherDefectTypeText" // Ensure ID matches Formik field name
                                                        name="otherDefectTypeText" // Add name attribute for Formik
                                                        value={formik.values.otherDefectTypeText}
                                                        multiline
                                                        maxRows={""}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.otherDefectTypeText && Boolean(formik.errors.otherDefectTypeText)}
                                                        helperText={
                                                            formik.touched.otherDefectTypeText && formik.errors.otherDefectTypeText
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </ConfonetCardBody>
                                </ConfonetCard>
                            </div>
                        </div>

                        <div className="margin-top-25 text-end margin-bottom-25">
                            <BasicButton
                                className="btn-custom btn-custom-primary"
                                type="button"
                                onClick={handleDailog}
                                disabled={formik.values.defectTypeText === ""}
                                variant="contained"
                                text={t("BtnText.Submit")}
                                endIcon={<IoArrowForwardCircleOutline />}
                            />
                        </div>
                    </form>
                </div>
            </section>


            <Dialogbox
                title="Revert"
                open={finishDialogueBox}
                onClose={() => setFinishDialogueBox(false)}
            >
                <DialogContent>
                    <p>Are you sure you want to revert the case?</p>
                </DialogContent>
                <DialogActions>
                    {finishDialogueBox && (
                        <>
                            <BasicButton
                                text={t("BtnText.No")}
                                onClick={() => setFinishDialogueBox(false)}
                                variant="outlined"
                            />
                            <BasicButton
                                text={t("BtnText.Yes")}
                                onClick={handleSubmit}
                                variant="contained"
                                autoFocus
                            />
                        </>
                    )}
                </DialogActions>
            </Dialogbox>
        </>
    )
}

export default RevertAfterApprovalView;