import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { ReactNode } from 'react';

export interface IConfonetToggleButtonProps extends ToggleButtonProps {
    icon: ReactNode
}

export const ConfonetToggleButton = ({icon, ...rest}: IConfonetToggleButtonProps) => {
    return (
        <ToggleButton {...rest}>
            {icon}    
        </ToggleButton>
    )
};

