import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  BasicBreadcrumbs,
  BasicButton,
  BasicCheckbox,
  ConfonetAutocomplete,
  ConfonetCard,
  ConfonetCardBody,
  ConfonetCardHeader,
  Dialogbox,
  PreviewCard,
  Textbox,
} from "../../../../../components";
import ConfonetTextEditorTiny from "../../../../../components/controls/ConfonetTextEditorTinyMCE";
import {
  ComplainantRespondantRequestDatum,
  GenerateNoticeAdvocateStatusViewModel,
  GenerateNoticePersonalStatusViewModel,
  GenerateNoticeViewModel,
  IGetNoticeDetailsResponse,
  IGetSavedNoticeResponse,
  ISaveNoticeRequest,
} from "../../../../../models/IssueNotice/IssueNoticeModel";
import { IGetAllNoticeNumberResponse } from "../../../../../models/cases/commissionCase/CourtroomHearingFormViewModel";
import { IDropdown } from "../../../../../models/common/IDropdown";
import {
  COMMISSION_DASHBOARD,
  COMMISSION_ISSUE_PRINT_NOTICE,
  DEALING_ASSISTANT_DASHBOARD,
} from "../../../../../navigation/CONSTANTS";
import { RootState } from "../../../../../redux";
import { CommissionCaseService } from "../../../../../services/CaseService/CommissionCaseService/CommissionCaseService";
import { MasterService } from "../../../../../services/MasterService/MasterService";
import {
  dropdownValidationSchema,
  fillTextWithData,
  flattenArrayToIPreview,
  makeDropdownOptions,
} from "../../../../../utils/Helper";
import NoticeViewTemplate from "../../../../../utils/Templates/NoticeViewTemplate";
import { ROLE } from "../../../../../utils/Enum";
import { ConsumerCaseServices } from "../../../../../services/CaseService/ConsumerCaseService/ConsumerCaseServices";
import { complainantRespondantDetails } from "../../../../../utils/CaseHelper";
import { IPersonalDetailWithAdvocateDetails } from "../../../../dashboard/components/CasePreview";
import PersonalDetailsModel from "../../../../../models/forms/PersonalDetailsModel";
import { NoticePdf } from "../NoticeHelper";
import NoticePreview from "./NoticePreview";
import { CaseStatusHistoryService } from "../../../../../services/CommonService/CaseStatusHistoryService";
import { useCaseSpecificData } from "../../../../../hooks/useCaseSpecificData";

/**
 * Component for generate notice page view
 */
const GenerateAndEditNoticeView = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  const { clientIpAddress } = useSelector((state: RootState) => state.clientIp);
  const [allComplainant, setAllComplainant] = useState<boolean>(false);
  const [allRespondent, setAllRespondent] = useState<boolean>(false);
  const [noticeTypeOptions, setNoticeTypeOptions] = useState<IDropdown[]>([]);
  const [noticeTypeList, setNoticeTypeList] = useState<
    IGetAllNoticeNumberResponse[]
  >([]);
  const [messageTypeDialogVisible, setMessageTypeDialogVisible] =
    useState<boolean>(false);
  const [messageTypeDialogVisibleone, setMessageTypeDialogVisibleone] =
    useState<boolean>(false);

  const [noticeMessage, setNoticeMessage] = useState<string>("");
  const [hearingDate, setHearingDate] = useState<string>("");
  const [updateNoticeMessage, setUpdateNoticeMessage] = useState<string>("");
  const [complainantDetail, setComplainantDetail] = useState<any>()
  const [respondentDetail, setRespondentDetail] = useState<any>()
  const [complainantDetails, setComplainantDetails] = useState<IPersonalDetailWithAdvocateDetails>()
  const [respondentDetails, setRespondentDetails] = useState<PersonalDetailsModel>()
  const [additionalComplainantDetails, setAdditionalComplainantDetails] = useState<PersonalDetailsModel[]>()
  const [additionalRespondentDetails, setAdditionalRespondentDetails] = useState<PersonalDetailsModel[]>()
  const [additionComplainantPerform, setAdditionComplainantPerform] = useState<PersonalDetailsModel[]>()
  const [additionalRespondentPerfrom, setadditionalRespondentPerfrom] = useState<PersonalDetailsModel[]>()
  const noticeDetails: IGetNoticeDetailsResponse[] =
    location.state?.noticeDetails;
  const noticeEditData: IGetSavedNoticeResponse[] =
    location.state?.generateEditData;
  const readOnly: boolean = location.state?.readOnly;

  const { complainantLabel, respondentLabel } = useCaseSpecificData({
    caseTypeId: noticeDetails[0].caseTypeId,
    handleNext: () => { },
    setNewFilingReferenceNumber: () => { }
  })

  const generateNoticeValidationSchema = Yup.object({
    noticeType: dropdownValidationSchema("Notice Type is required"),
    authorisedSignatory: Yup.string().required(
      "Authorized Signature is required"
    ),
    notice: Yup.string().required("Notice Details are required"),
    complainantStatuses: Yup.array().of(
      Yup.object({
        name: Yup.string(),
        status: Yup.boolean(),
        sequenceNumber: Yup.number(),
        advocate: Yup.object({
          name: Yup.string(),
          status: Yup.boolean(),
        }),
      })
    ),
    respondentStatuses: Yup.array()
      .of(
        Yup.object({
          name: Yup.string(),
          status: Yup.boolean(),
          sequenceNumber: Yup.number(),
          advocate: Yup.object({
            name: Yup.string(),
            status: Yup.boolean(),
          }),
        })
      )
      .test(
        "is-at-least-one-true",
        "At least one party must be selected",
        (_, context) => {
          const condition =
            context.parent.complainantStatuses.some(
              (value: GenerateNoticePersonalStatusViewModel) =>
                value.status || value.advocate?.status
            ) ||
            context.parent.respondentStatuses.some(
              (value: GenerateNoticePersonalStatusViewModel) =>
                value.status || value.advocate?.status
            );
          return condition;
        }
      ),
    isNoticeFinalized: Yup.boolean(),
  });

  const handleMessageType = async (data: GenerateNoticeViewModel) => {
    if (data.isNoticeFinalized) {
      setMessageTypeDialogVisible(true);
    } else {
      handleSubmit(data, 0);
    }
  };


  const formik = useFormik({
    initialValues: GenerateNoticeViewModel.init(),
    validationSchema: generateNoticeValidationSchema,
    onSubmit: handleMessageType,
  });

  const { setFieldValue, setFieldTouched } = formik;

  const handleGetNoticeType = async () => {
    const caseTypeId: number = noticeDetails[0].caseTypeId;
    const response = await MasterService.getAllNoticeType(caseTypeId);
    if (response) {
      setNoticeTypeList(response);
      setNoticeTypeOptions(
        makeDropdownOptions(response, "noticeTypeNameEn", "noticeTypeId")
      );
    }
  };

  const handleChangeNoticeType = (
    e: SyntheticEvent<Element, Event>,
    value: IDropdown | null
  ) => {
    setFieldValue("noticeType", value ?? { label: "", value: "" });
    const selectedValues = noticeTypeList.find(
      (noticeType: IGetAllNoticeNumberResponse) =>
        noticeType.noticeTypeId === value?.value
    );
    if (selectedValues) {
      setFieldValue(
        "authorisedSignatory",
        selectedValues.authorisedSignatory
      );

      const noticeTemplate = (
        <NoticeViewTemplate
          data={formik.values}
          caseNumber={location.state.caseNumber}
          authorisedSignatory={selectedValues.authorisedSignatory}
          notice={fillTextWithData(selectedValues.noticeTypeFormat)}
          userDetailsData={userDetailsData}
        />
      );
      setFieldValue(
        "notice",
        ReactDOMServer.renderToString(noticeTemplate)
      );
    }
    setFieldTouched("noticeType", false);
    setFieldTouched("authorisedSignatory", false);
    setFieldTouched("notice", false);
  };

  const handleSetDraftDetails = useCallback((
    complainantStatuses: GenerateNoticePersonalStatusViewModel[],
    respondentStatuses: GenerateNoticePersonalStatusViewModel[]
  ) => {
    const noticeType = noticeTypeList.find(
      (option: IGetAllNoticeNumberResponse) =>
        option.noticeTypeId === noticeEditData[0].noticeTypeId
    );
    if (noticeType) {
      setFieldValue("noticeType", {
        label: noticeType.noticeTypeNameEn,
        value: noticeType.noticeTypeId,
      });
      setFieldValue(
        "authorisedSignatory",
        noticeType.authorisedSignatory
      );
    }
    setFieldValue("notice", noticeEditData[0].noticeTypeText);
    setFieldValue(
      "isNoticeFinalized",
      noticeEditData[0].noticeFinalisedStatus === 2 ? true : false
    );
    noticeEditData.forEach((value: IGetSavedNoticeResponse) => {
      if (value.complainantRespondantTypeId === 1) {
        complainantStatuses[value.complainantRespondantSequenceNumber].status =
          Boolean(value.noticeServedToPartyStatus);
        if (
          complainantStatuses[value.complainantRespondantSequenceNumber]
            .advocate
        ) {
          complainantStatuses[
            value.complainantRespondantSequenceNumber
          ].advocate!.status = Boolean(value.noticeServedToAdvocateStatus);
        }
      } else {
        respondentStatuses[value.complainantRespondantSequenceNumber].status =
          Boolean(value?.noticeServedToPartyStatus);

        if (
          respondentStatuses[value?.complainantRespondantSequenceNumber]?.advocate
        ) {
          respondentStatuses[
            value?.complainantRespondantSequenceNumber
          ].advocate!.status = Boolean(value.noticeServedToAdvocateStatus);
        }
      }
    });


    return { complainantStatuses, respondentStatuses };
  }, [noticeEditData, noticeTypeList, setFieldValue]);

  const handleSetStatusDetails = useCallback(() => {
    let complainantStatuses: GenerateNoticePersonalStatusViewModel[] = [];
    let respondentStatuses: GenerateNoticePersonalStatusViewModel[] = [];
    noticeDetails.forEach((details: IGetNoticeDetailsResponse) => {
      if (details.complainantName && (details.complainantRespondentTypeId === 1 || details?.complainantRespondentTypeId === 3)) {
        complainantStatuses[details.sequenceNumber && details?.complainantRespondentTypeId] =

          new GenerateNoticePersonalStatusViewModel(
            details.complainantName,
            false,
            details.sequenceNumber,
            details.partyId,
            details.complainantRespondentTypeId,
          );
        if (details.advocateNameOfComplainant) {
          complainantStatuses[details.sequenceNumber].advocate =
            new GenerateNoticeAdvocateStatusViewModel(
              details.advocateNameOfComplainant,
              false
            );
        }
      }

      if (details.complainantName && (details.complainantRespondentTypeId === 2 || details?.complainantRespondentTypeId === 4)) {
        respondentStatuses[details.sequenceNumber && details?.complainantRespondentTypeId] =
          new GenerateNoticePersonalStatusViewModel(
            details.complainantName,
            false,
            details.sequenceNumber,
            details.partyId,
            details.complainantRespondentTypeId,
          );
        if (details.advocateNameOfComplainant) {
          respondentStatuses[details.sequenceNumber].advocate =
            new GenerateNoticeAdvocateStatusViewModel(
              details.advocateNameOfComplainant,
              false
            );
        }
      }
    });

    if (noticeEditData?.length > 0) {
      const draftStatuses = handleSetDraftDetails(
        complainantStatuses,
        respondentStatuses
      );
      complainantStatuses = draftStatuses.complainantStatuses;
      respondentStatuses = draftStatuses.respondentStatuses;


    };
    setFieldValue(
      "complainantStatuses",
      complainantStatuses.filter((value) => value)
    );
    setFieldValue(
      "respondentStatuses",
      respondentStatuses.filter((value) => value)
    );
  }, [handleSetDraftDetails, noticeDetails, noticeEditData?.length, setFieldValue]);

  const handleEditorChange = (value: string) => {
    setFieldValue("notice", value);
  };

  const handleAllComplainantChange = (
    _: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setAllComplainant(checked);
    formik.values.complainantStatuses.forEach(
      (status: GenerateNoticePersonalStatusViewModel, index: number) => {
        setFieldValue(`complainantStatuses[${index}].status`, checked);
        if (status.advocate) {
          setFieldValue(
            `complainantStatuses[${index}].advocate.status`,
            checked
          );
        }
      }
    );
  };


  const handleAllRespondentChange = (
    _: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setAllRespondent(checked);
    formik.values.respondentStatuses.forEach(
      (status: GenerateNoticePersonalStatusViewModel, index: number) => {
        setFieldValue(`respondentStatuses[${index}].status`, checked);
        if (status.advocate) {
          setFieldValue(
            `respondentStatuses[${index}].advocate.status`,
            checked
          );
        }
      }
    );
  };

  const handleAnyComplainantChange = (e: SyntheticEvent<Element, Event>) => {
    formik.handleChange(e);
    setAllComplainant(false);
  };

  const handleAnyRespondentChange = (e: SyntheticEvent<Element, Event>) => {
    formik.handleChange(e);
    setAllRespondent(false);
  };

  const handleSubmit = async (
    data: GenerateNoticeViewModel,
    messageSourceTypeId?: number
  ) => {
    const complainantRespondantRequestData: ComplainantRespondantRequestDatum[] =
      [
        ...data.complainantStatuses.map(
          (
            personalStatus: GenerateNoticePersonalStatusViewModel
          ): ComplainantRespondantRequestDatum => {
            return {
              complainantRespondantTypeId: 1,
              partyId: personalStatus.partyId,
              complainantRespondantSequenceNumber:
                personalStatus.sequenceNumber,
              noticeServedToPartyStatus: +personalStatus.status,
              noticeServedToAdvocateStatus: +(
                personalStatus.advocate?.status ?? 0
              ),
            };
          }
        ),
        ...data.respondentStatuses.map(
          (
            personalStatus: GenerateNoticePersonalStatusViewModel
          ): ComplainantRespondantRequestDatum => {
            return {
              complainantRespondantTypeId: 2,
              partyId: personalStatus.partyId,
              complainantRespondantSequenceNumber:
                personalStatus.sequenceNumber,
              noticeServedToPartyStatus: +personalStatus.status,
              noticeServedToAdvocateStatus: +(
                personalStatus.advocate?.status ?? 0
              ),
            };
          }
        ),
      ].filter(
        (val: ComplainantRespondantRequestDatum) =>
          val.noticeServedToAdvocateStatus || val.noticeServedToPartyStatus
      );

    let requestData: ISaveNoticeRequest = {
      fillingReferenceNumber: noticeDetails[0].filingReferenceNumber,
      responseTypeId: 0,
      isNoticeFinalized: formik.values.isNoticeFinalized ? 2 : 1,
      noticeSignedStatus: 1,
      noticeTypeId: data.noticeType.value,
      noticeTypeText: data.notice,
      userId: userDetailsData.userId,
      activeStatus: 1,
      complainantRespondantRequestData,
      messageSourceTypeId,
      clientIpAddress
    };

    if (messageSourceTypeId === 3) {

      NoticePdf(
        userDetailsData.userName,
        userDetailsData.mobileNumber,
        userDetailsData.emailId,
        userDetailsData.landmarkLocality,
        userDetailsData.postalPinCode,
        location.state.caseNumber,
        data.complainantStatuses[0].name,
        data.complainantStatuses[0].advocate?.name,
        data.respondentStatuses[0].name,
        data.respondentStatuses[0].advocate?.name,
        updateNoticeMessage,
        data.authorisedSignatory,
        formik.values.complainantStatuses,
        formik.values.respondentStatuses,
      );

    }

    const state = {
      noticeDetails,
      caseNumber: location.state?.caseNumber,
    };

    if (noticeEditData) {
      requestData = {
        ...requestData,
        noticeId: noticeEditData[0].noticeId
      };
      const response = await CommissionCaseService.updateCaseNotices(
        requestData
      );
      if (response?.length > 0) {
        toast.success("Notice updated successfully");
        navigate(COMMISSION_ISSUE_PRINT_NOTICE, { state });
      }
    } else {
      const response = await CommissionCaseService.saveCaseNotices(requestData);
      if (response?.length > 0) {
        toast.success("Notice saved successfully");
        navigate(COMMISSION_ISSUE_PRINT_NOTICE, { state });
      }
    }
  };

  useEffect(() => {
    handleGetNoticeType();
  }, []);

  useEffect(() => {
    if (noticeTypeOptions.length > 0) {
      handleSetStatusDetails();
    }
  }, [handleSetStatusDetails, noticeTypeList, noticeTypeOptions.length]);

  // fetch all the details of compainant and respondnat

  const getAllComplainantRespondent = async () => {
    const response = await ConsumerCaseServices.getComplainantRespondentDetail(noticeDetails[0].filingReferenceNumber);
    if (response) {
      const mainComplainantResponse = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 1 && item.complainantRespondant.complainantRespondentSequenceNumber === 0);
      const mainRespondantResponse = response.filter((item) => item.complainantRespondant.complainantRespondentTypeId === 2 && item.complainantRespondant.complainantRespondentSequenceNumber === 0);
      setComplainantDetail(mainComplainantResponse[0].complainantRespondant)
      setRespondentDetail(mainRespondantResponse[0].complainantRespondant)
      const { complainant, respondent, additionalComplainant, additionalRespondent, additionComplainantPerforma, additionalRespondentPerfroma } = await complainantRespondantDetails(response);
      setRespondentDetails(respondent);
      setComplainantDetails({ ...complainant });
      setAdditionalComplainantDetails(additionalComplainant);
      setAdditionalRespondentDetails(additionalRespondent);
      setAdditionComplainantPerform(additionComplainantPerforma);
      setadditionalRespondentPerfrom(additionalRespondentPerfroma)
    }
  }

  useEffect(() => {
    if (noticeDetails[0].filingReferenceNumber) {
      getAllComplainantRespondent();

    }
  }, [noticeDetails[0].filingReferenceNumber]);


  useEffect(() => {
    const complainantStatus = (
      <tr className="complainantStatus">
        <td style={{ borderWidth: "1px" }} colSpan={2}>
          <ol style={{ margin: 0, padding: "2%" }}>
            {formik.values.complainantStatuses.map(
              (status: GenerateNoticePersonalStatusViewModel) => {
                return status.status && <li>{status.name}</li>;
              }
            )}
          </ol>
        </td>
      </tr>
    );

    const complainantAdvocateStatus = (
      <tr className="complainantAdvocateStatus">
        <td colSpan={2}>
          <ol className="generate-notice" >
            {formik.values.complainantStatuses.map(
              (status: GenerateNoticePersonalStatusViewModel) => {

                return (

                  status.advocate?.status && <li>{status.advocate.name}</li>
                );
              }
            )}
          </ol>
        </td>
      </tr>
    );

    const respondentStatus = (
      <tr className="respondentStatus">
        <td colSpan={2}>
          <ol className="generate-notice" >
            {formik.values.respondentStatuses.map(
              (status: GenerateNoticePersonalStatusViewModel) => {
                return status.status && <li>{status.name}</li>;
              }
            )}
          </ol>
        </td>
      </tr>
    );


    const respondentAdvocateStatus = (
      <tr className="respondentAdvocateStatus">
        <td colSpan={2}>
          <ol className="generate-notice" >
            {formik.values.respondentStatuses.map(
              (status: GenerateNoticePersonalStatusViewModel) => {
                return (
                  status.advocate?.status && <li>{status.advocate.name}</li>
                );
              }
            )}
          </ol>
        </td>
      </tr>
    );

    // const updatedNotice = formik.values.notice
    //   .replace(
    //     /(<tr[^<]*class="complainantStatus"[^<]*>((?!<tr).)*<\/tr>)/,
    //     ReactDOMServer.renderToString(complainantStatus)
    //   )
    //   .replace(
    //     /(<tr[^<]*class="complainantAdvocateStatus"[^<]*>((?!<tr).)*<\/tr>)/,
    //     ReactDOMServer.renderToString(complainantAdvocateStatus)
    //   )
    //   .replace(
    //     /(<tr[^<]*class="respondentStatus"[^<]*>((?!<tr).)*<\/tr>)/,
    //     ReactDOMServer.renderToString(respondentStatus)
    //   )
    //   .replace(
    //     /(<tr[^<]*class="respondentAdvocateStatus"[^<]*>((?!<tr).)*<\/tr>)/,
    //     ReactDOMServer.renderToString(respondentAdvocateStatus)
    //   );
    // setFieldValue("notice", updatedNotice);
    // setFieldTouched("notice", false);
    const parser = new DOMParser();
    const doc = parser.parseFromString(formik.values.notice, 'text/html');

    // Replace complainantStatus row
    const complainantStatusElement = doc.querySelector('tr.complainantStatus');
    if (complainantStatusElement) {
      complainantStatusElement.outerHTML = ReactDOMServer.renderToString(complainantStatus);
    }

    // Replace complainantAdvocateStatus row
    const complainantAdvocateStatusElement = doc.querySelector('tr.complainantAdvocateStatus');
    if (complainantAdvocateStatusElement) {
      complainantAdvocateStatusElement.outerHTML = ReactDOMServer.renderToString(complainantAdvocateStatus);
    }

    // Replace respondentStatus row
    const respondentStatusElement = doc.querySelector('tr.respondentStatus');
    if (respondentStatusElement) {
      respondentStatusElement.outerHTML = ReactDOMServer.renderToString(respondentStatus);
    }

    // Replace respondentAdvocateStatus row
    const respondentAdvocateStatusElement = doc.querySelector('tr.respondentAdvocateStatus');
    if (respondentAdvocateStatusElement) {
      respondentAdvocateStatusElement.outerHTML = ReactDOMServer.renderToString(respondentAdvocateStatus);
    }

    // Serialize the updated DOM back to a string
    const updatedNotice = doc.body.innerHTML;

    // Update Formik
    setFieldValue('notice', updatedNotice);
    setFieldTouched('notice', false);

  }, [formik.values.complainantStatuses, formik.values.notice, formik.values.respondentStatuses, setFieldTouched, setFieldValue]);


  const getNoticeType = async () => {
    const caseTypeId: number = noticeDetails[0].caseTypeId;
    const response = await MasterService.getAllNoticeType(caseTypeId);
    const filteredFormats = response
      .filter(item => item.noticeTypeId === formik.values.noticeType.value)
      .map(item => item.noticeTypeFormat);
    setNoticeMessage(filteredFormats[0])

  }
  useEffect(() => {
    getNoticeType()
  }, [formik.values.noticeType])

  const addEmail = () => {
    setMessageTypeDialogVisibleone(!messageTypeDialogVisibleone)

  }
  // Utility function to replace placeholders with the current date
  function replacePlaceholdersWithCurrentDate(notice: string): string {
    // Get the current date in the desired format (e.g., '17th September 2024')
    const currentDate = DateTime.now();
    // Replace both placeholders with the current date
    return notice
      .replace(/<<\*\*DTOFNEXTHEARING\*\*>>/g, DateTime.fromISO(hearingDate).toFormat('dd-MM-yyyy')) // Replace <<**DTOFNEXTHEARING**>> with current date
      .replace(/<<\*\*DTTODAY\*\*>>/g, currentDate.toFormat('dd-MM-yyyy'))
      .replace(/<br\s*\/?>/gi, '\n');
    // Replace <<**DTTODAY**>> with current date

  }

  useEffect(() => {
    const updatedd = replacePlaceholdersWithCurrentDate(noticeMessage || "")
    setUpdateNoticeMessage(updatedd)
  }, [noticeMessage])

  const getCaseDetails = async () => {
    const caseStatusResponse = await CaseStatusHistoryService.getCaseStausDetails(noticeDetails[0].caseNumber);
    const casereponse = caseStatusResponse.caseHearingDetails.map(item => item.dateOfHearing).filter(item => item !== null)
    setHearingDate(casereponse[0])

  }

  useEffect(() => {
    getCaseDetails();
  }, [])

  return (
    <>
      <Dialogbox
        title="Edit Details"
        open={messageTypeDialogVisibleone}
        onClose={() => {
          setMessageTypeDialogVisibleone(false)
          getAllComplainantRespondent()
        }}
        maxWidth="lg"
      >
        <DialogContent dividers>

          <NoticePreview
            filingReferenceNumber={parseInt(location.state.noticeDetails[0].filingReferenceNumber)}
          />

        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialogbox>
      <Dialogbox
        title="Send Finalized Notice"
        open={messageTypeDialogVisible}
        onClose={() => {
          setMessageTypeDialogVisible(false)


        }}
        maxWidth="md"
      >
        <DialogContent dividers>

          <div className="row">
            <div className="col-lg-12">
              <p className="highlight-text"> Please choose how to send the notices</p>
            </div>
            <div className="col-lg-6">
              <p className="mb-2">{"Complainant Email"}</p>
              <p className="highlight-text">{complainantDetails && complainantDetails.email ? complainantDetails.email : "N/A"}</p>
              <p className="highlight-text">{`Address : ${complainantDetails?.address[0]?.houseNumber} ${complainantDetails?.address[0]?.landmark} ${complainantDetails?.address[0].postOffice.label} ${complainantDetails?.address[0].district.label} ${complainantDetails?.address[0].state.label} ${complainantDetails?.address[0].pinCode},`}</p>
              {
                complainantDetails?.email == "" ? (
                  <>
                    <BasicButton
                      text="Add Details"
                      variant="contained"
                      size="small"
                      onClick={() => addEmail()}
                    />
                  </>
                ) : ""

              }

            </div>
            <div className="col-lg-6">
              <p className="mb-2">{"Respondant Email"}</p>
              <p className="highlight-text">{respondentDetails && respondentDetails.email ? respondentDetails.email : "N/A"}</p>
              <p className="highlight-text">{`Address : ${respondentDetails?.address[0]?.houseNumber} ${respondentDetails?.address[0]?.landmark} ${respondentDetails?.address[0].postOffice.label} ${respondentDetails?.address[0].district.label} ${respondentDetails?.address[0].state.label} ${respondentDetails?.address[0].pinCode},`}</p>
              {
                respondentDetails?.email == "" ? (
                  <>
                    <BasicButton
                      text="Add Details"
                      variant="contained"
                      size="small"
                      onClick={() => addEmail()}
                    />
                  </>
                ) : ""

              }
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <BasicButton
            text={t("Label.SendByDasti")}
            variant="contained"
            size="small"
            onClick={() => handleSubmit(formik.values, 3)}
          />
          <BasicButton
            text={t("Label.SendBySpeedPost")}
            variant="contained"
            size="small"
            onClick={() => handleSubmit(formik.values, 3)}
          />
          {
            (respondentDetails?.email || complainantDetails?.email) &&
            <BasicButton
              text={t("Label.SendByEmail")}
              variant="contained"
              size="small"
              onClick={() => handleSubmit(formik.values, 1)}
            />
          }

          <BasicButton
            text={t("Label.SendByAll")}
            variant="contained"
            size="small"
            onClick={() => { }}
          />
        </DialogActions>
      </Dialogbox>
      <section className="section margin-top-35">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <BasicBreadcrumbs
                items={[
                  {
                    to: userDetailsData.roleId !== ROLE.DealingAssistant ? COMMISSION_DASHBOARD : DEALING_ASSISTANT_DASHBOARD,
                    label: t("Breadcrumb.Dashboard"),
                  },
                  { to: "", label: t("Sidebar.IssuePrintNotice") },
                ]}
              />
            </div>
          </div>
          <div className="row margin-top-25">
            <div className="col-lg-12 margin-bottom-25">
              <PreviewCard
                heading={t("Label.CaseDetails")}
                items={flattenArrayToIPreview({
                  caseNumber: location.state?.caseNumber,
                  filingReferenceNumber:
                    noticeDetails[0].filingReferenceNumber,
                  [complainantLabel]: noticeDetails[0].complainantName,
                  [respondentLabel]: noticeDetails[1].complainantName,
                  dateOfFiling: DateTime.fromFormat(
                    noticeDetails[0].caseFilingDate,
                    "yyyy-MM-dd"
                  ).toFormat("dd-MM-yyyy"),
                })}
              />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-12 margin-bottom-25">
                <ConfonetCard>
                  <ConfonetCardHeader>
                    <div className="card-title card-primary-header-title">
                      {t("Label.NoticeToBeIssued")}
                    </div>
                  </ConfonetCardHeader>

                  <ConfonetCardBody>
                    <div className="row margin-top-10">
                      <div className="col-md-5">
                        <Typography
                          className="margin-bottom-10"
                          variant="body2"
                        >
                          {complainantLabel}
                        </Typography>
                      </div>
                      <div className="col-md-5">
                        <Typography
                          className="margin-bottom-10"
                          variant="body2"
                        >
                          {complainantLabel + " " + t("Label.Advocate")}
                        </Typography>
                      </div>
                      <div className="col-md-2">
                        <BasicCheckbox
                          control={<Checkbox />}
                          id="complainant"
                          name="complainant"
                          label="All"
                          checked={allComplainant}
                          onChange={handleAllComplainantChange}
                          disabled={readOnly}
                        />
                      </div>
                    </div>
                    {formik.values.complainantStatuses && formik.values.complainantStatuses?.map(
                      (
                        complainantStatus: GenerateNoticePersonalStatusViewModel,
                        index: number
                      ) => {
                        return (
                          <div
                            className="row"
                            key={complainantStatus.sequenceNumber}
                          >
                            <div className="col-md-5 margin-bottom-10">
                              <BasicCheckbox
                                control={<Checkbox />}
                                id="complainant"
                                name={`complainantStatuses.[${index}].status`}
                                label={complainantStatus.name}
                                checked={complainantStatus.status}
                                onChange={handleAnyComplainantChange}
                                onBlur={formik.handleBlur}
                                disabled={readOnly}
                              />
                            </div>
                            {complainantStatus.advocate && (
                              <div className="col-md-5 margin-bottom-10">
                                <BasicCheckbox
                                  control={<Checkbox />}
                                  id="complainantadvocate1"
                                  name={`complainantStatuses.[${index}].advocate.status`}
                                  label={complainantStatus.advocate.name}
                                  checked={complainantStatus.advocate.status}
                                  onChange={handleAnyComplainantChange}
                                  onBlur={formik.handleBlur}
                                  disabled={readOnly}
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}

                    <div className="row margin-top-10">
                      <div className="col-md-5">
                        <Typography
                          className="margin-bottom-10"
                          variant="body2"
                        >
                          {respondentLabel}
                        </Typography>
                      </div>
                      <div className="col-md-5">
                        <Typography
                          className="margin-bottom-10"
                          variant="body2"
                        >
                          {respondentLabel + " " + t("Label.Advocate")}
                        </Typography>
                      </div>
                      <div className="col-md-2">
                        <BasicCheckbox
                          control={<Checkbox />}
                          id="respondent"
                          name="respondent"
                          label="All"
                          checked={allRespondent}
                          onChange={handleAllRespondentChange}
                          disabled={readOnly}
                        />
                      </div>
                    </div>
                    {formik.values.respondentStatuses && formik.values.respondentStatuses?.map(
                      (
                        respondentStatus: GenerateNoticePersonalStatusViewModel,
                        index: number
                      ) => {
                        return (
                          <div
                            className="row"
                            key={respondentStatus.sequenceNumber}
                          >
                            <div className="col-md-5 margin-bottom-10">
                              <BasicCheckbox
                                control={<Checkbox />}
                                id="respondent"
                                name={`respondentStatuses.[${index}].status`}
                                label={respondentStatus.name}
                                checked={respondentStatus.status}
                                onChange={handleAnyRespondentChange}
                                onBlur={formik.handleBlur}
                                disabled={readOnly}
                              />
                            </div>
                            {respondentStatus.advocate && (
                              <div className="col-md-5 margin-bottom-10">
                                <BasicCheckbox
                                  control={<Checkbox />}
                                  id="respondentadvocate1"
                                  name={`respondentStatuses.[${index}].advocate.status`}
                                  label={respondentStatus.advocate.name}
                                  checked={respondentStatus.advocate.status}
                                  onChange={handleAnyRespondentChange}
                                  onBlur={formik.handleBlur}
                                  disabled={readOnly}
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                    {(formik.touched.complainantStatuses ||
                      formik.touched.respondentStatuses) &&
                      formik.errors.respondentStatuses && (
                        <span className="text-danger">
                          {formik.errors.respondentStatuses as string}
                        </span>
                      )}
                  </ConfonetCardBody>
                </ConfonetCard>

              </div>

              <div className="col-lg-12 margin-bottom-25">
                <ConfonetCard>
                  <ConfonetCardHeader>
                    <div className="card-title card-primary-header-title">
                      {t("Label.GenerateNotice")}
                    </div>
                  </ConfonetCardHeader>

                  <ConfonetCardBody>
                    <div className="row margin-top-10">
                      <div className="col-lg-4 col-sm-4">
                        <ConfonetAutocomplete
                          id="noticeType"
                          options={noticeTypeOptions}
                          value={formik.values.noticeType}
                          renderInput={(params) => (
                            <Textbox
                              name="noticeType"
                              label={t("Label.NoticeType") + "*"}
                              error={
                                formik.touched.noticeType &&
                                Boolean(formik.errors.noticeType)
                              }
                              helperText={
                                formik.touched.noticeType &&
                                formik.errors.noticeType?.label
                              }
                              {...params}
                              disabled={readOnly}
                            // shrink={readOnly}
                            />
                          )}
                          onChange={handleChangeNoticeType}
                          onBlur={formik.handleBlur}
                          disabled={readOnly}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <Textbox
                          id="authorisedSignatory"
                          name="authorisedSignatory"
                          label={t("Label.AuthorizedSignatory") + "*"}
                          value={formik.values.authorisedSignatory}
                          error={
                            formik.touched.authorisedSignatory &&
                            Boolean(formik.errors.authorisedSignatory)
                          }
                          helperText={
                            formik.touched.authorisedSignatory &&
                            formik.errors.authorisedSignatory
                          }
                          disabled
                          shrink
                        />
                      </div>

                      <div className="col-lg-12 col-sm-12 child-element-margin-bottom-0">
                        <ConfonetTextEditorTiny

                          id="notice"
                          value={formik.values.notice}
                          onEditorChange={handleEditorChange}
                          height={800}
                          disabled={readOnly}
                        />
                        <div className="input-form-control-help-text margin-top-10">
                          <InfoIcon
                            className="float-start margin-right-5"
                            fontSize="small"
                          />{" "}
                          {t(
                            "Label.PleaseEnsureThatOnceSpaceCharacterIsInsertedBeforeAndAfterTheAboveFieldInTheEditor"
                          )}
                          .
                        </div>
                      </div>
                    </div>
                  </ConfonetCardBody>
                </ConfonetCard>
                <div className="row margin-top-25">
                  <div className="col-md-4 margin-bottom-10">
                    <BasicCheckbox
                      control={<Checkbox />}
                      id="complainant"
                      name={`isNoticeFinalized`}
                      label={t("Label.IsNoticeFinalized")}
                      checked={formik.values.isNoticeFinalized}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={readOnly}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-end margin-bottom-25">
                {readOnly ? (
                  <BasicButton
                    variant="contained"
                    text={t("BtnText.Continue")}
                    onClick={() =>
                      navigate(COMMISSION_ISSUE_PRINT_NOTICE, {
                        state: {
                          noticeDetails,
                          caseNumber: location.state?.caseNumber,
                        },
                      })
                    }
                  />
                ) : (
                  <BasicButton
                    type="submit"
                    variant="contained"
                    text={
                      noticeEditData ? t("BtnText.Update") : t("BtnText.Submit")
                    }
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default GenerateAndEditNoticeView;
